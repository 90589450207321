import * as React from 'react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import AlertDialog from '../common/alertDialog';
import DataTable from '../common/DataTable/DataTable';
import global from '../../classes/global';
import TextBoxInput from '../common/reusableComponents/TextBox';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import Spinner from '../common/reusableComponents/Spinner';

let _items: any = [];

const inspectionFailedItems = [
    { id: true, text: 'Yes' },
    { id: false, text: 'No' }
];

export default class PlantsInspection extends React.Component<any, any> {


    public currentUser: any;
    public columns: any;

    constructor(props: any) {
        super(props);

        this._getItemData = this._getItemData.bind(this);


        this.currentUser = this.props.currentUser;


        this.columns = [

            {
                field: 'inspectionFailed',
                title: 'Inspection failed',
                width: 110,
                type: 'string',
                allowHiding: false,
                link: 'toggle'
            },
            {
                field: 'id',
                title: 'ID',
                // width: 160,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'resourceCode',
                title: 'Resource Code',
                // width: 280,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'title',
                title: 'Title',
                // width: 160,
                type: 'string',
            },
            {
                field: 'category',
                title: 'Category',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'regonumber',
                title: 'Rego Number',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'department',
                title: 'Organisation',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'prestartformName',
                title: 'Prestart Form',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'active',
                title: 'Is Active',
                // width: 160,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'updatedOn',
                title: 'Updated On',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true,
                sortOrder: 'desc'
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created On',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
        ];
        let showHideColumns = localStorage.getItem("plantFailColumns");
        let tempColumns;
        if (showHideColumns !== null) {
            tempColumns = JSON.parse(showHideColumns);
        } else {
            tempColumns = this.columns;
            localStorage.setItem("plantFailColumns", JSON.stringify(this.columns));
        }
        this.state = {
            items: _items,
            columns: tempColumns,
            showSearchDiv: false,
            tableHeight: 665,
            inspectionFailedItems: inspectionFailedItems,
            allinspectionFailedItems: inspectionFailedItems,
            department: (this.props.role === global.worxOnline_ContractorAdmin || this.props.role === global.worxOnline_WorxAdmin) ? this.props.Department : '',

        };
    }

    public componentDidMount() {
        this._getDepartmentData();
        this._getPlantCategory();

    }
    private _getDepartmentData() {

        utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {
            // console.log(data);
            let departmentItems = [];

            for (let r of data) {
                departmentItems.push({ id: r.Id, text: r.Title });
            }
            departmentItems = utility._sortItems(departmentItems, 'text', false);
            this.setState({ departmentItems: departmentItems, alldepartmentItems: departmentItems });
        }, (err) => {
            console.log(err);
        });
    }
    public _getPlantCategory() {
        utility.genericGetAPICall(Config.PlantCategoryApi).then((data: any) => {
            //console.log(data);
            let categoryItems = [];
            for (let r of data) {
                if (r) {
                    categoryItems.push({ id: r.Id, text: r.Title });
                }
            }
            this.setState({ categoryItems: categoryItems, allcategoryItems: categoryItems });

        }, (err) => {
            console.log(err);
        });
    }
    public clearFilter() {
        this.setState({
            items: '',
            title: '',
            category: '',
            department: '',
            inspectionfailed: '',
        });
    }

    private _getItemData() {
        let criteriaSelected = false;
        let dataToPassToService: any = {};
        if (this.state.title) {
            dataToPassToService.Title = this.state.title;
            criteriaSelected = true;
        }
        if (this.state.category) {
            dataToPassToService.Category = this.state.category.id;
            criteriaSelected = true;
        }
        if (this.state.department) {
            dataToPassToService.DepartmentId = this.state.department.id;
            criteriaSelected = true;
        }
        if (this.state.inspectionfailed) {
            dataToPassToService.InspectionFailed = this.state.inspectionfailed.id;
            criteriaSelected = true;
        }
        if (!criteriaSelected) {
            this.setState({
                alertMessage: 'Please select search criteria',
                showAlert: true
            });
            return;
        }

        this.setState({
            loading: true
        });

        utility.genericPostAPIcall(Config.SearchPlantApi, dataToPassToService).then((data: any) => {
            _items = [];
            if (data.length === null) {
                if (_items.length <= 0) {
                    this.setState({
                        alertMessage: 'No results found for this criteria',
                        showAlert: true
                    });
                }
                this.setState({ items: _items, loading: false });
                return;
            }
            else {
                for (let r of data) {
                    _items.push({
                        id: r.Id,
                        title: r.Title,
                        resourceCode: r.Resourcecode,
                        category: r.Category,
                        regonumber: r.Regonumber,
                        department: r.Department,
                        departmentId: r.Departmentid,
                        prestartformid: r.Prestartformid,
                        prestartformName: r.Prestartformtitle,
                        inspectionFailed: r.Inspectionfailed ? true : false,
                        active: r.Isactive,
                        Selected: false,
                        updatedBy: r.Userupdated,
                        updatedOn: r.Dateupdated ? moment(r.Dateupdated).format("DD/MM/YY h:mm a") : null,
                        // updatedOn: r.Dateupdated ? new Date(r.Dateupdated) : null,
                        createdBy: r.Usercreated,
                        createdOn: r.Datecreated ? moment(r.Datecreated).format("DD/MM/YY h:mm a") : null,

                    });
                }
            }
            // _items = utility._sortItems(_items, 'updatedOn', true);

            this.setState({ items: _items, callAfterInit: true, loading: false, preItems: _items });

            //console.log(_items);
        }, (err) => {
            console.log(err);
            this.setState({ loading: false, callAfterInit: true, });
        });
    }

    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    public _changeInspectiponFailed(event: any, item: any) {

        let dataToPassToService: any = {};
        dataToPassToService.ID = item.id;
        dataToPassToService.Title = item.title;
        dataToPassToService.Resourcecode = item.rCode;
        dataToPassToService.Category = item.category;
        dataToPassToService.Regonumber = item.regonumber;
        dataToPassToService.Isactive = item.active;
        dataToPassToService.Tenantid = Config.tenantId;
        dataToPassToService.DepartmentId = item.departmentId;
        dataToPassToService.Prestartformid = item.prestartformid;
        dataToPassToService.Usercreated = this.currentUser.Email;
        dataToPassToService.Userupdated = this.currentUser.Email;
        dataToPassToService.inspectionFailed = event;
        //console.log("dataToPassToService", dataToPassToService);
        utility.genericUpdateAPICall(Config.plantListName, item.id, dataToPassToService).then((data: any) => {
            this.setState({
                loading: true
            });
            setTimeout(() => this._getItemData(), 500);
        }, (err: any) => {
            console.log(err);
        });
    }


    public _handleKeyDown = (e: any) => {
        if (e.event.originalEvent.key === 'Enter') {
            this._getItemData();
        }
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    public render(): React.ReactElement<any> {

        return (
            <React.Fragment>
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Equipment and Vehicles </span>
                    </div>
                </div>
                <div className={"searchFieldDiv"}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <TextBoxInput
                                stylingMode='underlined'
                                label="Title"
                                // pattern={"[A-Za-z]+"}
                                // minLength={2}
                                value={this.state.title}
                                onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                onKeyDown={(e: any) => this._handleKeyDown(e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <SelectBoxInput
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                items={this.state.categoryItems}
                                selectedItem={this.state.category}
                                label='Category '
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'category')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <SelectBoxInput
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                items={this.state.departmentItems}
                                selectedItem={this.state.department}
                                label='Organisation '
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <SelectBoxInput
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                items={this.state.inspectionFailedItems}
                                selectedItem={this.state.inspectionfailed}
                                label='Inspection Failed'
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'inspectionfailed')}
                            />                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <div className={"searchButtonDiv"}>
                                {(this.state.itemSelected && this.state.itemSelected.length > 0) && (
                                    <Button onClick={() => this.setState({ submitPlantUsageModal: true })} variant='contained' color="primary" className={'button'}>
                                        Submit
                                    </Button>
                                )}
                                <Button onClick={() => this.clearFilter()} variant='outlined'>
                                    Clear
                                </Button>
                                <Button onClick={this._getItemData} variant='outlined' color="primary" className={'button'}>
                                    Search
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    {!this.state.loading ?
                        <div className='tabStripDiv'>
                            {(this.state.items && this.state.items.length > 0) && (

                                <DataTable
                                    style={{ width: '100%' }}
                                    filteralbe={true}
                                    groupable={true}
                                    rows={this.state.items}
                                    columns={this.state.columns}
                                    fileName={'Equipment and Vehicles'}
                                    columnChooser={'plantFailColumns'}
                                    selectionMode={false}
                                    showHideColumnMenu={true}
                                    callAfterInit={this.state.callAfterInit}
                                    stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                    loading={this.state.loading}
                                    allowExportSelectedData={false}
                                    showNewBtn={false}
                                    // showNewModal = {() => this._showModal()}
                                    // deleteItem = {(a) => this.DeleteDialog(a)}
                                    // editItem = {(a) => this.EditItemModal(a)}
                                    // viewFile={(e)=>this.viewFile(e)}
                                    showCommandBtn={false}
                                    showViewDetailBtn={false}
                                    _changeInspectiponFailed={(e: any, data: any) => this._changeInspectiponFailed(e, data)}
                                />
                            )}
                        </div> :
                        <Spinner size='large' label="Loading..." />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }

}

