import * as React from 'react';
import * as PropTypes from 'prop-types';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import List from '@material-ui/core/List';
// import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';

import List, { ItemDragging } from 'devextreme-react/list';

import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';

import { AdminJson } from '../../../classes/AdminlistAutomation'
import TextBoxInput from '../../common/reusableComponents/TextBox';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import NumberBoxInput from '../../common/reusableComponents/NumberBox';
import AddEditFieldBuilder from './AddEditFieldBuilder';
import ArrayStore from 'devextreme/data/array_store';
import Spinner from '../../common/reusableComponents/Spinner';
import Autocomplete from 'devextreme-react/autocomplete';
import CreateNewList from './CreateNewList';
import { Switch } from 'devextreme-react';
import AdminLogIn from './AdmiListLogIn';
import AlertDialog from '../../common/alertDialog';
const typeOptions: any = [
    { id: 'string', text: 'string' },
    { id: 'datetime', text: 'datetime' },
    { id: 'date', text: 'date' },
    { id: 'boolean', text: 'boolean' },
]
interface ObjArray {
    [propName: string]: number | string | object
}
interface StringArray {
    string: any
}
export default class AdminListBuilder extends React.Component<any, any> {


    constructor(props: any) {
        super(props);
        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        }
        if (!this.props.Department) {
            window.open('#/AccessRequest', '_self');
        };
        this.onDragStart = this.onDragStart.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onReorder = this.onReorder.bind(this);

        this.state = {
            // pageListItems: AdminJson,
            // selectedItem: AdminJson[0],
            pageListItems: [],
            selectedItem: '',
            selected: 0,
            // selectedItemIndex: [AdminJson[0].Id],
            loading: false,
            fieldItems: [],
            openAdminLogIn:true
        }
    }


    public componentDidMount() {
        this.getListConfigurationData(true)
    }
    public getListConfigurationData(loadSelected?:boolean) {
        this.setState({loadComponent:true})
        utility.genericGetAPICallForList(Config.ListConfiguration,'GET').then((data: any) => {
            let tempPageListItems:any = [];
            if (data && data.length > 0) {
             
                data.forEach((element:any)=>{
                    tempPageListItems.push({
                        Id:element.Id,
                        Title:element.Title,
                        Category:element.Category,
                        Isactive:element.Isactive?true:false,
                        AddEditField:JSON.parse(element.Addeditfield),
                        apiInfo:JSON.parse(element.Apiinfo),
                        Columns:JSON.parse(element.Columns),
                        DataTable:JSON.parse(element.Datatable),
                    })
                });
                this.setState({ pageListItems: tempPageListItems,loadComponent:false })
                if(loadSelected){
                this.loadSelectedData(tempPageListItems[0]);
                }
            }
        }, (err) => {
            console.log(err);
            this.loadSelectedData(AdminJson[0]);
            this.setState({ pageListItems: AdminJson,loadComponent:false,selectedItemIndex:[] })
        });
    }
    public loadSelectedData(item?: any) {
        this.setState({selectedItemIndex:[item?.Id],selectedItem: item})
        utility.genericGetAPICallForList(item.apiInfo.read.name, item.apiInfo.read.method, item.apiInfo.read.dataToPassToService).then((data: any) => {
            //console.log(data);
            let fieldItems: Array<string> = [];
            if (data && data.length > 0) {
                let item = data[0];
                fieldItems = this.getObjectKeys(item);
            }

            this.setState({ fieldItems: fieldItems });

        }, (err) => {
            console.log(err);
        });
        setTimeout(() => this.setState({ loading: false }), 500)
    }
    getObjectKeys = (obj: object) => {

        var newArray: Array<string> = [];
        if (obj) {
            newArray = Object.keys(obj);
        }
        return newArray;

    }
    public handleSelect = (event: any, newValue: number) => {
        this.setState({ selected: newValue });
    }
    private handleValueChange = (event: any, item: string, field: string, i: number) => {

        let tempselectedItem = this.state.selectedItem;
        tempselectedItem.Columns[i][field] = event.value;

        this.setState({ selectedItem: tempselectedItem });
    }
    public handleDropdownChange(e: any, item: string, field: string, i: number) {
        let tempselectedItem = this.state.selectedItem;
        tempselectedItem.Columns[i][field] = e.selectedItem.id;

        this.setState({ selectedItem: tempselectedItem });
    }
    public onDataTableFieldChanged(e: any, fileName: string) {
        let tempselectedItem = this.state.selectedItem;
        tempselectedItem.DataTable[fileName] = e.value;
        this.setState({ selectedItem: tempselectedItem });
    }
    public onAPIInfoFieldChanged(e: any, apiType: string, fileName: string) {
        let tempselectedItem = this.state.selectedItem;
        let value = e.value;
        // if (fileName === 'dataToPassToService') {
        //     // if(typeof value !== 'object'){
        //     //     return;
        //     // }
        //     // value = JSON.parse(value);
        //     value = value;

        // }
        tempselectedItem.apiInfo[apiType][fileName] = value;

        this.setState({ selectedItem: tempselectedItem });
    }
    public validateTab(validateItem: string) {
        let className: string = '';
        let tempselectedItem = this.state.selectedItem;
        if (tempselectedItem[validateItem]) {
            if (validateItem === 'Columns') {
                let columns = tempselectedItem[validateItem];
                let validateColumn = true;
                columns.forEach((c: any) => {
                    if (!c.field || !c.title || !c.type) {
                        validateColumn = false;
                    }
                });
                className = validateColumn ? 'validateTabPass' : 'validateTabFail';

            }
            if (validateItem === 'AddEditField') {
                let AddEditField = tempselectedItem[validateItem];
                let validateAddEditField = true;
                AddEditField.forEach((c: any) => {
                    // return !c.Type || !c.Label || !c.addeditfield || !c.field
                    if (!c.Type || !c.Label || !c.addeditfield || !c.field) {
                        validateAddEditField = false;
                    }
                });
                className = validateAddEditField ? 'validateTabPass' : 'validateTabFail';
            }
            if (validateItem === 'apiInfo') {
                let ApiInfo = tempselectedItem[validateItem];
                let ApiInfoObject = this.getObjectKeys(tempselectedItem[validateItem]);
                
                let validateApiInfo = true;
                ApiInfoObject.forEach((element: any) => {
                  
                    if (ApiInfo[element] && (!ApiInfo[element]?.name || !ApiInfo[element]?.method)) {
                        if(ApiInfo[element]?.method === 'DELETE' && (ApiInfo[element]?.dataToPassToService && typeof ApiInfo[element]?.dataToPassToService !== 'object')){

                            validateApiInfo = false
                        }
                    }
                });
                className = validateApiInfo ? 'validateTabPass' : 'validateTabFail';
            }
            if (validateItem === 'DataTable') {
                // tempselectedItem[validateItem]
                className = 'validateTabPass';
            }
        }
        return className
    }
    public addColumnItem(item: string) {

        let selectedItem = this.state.selectedItem;
        if (item === 'Column') {
            let tempObjectofColumn: object = {
                field: '',
                title: '',
                width: 80,
                type: 'string',
                allowHiding: false,
            }
            selectedItem.Columns.push(tempObjectofColumn)
        }
        this.setState({ selectedItem: selectedItem, loading: true });
        setTimeout(() => this.setState({ loading: false }), 500)
    }
    public onSaveAddEditField(item: Array<object>) {
        let tempselectedItem = this.state.selectedItem;
        tempselectedItem.AddEditField = item;
        this.setState({ selectedItem: tempselectedItem });
        setTimeout(() => this.onSave(), 500)
    }
    public onSave() {
        let item = this.state.selectedItem;
        let dataToPassToService: any = {};
        dataToPassToService.Id = item.Id;
        dataToPassToService.Title = item.Title;
        dataToPassToService.Category = item.Category;
        dataToPassToService.Isactive = item.Isactive;
        dataToPassToService.Columns = JSON.stringify(item.Columns);
        dataToPassToService.Datatable = JSON.stringify(item.DataTable);
        dataToPassToService.Addeditfield = JSON.stringify(item.AddEditField);
        dataToPassToService.Apiinfo = JSON.stringify(item.apiInfo);
        dataToPassToService.Userupdated = this.props.currentUser.Email;
        dataToPassToService.Tenantid = Config.tenantId;
        utility.genericUpdateAPICall(Config.ListConfiguration, item?.Id,dataToPassToService).then((data: any) => {
            //console.log(data);
            this.getListConfigurationData();
            localStorage.removeItem(item.Title);
            localStorage.removeItem(`${item.Title}Columns`);
            this.setState({
                alertMessage: `${this.state.selectedItem.Title} data saved`,
                hideAlertButton: false,
                showAlert: true
            });

        }, (err: any) => {
            console.log(err);
            this.setState({
                alertMessage: 'Something went wrong!',
                hideAlertButton: false,
                showAlert: true
            });
        });
    }
    public render(): React.ReactElement<any> {
        const dataSourceOptions = {
            store: new ArrayStore({
                data: this.state.pageListItems,
                key: 'Id',
            }),
            group: 'Category',
            searchExpr: ['Title'],
        };
        return (
            <div>
                {this.state.showAlert && this.alert()}
                {this.state.openCreateModal && this.renderCreateNewListModal()}
                {this.state.openAdminLogIn && this.renderAdminLogInModal()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Admin List Builder </span>
                    </div>
                </div>
                <div style={{ padding: '10px' }} >
                    {!this.state.loadComponent?
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3} md={3}>
                            <Paper elevation={5} className='CreateNewListBtn' onClick={() => this.setState({ openCreateModal: true, mode: 'add' })}>
                                <div style={{ paddingTop: '13px' }} >Create New List</div>
                            </Paper>
                            <Paper elevation={5} style={{ height: '80vh', overflowY: 'auto', overflowX: 'hidden' }} >

                                <List
                                    dataSource={dataSourceOptions}
                                    // height={400}
                                    keyExpr="Id"
                                    selectionMode="single"
                                    itemRender={(e, i) => this.ItemTemplate(e, i)}
                                    grouped={true}
                                    // elementAttr={{ class: 'list' }}
                                    collapsibleGroups={true}
                                    selectedItemKeys={this.state.selectedItemIndex}
                                    searchExpr="Title"
                                    searchEnabled={true}
                                    searchMode={'contains'} />
                            </Paper>

                        </Grid>
                        <Grid item xs={12} sm={9} md={9}>
                            <Paper elevation={5} style={{ marginBottom: '5px' }}>
                                <Tabs
                                    value={this.state.selected}
                                    onChange={this.handleSelect}
                                    indicatorColor="secondary"
                                    textColor="secondary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    //ErrorIcon
                                    <Tab label={<span className={`tabHeading ${this.validateTab('Columns')}`}> <span>{this.validateTab('Columns') === 'validateTabPass' ? <CheckCircleIcon className={this.validateTab('Columns')} /> : <ErrorIcon className={this.validateTab('Columns')} />}</span>Columns</span>}  {...a11yProps(0)} />
                                    <Tab label={<span className={`tabHeading ${this.validateTab('DataTable')}`}><span>{this.validateTab('DataTable') === 'validateTabPass' ? <CheckCircleIcon className={this.validateTab('DataTable')} /> : <ErrorIcon className={this.validateTab('DataTable')} />}</span>Data Table</span>}  {...a11yProps(1)} />
                                    <Tab label={<span className={`tabHeading ${this.validateTab('AddEditField')}`}><span>{this.validateTab('AddEditField') === 'validateTabPass' ? <CheckCircleIcon className={this.validateTab('AddEditField')} /> : <ErrorIcon className={this.validateTab('AddEditField')} />}</span>Add Edit Field</span>}  {...a11yProps(2)} />
                                    <Tab label={<span className={`tabHeading ${this.validateTab('apiInfo')}`}><span>{this.validateTab('apiInfo') === 'validateTabPass' ? <CheckCircleIcon className={this.validateTab('apiInfo')} /> : <ErrorIcon className={this.validateTab('apiInfo')} />}</span>Api Details</span>}  {...a11yProps(3)} />
                                </Tabs>
                            </Paper>
                            <Paper elevation={5} className="AdminListBuilerPaper" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                                <TabPanel value={this.state.selected} index={0}>
                                    <div>
                                        <div>
                                            <Button variant='outlined' className='button' color="primary" onClick={() => this.onSave()} >
                                                Save
                                            </Button>
                                            <Button variant='outlined' className='button' onClick={() => this.addColumnItem('Column')} >
                                                Add
                                            </Button>
                                        </div>
                                        {!this.state.loading ?
                                            <List
                                                dataSource={this.state.selectedItem?.Columns}
                                                keyExpr="field"
                                                itemRender={(e, i) => this.ItemTemplateColumn(e, i)}
                                                // height={'65vh'}
                                                repaintChangesOnly={true}
                                                scrollByThumb
                                                scrollingEnabled
                                                pullRefreshEnabled
                                                allowItemDeleting={true}
                                                itemDeleteMode={'static'}
                                                visible={true}
                                            >
                                                <ItemDragging
                                                    allowReordering={true}
                                                    group="column"
                                                    data="Columns"
                                                    onDragStart={this.onDragStart}
                                                    onAdd={this.onAdd}
                                                    onRemove={this.onRemove}
                                                    onReorder={this.onReorder}>
                                                </ItemDragging>
                                            </List> :
                                            <Spinner />
                                        }

                                    </div>
                                </TabPanel>
                                <TabPanel value={this.state.selected} index={1}>

                                    <div>
                                        <Button variant='outlined' className='button' color="primary" onClick={() => this.onSave()} >
                                            Save
                                        </Button>
                                    </div>
                                    <div className='mt-20'>
                                        <div className='m-10'><CheckBoxInput onValueChanged={(e: any) => this.onDataTableFieldChanged(e, 'filteralbe')} text={"Filteralbe"} value={this.state.selectedItem.DataTable?.filteralbe} /></div>
                                        <div className='m-10'><CheckBoxInput onValueChanged={(e: any) => this.onDataTableFieldChanged(e, 'groupable')} text={"Groupable"} value={this.state.selectedItem.DataTable?.groupable} /></div>
                                        <div className='m-10'><CheckBoxInput onValueChanged={(e: any) => this.onDataTableFieldChanged(e, 'selectionMode')} text={"Selection Mode"} value={this.state.selectedItem.DataTable?.selectionMode} /></div>
                                        <div className='m-10'><CheckBoxInput onValueChanged={(e: any) => this.onDataTableFieldChanged(e, 'showHideColumnMenu')} text={"show Hide Column Menu"} value={this.state.selectedItem.DataTable?.showHideColumnMenu} /></div>
                                        <div className='m-10'><CheckBoxInput onValueChanged={(e: any) => this.onDataTableFieldChanged(e, 'allowExportSelectedData')} text={"Allow Export For Selected Data"} value={this.state.selectedItem.DataTable?.allowExportSelectedData} /></div>
                                        <div className='m-10'><CheckBoxInput onValueChanged={(e: any) => this.onDataTableFieldChanged(e, 'showCommandBtn')} text={"Show Command Buttom"} value={this.state.selectedItem.DataTable?.showCommandBtn} /></div>
                                        <div className='m-10'><CheckBoxInput onValueChanged={(e: any) => this.onDataTableFieldChanged(e, 'showNewBtn')} text={"Show New Button"} value={this.state.selectedItem.DataTable?.showNewBtn} /></div>
                                        <div className='m-10'><CheckBoxInput onValueChanged={(e: any) => this.onDataTableFieldChanged(e, 'showViewDetailBtn')} text={"Show View Detail Button"} value={this.state.selectedItem.DataTable?.showViewDetailBtn} /></div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={this.state.selected} index={2}>
                                    {!this.state.loading ?
                                        <AddEditFieldBuilder
                                            selectedItem={this.state.selectedItem}
                                            onSave={(e: any) => this.onSaveAddEditField(e)}
                                        /> :
                                        <Spinner />
                                    }
                                </TabPanel>
                                <TabPanel value={this.state.selected} index={3}>
                                    <div>
                                        <Button variant='outlined' className='button' color="primary" onClick={() => this.onSave()} >
                                            Save
                                        </Button>
                                    </div>
                                    <div className='m-10'>
                                        <Paper elevation={6}>
                                            <div className='m-10'>
                                                <h3>Create</h3>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12} md={4} >
                                                        <TextBoxInput
                                                            stylingMode='underlined'
                                                            label={"API Name"}
                                                            // pattern={"[A-Za-z]+"}
                                                            // minLength={2}
                                                            value={this.state.selectedItem.apiInfo?.create.name}
                                                            onValueChanged={(e: any) => this.onAPIInfoFieldChanged(e, 'create', 'name')}
                                                            onKeyDown={() => { }}
                                                            required={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} >
                                                        <TextBoxInput
                                                            stylingMode='underlined'
                                                            label={"Method"}
                                                            // pattern={"[A-Za-z]+"}
                                                            // minLength={2}
                                                            value={this.state.selectedItem.apiInfo?.create.method}
                                                            onValueChanged={(e: any) => this.onAPIInfoFieldChanged(e, 'create', 'method')}
                                                            onKeyDown={() => { }}
                                                            required={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} >
                                                        <TextBoxInput
                                                            stylingMode='underlined'
                                                            label={"Data To Pass To Service"}
                                                            // pattern={"[A-Za-z]+"}
                                                            // minLength={2}
                                                            value={this.state.selectedItem.apiInfo?.create.dataToPassToService}
                                                            // value={JSON.stringify(this.state.selectedItem.apiInfo?.create.dataToPassToService)}
                                                            onValueChanged={(e: any) => this.onAPIInfoFieldChanged(e, 'create', 'dataToPassToService')}
                                                            onKeyDown={() => { }}
                                                            required={true}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Paper>
                                    </div>
                                    <div className='m-10'>
                                        <Paper elevation={6}>
                                            <div className='m-10'>
                                                <h3>Update</h3>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12} md={4} >
                                                        <TextBoxInput
                                                            stylingMode='underlined'
                                                            label={"API Name"}
                                                            // pattern={"[A-Za-z]+"}
                                                            // minLength={2}
                                                            value={this.state.selectedItem.apiInfo?.update.name}
                                                            onValueChanged={(e: any) => this.onAPIInfoFieldChanged(e, 'update', 'name')}
                                                            onKeyDown={() => { }}
                                                            required={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} >
                                                        <TextBoxInput
                                                            stylingMode='underlined'
                                                            label={"Method"}
                                                            // pattern={"[A-Za-z]+"}
                                                            // minLength={2}
                                                            value={this.state.selectedItem.apiInfo?.update.method}
                                                            onValueChanged={(e: any) => this.onAPIInfoFieldChanged(e, 'update', 'method')}
                                                            onKeyDown={() => { }}
                                                            required={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} >
                                                        <TextBoxInput
                                                            stylingMode='underlined'
                                                            label={"Data To Pass To Service"}
                                                            // pattern={"[A-Za-z]+"}
                                                            // minLength={2}
                                                            value={this.state.selectedItem.apiInfo?.update.dataToPassToService}
                                                            onValueChanged={(e: any) => this.onAPIInfoFieldChanged(e, 'update', 'dataToPassToService')}
                                                            onKeyDown={() => { }}
                                                            required={true}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Paper>
                                    </div>
                                    <div className='m-10'>
                                        <Paper elevation={6}>
                                            <div className='m-10'>
                                                <h3>Read</h3>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12} md={4} >
                                                        <TextBoxInput
                                                            stylingMode='underlined'
                                                            label={"API Name"}
                                                            // pattern={"[A-Za-z]+"}
                                                            // minLength={2}
                                                            value={this.state.selectedItem.apiInfo?.read.name}
                                                            onValueChanged={(e: any) => this.onAPIInfoFieldChanged(e, 'read', 'name')}
                                                            onKeyDown={() => { }}
                                                            required={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} >
                                                        <TextBoxInput
                                                            stylingMode='underlined'
                                                            label={"Method"}
                                                            // pattern={"[A-Za-z]+"}
                                                            // minLength={2}
                                                            value={this.state.selectedItem.apiInfo?.read.method}
                                                            onValueChanged={(e: any) => this.onAPIInfoFieldChanged(e, 'read', 'method')}
                                                            onKeyDown={() => { }}
                                                            required={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} >
                                                        <TextBoxInput
                                                            stylingMode='underlined'
                                                            label={"Data To Pass To Service"}
                                                            // pattern={"[A-Za-z]+"}
                                                            // minLength={2}
                                                            value={this.state.selectedItem.apiInfo?.read.dataToPassToService}
                                                            onValueChanged={(e: any) => this.onAPIInfoFieldChanged(e, 'read', 'dataToPassToService')}
                                                            onKeyDown={() => { }}
                                                            required={true}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Paper>
                                    </div>
                                    <div className='m-10'>
                                        <Paper elevation={6}>
                                            <div className='m-10'>
                                                <h3>Delete</h3>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12} md={6} >
                                                        <TextBoxInput
                                                            stylingMode='underlined'
                                                            label={"API Name"}
                                                            // pattern={"[A-Za-z]+"}
                                                            // minLength={2}
                                                            value={this.state.selectedItem.apiInfo?.delete.name}
                                                            onValueChanged={(e: any) => this.onAPIInfoFieldChanged(e, 'delete', 'name')}
                                                            onKeyDown={() => { }}
                                                            required={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} >
                                                        <TextBoxInput
                                                            stylingMode='underlined'
                                                            label={"Method"}
                                                            // pattern={"[A-Za-z]+"}
                                                            // minLength={2}
                                                            value={this.state.selectedItem.apiInfo?.delete.method}
                                                            onValueChanged={(e: any) => this.onAPIInfoFieldChanged(e, 'delete', 'method')}
                                                            onKeyDown={() => { }}
                                                            required={true}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Paper>
                                    </div>

                                </TabPanel>

                            </Paper>
                        </Grid>
                    </Grid>:
                    <Spinner />
                                }
                </div>
            </div>
        );
    }
    public openEditList(item: object) {
        this.setState({
            mode: 'edit',
            item: item,
            openCreateModal: true
        })
    }
    public renderCreateNewListModal() {
        return (
            <CreateNewList
                showModal={this.state.openCreateModal}
                onSave={(e: any, mode: string) => this.createNewListItem(e, mode)}
                onClose={() => this.setState({ openCreateModal: false })}
                mode={this.state.mode}
                listItem={this.state.item}
            />
        )
    }
    public renderAdminLogInModal() {
        return (
            <AdminLogIn
            showDialog={this.state.openAdminLogIn}
            // onClose={() => this.setState({openAdminLogIn: false })}
            onOkButton={() => this.setState({openAdminLogIn: false })}
            onCancelButton={() => this.onCancelButton()}
            />
        )
    }
    public onCancelButton(){
        this.setState({openAdminLogIn: false })
        window.history.back();
    }
    
    public createNewListItem(item: any, mode: string) {
        let dataToPassToService: any = {};
        dataToPassToService.Title = item.Title;
        dataToPassToService.Category = item.Category;
        dataToPassToService.Isactive = item.Isactive;
        dataToPassToService.Columns = JSON.stringify(item.Columns);
        dataToPassToService.Datatable = JSON.stringify(item.DataTable);
        dataToPassToService.Addeditfield = JSON.stringify(item.AddEditField);
        dataToPassToService.Apiinfo = JSON.stringify(item.apiInfo);
        dataToPassToService.Userupdated = this.props.currentUser.Email;
        dataToPassToService.Tenantid = Config.tenantId;
        if (mode === 'add') {
            dataToPassToService.Usercreated =  this.props.currentUser.Email;
            utility.genericPostAPIcall(Config.ListConfiguration, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.getListConfigurationData();
            // let temppageListItems = this.state.pageListItems;
            // temppageListItems.push(item)
            this.setState({openCreateModal: false });
                // this.clearFilter();
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.Id = item.Id;
            utility.genericUpdateAPICall(Config.ListConfiguration, item.Id, dataToPassToService).then((data: any) => {
                localStorage.removeItem(item.Title);
                localStorage.removeItem(`${item.Title}Columns`);
                this.getListConfigurationData();
                this.setState({openCreateModal: false });
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });

            });
        }
    }
    public ItemTemplate(item: any, i: any) {
        return <div className='displayFlex' onClick={() => this.onListItemChanged(item, i)}><div style={{ width: '100%' }}>{item.Title}</div> <div><EditIcon onClick={() => this.openEditList(item)} /></div></div>;
    }

    public onListItemChanged(item: any, i: number) {
        this.setState({ selectedItem: item, selectedItemIndex: [item.Id], loading: true });
        this.loadSelectedData(item);

    }
    public ItemTemplateColumn(item: any, i: any) {

        return (
            <div>
                <Grid container spacing={1} >
                    <Grid item xs={12} sm={4} md={2}>
                        {/* <TextBoxInput
                            stylingMode='outlined'
                            label={"Field Name"}
                            // pattern={"[A-Za-z]+"}
                            // minLength={2}
                            value={item.field}
                            onValueChanged={(e: any) => this.handleValueChange(e, item, 'field', i)}
                            onKeyDown={() => { }}
                            required={true}
                        /> */}
                        <Autocomplete
                            stylingMode='outlined'
                            label={"Field Name"}
                            dataSource={this.state.fieldItems}
                            value={item.field}
                            showDataBeforeSearch={true}
                            onValueChanged={(e: any) => this.handleValueChange(e, item, 'field', i)}
                        />

                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextBoxInput
                            stylingMode='outlined'
                            label={"Caption/Title"}
                            // pattern={"[A-Za-z]+"}
                            // minLength={2}
                            value={item.title}
                            onValueChanged={(e: any) => this.handleValueChange(e, item, 'title', i)}
                            onKeyDown={() => { }}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <SelectBoxInput
                            stylingMode='outlined'
                            displayExpr="text"
                            valueExpr="id"
                            showClearButton={false}
                            items={typeOptions}
                            selectedItem={item.type ? { id: item.type, text: item.type } : ''}
                            label={"Type"}
                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, item, 'type', i)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <NumberBoxInput
                            stylingMode='outlined'
                            label={"Width"}
                            min={1}
                            // pattern={"[A-Za-z]+"}
                            // minLength={2}
                            value={item.width}
                            onValueChanged={(e: any) => this.handleValueChange(e, item, 'width', i)}
                            onKeyDown={() => { }}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <br />
                        <CheckBoxInput
                            value={item.allowHiding ? item.allowHiding : false}
                            onValueChanged={(e: any) => this.handleValueChange(e, item, 'allowHiding', i)}
                            text={'Allow Hiding'} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={1}>
                        <br />
                        <CheckBoxInput
                            value={item.visibleColumn ? item.visibleColumn : false}
                            onValueChanged={(e: any) => this.handleValueChange(e, item, 'visibleColumn', i)}
                            text={'Visible'} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={1}>
                        <br />
                        <CheckBoxInput
                            value={item.isArray ? item.isArray : false}
                            onValueChanged={(e: any) => this.handleValueChange(e, item, 'isArray', i)}
                            text={'Is Array'} />
                    </Grid>
                </Grid>
            </div>
        );
    }
    public renderListItem(ev: any, index: any,) {
        return (
            <div>{ev.Title}</div>
        )
    }
    onDragStart(e: any) {

        e.itemData = this.state['selectedItem'][e.fromData][e.fromIndex];
    }

    onAdd(e: any) {

        let tempSelectedItem = this.state.selectedItem;
        const tasks = this.state['selectedItem'][e.toData];
        
        tempSelectedItem.Columns = [...tasks.slice(0, e.toIndex), e.itemData, ...tasks.slice(e.toIndex)]
        this.setState({
            selectedItem: tempSelectedItem,
        });
    }
    
    onRemove(e: any) {
        
        let tempSelectedItem = this.state.selectedItem;
        const tasks = this.state['selectedItem'][e.fromData];

        tempSelectedItem.Columns = [...tasks.slice(0, e.fromIndex), ...tasks.slice(e.fromIndex + 1)]
        this.setState({
            selectedItem: tempSelectedItem,
        });
    }

    onReorder(e: any) {
        this.onRemove(e);
        this.onAdd(e);
    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
}



function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}