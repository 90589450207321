import React from 'react'
// import { GoogleMap, InfoWindow, Marker, Polygon, useJsApiLoader } from 'react-google-maps';
import { GoogleMap, InfoWindow, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api';
import global from '../../../classes/global';
import Config from '../../../classes/config';
import ListView from '../../Scheduler/Support/ListView';
import ColibanTopology from './ColibanGeoJson';
import GoogleMapStyle from './GoogleMapStyle';
import Accordion from 'devextreme-react/accordion';
import { NavLink } from 'react-router-dom';

const geoUrl = ColibanTopology;
const styles: any = GoogleMapStyle;
const MyMapComponent = ((props: any) => {
    const containerStyle = {
        width: '100%',
        height: props.height ? props.height : '70vh'
    };
    const centerPosition = {
        //lng: 144.946457, lat: -37.840935
        lat: props.center && props.center.lat ? props.center.lat : -36.757786, // latitude for the center of the map
        lng: props.center && props.center.lng ? props.center.lng : 144.278702 // longitude for the center of the map
    };
    const isLoaded = true;
    // useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: global.googleAPIKey
    // })

    const [map, setMap] = React.useState(null)
    const [zoom, setZoom] = React.useState(props.zoom)
    const [showMarker, setShowMarker] = React.useState(false)
    const [center, setCenter] = React.useState(centerPosition)
    const [showInfoWindow, setShowInfoWindow] = React.useState(false)

    const onLoad = React.useCallback(function callback(map: any) {
        map && map.addListener('zoom_changed', () => {
            setZoom(map.zoom)
        });
        setMap(map)
        setShowMarker(true)
        setShowInfoWindow(true)
    }, [zoom])

    const onUnmount = React.useCallback(function callback(map: any) {
        // setMap(null)
        console.log("unmount")

    }, [])


    const tempOpenId = localStorage.getItem('openIDState');

    const [openID, openInfoWindow] = React.useState(tempOpenId);
    const [openSububrID, openSububrInfoWindow]: any = React.useState({});

    const onInfoWindowOpen = (item: any, isDashboard?: boolean) => {
        item && openInfoWindow(item?.id ? item?.id : item);
        isDashboard && setSelectedItems([item]);
        localStorage.setItem('openIDState', item?.id ? item?.id : item);
        setShowInfoWindow(true)
    }
    const onInfoWindowClose = (item: any, isDashboard?: boolean) => {
        setShowInfoWindow(false)
    }
    const onSuburbInfoWindowOpen = (item: any) => {
        openSububrInfoWindow(item)
        setShowInfoWindow(true)
    }
    const onSuburbInfoWindowClose = (item: any) => {

        setShowInfoWindow(false)
    }

    const [openCrewID, openCrewInfoWindow] = React.useState();

    const onCrewInfoWindowOpen = (id: any) => {
        openCrewInfoWindow(id);
    }
    const onSelectionMade = (item: any) => {

        if (item.length === 1) {
            props.onUnassignedSelection(item[0]);
        }
    }

    const [selectedItems, setSelectedItems]: any = React.useState([]);
    const selectionChanged = (e: any) => {

        let newItems: any = [];
        if (selectedItems) {
            // newItems.push(selectedItems)
            // newItems = [...selectedItems]
            newItems = [selectedItems]
        }

        e.removedItems.forEach((item: any) => {
            const index = newItems.indexOf(item);
            if (index >= 0) {
                newItems.splice(index, 1);
            }
        });
        if (e.addedItems && e.addedItems.length > 0) {
            newItems = e.addedItems;
        }
        setSelectedItems(newItems);
        // this.setState({
        //     selectedItems: newItems,
        // });
    }

    const CustomTitle = (data: any, i: any) => {
        return (
            <div
                style={{ width: '100%' }}>
                {data.title ? data.title : 'select'}

            </div>
        );
    }
    const CustomItem = (marker: any) => {
        return (
            <div style={{ margin: `12px` }}>

                <div><span className={'semiBold infoWindowWODetail'}>Workorder #: </span><span><NavLink to={marker.link} className={"WOlink"} > {marker.wonumber}</NavLink></span></div>
                <div><span className={'semiBold infoWindowWODetail'}>Location: </span><span>{marker.location}</span></div>
                <div><span className={'semiBold infoWindowWODetail'}>Activity: </span><span>{marker.workType}</span></div>
                <div><span className={'semiBold infoWindowWODetail'}>Priority: </span><span>{marker.priority}</span></div>
                <div><span className={'semiBold infoWindowWODetail'}>Status: </span><span>{marker.status}</span></div>
                <div><span className={'semiBold infoWindowWODetail'}>Latest Status Date: </span><span>{marker.latestStatusDate}</span></div>
                <div><span className={'semiBold infoWindowWODetail'}>Reported Date: </span><span>{marker.reporteddate}</span></div>
                <div><span className={'semiBold infoWindowWODetail'}>Reason to Attend: </span><span>{marker.reasonToAttend}</span></div>
                <div><span className={'semiBold infoWindowWODetail'}>Area: </span><span>{marker.area}</span></div>
                <div><span className={'semiBold infoWindowWODetail'}>SalesForce: </span><span>{marker.saleForceId}</span></div>
                <div><span className={'semiBold infoWindowWODetail'}>Service Request: </span><span>{marker.serviceReq}</span></div>
            </div>
        );
    }
    // const mapClick=(e)=>{
    //     console.log(e)
    //     if (e.ctrlKey) {
    //         console.log(e)
    //       } 
    // }
    var keys;
    if (props.showWODetail && props.markers) {
        keys = Object.keys(props.markers);

    }
    var schedulerKeys;
    if (props.showSchedulerMarker && props.schedulerMarkers) {
        schedulerKeys = Object.keys(props.schedulerMarkers);

    }

    return isLoaded ? (

        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            onLoad={onLoad}
            // onUnmount={onUnmount}          
            options={{
                //   streetViewControl: true,
                disableDefaultUI: true, // disable default map UI
                draggable: true, // make map draggable
                //   zoomControlOptions: { position: 9 },
                keyboardShortcuts: false, // disable keyboard shortcuts
                scaleControl: true, // allow scale controle
                scrollwheel: true, // allow scroll wheel
                //styles: props.useStyle ? styles : '',
                styles: [
                    {
                        featureType: "poi.business",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                ],
                zoom: zoom ? zoom : props.zoom,
                maxZoom: 19,
                minZoom: 8,
            }}
        >
            { /* Child components, such as markers, info windows, etc. */}
            <Polygon
                paths={geoUrl}
                options={{
                    strokeColor: "#FFC0CB",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: "#004459",
                    fillOpacity: 0.10
                }}
            />
            {props.singleMarkers && props.showMarker &&
                <Marker
                    key={'natureStrip'}
                    position={{
                        lat: props.singleMarkers.lat, // latitude to position the marker
                        lng: props.singleMarkers.lng // longitude to position the marker
                    }}
                // icon={
                //     { url: `https://chart.apis.google.com/chart?chst=d_map_spin&chld=1|0|black|14|` }
                // }
                >

                </Marker>}
            {props.showWODetail && props.showMarker && (
                <>
                    {keys && keys.map((key) => {
                        if (key) {
                            let icon;
                            if (props.markers[key] && props.markers[key].length > 1) {
                                icon = {
                                    // url: `${global.mapIconFolderURL}/${props.markers[key][0].icon.toLowerCase()}.png`
                                    url: `https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bb|${props.markers[key].length}|FFFFFF|000000`,
                                };
                            } else if (props.markers[key][0].isIconAvailable) {
                                icon = {
                                    url: `${global.mapIconFolderURL}/${props.markers[key][0].icon.toLowerCase()}.png`
                                };
                            } else {
                                icon = {
                                    url: `${global.mapIconFolderURL}/${props.markers[key][0].icon.toLowerCase()}.png`
                                };
                                // icon = {
                                //     url: `https://chart.apis.google.com/chart?chst=d_map_spin&chld=1|0|00ac93|14|_|${props.markers[key].length}`,
                                // };
                            }
                            // console.log("props.markers[key]",props.markers[key])
                            return (
                                <Marker
                                    key={key}
                                    position={{
                                        lat: props.markers[key][0].lat, // latitude to position the marker
                                        lng: props.markers[key][0].long // longitude to position the marker
                                    }}
                                    icon={icon}
                                    onClick={() => onInfoWindowOpen(props.markers[key][0], true)}
                                >
                                    {(openID === props.markers[key][0].id) && showInfoWindow &&
                                        <InfoWindow
                                            onCloseClick={() => onInfoWindowClose('')}
                                        // options={{ closeBoxURL: ``, enableEventPropagation: true }}
                                        >
                                            <div style={{ opacity: 0.75, padding: `12px` }} className={'infoWIndowBox'}>

                                                <Accordion
                                                    dataSource={props.markers[key]}
                                                    collapsible={true}
                                                    multiple={false}
                                                    // animationDuration={300}
                                                    selectedItems={selectedItems}
                                                    onSelectionChanged={selectionChanged}
                                                    itemTitleRender={CustomTitle}
                                                    itemRender={CustomItem}
                                                />
                                            </div>
                                        </InfoWindow>}
                                </Marker>
                            )
                        }
                    }

                    )}
                </>)}
            {props.showSuburbDetail && props.showMarker && (
                <>
                    {props.markers && props.markers.map((marker: any) => {

                        if (marker.lat) {
                            let icon
                            icon = {
                                url: `https://chart.apis.google.com/chart?chst=d_map_spin&chld=1|0|00ac93|14|_|${marker.Count}`,
                            };

                            return (
                                <Marker
                                    key={marker.id}
                                    position={{
                                        lat: marker.lat, // latitude to position the marker
                                        lng: marker.lng // longitude to position the marker
                                    }}
                                    icon={icon}
                                    onClick={() => onSuburbInfoWindowOpen(marker)}
                                >
                                    {/* {(openSububrID?.id === marker.id) && 
                                    } */}
                                </Marker>
                            )
                        }
                    }
                    )}
                    {openSububrID?.id && showInfoWindow &&
                        <InfoWindow
                            onCloseClick={() => onSuburbInfoWindowClose({})}

                            options={{ pixelOffset: new window.google.maps.Size(0, -62) }}
                            position={{
                                lat: openSububrID.lat, // latitude to position the marker
                                lng: openSububrID.lng // longitude to position the marker
                            }}
                        // options={{
                        //     // position:{{marker}}
                        // }}
                        >
                            <div style={{ opacity: 0.75, padding: `12px` }} className={'infoWIndowBox'}>
                                <div className='theme' style={{ fontSize: `16px` }}>
                                    {openSububrID.title}<br />
                                    <div style={{ textAlign: `center`, fontSize: `24px` }}>{openSububrID.Count}</div>
                                </div >
                            </div>
                        </InfoWindow>
                    }
                </>
            )}
            {props.showSchedulerMarker && props.showMarker && (
                <>
                    {schedulerKeys && schedulerKeys.map((key) => {

                        if (key) {
                            let icon;

                            let tempStatus = props.schedulerMarkers[key][0].fieldstatus;

                            let mapIconColor = Config.statusColors[tempStatus] ? Config.statusColors[tempStatus] : 'C9C65F';
                            mapIconColor = mapIconColor.replace("#", '');

                            if (props.schedulerMarkers[key] && props.schedulerMarkers[key].length > 1) {
                                icon = {
                                    url: `https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bb|${props.schedulerMarkers[key].length}|FFFFFF|000000`,
                                    // url: `https://chart.apis.google.com/chart?chst=d_map_spin&chld=1|0|00ac93|14|_|${props.schedulerMarkers[key].length}`,
                                };
                            } else if (props.schedulerMarkers[key][0].selectedWO) {
                                icon = {
                                    // url: `https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=${props.schedulerMarkers[key].length}|FF776B|ffffff`,
                                    url: `https://chart.apis.google.com/chart?chst=d_map_spin&chld=1|0|888888|14|_|${props.schedulerMarkers[key].length}`,
                                    //
                                };
                                // } else if (props.schedulerMarkers[key][0].fieldstatus === 'Unassigned') {
                                //     icon = {
                                //         url: `https://chart.apis.google.com/chart?chst=d_map_spin&chld=1|0|${mapIconColor.replcae("#","")}|14|_|${props.schedulerMarkers[key].length}`,
                                //     };
                            } else {
                                icon = {
                                    url: `https://chart.apis.google.com/chart?chst=d_map_spin&chld=1|0|${mapIconColor}|14|_|${props.schedulerMarkers[key].length}`,
                                };
                            }
                            return (
                                <Marker
                                    key={key}
                                    position={{
                                        // lat: props.markers[key].lenght>0?props.markers[key][0].lat:'', // latitude to position the marker
                                        // lng: props.markers[key].lenght>0?props.markers[key][0].long:'' // longitude to position the marker
                                        lat: props.schedulerMarkers[key][0].lat, // latitude to position the marker
                                        lng: props.schedulerMarkers[key][0].long // longitude to position the marker
                                    }}
                                    // icon={icon}
                                    onClick={() => onInfoWindowOpen(key)}
                                    onDblClick={() => onSelectionMade(props.schedulerMarkers[key])}
                                >
                                    {(openID === key) && showInfoWindow &&
                                        <InfoWindow
                                            onCloseClick={() => onInfoWindowClose('')}
                                        // options={{ closeBoxURL: ``, enableEventPropagation: true }}
                                        >
                                            <div style={{ opacity: 0.75 }} className={'infoWIndowBox'}>
                                                <ListView
                                                    draggingGroupName={props.draggingGroupName}
                                                    onListDragStart={(e: any) => props.onListDragStart(e)}
                                                    items={props.schedulerMarkers[key]}
                                                    onItemDragStart={(e: any) => props.onItemDragStart(e)}
                                                    onItemDragEnd={(e: any) => props.onItemDragEnd(e)}
                                                    mapView={true}
                                                    hidePageable={true}
                                                    viewItem={(e: any) => props.viewItem(e)}
                                                    viewWO={(e: any) => props.viewWO(e)}
                                                    zoomToSelectWO={(e: any) => props.zoomToSelectWO(e)}
                                                    callAfterInit={props.callAfterInit}
                                                    stopCommsAfterInit={() => props.stopCommsAfterInit()}
                                                    view={props.view}
                                                    onUnassignedSelection={(e: any) => props.onUnassignedSelection(e)}
                                                    onUnassignedSelectionOption={true}
                                                    showCrew={true}
                                                />
                                            </div>
                                        </InfoWindow>}
                                </Marker>
                            )
                        }
                    }

                    )}
                </>
            )}
            {props.crewLocation && props.crewLocation.map((crew: any, indexC: any) => {

                return (
                    <Marker
                        key={crew.code}
                        position={{
                            lat: crew.lat, // latitude to position the marker
                            lng: crew.lng // longitude to position the marker
                        }}
                        icon={{
                            // url: `https://chart.apis.google.com/chart?chst=d_map_spin&chld=1|0|00ac93|14|_|`
                            url: `https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bb|${crew.code}|00ac93|ffffff`,
                        }}
                        onClick={() => onCrewInfoWindowOpen(indexC)}
                    >
                        {(openCrewID === indexC) && <InfoWindow
                            onCloseClick={() => onCrewInfoWindowOpen('')}
                        // options={{ closeBoxURL: ``, enableEventPropagation: true }}
                        >
                            <div style={{ opacity: 0.75, padding: `12px` }} className={'infoWIndowBox'}>
                                <div className='theme' style={{ fontSize: `16px` }}>
                                    {crew.title}<br />
                                    <span style={{ fontSize: '12px' }}>{crew.location}</span>
                                </div >
                            </div>
                        </InfoWindow>}
                    </Marker>
                );
            }
            )}
        </GoogleMap>
    ) : <></>
}
)
export default MyMapComponent