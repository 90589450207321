import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';

import Grid from '@material-ui/core/Grid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

import AlertDialog from '../../common/alertDialog';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import Spinner from '../../common/reusableComponents/Spinner';
import capitalize from '@material-ui/core/utils/capitalize';

const CategoryItems:any=[
    {id:'User Management',text:'User Management'},
    {id:'Work Management',text:'Work Management'},
    {id:'Risk Management',text:'Risk Management'},
    {id:'Other Management',text:'Other Management'}
]
export default class CreateNewList extends React.Component<any, any> {

  
    constructor(props: any) {
        super(props);

        this.clearFilter = this.clearFilter.bind(this);
        this.create = this.create.bind(this);
        this.state = {
            ssActive:true
        };
    }

    public componentDidMount() {
        
    }
    public didMount() {
     if(this.props.mode =='edit'){
  
        this.setState({
            title:this.props.listItem?.Title,
            category:this.props.listItem?.Category?{id:this.props.listItem?.Category,text:this.props.listItem?.Category}:'',
            isActive:this.props.listItem?.Isactive?true:false,
        })
     }

    }
  

    public create() {
        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.category) {
            this.setState({
                alertMessage: 'Please select category',
                showAlert: true
            });
            return;
        }
        let obj:any={};
        if(this.props.mode ==='add'){
        obj ={
            Title: this.state.title,
            Category: this.state.category?.id,
            Isactive:this.state.isActive,
            apiInfo: {
                create: {
                    name: this.state.title.replace(/\s/g,''),
                    method: 'POST',
                    dataToPassToService: '{}'
                },
                update: {
                    name: this.state.title.replace(/\s/g,''),
                    method: 'PUT',
                    dataToPassToService: '{}'
                },
                read: {
                    name: this.state.title.replace(/\s/g,''),
                    method: 'GET',
                    dataToPassToService: '{}'
                },
                delete: {
                    name: this.state.title.replace(/\s/g,''),
                    method: 'DELETE'
                },

            },
            Columns: [
                {
                    field: 'Id',
                    title: 'ID',
                    width: 80,
                    type: 'string',
                    allowHiding: false,
                    visibleColumn: true,
                    isHiddenForEditAdd: true,
                    forEdit: true,
                    forCreate: false
                },
                {
                    field: 'Title',
                    title: 'Title',
                    // width: 160,
                    type: 'string',
                    visibleColumn: true,
                    isHiddenForEditAdd: false,
                    forEdit: true,
                    forCreate: false
                },
                
                {
                    field: 'Isactive',
                    title: 'Active',
                    width: 120,
                    type: 'boolean',
                    allowHiding: true,
                    visibleColumn: true,
                    isHiddenForEditAdd: false,
                    forEdit: true,
                    forCreate: false
                },
                {
                    field: 'Dateupdated',
                    title: 'Updated On',
                    width: 160,
                    type: 'datetime',
                    visibleColumn: false,
                    allowHiding: true,
                    isHiddenForEditAdd: true,
                    forEdit: true,
                    forCreate: true,
                    sortOrder:'desc'
                },
                {
                    field: 'Userupdated',
                    title: 'Updated By',
                    width: 160,
                    type: 'string',
                    visibleColumn: false,
                    allowHiding: true,
                    isHiddenForEditAdd: true,
                    forEdit: true,
                    forCreate: true
                },
                {
                    field: 'Datecreated',
                    title: 'Created On',
                    width: 160,
                    type: 'datetime',
                    visibleColumn: false,
                    allowHiding: true,
                    isHiddenForEditAdd: true,
                    forEdit: false,
                    forCreate: true
                },
                {
                    field: 'Usercreated',
                    title: 'Created By',
                    width: 160,
                    type: 'string',
                    visibleColumn: false,
                    allowHiding: true,
                    isHiddenForEditAdd: true,
                    forEdit: false,
                    forCreate: true
                },
            ],
            AddEditField: [
                {
                    ID:1,
                    Type: 'TextField',
                    Label: 'Title',
                    Placeholder: 'Title',
                    StateValue: 'title',
                    field: 'Title',
                    addeditfield: 'Title', //remove later
                    IsMandatory: true,
                    Value: '',
                    Grid: { sm: 12, md: 12, lg: 12 }
                },
              
                {
                    ID:2,
                    Type: 'CheckBox',
                    Label: 'Is Active',
                    StateValue: 'isactive',
                    Value: true,
                    field: 'IsActive',
                    addeditfield: 'Isactive', //remove later
                    Grid: { sm: 12, md: 12, lg: 12 }
                },
            ],
            DataTable:{
                filteralbe:true,
                groupable:true,
                selectionMode:true,
                showHideColumnMenu:true,
                allowExportSelectedData:true,
                showCommandBtn:true,
                showNewBtn:true,
                showViewDetailBtn:true,
            }
        }
    }else{
        obj = this.props.listItem;
        obj.Title = this.state.title;
        obj.Category = this.state.category?.id;
        obj.Isactive = this.state.isActive;
    }
 
        this.props.onSave(obj,this.props.mode)
    }


  
    public clearFilter() {
        this.setState({
            title: '',
            category: '',
        });
        this.props.onClose();

    }


    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }

    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.props.mode && capitalize(this.props.mode)} List`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                        <div className='popContentDiv'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Title *"
                                        value={this.state.title}
                                        onKeyDown={() => { }}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                        required={true}
                                    />
                                </Grid>
                              
                                     <Grid item xs={12} sm={6}>
                                        <SelectBoxInput
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            showClearButton={true}
                                            items={CategoryItems}
                                            selectedItem={this.state.category}
                                            label='Category'
                                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'category')}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                    </Grid>
                            </Grid>
                        </div>
                        </ScrollView>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode:"outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode:"contained",
                            onClick: this.create,
                        }}
                    />
                </Popup>
            </div>
        );
    }

}

