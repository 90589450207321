import * as React from 'react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import utility from '../../classes/utility';
import global from '../../classes/global';
import { Config } from '../../classes/config';

import AlertDialog from '../common/alertDialog';
import DataTable from '../common/DataTable/DataTable';
import DateBoxInput from '../common/reusableComponents/DateBox';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import TextBoxInput from '../common/reusableComponents/TextBox';
import Spinner from '../common/reusableComponents/Spinner';
import DisplayDetailsModal from '../common/DisplayDetailModal/DisplayDetailModal';


let _items: any = [];

export default class Searchtimesheet extends React.Component<any, any> {

  public employeenameitems: any = [];
  public departmentItems: any = [];
  public columns: any;

  constructor(props: any) {
    super(props);
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };
    this.employeenameitems = [];
    this.departmentItems = [];
    this.getTimesheetwithcriteria = this.getTimesheetwithcriteria.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.viewDetials = this.viewDetials.bind(this);
    // this.CommandEditTimeCell = this.CommandEditTimeCell.bind(this);
    // this.CommandShowEditTimeCell = this.CommandShowEditTimeCell.bind(this);

    this.columns = [

      {
        field: 'Workordernumber',
        title: 'Work Order #',
        // width: 160,
        type: 'string',
        allowHiding: false,
        link: 'workorderLink',
      },
      // {
      //   field: 'techoneworkordernumber',
      //   title: 'IPS WO #',
      //   // width: 160,
      //   type: 'string',
      //   allowHiding: true,
      // },
      {
        field: 'employee',
        title: 'Employee',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'type',
        title: 'Type',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'starttime',
        title: 'Start Time',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'endtime',
        title: 'End Time',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'hoursworked',
        title: 'Hours Worked',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'department',
        title: 'Organisation',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'updatedOn',
        title: 'Updated On',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created On',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
    ];
    let showHideColumns = localStorage.getItem("timesheetColumns");
    let tempColumns;
    if (showHideColumns !== null) {
      tempColumns = JSON.parse(showHideColumns);
    } else {
      tempColumns = this.columns;
      localStorage.setItem("timesheetColumns", JSON.stringify(this.columns));
    }
    this.state = {
      items: _items,
      columns: tempColumns,
      callAfterInit: false,
      loading: false,
      department: (this.props.role === global.worxOnline_ContractorAdmin || this.props.role === global.worxOnline_WorxAdmin) ? this.props.Department : '',
    };
  }



  public componentDidMount() {
    let tempstate = localStorage.getItem('timesheetState')
    this._getTimesheetData();
    this._getDepartmentData();
    if (tempstate) {
      // this.state = JSON.parse(tempstate)
      let allState = JSON.parse(tempstate);

      this.setState({
        dateCreatedfrom: allState.dateCreatedfrom ? allState.dateCreatedfrom : '',
        dateCreatedTo: allState.dateCreatedTo ? allState.dateCreatedTo : '',
        workorderno: allState.workorderno ? allState.workorderno : '',
        employeename: allState.employeename ? allState.employeename : '',
        department: allState.department ? allState.department : '',

      })
      // this.setState(this.state= JSON.parse(tempstate));
      // localStorage.clear();
    }

  }

  public componentDidUpdate() {
    if (this.state.employeenameitems <= 0) {
      this._getTimesheetData();

    }
  }

  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      this.getTimesheetwithcriteria();
    }
  }
  public clearFilter() {
    this.setState({
      items: '',
      workorderno: '',
      employeename: '',
      dateCreatedfrom: '',
      dateCreatedTo: '',
      department: (this.props.role === global.worxOnline_ContractorAdmin || this.props.role === global.worxOnline_WorxAdmin) ? this.state.department : ''
    });
    localStorage.removeItem('datagridFilterValue');
  }

  public viewDetials(item: any) {
    this.setState({
      details: item,
      showModal: true
    });
  }
  public renderDetailsModal() {
    return (
      <DisplayDetailsModal
        columns={this.columns}
        details={this.state.details}
        showModal={this.state.showModal}
        onClick={() => this._closeModal()}
      />
    );
  }
  private _closeModal = (): void => {
    this.setState({ showModal: false });
  }


  private _getTimesheetData() {

    utility.genericGetAPICall(Config.employeelistname).then((data: any) => {
      //console.log(data);
      this.employeenameitems = [];

      for (let r of data) {
        this.employeenameitems.push({ id: r.Id, text: (r.EmployeeNo + ' - ' + r.Title) });
      }
      this.setState({
        employeenameitems: this.employeenameitems,
        allemployeenameitems: this.employeenameitems,
      });

    }, (err) => {
      console.log(err);
    });
  }

  private _getDepartmentData() {
    utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {

      this.departmentItems = [];

      for (let r of data) {
        this.departmentItems.push({ id: r.Id, text: r.Title });
      }
      this.departmentItems = utility._sortItems(this.departmentItems, 'text', false);
      this.setState({ departmentItems: this.departmentItems, alldepartmentItems: this.departmentItems });
    }, (err) => {
      console.log(err);
    });
  }
  public getTimesheetwithcriteria() {
    let criteriaSelected = false;

    let dataToPassToService: any = {};

    if (this.state.workorderno) {
      //dataToPassToService += "WorkOrder/WONumber eq '" + this.state.workorderno + "'and ";
      dataToPassToService.WorkOrderNumber = this.state.workorderno;
      criteriaSelected = true;
    }

    if (this.state.employeename) {
      dataToPassToService.EmployeeID = this.state.employeename.id;
      criteriaSelected = true;
    }
    if (this.state.department) {
      dataToPassToService.DepartmentId = this.state.department.id;
      criteriaSelected = true;
    }
    if (this.state.dateCreatedTo && this.state.dateCreatedfrom) {
      let dcreatedfrom = new Date(this.state.dateCreatedfrom);
      let dcreatedTo = new Date(this.state.dateCreatedTo);
      if (dcreatedTo.getTime() < dcreatedfrom.getTime()) {
        this.setState({
          alertMessage: 'Date To should be greater than Date From',
          showAlert: true
        });
        return;
      }
    }

    if (this.state.dateCreatedfrom || this.state.dateCreatedTo) {
      let dfrom;
      let dTo;
      if (this.state.dateCreatedfrom) {
        dfrom = new Date(this.state.dateCreatedfrom).toISOString();
        dataToPassToService.DateFrom = dfrom;
      }
      if (this.state.dateCreatedTo) {
        var extraday = new Date(this.state.dateCreatedTo);
        extraday.setDate(extraday.getDate() + 1);
        dTo = extraday.toISOString().substring(0, 10) + "T13:59:59.000Z";
        dataToPassToService.DateTo = dTo;
      }

      criteriaSelected = true;
    }

    if (!criteriaSelected) {
      this.setState({
        alertMessage: 'Please select search criteria',
        showAlert: true
      });
      return;
    }

    //console.log("dataToPassToService",dataToPassToService);
    this.setState({
      callAfterInit: true,
      loading: true
    });

    utility.genericPostAPIcall(Config.WOTimesheetSearchAPI, dataToPassToService).then((data: any) => {
      _items = [];
      //  console.log(data);
      if (data.length === null) {
        if (_items.length <= 0) {
          this.setState({
            alertMessage: 'No results found for this criteria',
            showAlert: true
          });
        }
        this.setState({ items: _items, loading: false });
        return;
      }
      else {
        data.forEach((item: any) => {

          let startTimevalue;
          let endTimevalue;
          if (item.Starttime !== null && item.Starttime !== '') {
            startTimevalue = moment(item.Starttime).format("DD/MM/YY h:mm a");
          }
          if (item.Endtime !== null && item.Endtime !== '') {
            endTimevalue = moment(item.Endtime).format("DD/MM/YY h:mm a");
          }

          _items.push({
            id: item.Id,
            Workordernumber: item.Wonumber,
            techoneworkordernumber: item.Erpwonumber,
            woid: item.Workorderid,
            type: item.Timesheettype,
            employee: item.Fullname,
            department: item.Department,
            starttime: item.Starttime ? item.Starttime : '',
            endtime: item.Endtime ? item.Endtime : '',
            // viewTimesheet: '',
            hoursworked: item.Hoursworked,
            // Editedhoursworked: item.EditedHoursworked ? item.EditedHoursworked : item.hoursworked,
            // Editedstarttime: item.Editedstarttime ? moment(item.Editedstarttime).format("DD/MM/YYYY h:mm a") : null,
            // Editedendtime: item.Editedfinishtime ? moment(item.Editedfinishtime).format("DD/MM/YYYY h:mm a") : null,
            updatedBy: item.Userupdated,
            updatedOn: item.Dateupdated ? item.Dateupdated : null,
            createdBy: item.Usercreated,
            createdOn: item.Datecreated ? item.Datecreated : null,
          });
        });

      }
      if (_items.length <= 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });
      }
      _items = utility._sortItems(_items, 'updatedOn', true);

      this.setState({ items: _items, loading: false });
      var stateData = { ...this.state };
      localStorage.setItem('timesheetState', JSON.stringify(stateData));
      //console.log(_items);
    }, (err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }


  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }
  public handleValueChange(e: any, fieldName: string) {
    this.setState({ [fieldName]: e.value });
  }

  public handleDropdownChange(e: any, fieldName: string) {
    this.setState({ [fieldName]: e.selectedItem });
  }
  public render(): React.ReactElement<any> {

    return (
      <div>
        {this.state.showAlert && this.alert()}
        {this.state.showModal && this.renderDetailsModal()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}>Timesheet</span>
          </div>
        </div>
        <div className={"searchFieldDiv"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <DateBoxInput
                stylingMode='underlined'
                type="date"
                displayFormat={"dd/MM/yy"}
                label={'Date From'}
                showClearButton={true}
                value={this.state.dateCreatedfrom ? new Date(this.state.dateCreatedfrom) : null}
                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedfrom')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <DateBoxInput
                stylingMode='underlined'
                label={'Date To'}
                type="date"
                displayFormat={"dd/MM/yy"}
                showClearButton={true}
                value={this.state.dateCreatedTo ? new Date(this.state.dateCreatedTo) : null}
                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedTo')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} className={''}>
              <TextBoxInput
                stylingMode='underlined'
                label="Work Order #"
                // pattern={"[A-Za-z]+"}
                // minLength={2}
                value={this.state.workorderno}
                onValueChanged={(e: any) => this.handleValueChange(e, 'workorderno')}
                onKeyDown={this._handleKeyDown}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='Employee'
                items={this.state.employeenameitems}
                selectedItem={this.state.employeename}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'employeename')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode='underlined'
                label="Organisation"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.departmentItems}
                selectedItem={this.state.department}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                disabled={(this.props.role === global.worxOnline_ContractorAdmin || this.props.role === global.worxOnline_WorxAdmin) ? true : false}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv"}>
                <Button onClick={this.clearFilter} variant='outlined'>
                  Clear
                </Button>
                <Button onClick={this.getTimesheetwithcriteria} variant='outlined' color="primary" className={'button'}>
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>

          {!this.state.loading ?
            <div className='tabStripDiv'>
              {(this.state.items && this.state.items.length > 0) && (
                <DataTable
                  style={{ width: '100%' }}
                  filteralbe={true}
                  groupable={true}
                  rows={this.state.items}
                  columns={this.state.columns}
                  fileName={'Timesheet'}
                  columnChooser={'timesheetColumns'}
                  selectionMode={false}
                  showHideColumnMenu={true}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  viewItem={(a: any) => this.viewDetials(a)}
                  // showNewModal = {() => this._showModal()}
                  // deleteItem = {(a:any) => this.DeleteDialog(a)}
                  // editItem = {(a:any) => this.EditItemModal(a)}
                  showCommandBtn={false}
                  showViewDetailBtn={true}
                />
              )}
            </div>
            :
            <Spinner size='large' label={"Loading.."} />
          }
        </div>


      </div>
    );
  }

  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }

}

