import * as React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

export class ConfirmDialogProps {
  public showDialog: any;
  public message: any;
  public title: any;
  public onClose: any;
  public hideAlertButton?: any;
  public CancelhideAlertButton?: any;
  public onOkButton: any;
  public onCancelButton: any;
}
export default class ConfirmDialog extends React.Component<ConfirmDialogProps, any> {

  public state = {
    showDialog: this.props.showDialog
  };

  public render(): React.ReactElement<any> {
    return (
      <div>

        <Popup
          width={300}
          maxHeight={300}
          minHeight={200}
          height={'auto'}
          showTitle={true}
          showCloseButton={false}
          title={this.props.title}
          dragEnabled={false}
          hideOnOutsideClick={false}
          visible={this.state.showDialog}
          // onContentReady={() => this.didMount()}
          //closeOnOutsideClick={true}
          onVisibleChange={this._closeDialog}


        >
          <div className='popContentDiv' style={{ fontSize: '16px' }}> {this.props.message}</div>
          {(!this.props.hideAlertButton) && (
            <ToolbarItem
              widget="dxButton"
              toolbar="bottom"
              location="after"
              options={{
                text: 'Ok',
                stylingMode: "contained",
                onClick: this._OKcloseDialog,
              }}
            />
          )}
          {(!this.props.hideAlertButton) && (

            <ToolbarItem
              widget="dxButton"
              toolbar="bottom"
              location="after"
              options={{
                text: 'Cancel',
                stylingMode: "outlined",
                onClick: this._closeDialog,
              }}
            />
          )}

        </Popup>
      </div>
    );
  }

  private _closeDialog = (): void => {
    this.setState({ showDialog: false });
    this.props.onCancelButton();
  }
  private _OKcloseDialog = (): void => {
    this.setState({ showDialog: false });
    this.props.onOkButton();
  }
}


