import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';

import Grid from '@material-ui/core/Grid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import Spinner from '../../common/reusableComponents/Spinner';
import AlertDialog from '../../common/alertDialog';
import TextAreaInput from '../../common/reusableComponents/TextArea';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';




export default class AddEditForms extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.updateForms = this.updateForms.bind(this);
        this.clearFilter = this.clearFilter.bind(this);

        this.state = {
            builderLink: '/'
        };
    }

    public componentDidMount() {
        //console.log('component load');
        // this.getMeetingReportData();
        // this.getReportTemplate();
    }
    public didMount() {
        //console.log(this.props.details);
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                isActive: true,
                hideAlertButton: false,
                mode: this.props.mode,
            })
        }
    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: details.id,
            title: details.title,
            model: details.model,
            builderLink: '/Builder?ID=' + details.id,
        });
    }
    public updateForms() {
        //console.log(this.state.title);
        //console.log(this.props.currentUser);
        //genericPostAPIcallForAdminList
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.model) {
            this.setState({
                alertMessage: 'Please enter  model',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.Title = this.state.title;
        dataToPassToService.Formname = this.state.title;
        dataToPassToService.Model = this.state.model;
        dataToPassToService.IsActive = true;
        dataToPassToService.Userupdated = this.props.currentUser.Email;
        if (this.props.mode === 'edit') {
            dataToPassToService.Id = this.state.id;

            utility.genericUpdateAPICall(Config.FormsListName, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Forms has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.Usercreated = this.props.currentUser.Email;
            utility.genericPostAPIcall(Config.FormsListName, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Forms has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
        utility.removeItem(Config.FormsListName);
    }

    public clearFilter() {
        this.setState({ title: '', name: '', model: '' });
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }

    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.props.mode && capitalize(this.props.mode)} Form`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                        <div className='popContentDiv'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Title *"
                                        value={this.state.title}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'title')}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>

                                    <TextAreaInput
                                        stylingMode='underlined'
                                        value={this.state.model}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'model')}
                                        label="Model "
                                    />
                                </Grid>

                            </Grid>
                        </div>
                    </ScrollView>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.updateForms,
                        }}
                    />
                </Popup>
            </div>

        );
    }

}

