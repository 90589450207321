import * as React from 'react';

import global from '../../classes/global';

import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import Autocomplete from 'devextreme-react/autocomplete';
export interface ITestFrameState {
    iFramePanelOpened: boolean;
    iFrameUrl: string;
}

export default class AssetMap extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            iFramePanelOpened: false,
            iFrameUrl: global.AssetMapURL
        };
    }

    public _onIframeLoaded() {

        window.addEventListener('message', this.handleIframeTaskMap);
    }

    public componentDidMount() {
        // window.addEventListener('message', this.handleIframeTask);
        //console.log(this.props);
    }
    public handleValueChange(e: any, fieldName: string) {

        this.setState({ [fieldName]: e.value });
    }
    renderAddress(data: any) {

        return (
            <div className={'searchDropContent pointer'} >
                <strong>{data.street}</strong><br />
                {data.suburb + " " + data.state}
            </div>
        );
    }
    public handleIframeTaskMap = (e: any) => {

        // if (e.origin !== 'https://worxonline.sharepoint.com') {
        //     return;
        // }

        if (e.data.command === 'asset selected') {

            let assetData = e.data.assets;
            if (this.props.selectModeMap === true) {
                this.props.selectAssets(assetData);
            } else if (this.props.selectParent === true) {
                this.props.selectAssetsforParent(assetData);

            }
            this.setState({
                iFramePanelOpened: false
            });


        }
        // window.removeEventListener("message", this.handleIframeTask, false);
    };


    public render(): React.ReactElement<any> {
        return (
            <div>

                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={this.props.polylines ? this.props.department : this.props.address}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.iFramePanelOpened}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={() => this.props.onClose()}
                    onShowing={() => this._onIframeLoaded()}

                >
                    <Autocomplete
                        dataSource={this.state.formattedAddressItems}
                        value={this.state.addressSearch}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'addressSearch')}
                        showClearButton={true}
                        valueExpr="FormattedAddress"
                        minSearchLength={3}
                        itemRender={this.renderAddress}
                        placeholder={this.state.loadingAddress ? "Loading addresses.." : "Search address ( Type atleast 3 letter to get results)"}
                    />
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                        <iframe
                            src={this.state.iFrameUrl}
                            allowFullScreen={true}
                            width={'100%'}
                            style={{ height: '100vh' }}
                            onLoad={this._onIframeLoaded.bind(this)}
                        />
                    </ScrollView>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Close',
                            stylingMode: "outlined",
                            onClick: () => this.props.onClose(),
                        }}
                    />
                </Popup>
            </div>
        );
    }
}
