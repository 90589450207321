import * as React from 'react';
import { TextBox } from 'devextreme-react/text-box';

interface Props {
    label?: string;
    stylingMode: string | any;
    placeholder?: string | any;
    mask?: string | any;
    useMaskedValue?: string | any;
    mode?: string | any;
    value: string;
    showClearButton?: Boolean | any;
    readOnly?: Boolean | any;
    disabled?: Boolean | any;
    onValueChanged: any;
    onKeyDown?: any;
    required?: any;
    spellcheck?: Boolean | any;
}
export default class TextBoxInput extends React.Component<Props, any> {

    public state = {
    };

    public render(): React.ReactElement<Props> {
        return (

            <TextBox
                labelMode={'floating'}
                label={this.props.label}
                stylingMode={this.props.stylingMode}
                placeholder={this.props.placeholder}
                value={this.props.value}
                onValueChanged={(e: any) => this.props.onValueChanged(e)}
                showClearButton={this.props.showClearButton}
                onEnterKey={(e: any) => this.props.onKeyDown(e)}
                mask={this.props.mask}
                // showMaskMode={'onFocus'}
                useMaskedValue={this.props.useMaskedValue}
                mode={this.props.mode}
                readOnly={this.props.readOnly}
                disabled={this.props.disabled}
                spellcheck={this.props.spellcheck}
            >
                {/* {this.props.showButton && (
                        <Button
                        name='test'
                            location={this.props.buttonLocation}
                            options={{
                                icon: 'map',
                                type: 'default',
                                onClick: () => this.props.onClickButton()
                            }}
                        />
                    )} */}
                {/* {this.props.required && (
                    <Validator>
                        <RequiredRule message="Address is required" />
                    </Validator>
                )} */}
            </TextBox>
        );
    }
}


