import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./classes/authConfig";
// import { msalConfig } from "./authConfig";
const LightTheme = React.lazy(() => import('../src/components/common/theme/lightTheme'));
const DarkTheme = React.lazy(() => import('../src/components/common/theme/darkTheme'));

export const msalInstance = new PublicClientApplication(msalConfig);
const ThemeSelector = ( children ) => {
  const CHOSEN_THEME = localStorage.getItem('theme') || 'theme-light';
  return (
    <>
      <React.Suspense fallback={<></>}>
        {(CHOSEN_THEME === 'theme-light') && <LightTheme />}
        {(CHOSEN_THEME === 'theme-dark') && <DarkTheme />}
      </React.Suspense>
      {/* {children} */}
      <App msalInstance={msalInstance}/>
    </>
  )
}
ReactDOM.render(
  <React.StrictMode>
    <ThemeSelector />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
