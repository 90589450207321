import * as React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';

import Scheduler, { Resource, View, AppointmentDragging, Scrolling } from 'devextreme-react/scheduler';
import Draggable from 'devextreme-react/draggable';
import {
  NavLink
} from "react-router-dom";
import { Config } from '../../classes/config';
import utility from '../../classes/utility';
import AlertDialog from '../common/alertDialog';
import MyMapComponent from '../common/MapComponent/GoogleMapComponent';
import ListView from './Support/ListView';
import DetailsModal from './Support/SchedulerDetailsModal';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import MapIcon from '@material-ui/icons/Map';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import EventNoteIcon from '@material-ui/icons/EventNote';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Popover from '@material-ui/core/Popover';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import global from '../../classes/global';
import SchedulerCard from './Support/SchedulerCard';
import SchedulerCrewListView from './Support/SchedulerCrewListView';
import SchedulerWorkorderListView from './Support/SchedulerWorkorderListView';
import AssignMultiple from './Support/AssignedMultiple';
import MapSchedulerList from './Support/MapSchedulerList';
import CheckBoxInput from '../common/reusableComponents/CheckBox';
import Resizable from 'devextreme-react/resizable';
import Spinner from '../common/reusableComponents/Spinner';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import MapModal from '../map/map';
import MultiSelect from '../common/reusableComponents/MultiSelect';
import SpeedDialAction from 'devextreme-react/speed-dial-action';
// import 'devextreme/dist/css/dx.light.css';
let AssignedworkOrdersFromList: any = [];
let UnassignedworkOrdersFromList: any = [];


const groups = ['crew'];
const draggingGroupName = 'workorderDataGroup';

const renderAgendaResourceCell = () => {
  return (<span></span>);
}
class DevScheduler extends React.Component<any, any> {
  public resources: any = [];
  public crewItems: any = [];
  public selectedDeptKey: any;
  public StatusItems: any = [];
  public currentUser: any;
  public setupDataPromise: any = [];
  public interval: any;
  constructor(props: any) {
    super(props);
    let currentDetail = localStorage.getItem("currentDetail");
    if (!currentDetail) {
      //window.open('/#/SignIn', '_self');
    }
    if (this.props.role === global.worxOnline_Reader) {
      window.open('#/AccessDenied', '_self');
    }
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };
    this.currentUser = this.props.currentUser;
    this.crewItems = [];
    this.selectedDeptKey = this.props.Department.id;
    this.onAppointmentFormOpening = this.onAppointmentFormOpening.bind(this);
    this.onAppointmentUpdating = this.onAppointmentUpdating.bind(this);
    this.onAppointmentUpdated = this.onAppointmentUpdated.bind(this);
    this.onCurrentDateChange = this.onCurrentDateChange.bind(this);
    this.onCurrentViewChange = this.onCurrentViewChange.bind(this);
    this.onAppointmentRemove = this.onAppointmentRemove.bind(this);
    this.onAppointmentAdd = this.onAppointmentAdd.bind(this);
    this.renderTimeCell = this.renderTimeCell.bind(this);
    this.renderDateCell = this.renderDateCell.bind(this);
    this.handleShowHildePopOver = this.handleShowHildePopOver.bind(this);
    this.handleCloseShowHildePopOver = this.handleCloseShowHildePopOver.bind(this);

    this.onDragStart = this.onDragStart.bind(this);
    this.onAdd = this.onAdd.bind(this);

    let cardView = localStorage.getItem('cardView') ? localStorage.getItem('cardView') : 'List';

    let schedulerSplitterView = localStorage.getItem('schedulerSplitterView') ? localStorage.getItem('schedulerSplitterView') : 'viewOne';
    if (window.innerWidth < 1300) {
      schedulerSplitterView = 'viewTwo'
    }

    this.state = {
      currentDate: new Date(),
      currentView: "Day",
      workorderData: [],
      unassignedWorkorderData: [],
      workordersForMap: [],
      zoom: 8,
      center: { lat: -36.753131, lng: 144.283882 },
      selectedMapBtnCrew: true,
      selectedMapBtnAssign: true,
      selectedMapBtnUnassign: true,
      loading: true,
      selected: 0,
      progressLine: 0,
      showweekend: false,
      schedulerSplitterView: schedulerSplitterView,
      workingHour: [9, 17],
      schedulerViewChange: 'list',
      cardView: cardView,
      onCrewSelected: false,
      unAssignedResizableWidth: '300px',
      useStyle: localStorage.getItem('theme') === 'theme-dark' ? true : false
    }
  }
  public componentWillMount() {
    this.getDepartments();
    this._getStatusDataAsync();

  }

  private _getStatusDataAsync() {

    utility.genericGetAPICall(Config.statuslist).then((data: any) => {
      // console.log(data);
      this.StatusItems = [];
      let woStatusItems: any = [];
      for (let r of data) {
        if (r.Statustype === 'Contractor') {
          this.StatusItems.push({ id: r.Id, text: r.Title });
        } else if (r.Statustype === 'WorkOrder') {
          woStatusItems.push({ id: r.Id, text: r.Title });
        }
      }
      this.setState({ StatusItems: this.StatusItems, woStatusItems: woStatusItems });

    }, (err) => {
      console.log(err);
    });
  }

  public getDepartments() {
    this.setState({
      loading: true,
      alertMessage: <Spinner label='Please wait...' />,
      hideAlertButton: true,
      showAlert: true
    });

    utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {

      let deptList = data;
      let deptComboBoxOoptions: any = [];
      deptList.forEach((element: any) => {

        if (element.ShowOnScheduler) {
          deptComboBoxOoptions.push({
            id: element.Id,
            text: element.Title
          });
        }
      });
      let showOnscheduler = deptComboBoxOoptions.some((d: any) => d.id === this.props.Department.id);
      if (showOnscheduler) {
        this.setState({
          deptOptions: deptComboBoxOoptions,
          department: this.props.Department
        });
        this.getTeams();
        this.getCrew();
      } else {
        this.setState({
          alertMessage: 'Oops! Look like your department is not eligible to use Scheduler. Please contact site admin if you want to access the Scheduler',
          hideAlertButton: false,
          showAlert: true,
          goHome: true
        });
      }

    }, (err) => {
      console.log(err);
    });
  }
  public getTeams() {
    this.setState({ loading: true });
    let dataToPassToService: any = {};
    dataToPassToService.DepartmentId = +this.selectedDeptKey;
    utility.genericPostAPIcall(Config.SearchTeamApi, dataToPassToService).then((data: any) => {
      let teamsItems = [];
      this.setState({ team: { id: 'All', text: 'All' } });
      teamsItems.push({ id: 'All', text: 'All' });
      data.forEach((r: any) => {
        if (this.props.role === global.worxOnline_ContractorAdmin && (r.Code + "|" + r.Title) === this.props.team) {
          this.setState({ team: { id: r.Id, text: (r.Code + ' - ' + r.Title) } });
        }
        teamsItems.push({ id: r.Id, text: (r.Code + ' - ' + r.Title) });
      });

      this.setState({ teamsItems: teamsItems, allteamsItems: teamsItems, loading: false });
    }, (err: any) => {
      console.log(err);
    });
  }
  public getCrew() {
    this.setState({
      alertMessage: <Spinner label='Please wait...' />,
      hideAlertButton: true,
      showAlert: true,
      loading: true
    });

    let dataToPassToService: any = {};

    dataToPassToService.DepartmentId = +this.selectedDeptKey;
    utility.genericPostAPIcall(Config.SearchCrewApi, dataToPassToService).then((data: any) => {

      let crews = data;
      this.resources = [];
      this.crewItems = [];
      let selectedCrews: any = [];
      crews.forEach((element: any) => {


        if (this.props.role === global.worxOnline_ContractorAdmin && element.TeamID === this.props.teamId) {
          selectedCrews.push({ id: (element.Id), text: (`${element.Title} (${element.CrewID})`), 'Select All': (`${element.Title} (${element.CrewID})`), color: element.MapColour ? element.MapColour : '#90C3D4', teamID: element.TeamID, teamName: element.TeamName, loading: false });

        }
        else {
          this.crewItems.push({ id: (element.Id), text: (`${element.Title} (${element.CrewID})`), 'Select All': (`${element.Title} (${element.CrewID})`), color: element.MapColour ? element.MapColour : '#90C3D4', teamID: element.TeamID, teamName: element.TeamName, loading: false });
        }

      });
      if (selectedCrews.length > 0) {
        this.resources = selectedCrews;
        this.setState({
          crewItems: selectedCrews,
          allcrewItems: this.crewItems,
        });
      }
      else {
        this.resources = this.crewItems;
        this.setState({
          crewItems: this.crewItems,
          allcrewItems: this.crewItems,
        });
      }



      this.setupDataPromise = [];
      this.getWorkOrdersForDepartment(this.state.currentDate, this.state.currentView, false);
      this.getUnassignedWorkOrders();
      Promise.all(this.setupDataPromise).then(() => {

        this.showFilterMapIcon();
      });
    }, (err) => {
      console.log(err);
    });
  }
  public getWorkOrders() {
    this.setupDataPromise = [];
    this.setState({ workorderData: [] });
    this.getWorkOrdersForDepartment(this.state.currentDate, this.state.currentView, true)
    this.getUnassignedWorkOrders();
    Promise.all(this.setupDataPromise).then(() => {
      setTimeout(() => this.showFilterMapIcon(), 500);
    });
  }
  public getWorkOrdersForDepartment(date?: any, day?: any, refresh?: any) {
    this.setState({
      alertMessage: <Spinner label='Please wait...' height={40} width={40} size={"medium"} />,
      hideAlertButton: true,
      showAlert: true
    });
    AssignedworkOrdersFromList = []

    let dataToPassToServiceUnassigned: any = {};

    let tempDate = date ? date : new Date();
    let tempDay = day ? day : 'Day';
    let range = this.getRange(tempDate, tempDay);

    let startDate = range ? range.startDate : new Date();
    startDate.setHours(0, 0, 0, 0)
    let endDate = range ? range.endDate : new Date();
    if (tempDay === 'Day' || tempDay === 'agenda') {
      endDate = new Date(endDate);
      endDate.setDate(endDate.getDate() + 1);
    }
    dataToPassToServiceUnassigned.DepartmentId = +this.selectedDeptKey;
    dataToPassToServiceUnassigned.ScheduledStartFrom = startDate.toISOString();
    dataToPassToServiceUnassigned.ScheduledStartTo = endDate.toISOString().substring(0, 10) + "T13:59:59.000Z";

    let searchAPI = `${Config.WOdetailsSearchAPI}?PageNumber=1&PageSize=1000&showAll=true`
    this.setupDataPromise.push(
      utility.genericPostAPIcall(searchAPI, dataToPassToServiceUnassigned).then((results: any) => {

        if (results.length === 0) {
          let tempStartDate = new Date();
          let tempEndDate = new Date();
          tempStartDate.setHours(0, 0, 0, 0);
          tempEndDate.setHours(24, 0, 0, 0);
          AssignedworkOrdersFromList.push({
            text: "No Work Order Found",
            startDate: tempStartDate,
            endDate: tempEndDate,
            hideInMap: true

          });
        }
        else {
          results.forEach((item: any) => {
            if (item.Ishandledinworx) {
              let startdate = new Date(item.Datescheduledstart);
              let enddate = new Date();
              if (item.Datescheduledend) {
                enddate = new Date(item.Datescheduledend);
              } else {
                enddate = new Date(item.Datescheduledstart);
                enddate.setHours(enddate.getHours() + 1);
              }

              let bgColor = 'yellow';
              let lat;
              let lng;
              if (item.Latlong) {
                lat = item.Latlong.Y;
                lng = item.Latlong.X;
              }

              let newWorkorder = false;
              if (item.Datecreated) {
                let tempDate = new Date();
                tempDate.setHours(tempDate.getHours() - 1);
                if (new Date(item.Datecreated).getTime() >= tempDate.getTime()) {
                  newWorkorder = true;
                }
              }
              if (item.Wonumber) {
                let tempAddress;
                if (item.LocationGeom && item.LocationGeom !== null) {
                  let tempLocation = JSON.parse(item.LocationGeom);

                  tempAddress = `${tempLocation.city}`;

                }
                let tempstreetAddress;
                if (item.LocationGeom) {
                  let tempLocationGeom = JSON.parse(item.LocationGeom);

                  tempstreetAddress = `${tempLocationGeom.streetNumber} ${tempLocationGeom.streetName} ${tempLocationGeom.suffix} ${tempLocationGeom.city} ${tempLocationGeom.region} ${tempLocationGeom.postalCode}`;

                }
                var JSONAssetData;
                let assetdata;
                if (item.Assetsinfo !== null) {
                  JSONAssetData = JSON.parse(item.Assetsinfo);
                  if (!Array.isArray(JSONAssetData)) {

                    JSONAssetData = [JSONAssetData];
                  }
                  if (JSONAssetData && JSONAssetData.length > 0) {
                    assetdata = JSONAssetData[0].FormattedAssetId ? JSONAssetData[0].FormattedAssetId : JSONAssetData[0].assetId;
                  }

                }

                AssignedworkOrdersFromList.push({
                  id: item.Id,
                  servicerequest: item.Servicerequestid,
                  Workordernumber: item.Wonumber,
                  text: item.Title,
                  priority: item.Priority,
                  PriorityCode: item.PriorityCode ? (item.PriorityCode + '-' + item.Priority) : '',
                  worktype: item.Worktypename,
                  crewtitle: item.Crewtitle,
                  assetdata: assetdata,
                  crew: item.Crewid,
                  createddate: item.Datecreated,
                  reporteddate: item.Datescheduledstart ? moment(item.Datescheduledstart).format("DD/MM/YYYY") : '',
                  eststartdate: item.Datescheduledstart,
                  fieldstatus: item.Contractorstatus,
                  wostatus: item.Workorderstatus,
                  contractorstatus: item.Contractorstatus,
                  suburb: tempAddress,
                  location: item.Location,
                  startDate: startdate,
                  endDate: enddate,
                  bgColor: bgColor,
                  lat: lat ? lat : '',
                  long: lng ? lng : '',
                  WOComments: item.WOComments,
                  AllowEditing: false,
                  newCreated: newWorkorder,
                  selectedWO: false,
                  address: tempstreetAddress
                });
              }
            }
          });
        }
        if (refresh) {
          setTimeout(() => this.showFilterMapIcon(), 200);
        }
        const sortedbyDate = utility._sortItems(AssignedworkOrdersFromList, 'reporteddate', true);
        const sorteditems = utility._sortItems(sortedbyDate, 'PriorityCode', true);


        this.setState({
          workorderData: sorteditems,
          showAlert: false,
          dateRange: range,
          onCrewSelected: true
        });


      }, (err) => {
        console.log(err);
        this.setState({
          alertMessage: 'Something went wrong! Unable to get assiged work orders',
          showAlert: true,
          hideAlertButton: false,
        });
      }));

  }
  public getUnassignedWorkOrders() {

    UnassignedworkOrdersFromList = [];

    let unassignedstatus: any = [];
    let wostatus: any = [];

    if (this.state.StatusItems) {
      this.state.StatusItems.forEach((s: any) => {
        if (s.text === global.Unassigned || s.text === global.Reassigned) {
          unassignedstatus.push(s.id);
        }
      });
    }
    if (this.state.woStatusItems) {
      this.state.woStatusItems.forEach((s: any) => {
        if (s.text === 'Open') {
          wostatus.push(s.id);
        }
      });
    }
    let dataToPassToServiceUnassigned: any = {};
    dataToPassToServiceUnassigned.DepartmentId = +this.selectedDeptKey;
    dataToPassToServiceUnassigned.CrewId = -1;
    if (this.props.role === global.worxOnline_ContractorAdmin) {
      dataToPassToServiceUnassigned.TeamCode = this.props.team;
    }
    if (unassignedstatus && unassignedstatus.length > 0) {
      dataToPassToServiceUnassigned.Contractorstatusid = unassignedstatus;
    }
    if (wostatus && wostatus.length > 0) {
      dataToPassToServiceUnassigned.StatusId = wostatus;
    }
    this.setupDataPromise.push(
      utility.genericPostAPIcall(`${Config.WOdetailsSearchAPI}?PageNumber=1&PageSize=1000&showAll=true`, dataToPassToServiceUnassigned).then((results: any) => {
        // debugger;
        if (results.length === null) {
        }
        else {
          results.forEach((item: any) => {
            if (item.Ishandledinworx) {
              let lat;
              let lng;
              if (item.Latlong) {
                lat = item.Latlong.Y;
                lng = item.Latlong.X;
              }
              // to see if work order is newly created to display  in bold

              let newWorkorder = false;
              if (item.Datecreated) {
                let tempDate = new Date();
                tempDate.setHours(tempDate.getHours() - 1);
                if (new Date(item.Datecreated).getTime() >= tempDate.getTime()) {
                  newWorkorder = true;
                }
              }
              if (item.Wonumber) {
                let tempAddress;
                if (item.LocationGeom && item.LocationGeom !== null) {
                  let tempLocation = JSON.parse(item.LocationGeom);

                  tempAddress = `${tempLocation.city}`;

                }
                let tempstreetAddress;
                if (item.LocationGeom) {
                  let tempLocationGeom = JSON.parse(item.LocationGeom);

                  tempstreetAddress = `${tempLocationGeom.streetNumber} ${tempLocationGeom.streetName} ${tempLocationGeom.suffix} ${tempLocationGeom.city} ${tempLocationGeom.region} ${tempLocationGeom.postalCode}`;

                }
                var JSONAssetData;
                let assetdata;
                if (item.Assetsinfo !== null) {
                  JSONAssetData = JSON.parse(item.Assetsinfo);
                  if (!Array.isArray(JSONAssetData)) {

                    JSONAssetData = [JSONAssetData];
                  }
                  // console.log("JSONAssetData", JSONAssetData);
                  if (JSONAssetData && JSONAssetData.length > 0) {
                    assetdata = JSONAssetData[0].FormattedAssetId ? JSONAssetData[0].FormattedAssetId : JSONAssetData[0].assetId;
                  }

                }
                UnassignedworkOrdersFromList.push({
                  id: item.Id,
                  servicerequest: item.Servicerequestid,
                  Workordernumber: item.Wonumber,
                  text: item.Title,
                  priority: item.Priority,
                  PriorityCode: item.PriorityCode ? (item.PriorityCode + '-' + item.Priority) : '',
                  assetdata: assetdata,
                  worktype: item.Worktypename,
                  crew: item.Crewid,
                  // crewColor: <div className="crewTitleColorScheduler" style={{backgroundColor:this.getCrewColor(item)}}> </div>,
                  createddate: item.Datecreated,
                  reporteddate: item.Datescheduledstart ? moment(item.Datescheduledstart).format("DD/MM/YYYY") : '',
                  eststartdate: item.Datescheduledstart,
                  fieldstatus: global.Unassigned,
                  wostatus: item.Workorderstatus,
                  contractorstatus: item.Contractorstatus,
                  suburb: tempAddress,
                  location: item.Location,
                  startDate: item.Datescheduledstart ? new Date(item.Datescheduledstart) : null,
                  endDate: item.Datescheduledend ? new Date(item.Datescheduledend) : null,
                  lat: lat ? lat : 0,
                  long: lng ? lng : 0,
                  WOComments: item.WOComments,
                  newCreated: newWorkorder,
                  selectedWO: false,
                  address: tempstreetAddress
                });
              }
            }

          });
        }

        UnassignedworkOrdersFromList = utility._sortItems(UnassignedworkOrdersFromList, 'reporteddate', true);
        const sorteditems = utility._sortItems(UnassignedworkOrdersFromList, 'PriorityCode', true);

        this.setState({
          unassignedWorkorderData: sorteditems,
          // loading: false, 
          // showAlert: false,        
          // progressLine: 100,
        });

      }, (err) => {
        console.log(err);
        this.setState({
          alertMessage: 'Something went wrong! Unable to get unassiged work orders',
          showAlert: true,
          hideAlertButton: false,
        });
      }));

  }
  // public getNotificationsItems(items: any) {

  //   let newWOs = this.state.newWorkorders ? this.state.newWorkorders : [];
  //   if (newWOs.length !== items.length) {
  //     this.getUnassignedWorkOrders();
  //     this.setState({ newWorkorders: items });
  //   }
  // }
  public handleSelectTabStrip = (e: any) => {
    this.setState({ selected: e.selected });
    if (e.selected === 1) {
      this.setState({
        woType: '',
        completedchk: false
      })
    }
  }
  public render() {
    const getObjectKeys = (obj: any) => {

      var newArray: any = [];
      if (obj) {
        newArray = Object.keys(obj);
      }
      return newArray;
    }
    let schedulerGridDivWidth: any = '100%';
    let maxSchedulerWidth: any = '100%';
    let schedulerGridDivWidth1: boolean = false;
    if (window.document.getElementsByClassName('MuiDrawer-paper').length > 0 && !schedulerGridDivWidth1) {
      var drawerWidth = window.document.getElementsByClassName('MuiDrawer-paper')[0].clientWidth;
      schedulerGridDivWidth = (window.innerWidth - drawerWidth);
      maxSchedulerWidth = schedulerGridDivWidth
      if (this.state.schedulerSplitterView === 'viewOne') {
        schedulerGridDivWidth -= 400;
        maxSchedulerWidth = schedulerGridDivWidth
      }
      schedulerGridDivWidth += 'px'
      schedulerGridDivWidth1 = true;

    }
    let isNarrow = false;
    if (window.innerWidth < 984) {
      isNarrow = true;
    }
    return (
      <div className={'dashboardHome'}>
        {this.state.showModal && this.renderDetialsModal()}
        {this.state.showAlert && this.alert()}
        {this.state.showMoreSettingMenu && this.renderShowMoreSettingMenu()}
        {this.state.openMapModal && this.renderMapModal()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}>Scheduler</span>
          </div>
        </div>
        <div className={"schedulerDiv"}>
          {!this.state.loading ?
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Paper elevation={6}>
                  <div className={'mtl-20'}>
                    <Grid container spacing={1}>
                      <Grid item xs={10} sm={10} md={9} lg={10}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6} md={6} lg={4}>
                            <SelectBoxInput
                              stylingMode='underlined'
                              displayExpr="text"
                              valueExpr="id"
                              showClearButton={false}
                              label='Organisation'
                              items={this.state.deptOptions}
                              selectedItem={this.state.department}
                              onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                              disabled={(this.props.role === global.worxOnline_ContractorAdmin || this.props.role === global.worxOnline_WorxAdmin) ? true : false}

                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={4}>
                            <SelectBoxInput
                              stylingMode='underlined'
                              displayExpr="text"
                              valueExpr="id"
                              showClearButton={false}
                              label='Teams'
                              items={this.state.teamsItems}
                              selectedItem={this.state.team}
                              onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'team')}
                              disabled={(this.props.role === global.worxOnline_ContractorAdmin) ? true : false}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={4}>
                            <MultiSelect
                              items={this.state.crewItems}
                              onChange={(e: any) => this._multiFieldChanged(e, 'crewteam')}
                              selectedItem={this.state.crewteam}
                              // opened={true}
                              label="Crew"
                              stylingMode='underlined'
                              displayExpr="text"
                              showClearButton={true}
                              placeholder="Select a value..."
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={2} sm={2} md={3} lg={2}>
                        <Grid container spacing={1}>

                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <IconButton aria-label="view" color="primary" className="schedulerSettingBtn" onClick={this.handleShowHildePopOver}>
                              <SettingsIcon className={'primary'} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  {/* <ProgressBar
                  value={this.state.progressLine}
                  labelVisible={false}
                  animation={{ duration: this.state.progressLine  === 0 ? 0 : 90000 }}
                /> */}
                </Paper>
              </Grid>
            </Grid>
            :
            <Spinner size="large" label="Loading all component. Please wait..." />
          }
        </div>
        {this.state.showColorLegend && (
          <div style={{ margin: '15px 20px', animation: 'fadeIn 2s' }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Paper elevation={6}>
                  <div className='displayFlex'>
                    <div style={{ padding: '10px 20px', width: '100%' }}>Priority Colors</div>
                    <CloseOutlinedIcon style={{ margin: '10px' }} className={'primary'} onClick={() => this.setState({ showColorLegend: false })} />
                  </div>
                  <div className={'ColorLegendDiv'}>
                    {getObjectKeys(Config.priorityColors).map((item: any) => {
                      return (
                        <div className={'ColorLegendItem'} style={{ backgroundColor: Config.priorityColors[item] }}>{item}</div>
                      );
                    })
                    }
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
        <br />
        <Paper square>

          <Tabs
            value={this.state.selected}
            onChange={this.handleSelect}
            indicatorColor="primary"
            // textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label={<span className='tabHeading'><EventNoteIcon className="subMenuLinkIcon" /> Calendar</span>} {...a11yProps(0)} />
            <Tab label={<span className='tabHeading'><ListAltIcon className="subMenuLinkIcon" />List</span>}  {...a11yProps(1)} />
            <Tab label={<span className='tabHeading'><MapIcon className="subMenuLinkIcon" /> Map</span>}  {...a11yProps(2)} />
          </Tabs>
        </Paper>
        <br />

        <TabPanel value={this.state.selected} index={0}>
          <div className="schedulerDiv mt-20">
            <Grid container spacing={1}>
              <Grid className='schedulerGridDiv' item xs={12} sm={12}>

                <div
                  className={this.state.schedulerSplitterView === "viewOne" ? 'displayFlex' : ''}
                  style={{
                    // width:schedulerGridDivWidth,
                    display: this.state.schedulerSplitterView === "viewOne" ? 'flex' : 'block'
                  }}>
                  <Resizable
                    width={this.state.scheduleResizableWidth ? this.state.scheduleResizableWidth : isNarrow ? '100%' : (maxSchedulerWidth - 50)}
                    handles="right"
                    minWidth={maxSchedulerWidth - 400}
                    maxWidth={maxSchedulerWidth}
                    onResize={(e: any) => this.onResizeDiv(e)}
                  >
                    <Paper elevation={6} style={{ height: '70vh' }}>
                      <div className={'p-10'}>
                        {(this.state.workorderData && this.state.workorderData.length > 0) && (
                          <div>
                            <Scheduler
                              timeZone="Australia/Sydney"
                              dataSource={this.state.workorderData}
                              // dataSource={this.state.workorderData}
                              currentView={this.state.currentView}
                              currentDate={this.state.currentDate}
                              groups={groups}
                              // groups={this.state.currentView  === 'agenda' ? [''] : groups}
                              // height={600}
                              height={'68vh'}
                              // width={this.state.scheduleResizableWidth-10}
                              firstDayOfWeek={0}
                              startDayHour={this.state.workingHour ? this.state.workingHour[0] : 9}
                              endDayHour={this.state.workingHour ? this.state.workingHour[1] : 17}
                              showAllDayPanel={true}
                              crossScrollingEnabled={true}
                              resourceCellRender={this.renderResourceCell}
                              // adaptivityEnabled={true}
                              editing={{ allowAdding: false }}
                              showCurrentTimeIndicator={true}
                              appointmentComponent={this.renderAppointment}
                              appointmentTooltipRender={this.renderAppointmentTooltip}
                              onAppointmentFormOpening={this.onAppointmentFormOpening}
                              onAppointmentUpdating={this.onAppointmentUpdating}
                              onAppointmentUpdated={this.onAppointmentUpdated}
                              onCurrentViewChange={(e: any) => this.onCurrentViewChange(e)}
                              onCurrentDateChange={this.onCurrentDateChange}
                              timeCellRender={this.renderTimeCell}
                              dateCellRender={this.renderDateCell}
                            // onOptionChanged={this.onOptionChanged}
                            // getStartViewDate={this.onAppointmentUpdated}
                            >

                              <View
                                name="Day"
                                type="timelineDay"
                                groupOrientation="vertical"
                                cellDuration={60}
                                intervalCount={1}
                              />
                              <View
                                name={"Work Week"}
                                type={"timelineWorkWeek"}
                                groupOrientation="vertical"
                                cellDuration={(this.state.workingHour[1] - this.state.workingHour[0]) * 60}
                                intervalCount={1}
                                startDayHour={this.state.workingHour ? this.state.workingHour[0] : 9}
                                endDayHour={this.state.workingHour ? this.state.workingHour[1] : 17}

                              />
                              <View
                                name={"Week"}
                                type={"timelineWeek"}
                                groupOrientation="vertical"
                                cellDuration={(this.state.workingHour[1] - this.state.workingHour[0]) * 60}
                                intervalCount={1}
                                startDayHour={this.state.workingHour ? this.state.workingHour[0] : 9}
                                endDayHour={this.state.workingHour ? this.state.workingHour[1] : 17}

                              />
                              <View
                                name="Month"
                                type="timelineMonth"
                                groupOrientation="vertical"
                                cellDuration={60}
                                intervalCount={1}
                              />
                              <View
                                name="Calendar"
                                type="month"
                                groupOrientation="vertical"
                                cellDuration={60}
                                intervalCount={1}
                                maxAppointmentsPerCell={2}
                              />
                              <View
                                type="agenda"
                                resourceCellRender={renderAgendaResourceCell}
                                agendaDuration={1}
                              />
                              <Resource
                                dataSource={this.resources}
                                fieldExpr="crew"
                                label="Crew"
                                useColorAsDefault={'crew'}
                              />
                              <AppointmentDragging
                                group={draggingGroupName}
                                onRemove={this.onAppointmentRemove}
                                onAdd={this.onAppointmentAdd}
                              />

                              <Scrolling
                                mode='virtual'
                              />
                            </Scheduler>
                          </div>
                        )}

                      </div>
                    </Paper>
                  </Resizable>
                  {/* </Grid> */}
                  {this.state.schedulerSplitterView === 'viewOne' && (
                    // <Grid item xs={12} sm={3}>
                    <Paper elevation={6} className="fill-available" style={{ height: '70vh', marginLeft: '10px', overflow: 'auto' }}>

                      {this.renderListView(false, false)}

                    </Paper>
                    // </Grid>
                  )}
                </div>
                {/* </Grid> */}

              </Grid>
              <Grid item xs={12} sm={12}>
                <Paper elevation={6}>

                  {this.state.schedulerSplitterView === "viewTwo" && (

                    this.renderListView(false, false)
                  )}
                </Paper>
              </Grid>

            </Grid >
            <br />
          </div>
        </TabPanel>
        <TabPanel value={this.state.selected} index={1}>
          <div className="schedulerDiv mt-20">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Paper elevation={6} style={{ minHeight: isNarrow ? 'auto' : '70vh', maxHeight: '70vh', overflow: 'auto' }}>
                      <div>
                        {(this.state.workorderData) && (
                          <div
                          // style={{ height: "80vh"}}
                          >
                            {!this.state.loading ?
                              <SchedulerCrewListView
                                crews={this.resources}
                                workorders={this.state.workorderData}
                                // // dataSource={this.state.workorderData}
                                // currentView={this.state.currentView}
                                // currentDate={this.state.currentDate}
                                view={this.state.cardView}
                                onDragOver={(e: any) => this.onDragOver(e)}
                                onDrop={(e: any, crew: any) => this.onDrop(e, crew)}
                                selectedWorkorderbyCrew={(wo: any, crew: any) => this.onSelecteWoFromCrewList(wo, crew)}
                                onCrewSelected={this.state.onCrewSelected}
                                stopOnCrewSelected={() => this.stopOnCrewSelected()}
                              />
                              :
                              <><br /><Spinner size="large" height={60} width={60} /></>
                            }
                          </div>
                        )}
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Paper elevation={6} style={{ minHeight: isNarrow ? 'auto' : '70vh', maxHeight: '70vh', overflow: 'auto' }}>
                      <div>
                        {(this.state.workorderData) && (
                          <div
                          >
                            {!this.state.loading ?
                              <SchedulerWorkorderListView
                                crews={this.resources}
                                workorders={this.state.workorderData}
                                selectedWorkorderbyCrew={this.state.selectedWorkorderbyCrew}
                                // dataSource={this.state.workorderData}
                                currentView={this.state.currentView}
                                currentDate={this.state.currentDate}
                                viewItem={(e: any) => this.viewDetials(e)}
                                viewWO={(e: any) => this.viewWO(e)}
                                zoomToSelectWO={(e: any) => this.zoomToSelectWO(e, true)}
                                view={this.state.cardView}
                                onDragOver={(e: any) => this.onDragOver(e)}
                                onDrop={(e: any) => this.onDrop(e)}
                                selectedCrewInSchedulerList={this.state.selectedCrewInSchedulerList}
                                onDragStart={(e: any, woData: any) => this.onDragStart(e, woData)}
                                onCrewSelected={this.state.onCrewSelected}
                                stopOnCrewSelected={() => this.stopOnCrewSelected()}
                                onViewDateChange={(date: any, view: any) => this.onViewDateChange(date, view)}
                                unassignWO={(woData: any) => this.unassignWO(woData)}
                              /> :
                              <><br /><Spinner size="large" height={60} width={60} /></>
                            }
                          </div>
                        )}
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Paper elevation={6} style={{ minHeight: isNarrow ? 'auto' : '70vh', maxHeight: '70vh', overflow: 'auto' }}>
                      {this.renderListView(true, true, true)}
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Paper elevation={6} className='mt-10'>

                    {this.renderMapView()}

                  </Paper>
                </Grid>
              </Grid>
            </Grid >
            <br />
          </div>
        </TabPanel>
        <TabPanel value={this.state.selected} index={2}>
          <div className="schedulerDiv mt-20">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Paper elevation={6} className='mt-10'>
                  {this.renderMapView(true, isNarrow)}
                </Paper>
                <br />
              </Grid>
            </Grid>
          </div>
        </TabPanel>

        {(this.state.selectedWOItems && this.state.selectedWOItems.length > 0) && !this.state.multiSelectbyDragDrop && (
          <div className="buttonDiv">
            <SpeedDialAction
              icon="add"
              label={`Assign ${this.state.selectedWOItems.length} WOs`}
              // visible={false}
              onClick={() => this.handleActionButton()}
              style={{ zIndex: 999 }} />
          </div>
        )}
        {this.state.showAssignMultipleModal && this.renderAssignMultipleModal()}
      </div>
    );
  }
  public renderMapView(showList?: any, isNarrow?: boolean) {
    var statusKeys = this.state.statusGroup && Object.keys(this.state.statusGroup);

    return (
      <div>
        {!this.state.loading ?
          <>
            <div className="mapBtnGroupContainer">
              <div className="mapBtnGroup">
                {/* <div className={this.state.selectedMapBtnCrew?"selectedMapBtn":''} onClick={()=>this.filerMapIcon("selectedMapBtnCrew")}>Crew</div> */}
                <div className='mapBtnAssign selectedMapBtn'> <CheckBoxInput value={this.state.selectedMapBtnAssign ? true : false} onValueChanged={() => this.filerMapIcon("selectedMapBtnAssign")} text={global.Assigned} /></div>
                <div className='mapBtnAssign selectedMapBtn'> <CheckBoxInput value={this.state.selectedMapBtnUnassign ? true : false} onValueChanged={() => this.filerMapIcon("selectedMapBtnUnassign")} text={global.Unassigned} /></div>
                {(this.state.zoom > 8) && <div className={'zoomOutMapBtn'} onClick={() => this.zoomOutWO()}><ZoomOutMapIcon titleAccess="Zoom Out" /></div>}
              </div>
            </div>
            <div className='legendWrapper'>
              {statusKeys && statusKeys.map((status: any) => {

                if (status !== 'undefined' && status !== 'null') {
                  return (
                    <div className='legendDiv' style={{ backgroundColor: Config.statusColors[status] }}>
                      <span className='legendContrastColor'>{status}</span>
                    </div>
                  )
                }
              })}
              {(this.state.selectedWOItems && this.state.selectedWOItems.length > 0 && <div className='legendDiv' style={{ backgroundColor: '#888888' }}>Selected Workorder</div>)}
            </div>
            {showList && (
              <div className={isNarrow ? 'listOnMapWrapperNarrow' : 'listOnMapWrapper'}>
                <MapSchedulerList
                  items={this.state.workordersForMap}
                  viewItem={(e: any) => this.viewDetials(e)}
                  viewWO={(e: any) => this.viewWO(e)}
                  zoomToSelectWO={(e: any) => this.zoomToSelectWO(e, false)}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  view={this.state.cardView}
                  onUnassignedSelection={(e: any) => this.onUnassignedSelection(e)}
                  onUnassignedSelectionOption={true}
                  schedulerlistDraggingAllowed={false}
                />
              </div>
            )}
            <MyMapComponent
              schedulerMarkers={this.state.WOMarker}
              useStyle={this.state.useStyle}
              showSchedulerMarker={true}
              center={this.state.center}
              zoom={this.state.zoom}
              crewLocation={this.state.crewLocation}
              draggingGroupName={draggingGroupName}
              onListDragStart={(e: any) => this.onListDragStart(e)}
              items={this.state.unassignedWorkorderData}
              onItemDragStart={(e: any) => this.onItemDragStart(e)}
              onItemDragEnd={(e: any) => this.onItemDragEnd(e)}
              viewItem={(e: any) => this.viewDetials(e)}
              viewWO={(e: any) => this.viewWO(e)}
              zoomToSelectWO={(e: any) => this.zoomToSelectWO(e, false)}
              callAfterInit={this.state.callAfterInitMap}
              stopCommsAfterInit={() => this.stopCommsAfterInit()}
              view={this.state.cardView}
              onUnassignedSelection={(e: any) => this.onUnassignedSelection(e)}
              onUnassignedSelectionOption={true}
              showMarker={true}
            />
          </> :
          <><br /><Spinner size="large" height={60} width={60} /></>
        }
      </div>
    )
  }
  public renderListView(schedulerlistDraggingAllowed: any, onUnassignedSelectionOption: any, showOnMap?: any) {
    return (
      <>
        <div className={'ptlr-20'}>Unassigned Work Orders ({this.state.unassignedWorkorderData.length})</div>

        {this.state.unassignedWorkorderData?.length > 0 ?
          <Draggable
            id="list"
            data="dropArea"
            group={draggingGroupName}
            onDragStart={(e: any) => this.onListDragStart(e)}>
            <ListView
              items={this.state.unassignedWorkorderData}
              draggingGroupName={draggingGroupName}
              onListDragStart={(e: any) => this.onListDragStart(e)}
              onItemDragStart={(e: any) => this.onItemDragStart(e)}
              onItemDragEnd={(e: any) => this.onItemDragEnd(e)}
              viewItem={(e: any) => this.viewDetials(e)}
              viewWO={(e: any) => this.viewWO(e)}
              zoomToSelectWO={(e: any) => this.zoomToSelectWO(e, true)}
              callAfterInit={this.state.callAfterInit}
              stopCommsAfterInit={() => this.stopCommsAfterInit()}
              onDragStart={(e: any, woData: any) => this.onDragStart(e, woData)}
              view={this.state.cardView}
              onUnassignedSelection={(e: any) => this.onUnassignedSelection(e)}
              onUnassignedSelectionOption={onUnassignedSelectionOption}
              schedulerlistDraggingAllowed={schedulerlistDraggingAllowed}
            />
          </Draggable>
          :
          <div className='alignCenter'>
            No data to display
          </div>}
      </>
    )
  }
  public renderMapModal() {
    return (
      <MapModal
        polylines={false}
        center={this.state.center}
        showModal={this.state.openMapModal}
        zoom={this.state.zoom}
        onClick={() => this._closeMapModal()}
        address={this.state.address}
        crewLocation={this.state.crewLocation}
      />
    );
  }
  private _closeMapModal = (): void => {
    this.setState({ openMapModal: false, });
  }

  public handleActionButton = () => {
    this.setState({ showAssignMultipleModal: true });
  }
  public renderAssignMultipleModal() {
    return (
      <AssignMultiple
        showModal={this.state.showAssignMultipleModal}
        // selectedWOItems={this.state.selectedWOItems}
        selectedCrew={this.state.selectedCrewInSchedulerListforCrewListDrop ? this.state.selectedCrewInSchedulerListforCrewListDrop : this.state.selectedCrewInSchedulerList}
        StatusItems={this.state.StatusItems}
        crewItems={this.state.crewItems}
        assignMultipleWO={(item: any) => this.assignMultipleWO(item)}
        onClose={() => this._closeAssignMultiple()}
      />
    )
  }
  public _closeAssignMultiple() {

    this.setState({
      showAssignMultipleModal: false,
      multiSelectbyDragDrop: false,
    });
  }
  public assignMultipleWO(item: any) {

    this.setState({ loading: true });
    let tempStatus = this.state.StatusItems;
    let tempFieldStatus = tempStatus.find((r: any) => r.text === global.Assigned);
    let tempUnassignedWorkorderData = this.state.unassignedWorkorderData;
    let tempWorkorderData = this.state.workorderData;
    let selectedWOItems = this.state.selectedWOItems;

    let crewName = this.resources.find((r: any) => r.id === item.crew.id)
    let comments = `Work order dispatched to ${crewName.text} from scheduler`;
    // let Created = `${this.currentUser.DisplayName} ${utility.cuniq()}`

    return new Promise<void>((resolve: () => void): void => {
      let promises: Promise<any>[] = [];
      selectedWOItems.forEach((wo: any) => {
        const index = this.state.unassignedWorkorderData.findIndex((w: any) => w.id === wo.id);

        // let description = `${wo.WOComments}\n [${Created}] ${comments}`;

        if (index >= 0) {
          wo.fieldstatus = global.Assigned;

          tempUnassignedWorkorderData.splice(index, 1);
          tempWorkorderData.push(wo);

        }
        let dataToUpdate: any = {};
        dataToUpdate.Id = +wo.id;
        dataToUpdate.Crewid = item.crew.id;
        dataToUpdate.ContractorStatusID = tempFieldStatus.id;
        dataToUpdate.Comments = comments;
        // dataToUpdate.WoComments = description;
        dataToUpdate.UpdatedBy = this.currentUser.Email;
        // dataToUpdate.StatusID = tempFieldStatus.key;
        dataToUpdate.ScheduleStartDate = item.startDate.toISOString();
        dataToUpdate.ScheduleFinishDate = item.endDate.toISOString();
        // console.log({dataToUpdate});
        this.showSchedulingPopup();
        let promise = utility.genericUpdatePortalAPICall(Config.SchedulerWorkUpdateList, dataToUpdate).then(() => {

        }, (err) => {
          console.log(err);
          this.showErrorSchedulingPopup();
        });
        promises.push(promise);
      });
      Promise.all(promises).then(() => {

        resolve();
        this.getWorkOrders();
        // this.setState({
        //     unassignedWorkorderData: [...tempUnassignedWorkorderData],
        //     workorderData: [...tempWorkorderData]
        //   });
        //   AssignedworkOrdersFromList = [...this.state.workorderData];
        //   UnassignedworkOrdersFromList = [...tempWorkorderData];

        // this.showFilterMapIcon();
        this.setState({
          hideAlertButton: false,
          showAlert: false,
          selectedWOItems: [],
          showAssignMultipleModal: false,
          multiSelectbyDragDrop: false
        });
        // setTimeout(()=>this.setState({assignloading:false}),500);
      });
    });

  }
  public createItems(forAll?: any) {
    let taskListC: any = [];
    if (AssignedworkOrdersFromList && UnassignedworkOrdersFromList) {


      let tasks = AssignedworkOrdersFromList.concat(UnassignedworkOrdersFromList);

      if (!forAll) {
        tasks = tasks.filter((t: any) => t.fieldStatus === global.Unassigned);
      }
      tasks.forEach((item: any) => {
        let tempAddress;
        if (item.LocationGeom && item.LocationGeom !== null) {
          let tempLocation = JSON.parse(item.LocationGeom);

          tempAddress = `${tempLocation.city}`;

        }
        let tempstreetAddress;
        if (item.LocationGeom) {
          let tempLocationGeom = JSON.parse(item.LocationGeom);

          tempstreetAddress = `${tempLocationGeom.streetNumber} ${tempLocationGeom.streetName} ${tempLocationGeom.suffix} ${tempLocationGeom.city} ${tempLocationGeom.region} ${tempLocationGeom.postalCode}`;

        }
        taskListC.push({
          id: item.id,
          text: item.text,
          worktype: item.worktype,
          Workordernumber: item.Workordernumber,
          location: item.location,
          suburb: tempAddress,
          priority: item.priority,
          PriorityCode: item.PriorityCode ? (item.PriorityCode + '-' + item.Priority) : '',
          lat: item.lat,
          long: item.long,
          fieldstatus: item.fieldstatus,
          wostatus: item.wostatus,
          contractorstatus: item.contractorstatus,
          servicerequest: item.servicerequest,
          selectedWO: item.selectedWO,
          WOComments: item.WOComments,
          crewColor: <div className="crewTitleColorScheduler" style={{ backgroundColor: this.getCrewColor(item) }}> </div>,
          address: tempstreetAddress
        });
      });
    }
    return taskListC;
  }
  public getCrewColor(item: any) {
    let crewColor = '#000';
    if (item.crew) {
      let tempCrewColor = this.resources.find((r: any) => r.id === item.crew);

      if (tempCrewColor) {
        crewColor = tempCrewColor.color;
      }
    }
    return crewColor
  }


  public filerMapIcon(view: any) {
    this.setState({ [view]: !this.state[view], loading: true });
    setTimeout(() => this.showFilterMapIcon(), 500);
  }
  public showFilterMapIcon() {
    let filterdWOMarker: any = [];
    let filterOption: any = '';
    let tempWOMarker: any = [];
    let resultGroup: any = null;
    if (this.state.selectedMapBtnAssign && this.state.selectedMapBtnUnassign) {
      tempWOMarker = utility._sortItems(this.createItems(true), 'location', false);
      filterdWOMarker = tempWOMarker;
      resultGroup = utility.groupBy(tempWOMarker, 'lat');
    } else {
      let showAssing = this.state.selectedMapBtnAssign;
      let showUnassing = this.state.selectedMapBtnUnassign;

      filterOption = showAssing ? global.Assigned : showUnassing ? global.Unassigned : '';

      tempWOMarker = utility._sortItems(this.createItems(true), 'location', false);

      tempWOMarker.forEach((element: any) => {
        if (element.fieldstatus === filterOption) {
          filterdWOMarker.push(element);
        }
      });
      resultGroup = utility.groupBy(filterdWOMarker, 'lat');
    }
    let statusGroup = utility.groupBy(tempWOMarker, 'contractorstatus');
    filterdWOMarker = utility._sortItems(filterdWOMarker, 'fieldstatus', false);

    this.setState({
      statusGroup: statusGroup,
      WOMarker: resultGroup,
      workordersForMap: filterdWOMarker,
      loading: false,
      callAfterInitMap: true,
      callAfterInit: true,

    });
  }
  private _multiFieldChanged = (event: any, fieldName: string) => {

    if (fieldName === 'crewteam') {

      if (event.length > 0) {
        this.resources = event;
      } else {
        this.resources = this.state.crewItems;
      }
    }
    this.setState({ [fieldName]: event, onCrewSelected: true, selectedWorkorderbyCrew: [] });
  }

  public handleWorkingHourChange = (event: any, newValue: any) => {
    if ((newValue[0] + 1) < newValue[1]) {
      this.setState({ workingHour: newValue });
    }
  }

  public clearFilterWO() {
    this.setState({ filterWordOrders: '', unassignedWorkorderData: UnassignedworkOrdersFromList });
    setTimeout(() => this.setState({ callAfterInit: true }), 500);
  }
  public handleValueChange(e: any, fieldName: string) {
    this.setState({ [fieldName]: e.value });
  }
  public handleDropdownChange(event: any, fieldName: string) {

    ////console.log(newValue);
    if (fieldName === 'department' && event.selectedItem) {
      this.selectedDeptKey = event.selectedItem.id;
      this.getTeams();
      this.getCrew();

    }
    if (fieldName === 'team' && event.selectedItem) {
      this.setState({ loading: true });
      let tempCrew = this.state.allcrewItems;

      let filteredCrew: any = [];
      tempCrew.forEach((c: any) => {
        if (c.teamID === (event.selectedItem && event.selectedItem.id)) {
          filteredCrew.push(c);
        }
      });
      this.setState({
        crewItems: !event.selectedItem || (event.selectedItem && event.selectedItem.id === 'All') ? tempCrew : filteredCrew,
        crewteam: [], loading: false
      });
      this.resources = !event.selectedItem || (event.selectedItem && event.selectedItem.id === 'All') ? tempCrew : filteredCrew;
    }
    let value = event.selectedItem;
    // if (!value && fieldName  === 'team') {
    //   value = { id: 'All', text: 'All' }
    // }

    this.setState({ [fieldName]: value, onCrewSelected: true, selectedWorkorderbyCrew: [] });

  }
  public onHorizontalChange = (fieldName: any, event: any) => {
    this.setState({
      [fieldName]: event.newState,
    });
  }
  public handleSelect = (event: any, newValue: any) => {
    this.setState({ selected: newValue });
  }
  public onResizeDiv(e: any) {

    this.setState({
      scheduleResizableWidth: e.width,
    });
  }
  public changeSchedulerSplitterView(view: any) {

    if (this.state[view]) {
      return;
    }
    if (view === 'viewOne') {
      this.setState({
        schedulerSplitterView: view,
        horizontalTopPanes: [{ size: '70%' }, { min: '30%' }],
        horizontalBottomPanes: [{}, {}],
        schedulerViewChange: 'list',
        scheduleResizableWidth: null
      });

    }
    if (view === 'viewTwo') {
      this.setState({
        schedulerSplitterView: view,
        horizontalTopPanes: [{}, {}],
        horizontalBottomPanes: [{ min: '25%' }, { size: '70%', collapsible: true }],
        schedulerViewChange: 'list',
        scheduleResizableWidth: null
      })
    }
    localStorage.setItem('schedulerSplitterView', view);
  }
  public onChangeListItemView(view: any) {
    this.setState({
      cardView: view
    });
    localStorage.setItem('cardView', view);
  }
  public renderAppointment = (model: any) => {
    const { appointmentData } = model.data;

    if (this.state.currentView === 'agenda') {
      return (
        <div>
          <div className="dx-item-content dx-scheduler-appointment-content">
            <div className="dx-scheduler-agenda-appointment-left-layout">
              <div className="dx-scheduler-agenda-appointment-marker" style={{ backgroundColor: this.getCrewColor(appointmentData) }}>
              </div>
            </div>
            <div className="dx-scheduler-agenda-appointment-right-layout">
              <div className="dx-scheduler-appointment-title">{appointmentData.text}</div>
              <div className="dx-scheduler-appointment-content-details">
                <div className="dx-scheduler-appointment-content-date">{moment(appointmentData.startDate).format('h:mm a') + "-" + moment(appointmentData.endDate).format('h:mm a')}</div>
                <div className="dx-scheduler-appointment-resource-list">
                  <div className="dx-scheduler-appointment-resource-item">
                    <div>Status:</div>
                    <div className="dx-scheduler-appointment-resource-item-value">{appointmentData.fieldstatus}</div>
                  </div>
                  <div className="dx-scheduler-appointment-resource-item">
                    <div>WO #:</div>
                    <div className="dx-scheduler-appointment-resource-item-value">{appointmentData.Workordernumber}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      let statusColor = Config.statusColors[appointmentData.fieldstatus]
      return (
        <div className="showtime-preview">
          <div> {appointmentData.text} ({appointmentData.Workordernumber})</div>
          <div>
            <div className='schedulerStatusWrapper' style={{ backgroundColor: statusColor ? statusColor : '#ddd' }}><strong>{appointmentData.fieldstatus}</strong></div>
          </div>
          <div>
          </div>
        </div>
      );
    }
  }
  public renderAppointmentTooltip = (model: any) => {
    let woData = model.appointmentData;
    return (
      <SchedulerCard
        woData={woData}
        viewItem={(e: any) => this.viewDetials(e)}
        viewWO={(e: any) => this.viewWO(e)}
        zoomToSelectWO={(e: any) => this.zoomToSelectWO(e, true)}
        draggingGroupName={""}
        draggingAllowed={false}
        view={this.state.cardView}
        mapView={false}
        showUnassignWO={true}
        unassignWO={() => this.unassignWO(woData)}
      />
    );
  }
  public onUnassignedSelection(item: any) {

    let tempSelectedWOItems = this.state.selectedWOItems;
    if (item && item.fieldstatus === global.Unassigned) {
      // this.state.unassignedWorkorderData.forEach(wo=>{
      //   if(item[0].id  === wo.id)
      //   wo.selectedWO = true
      //   console.log(wo)
      // });
      let index = this.state.unassignedWorkorderData.findIndex((woi: any) => woi.id === item.id);
      this.state.unassignedWorkorderData[index].selectedWO = !this.state.unassignedWorkorderData[index].selectedWO;

      tempSelectedWOItems = this.state.unassignedWorkorderData.filter((unwo: any) => unwo.selectedWO === true);

    }
    this.setState({
      unassignedWorkorderData: [...this.state.unassignedWorkorderData],
      workorderData: [...this.state.workorderData],
      selectedWOItems: tempSelectedWOItems,
      loading: true
    });
    AssignedworkOrdersFromList = [...this.state.workorderData];
    UnassignedworkOrdersFromList = [...this.state.unassignedWorkorderData];

    this.showFilterMapIcon();
    this.setState({
      hideAlertButton: false,
      showAlert: false
    });
  }
  public unassignWO = (event: any) => {
    if (this.checkWOStatusInProgress(event.fieldstatus)) {
      let msg = 'This work order is in progress and cannot be modified.';
      if (event.fieldstatus === 'Complete') {
        msg = 'This work order is completed and cannot be modified.';
      }
      this.setState({
        alertMessage: msg,
        hideAlertButton: false,
        showAlert: true
      });
      return;
    }
    // let dataToUpdate = {
    //     Crewid: '',
    //     StatusID: 8
    // };
    this.setState({ loading: true });
    let dataToUpdate: any = {};

    let tempStatus = this.state.StatusItems;
    let tempFieldStatus = tempStatus.filter((r: any) => r.text === global.Unassigned);
    let fieldStatus = tempFieldStatus[0].id;


    let comments = 'Unassinged workorder from scheduler';
    // let Created = `${this.currentUser.DisplayName} ${utility.cuniq()}`
    // let description = `${event.WOComments}\n [${Created}] ${comments}`;
    // event.WOComments = description;
    dataToUpdate.Id = +event.id;
    dataToUpdate.Crewid = -1;
    // dataToUpdate.StatusID = fieldStatus;
    dataToUpdate.ContractorStatusID = fieldStatus;
    dataToUpdate.Comments = comments;
    // dataToUpdate.WoComments = description;
    dataToUpdate.UpdatedBy = this.currentUser.Email;

    this.showSchedulingPopup();

    utility.genericUpdatePortalAPICall(Config.SchedulerWorkUpdateList, dataToUpdate).then(() => {
      const index = this.state.workorderData.findIndex((w: any) => w.id === event.id);

      if (index >= 0) {
        this.state.workorderData.splice(index, 1);
        // console.log({event});
        event.crew = -1;
        event.fieldstatus = global.Unassigned
        this.state.unassignedWorkorderData.push(event);
        this.setState({
          unassignedWorkorderData: [...this.state.unassignedWorkorderData],
          workorderData: [...this.state.workorderData]
        });
        AssignedworkOrdersFromList = [...this.state.workorderData];
        UnassignedworkOrdersFromList = [...this.state.unassignedWorkorderData];
      }
      this.showFilterMapIcon();
      this.setState({
        hideAlertButton: false,
        showAlert: false,
        onCrewSelected: true
      });
    }, (err) => {
      console.log(err);
      this.showErrorSchedulingPopup();
    });
  }
  public onAppointmentRemove(e: any) {
    if (this.checkWOStatusInProgress(e.itemData.fieldstatus)) {
      let msg = 'This work order is in progress and cannot be modified.';
      if (e.itemData.fieldstatus === 'Complete') {
        msg = 'This work order is completed and cannot be modified.';
      }
      this.setState({
        alertMessage: msg,
        hideAlertButton: false,
        showAlert: true
      });
      return;
    }
    // let dataToUpdate = {
    //     Crewid: '',
    //     StatusID: 8
    // };
    let dataToUpdate: any = {};

    let tempStatus = this.state.StatusItems;
    let tempFieldStatus = tempStatus.filter((r: any) => r.text === global.Unassigned);
    let fieldStatus = tempFieldStatus[0].id;

    let comments = 'Unassinged workorder from scheduler';
    // let Created = `${this.currentUser.DisplayName} ${utility.cuniq()}`
    // let description = `${e.itemData.WOComments}\n [${Created}] ${comments}`;
    // e.itemData.WOComments = description;

    dataToUpdate.Id = +e.itemData.id;
    dataToUpdate.Crewid = -1;
    // dataToUpdate.StatusID = fieldStatus;
    dataToUpdate.ContractorStatusID = fieldStatus;
    dataToUpdate.Comments = comments;
    // dataToUpdate.WoComments = description;
    dataToUpdate.UpdatedBy = this.currentUser.Email;

    this.showSchedulingPopup();

    utility.genericUpdatePortalAPICall(Config.SchedulerWorkUpdateList, dataToUpdate).then(() => {
      const index = this.state.workorderData.findIndex((w: any) => w.id === e.itemData.id);

      if (index >= 0) {
        e.itemData.fieldstatus = global.Unassigned;
        this.state.workorderData.splice(index, 1);
        this.state.unassignedWorkorderData.push(e.itemData);
        this.setState({
          unassignedWorkorderData: [...this.state.unassignedWorkorderData],
          workorderData: [...this.state.workorderData]
        });
        AssignedworkOrdersFromList = [...this.state.workorderData];
        UnassignedworkOrdersFromList = [...this.state.unassignedWorkorderData];
      }
      this.showFilterMapIcon();
      this.setState({
        hideAlertButton: false,
        showAlert: false
      });
    }, (err) => {
      console.log(err);
      this.showErrorSchedulingPopup();
    });
  }

  public onAppointmentAdd(e: any) {
    let dataToUpdate: any = {};

    let tempStatus = this.state.StatusItems;
    let tempFieldStatus = tempStatus.filter((r: any) => r.text === global.Assigned);
    e.itemData.endDate.setHours(e.itemData.endDate.getHours() + 5);
    let fieldStatus = tempFieldStatus[0].id;
    let crewName = this.resources.find((r: any) => r.id === e.itemData.crew)
    let comments = `Work order dispatched to ${crewName.text} from scheduler`;
    // let Created = `${this.currentUser.DisplayName} ${utility.cuniq()}`
    // let description = `${e.itemData.WOComments}\n [${Created}] ${comments}`;
    // e.itemData.WOComments = description;
    dataToUpdate.Id = +e.fromData.id;
    dataToUpdate.Crewid = e.itemData.crew;
    // dataToUpdate.StatusID = fieldStatus;
    dataToUpdate.ContractorStatusID = fieldStatus;
    dataToUpdate.Comments = comments;
    // dataToUpdate.WoComments = description;
    dataToUpdate.UpdatedBy = this.currentUser.Email;
    dataToUpdate.ScheduleStartDate = e.itemData.startDate.toISOString();
    dataToUpdate.ScheduleFinishDate = e.itemData.endDate.toISOString();

    this.showSchedulingPopup();

    utility.genericUpdatePortalAPICall(Config.SchedulerWorkUpdateList, dataToUpdate).then(() => {

      const index = this.state.unassignedWorkorderData.findIndex((w: any) => w.id === e.fromData.id);


      if (index >= 0) {
        e.itemData.fieldstatus = global.Assigned;

        UnassignedworkOrdersFromList.splice(index, 1);
        this.state.workorderData.push(e.itemData);

        this.setState({
          unassignedWorkorderData: UnassignedworkOrdersFromList,
          workorderData: [...this.state.workorderData]
        });
        AssignedworkOrdersFromList = [...this.state.workorderData];
        // UnassignedworkOrdersFromList = [...this.state.unassignedWorkorderData];
      }
      this.showFilterMapIcon();
      this.setState({
        hideAlertButton: false,
        showAlert: false
      });
    }, (err) => {
      console.log(err);
      this.showErrorSchedulingPopup();
    });
  }

  public onAppointmentUpdating(data: any) {

    if (this.checkWOStatusInProgress(data.oldData.fieldstatus)) {
      let msg = 'This work order is in progress and cannot be modified.';
      if (data.oldData.fieldstatus === 'Complete') {
        msg = 'This work order is completed and cannot be modified.';
      }
      this.setState({
        alertMessage: msg,
        hideAlertButton: false,
        showAlert: true
      });
      // return;
      data.cancel = true;
    }
  }
  public onAppointmentUpdated(data: any) {

    let dataToUpdate: any = {};

    dataToUpdate.Id = +data.appointmentData.id;
    dataToUpdate.Crewid = data.appointmentData.crew;
    dataToUpdate.ScheduleStartDate = new Date(data.appointmentData.startDate).toISOString();
    dataToUpdate.ScheduleFinishDate = new Date(data.appointmentData.endDate).toISOString()

    // this.showSchedulingPopup();

    utility.genericUpdatePortalAPICall(Config.SchedulerWorkUpdateList, dataToUpdate).then(() => {

      const index = this.state.workorderData.indexOf(data.appointmentData);

      if (index >= 0) {
        this.state.workorderData[index].crew = data.appointmentData.crew;
        this.state.workorderData[index].startDate = data.appointmentData.startDate;
        this.state.workorderData[index].endDate = data.appointmentData.endDate;

        this.setState({
          workorderData: [...this.state.workorderData]
        });
        AssignedworkOrdersFromList = [...this.state.workorderData];
        UnassignedworkOrdersFromList = [...this.state.unassignedWorkorderData];
        this.showFilterMapIcon();
      }
      // this.getWorkOrdersForDepartment('', '', true)
      // this.setState({
      //   hideAlertButton: false,
      //   showAlert: false,
      //   loading:false
      // });
    }, (err) => {
      console.log(err);
      this.showErrorSchedulingPopup();
    });
  }
  public onAppointmentFormOpening(data: any) {

    data.cancel = true;

  }

  // func for drag and drop
  public onListDragStart(e: any) {
    e.cancel = true;
  }

  public onItemDragStart(e: any) {
    e.itemData = e.fromData;
  }

  public onItemDragEnd(e: any) {
    if (e.toData) {
      e.cancel = true;
    }
  }
  public onDragStart(e: any, woData: any, crew?: any) {

    e.dataTransfer.setData("wo", JSON.stringify(woData))
    // e.itemData = this.state[e.fromData][e.fromIndex];

  }

  onAdd(e: any) {
    // const tasks = this.state[e.toData];
    // this.setState({
    //   [e.toData]: [...tasks.slice(0, e.toIndex), e.itemData, ...tasks.slice(e.toIndex)],
    // });
  }


  public onDragOver = (ev: any) => {
    ev.preventDefault();

  }
  public onDrop = (ev: any, crew?: any) => {
    let data = ev.dataTransfer.getData("wo");
    let wo = JSON.parse(data);

    this.setState({
      showAssignMultipleModal: true,
      selectedWOItems: [wo],
      multiSelectbyDragDrop: true,
      selectedCrewInSchedulerListforCrewListDrop: crew ? crew : null
    });
  }
  private renderResourceCell = (model: any) => {
    let range = this.getRange(this.state.currentDate, this.state.currentView);
    let totalWOPerCrew = this.state.workorderData.filter((wo: any) => wo.crew === model.id);
    // let link = `/Searchworkorder?crew=${model.id}&department=${JSON.stringify(this.state.department)}&startenddate${JSON.stringify(range)}`
    let woProps: any = {
      pathname: '/Searchworkorder',
      woProps: {
        crew: { id: model.id, text: model.text },
        department: this.state.department,
        range: range
      }
    }
    return (
      <div className="schedulerResourceCell">
        <Grid container spacing={1}>
          <Grid item xs={2} sm={2}>
            <div className="crewTitleColorScheduler" style={{ backgroundColor: model.data.color ? model.data.color : "#333" }}></div>
          </Grid>
          <Grid item xs={10} sm={10}>
            <div className="schedulerResourceCellText">{model.data.text}</div>
            {totalWOPerCrew.length > 0 ?
              <NavLink to={woProps}
              >
                <div className="schedulerResourceCellWO">{`${totalWOPerCrew.length} workorder${totalWOPerCrew.length > 1 ? 's' : ''}`}</div>
              </NavLink>
              :
              <div className="schedulerResourceCellWO">{`${totalWOPerCrew.length} workorder${totalWOPerCrew.length > 1 ? 's' : ''}`}</div>}
          </Grid>
        </Grid>
      </div>
    );
  }
  public renderAgendaResourceCell = (model: any) => {
    let totalWOPerCrew = this.state.workorderData.filter((wo: any) => wo.crew === model.id);
    let woProps: any = {
      pathname: '/Searchworkorder',
      woProps: {
        crew: [{ id: model.id, text: model.text }],
        department: this.state.department,
        range: this.state.dateRange
      }
    }

    // let link = `/Searchworkorder?crew=${model.id}&department=${JSON.stringify(this.state.department)}&startenddate${JSON.stringify(range)}`
    return (
      <div className="schedulerResourceCell">
        <Grid container spacing={1}>

          <Grid item xs={10} sm={10}>
            {totalWOPerCrew.length > 0 ?
              <NavLink to={woProps}
              >
                <div className="schedulerResourceCellWO">{`${totalWOPerCrew.length} workorder${totalWOPerCrew.length > 1 ? 's' : ''}`}</div>
              </NavLink>
              :
              <div className="schedulerResourceCellWO">{`${totalWOPerCrew.length} workorder${totalWOPerCrew.length > 1 ? 's' : ''}`}</div>}
          </Grid>
        </Grid>
      </div>
    );
  }
  private renderTimeCell(data: any, index: any) {
    if (this.state.currentView === 'Day') {
      return <p className="renderTimeCellStyle">{data.text}</p>;
    } else {
      return (<p className="renderTimeCellStyle">{moment(this.state.workingHour[0], 'HH').format('h:mm a')} - {moment(this.state.workingHour[1], 'HH').format('h:mm a')}</p>);
    }
  }
  private renderDateCell(data: any, index: any) {
    return <p className="renderDateCellStyle">{data.text}</p>;
  }

  // func for current time and date changes
  public onCurrentViewChange(currentView: any) {
    let currentDate = this.state.currentDate;
    this.setState({ currentView });
    this.getWorkOrdersForDepartment(currentDate, currentView, true);
  }
  public onCurrentDateChange(currentDate: any) {
    let currentView = this.state.currentView;
    this.setState({ currentDate: currentDate });
    this.getWorkOrdersForDepartment(currentDate, currentView, true);
  }

  public onViewDateChange(date: any, view: any) {
    this.setState({
      currentDate: date,
      currentView: view
    });
    this.getWorkOrdersForDepartment(date, view, true);
  }
  public getRange = (date: any, view: any) => {
    if (view === "Day" || view === 'agenda') {
      return { startDate: date, endDate: date };
    }
    if (view === "Week" || view === "Work Week") {
      let firstDay = date.getDate() - date.getDay();
      // let lastDay = firstDay + 6;
      let tempStartDate = new Date(date)
      let startDate = tempStartDate.setDate(firstDay);
      let tempEndDate = new Date(startDate);
      let endDate = tempEndDate.setDate(tempEndDate.getDate() + 6);

      return {
        startDate: new Date(startDate),
        endDate: new Date(endDate)
      };
    }
    if (view === "Month" || view === 'Calendar') {
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return {
        startDate: firstDay,
        endDate: lastDay
      };
    }
  }
  public handleShowHildePopOver(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({
      anchorEl: event.currentTarget,
      showMoreSettingMenu: true
    })
  }
  public handleCloseShowHildePopOver(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({
      anchorEl: null,
      showMoreSettingMenu: false
    })
  }
  public renderShowMoreSettingMenu() {
    return (
      <Popover
        id={"i"}
        open={this.state.showMoreSettingMenu}
        anchorEl={this.state.anchorEl}
        onClose={this.handleCloseShowHildePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}

      >
        <div className="popOverContent">
          {this.state.selected === 0 && (
            <div className="workingHoursDiv mb-10">
              <div className='displayFlex'>
                <QueryBuilderIcon className="workingHoursIcon" titleAccess='Select working hours'></QueryBuilderIcon>
                <Slider
                  getAriaLabel={() => 'Temperature range'}
                  aria-label="Always visible"
                  value={this.state.workingHour}
                  onChange={this.handleWorkingHourChange}
                  valueLabelDisplay="auto"
                  min={0}
                  step={1}
                  max={24}
                  getAriaValueText={(e: any) => `${e}`}
                />

              </div>
            </div>
          )}
          <div className={"mtb-20 displayFlex"}>
            {this.state.selected === 0 && (
              <div className={"toggleViewScheduler"}>
                <div title='Change view of scheduler' className={this.state.schedulerSplitterView === 'viewOne' ? "selectedToggleViewSchedulerIconDiv" : "toggleViewSchedulerIconDiv"} onClick={() => this.changeSchedulerSplitterView("viewOne")}><ViewCompactIcon className="toggleViewSchedulerIcon iconInverse" ></ViewCompactIcon></div>
                <div title='Change view of scheduler' className={this.state.schedulerSplitterView === 'viewTwo' ? "selectedToggleViewSchedulerIconDiv" : "toggleViewSchedulerIconDiv"} onClick={() => this.changeSchedulerSplitterView("viewTwo")}><ViewCompactIcon className="toggleViewSchedulerIcon " ></ViewCompactIcon></div>
              </div>
            )}
            <IconButton aria-label="view" color="primary" onClick={() => this.getWorkOrders()} >
              <RefreshIcon titleAccess='Refresh the data' className={'primary'} />
            </IconButton>
            <IconButton aria-label="view" color="primary" onClick={() => this.setState({ showColorLegend: !this.state.showColorLegend })} >
              <InfoOutlinedIcon titleAccess='View the colour coding of the statuses' className={'primary'} />
            </IconButton>
          </div>
          <div>
            <p>List Item View</p>
            <div className='displayFlex'>
              <div className={`SchedulerViewChangeDiv ${this.state.cardView === 'List' ? 'SchedulerViewSelected' : ''}`} onClick={() => this.onChangeListItemView('List')}>List</div>
              <div className={`SchedulerViewChangeDiv ${this.state.cardView === 'Card' ? 'SchedulerViewSelected' : ''}`} onClick={() => this.onChangeListItemView('Card')}>Card</div>
            </div>
          </div>
        </div>
      </Popover>
    );
  }


  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this._closeDialog()}
        hideAlertButton={this.state.hideAlertButton}
      />
    );
  }
  private _closeDialog = (): void => {
    if (this.state.goHome) {
      this.setState({ alertMessage: '', showAlert: false, goHome: false });
      window.open('#/', '_self');
    } else {
      this.setState({ alertMessage: '', showAlert: false });
    }
  }

  public onSelecteWoFromCrewList(wo: any, crew: any) {
    this.setState({ selectedWorkorderbyCrew: wo, onCrewSelected: true, selectedCrewInSchedulerList: crew });

  }
  private stopOnCrewSelected = (): void => {
    this.setState({ onCrewSelected: false });
  }
  public viewDetials(item: any) {
    let selected = item;
    //console.log(selected);
    this.setState({
      details: selected,
      showModal: true
    });
  }

  public viewWO(item: any) {
    let selected = item;
    var a = window.location.href;
    var b = a.substring(0, a.lastIndexOf('/') + 1);
    window.open(b + `WorkOrderDetail?WOID=${selected.id}&SR=${selected.servicerequest}&WONum=${selected.Workordernumber}`, '_blank');
  }
  public zoomToSelectWO(item: any, openModal?: any) {

    if (item.lat && item.long) {
      this.setState({
        // zoom: 20,
        center: { lat: item.lat, lng: item.long },
        loading: true,
        address: item.location,
        openMapModal: openModal ? true : false
      });
      setTimeout(() => this.setState({ loading: false }), 500);
    } else {
      this.setState({
        alertMessage: 'Map data not found.',
        showAlert: true,
        hideAlertButton: false
      });
    }
  }
  public zoomOutWO() {
    this.setState({
      // zoom: 8,
      loading: true
    });
    setTimeout(() => this.setState({ loading: false }), 500);
  }
  public renderDetialsModal() {
    return (
      <DetailsModal
        details={this.state.details}
        showModal={this.state.showModal}
        onClick={() => this._closeModal()}
      />
    );
  }

  private _closeModal = (): void => {
    this.setState({ showModal: false });
  }
  public showSchedulingPopup() {
    this.setState({
      alertMessage: <Spinner label='Scheduling...' />,
      hideAlertButton: true,
      showAlert: true,
      loading: true
    });
  }

  public showErrorSchedulingPopup() {
    this.setState({
      alertMessage: 'Unable to process the request. Please try again later',
      hideAlertButton: false
    });
  }
  public checkWOStatusInProgress(status: any) {
    if (status === 'Closed' || status === 'Complete') {
      return true;
    }
    return false;
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false, callAfterInitMap: false });
  }
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default DevScheduler;