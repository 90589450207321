import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';


import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditPlant from './AddEditPlant';


let _items: any = [];


export default class Plants extends React.Component<any, any> {


    public currentUser: any;
    public columns: any;

    constructor(props: any) {
        super(props);

        this._getItemData = this._getItemData.bind(this);
        this.DeleteDialog = this.DeleteDialog.bind(this);

        if (this.props.role !== global.worxOnline_Admin && this.props.role !== global.worxOnline_ContractorAdmin && this.props.role !== global.worxOnline_WorxAdmin) {
            window.open('#/AccessDenied', '_self');
        }
        // this.currentUser = {
        //     DisplayName: props.context.pageContext.user.displayName,
        //     Email: props.context.pageContext.user.email,
        //     LoginName: props.context.pageContext.user.loginName
        // };

        this.currentUser = this.props.currentUser;
        //console.log(this.currentUser);


        this.columns = [


            {
                field: 'id',
                title: 'ID',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'resourceCode',
                title: 'Resource Code',
                // width: 280,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'title',
                title: 'Title',
                // width: 160,
                type: 'string',
            },
            {
                field: 'category',
                title: 'Category',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'regonumber',
                title: 'Rego Number',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'department',
                title: 'Organisation',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'prestartformName',
                title: 'Prestart Form',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'active',
                title: 'Is Active',
                // width: 160,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'inspectionFailed',
                title: 'Inspection failed',
                width: 110,
                type: 'boolean',
                allowHiding: true,
            },
            {
                field: 'updatedOn',
                title: 'Updated On',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true,
                sortOrder: 'desc'
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created On',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
        ];
        let showHideColumns = localStorage.getItem("plantColumns");
        let tempColumns;
        if (showHideColumns !== null) {
            tempColumns = JSON.parse(showHideColumns);
        } else {
            tempColumns = this.columns;
            localStorage.setItem("plantColumns", JSON.stringify(this.columns));
        }
        this.state = {
            items: _items,
            columns: tempColumns,
            showSearchDiv: false,
            tableHeight: 665
        };
    }

    public componentDidMount() {
        this._getItemData();

    }

    public DeleteItem(item: any) {
        //console.log("item", item);

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true,
            showDeleteAlert: false,
        });

        utility.genericDeleteAPICall(Config.plantListName, item).then((data: any) => {
            this.setState({
                alertMessage: 'Equipment and Vehicles has been deleted.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
                loading: false
            });
            this._getItemData();
        }, (err) => {
            console.log(err);
            this.setState({
                alertMessage: 'Cannot delete Equipment and Vehicles. Please try again later.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
            });
        });

    }

    // edit activty modal

    private EditItemModal(item: any) {
        //console.log("item", item);
        this.setState({ showModal: true, details: item, mode: "edit" });
    }
    public renderNewModal() {
        return (
            <AddEditPlant
                showModal={this.state.showModal}
                onClose={() => this._closeModal()}
                onClick={() => this._getItemData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                details={this.state.details}
                role={this.props.role}
                Department={this.props.Department}
                mode={this.state.mode}
            />
        );
    }


    private _showModal = (): void => {

        this.setState({ showModal: true, details: null, mode: "add" });
    }

    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }

    public message(message: any) {
        //console.log(message);
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }

    private _getItemData() {
        let dataToPassToService: any = {};
        this.setState({
            loading: true
        });
        if (this.props.role === global.worxOnline_WorxAdmin || this.props.role === global.worxOnline_ContractorAdmin) {
            dataToPassToService.DepartmentId = this.props.Department ? this.props.Department.id : -1
        }
        utility.genericGetAPICallForList(Config.SearchPlantApi, 'POST', dataToPassToService).then((data: any) => {
            _items = [];

            if (data.length === null) {
                if (_items.length <= 0) {
                    this.setState({
                        alertMessage: 'No results found for this criteria',
                        showAlert: true
                    });
                }
                this.setState({ items: _items, loading: false });
                return;
            }
            else {
                for (let r of data) {
                    _items.push({
                        id: r.Id,
                        title: r.Title,
                        resourceCode: r.Resourcecode,
                        category: r.Category,
                        categoryId: r.Categoryid,
                        regonumber: r.Regonumber,
                        department: r.Department,
                        departmentId: r.Departmentid,
                        prestartformid: r.Prestartformid,
                        prestartformName: r.Prestartformtitle,
                        inspectionFailed: r.Inspectionfailed,
                        active: r.Isactive,
                        default: r.Isdefault,
                        updatedBy: r.Userupdated,
                        updatedOn: r.Dateupdated ? r.Dateupdated : null,
                        // updatedOn: r.Dateupdated ? new Date(r.Dateupdated) : null,
                        createdBy: r.Usercreated,
                        createdOn: r.Datecreated ? r.Datecreated : null,

                    });
                }
            }
            _items = utility._sortItems(_items, 'updatedOn', true);

            this.setState({ items: _items, callAfterInit: true, loading: false, preItems: _items });

            //console.log(_items);
        }, (err) => {
            console.log(err);
            this.setState({ loading: false, callAfterInit: true, });
        });
    }

    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }

    public render(): React.ReactElement<any> {
        return (
            <React.Fragment>
                {this.renderNewModal()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Equipment and Vehicles </span>
                    </div>
                </div>
                <div>

                    {!this.state.loading ?
                        <DataTable
                            style={{ width: '100%' }}
                            filteralbe={true}
                            groupable={true}
                            rows={this.state.items}
                            columns={this.state.columns}
                            fileName={'Equipment and Vehicles'}
                            columnChooser={'plantColumns'}
                            selectionMode={false}
                            showHideColumnMenu={true}
                            callAfterInit={this.state.callAfterInit}
                            stopCommsAfterInit={() => this.stopCommsAfterInit()}
                            loading={this.state.loading}
                            allowExportSelectedData={false}
                            showNewModal={() => this._showModal()}
                            deleteItem={(a: any) => this.DeleteDialog(a)}
                            editItem={(a: any) => this.EditItemModal(a)}
                            // viewFile={(e:any)=>this.viewFile(e)}
                            showCommandBtn={true}
                            showNewBtn={true}
                            hideNewBtn={this.state.showModal || this.state.showModal || this.props.hideNewBtn}
                            showViewDetailBtn={false}
                        />
                        :
                        <Spinner size='large' label="Loading.." />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }
    public DeleteDialog(item: any) {
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            deleteItem: item
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.DeleteItem(this.state.deleteItem)}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }
}

