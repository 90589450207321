import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';

import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';

let _items: any = [];

export default class Suppliers extends React.Component<any, any> {

    public currentUser: any;
    public columns: any;

    constructor(props: any) {
        super(props);


        this.currentUser = this.props.currentUser;
        this.columns = [
            {
                field: 'id',
                title: 'ID',
                width: 80,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'code',
                title: 'Code',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'title',
                title: 'Title',
                // width: 160,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'abn',
                title: 'ABN',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'city',
                title: 'City',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'updatedOn',
                title: 'Updated On',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true,
                sortOrder: 'desc'
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created On',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
        ];
        let showHideColumns = localStorage.getItem("suppliersColumns");
        let tempColumns;
        if (showHideColumns !== null) {
            tempColumns = JSON.parse(showHideColumns);
        } else {
            tempColumns = this.columns;
            localStorage.setItem("suppliersColumns", JSON.stringify(this.columns));
        }
        this.state = {
            items: _items,
            columns: tempColumns,
        };

    }

    public componentDidMount() {
        this._getItemData();
    }

    private _getItemData() {
        let dataToPassToService: any = {};
        this.setState({
            loading: true
        });
        //console.log("dataToPassToService", dataToPassToService);
        utility.genericGetAPICallForList(Config.searchSuppliersList, 'POST', dataToPassToService).then((data: any) => {
            _items = [];
            //console.log(data);

            for (let r of data) {
                _items.push({
                    id: r.Id,
                    title: r.Title,
                    code: r.Code,
                    abn: r.Abn,
                    city: r.City,
                    postCode: r.Postcode,
                    updatedBy: r.Userupdated,
                    updatedOn: r.Dateupdated,
                    createdBy: r.Usercreated,
                    createdOn: r.Datecreated,

                });
            }


            _items = utility._sortItems(_items, 'updatedOn', true);
            this.setState({ items: _items, callAfterInit: true, loading: false });
            //console.log(_items);
        }, (err) => {
            console.log(err);
            this.setState({ loading: false });
        });
    }


    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }


    public render(): React.ReactElement<any> {
        return (
            <React.Fragment>
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Supplier </span>
                    </div>
                </div>
                <div>
                    {!this.state.loading ?
                        <DataTable
                            style={{ width: '100%' }}
                            filteralbe={true}
                            groupable={true}
                            rows={this.state.items}
                            columns={this.state.columns}
                            fileName={'Supplier'}
                            columnChooser={'suppliersColumns'}
                            selectionMode={false}
                            showHideColumnMenu={true}
                            callAfterInit={this.state.callAfterInit}
                            stopCommsAfterInit={() => this.stopCommsAfterInit()}
                            loading={this.state.loading}
                            allowExportSelectedData={false}
                            // showNewModal={() => this._showModal()}
                            // deleteItem={(a:any) => this.DeleteDialog(a)}
                            // editItem={(a:any) => this.EditItemModal(a)}
                            // viewFile={(e)=>this.viewFile(e)}
                            showCommandBtn={false}
                            showNewBtn={false}
                            showViewDetailBtn={false}
                        /> :
                        <Spinner size='large' label='Loading...' />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }


}