import { Configuration, PopupRequest } from "@azure/msal-browser";

import global from './global';
// Config object to be passed to MSAL on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: global.AzureClientAppId,
        // authority: 'https://w0rx.b2clogin.com/w0rx.onmicrosoft.com/common/oauth2/v2.0/authorize',
        // authority: 'https://w0rx.b2clogin.com/w0rx.onmicrosoft.com/B2C_1_SignupAndSignin/oauth2/v2.0/token',
        authority: global.singInB2C,
        knownAuthorities: [global.singInB2CKnownAuthority],
        redirectUri: global.homeURL
    },
    cache: {
        // cacheLocation: "sessionStorage",
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },
    system: {
        iframeHashTimeout: 10000,
      }
};

// Add here scopes for id token to used at MS Identity platforms endpoints
export const loginRequest: PopupRequest = {
   
    scopes: [global.AzureScope]
};

// Add here the endpoints for MS Graph API services you would like to use
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
}