import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditRiskForm from './AddEditRiskForm';
import moment from 'moment';

let _items: any = [];

export default class RiskGroup extends React.Component<any, any> {

  public currentUser: any;
  public columns: any;
  public myRef: any;
  constructor(props: any) {
    super(props);
    this.DeleteItem = this.DeleteItem.bind(this);
    this.EditItemModal = this.EditItemModal.bind(this);
    this.DeleteDialog = this.DeleteDialog.bind(this);
    this.myRef = React.createRef();
    this.currentUser = this.props.currentUser;
    // if (this.props.roleId !== this.props.tenantConfig.Config.AdminRoleId) {
    //   window.open('#/AccessDenied', '_self');
    // }
    this.columns = [
      {
        field: 'id',
        title: 'ID',
        width: 80,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'title',
        title: 'Title',
        width: 250,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'Isactive',
        title: 'Active',
        width: 160,
        type: 'boolean',
        allowHiding: false,
      },
      {
        field: 'updatedOn',
        title: 'Updated',
        width: 160,
        type: 'string',
        visible: false,
        allowHiding: true
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visible: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created',
        width: 160,
        type: 'string',
        visible: false,
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        visible: false,
        allowHiding: true
      },
    ];
    this.state = {
      items: _items,
      columns: this.columns
    };
  }

  public componentDidMount() {
    this._getDepartmentData();
    this._getItemData();
  }
  public _getDepartmentData() {
    utility.genericGetAPICallForList(`${Config.DepartmentList}`, 'GET').then((data: any) => {
      //console.log(data);
      const departmentItems = [];

      for (let r of data) {
        if (r.Isactive) {
          departmentItems.push({ id: r.id, text: r.title });
        }
      }
      this.setState({ departmentItems: departmentItems });
    }, (err) => {
      console.log(err);
    });
  }
  public DeleteItem(id: any) {
    this.setState({
      alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
      hideAlertButton: true,
      showAlert: true,
      showDeleteAlert: false,
    });

    utility.genericDeleteAPICall(Config.NewRiskFormsList, id).then((data: any) => {
      this.setState({
        alertMessage: 'Risk Form has been deleted.',
        showAlert: true,
        showDeleteAlert: false,
        hideAlertButton: false,
        loading: false
      });
      this._getItemData()
    }, (err) => {
      // console.log(err);
      this.setState({
        alertMessage: 'Cannot delete Risk Form. Please try again later.',
        showAlert: true,
        showDeleteAlert: false,
        hideAlertButton: false,
      });
    });

  }

  // edit activty modal

  private EditItemModal(item: any) {
    this.setState({ showModal: true, details: item, mode: 'edit' });
  }

  public renderNewModal() {
    return (
      <AddEditRiskForm
        showModal={this.state.showModal}
        onClose={() => this._closeModal()}
        onClick={() => this._getItemData()}
        message={(e: any) => this.message(e)}
        currentUser={this.currentUser}
        mode={this.state.mode}
        details={this.state.details}
        Client={this.props.aadHttpClient}
        AdminRoleId={this.props.tenantConfig.Config.AdminRoleId}
      />
    );
  }
  private _showModal = (): void => {

    this.setState({ showModal: true, details: null, mode: 'add' });
  }

  private _closeModal = (): void => {
    this.setState({ showModal: false });
  }

  public message(message: any) {
    // //console.log(message);
    this.setState({
      alertMessage: message + ' successfully',
      showAlert: true
    });
  }
  private _getItemData() {
    this.setState({
      loading: true,
      showModal: false
    });
    utility.genericGetAPICallForList(Config.NewRiskFormsList, 'GET')
      .then(
        (data: any) => {
          _items = [];
          data.forEach((r: any) => {
            _items.push({
              id: r.id,
              title: r.title ? r.title : '',
              model: r.model ? r.model : '',
              updatedBy: r.updatedBy ? r.updatedBy : '',
              updatedOn: r.updated ? moment(r.updated).format("DD/MM/yyyy HH:mm a") : '',
              createdBy: r.createdBy ? r.createdBy : '',
              createdOn: r.created ? moment(r.created).format("DD/MM/yyyy HH:mm a") : '',
              formName: r.formName ? r.formName : '',
              created: r.created,
              Isactive: r.isActive ? true : false,
              isAdhoc: r.isAdhoc ? true : false
            });
          });

          _items = utility._sortItems(_items, 'title', false);

          this.setState({ items: _items, callAfterInit: true, loading: false });
          //console.log(_items);
        },
        (err) => {
          console.log(err);
          this.setState({ loading: false });
        }
      );
  }

  public setSelection = (id: any) => {
    this.setState({
      selectedRow: id
    });
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }
  public render(): React.ReactElement<any> {
    return (
      <React.Fragment>
        {this.renderNewModal()}
        {this.state.showDeleteAlert && this.DeleteAlert()}
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}> Risk Forms </span>
          </div>
        </div>
        <div>
          {!this.state.loading ?
            <div ref={this.myRef}>
              <DataTable
                style={{ width: '100%' }}
                filteralbe={true}
                groupable={true}
                rows={this.state.items}
                columns={this.state.columns}
                fileName={'Risk Form'}
                columnChooser={'riskFormColumns'}
                selectionMode={false}
                showHideColumnMenu={true}
                callAfterInit={this.state.callAfterInit}
                stopCommsAfterInit={() => this.stopCommsAfterInit()}
                loading={this.state.loading}
                allowExportSelectedData={false}
                showNewModal={() => this._showModal()}
                deleteItem={(a: any) => this.DeleteDialog(a)}
                editItem={(a: any) => this.EditItemModal(a)}
                // viewFile={(e)=>this.viewFile(e)}
                showCommandBtn={true}
                showNewBtn={true}
                hideNewBtn={this.state.showModal || this.state.showEditModal || this.props.hideNewBtn}
                showViewDetailBtn={false}
                defaultFilterValue={true}
              />
            </div>
            :
            <Spinner size='large' type={'converging-spinner'} className="loader" />
          }

        </div>
      </React.Fragment>
    );
  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        hideAlertButton={this.state.hideAlertButton}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }
  public DeleteDialog(item: any) {
    // //console.log({ item });
    this.setState({
      DeletealertMessage: 'Are you sure you want to delete?',
      showDeleteAlert: true,
      deleteItem: item
    });
  }
  public DeleteAlert() {
    return (
      <DeleteDialog
        showDialog={this.state.showDeleteAlert}
        message={this.state.DeletealertMessage}
        title={"worxOnline"}
        onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
        onOkButton={() => this.DeleteItem(this.state.deleteItem)}
        onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
      />
    );
  }

}

