import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';


import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';

let _items: any = [];

export default class FailedWorkorders extends React.Component<any, any> {

    public departmentItems: any = [];
    public teamsItems: any = [];
    public currentUser: any;
    public columns: any;

    constructor(props: any) {
        super(props);

        this.departmentItems = [];
        this.teamsItems = [];

        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        }

        this.currentUser = this.props.currentUser;
        this.columns = [

            {
                field: 'id',
                title: 'ID',
                width: 80,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'Wonumber',
                title: 'Work Order #',
                width: 130,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'Error',
                title: 'Error',
                width: 650,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'Datecreated',
                title: 'Created',
                width: 180,
                type: 'datetime',
                allowHiding: false,
                sortOrder: 'desc'
            }

        ];
        let showHideColumns = localStorage.getItem("FailedWorkordersColumns");
        let tempColumns;
        if (showHideColumns !== null) {
            tempColumns = JSON.parse(showHideColumns);
        } else {
            tempColumns = this.columns;
            localStorage.setItem("FailedWorkordersColumns", JSON.stringify(this.columns));
        }
        this.state = {
            items: _items,
            columns: tempColumns,
        };
    }

    public componentDidMount() {
        this._getFailedWorkordersData();

    }
    public message(message: any) {
        //console.log(message);
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }

    private _getFailedWorkordersData() {
        let dataToPassToService: any = {};
        this.setState({
            loading: true
        });
        //console.log("dataToPassToService", dataToPassToService);
        utility.genericGetAPICallForList(Config.FailedWorkordersapi, 'Get', dataToPassToService).then((data: any) => {
            _items = [];
            if (data.length === null) {
                if (_items.length <= 0) {
                    this.setState({
                        alertMessage: 'No results found for this criteria',
                        showAlert: true
                    });
                }
                this.setState({ items: _items, loading: false });
                return;
            }
            else {
                data.forEach((element: any, index: any) => {
                    let errorMessage: any = '';
                    if (element.Error) {
                        try {
                            errorMessage = JSON.parse(element.Error);
                            errorMessage = errorMessage.responseStatus.message;
                        }
                        catch {
                            errorMessage = 'The application failed to process the request.';
                        }

                    }
                    _items.push({
                        id: element.Id,
                        Wonumber: element.Wonumber,
                        Error: errorMessage,
                        Wohistoryid: element.Wohistoryid,
                        Datecreated: element.Datecreated


                    });
                });
            }
            _items = utility._sortItems(_items, 'updatedOn', true);

            this.setState({ items: _items, callAfterInit: true, loading: false, preItems: _items });

            //console.log(_items);
        }, (err) => {
            console.log(err);
            this.setState({ loading: false });
        });
    }

    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }

    public render(): React.ReactElement<any> {

        return (
            <React.Fragment>
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Failed Work Orders</span>
                    </div>
                </div>
                <div>
                    {!this.state.loading ?
                        <DataTable
                            style={{ width: '100%' }}
                            filteralbe={true}
                            groupable={true}
                            rows={this.state.items}
                            columns={this.state.columns}
                            fileName={'FailedWorkorders'}
                            columnChooser={'FailedWorkorderssColumns'}
                            selectionMode={false}
                            showHideColumnMenu={true}
                            callAfterInit={this.state.callAfterInit}
                            stopCommsAfterInit={() => this.stopCommsAfterInit()}
                            loading={this.state.loading}
                            allowExportSelectedData={false}
                            showCommandBtn={true}
                            showNewBtn={false}
                            hideNewBtn={true}
                            showViewDetailBtn={false}
                            hideCommandDeleteBtn={true}
                            hideCommandEditBtn={true}
                        />
                        :
                        <Spinner size='large' type={'converging-spinner'} className="loader" />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }

}