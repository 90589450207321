import * as React from 'react';

import moment from 'moment';
//kendo
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import utility from '../../classes/utility';
import AlertDialog from '../common/alertDialog';
import { Config } from '../../classes/config';
import global from "../../classes/global";
import { Paper } from '@material-ui/core';
import { Toast } from 'devextreme-react/toast';
import SettingsIcon from '@material-ui/icons/Settings';
import profilePic from '../../images/profilePic.png';
import EmailIcon from '@material-ui/icons/Email';
import WorkIcon from '@material-ui/icons/Work';
import PhoneIcon from '@material-ui/icons/Phone';
import StorageIcon from '@material-ui/icons/Storage';
import ContactsIcon from '@material-ui/icons/Contacts';
import Switch from 'devextreme-react/switch';
import Spinner from '../common/reusableComponents/Spinner';
export default class Profile extends React.Component<any, any> {

    public currentUser: any;
    constructor(props: any) {
        super(props);
        this.currentUser = this.props.currentUser;
        // 
        if (!this.props.Department) {
            window.open('#/AccessRequest', '_self');
        };
        this.state = {
            themeMode: localStorage.getItem('theme') === 'theme-dark' ? true : localStorage.getItem('theme') === null ? false : false,
        };
    }


    public componentDidMount() {
        // console.log(global.accountInfo);
        this._getProfileData();
    }
    private _getProfileData() {
        let dataToPassToService: any = {};
        this.setState({
            loading: false,
            info: []
        });
        dataToPassToService.Email = this.currentUser.Email;
        //console.log("dataToPassToService", dataToPassToService);
        utility.genericPostAPIcall(Config.SearchEmployeeApi, dataToPassToService).then((data: any) => {

            this.setState({
                id: data[0].Id,
                title: data[0].Title,
                firstName: data[0].FirstName,
                lastName: data[0].LastName,
                employeeno: data[0].EmployeeNo,
                loginName: data[0].LoginName,
                departmentid: data[0].DepartmentId,
                department: data[0].DepartmentName,
                departmentCode: data[0].DepartmentCode,
                crewId: data[0].CrewId,
                crewName: data[0].CrewName,
                teamId: data[0].TeamId,
                teamName: data[0].TeamName,
                email: data[0].Email,
                phone: data[0].Phone,
                jobTitle: data[0].JobTitle,
                active: data[0].IsActive,
                contractor: data[0].IsContractor,
                userType: data[0].StaffType,
                pin: data[0].Pin,
                roleId: data[0].RoleId,
                updatedBy: data[0].Userupdated,
                updatedOn: data[0].Dateupdated ? moment(data[0].Dateupdated).format("DD/MM/YYYY h:mm") : null,
                createdBy: data[0].Usercreated,
                createdOn: data[0].Datecreated ? moment(data[0].Datecreated).format("DD/MM/YYYY h:mm") : null,
                info: data[0],
                loading: false
            })

        }, (err) => {
            console.log(err);
            this.setState({ loading: false });
        });
    }
    public themeProvider = (e: any) => {
        if (this.state.themeMode) {
            this.setTheme('theme-light');
        } else {
            this.setTheme('theme-dark');
        }
        window.location.reload();
        this.setState({ themeMode: !this.state.themeMode });
    }
    public setTheme = (themeName: any) => {
        localStorage.setItem('theme', themeName);
        document.documentElement.className = themeName;
    }
    private _fieldChanged = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.target.value });
    }
    public render(): React.ReactElement<any> {

        return (
            <div className="dashboardHome">
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"profileeHeading"}>Welcome, <strong>{this.state.title}</strong> </span>
                    </div>
                </div>
                {!this.state.loading && this.state.info ?
                    <div className={"searchFieldDiv"}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <Paper elevation={6}>
                                    <div className="ProfileCard">
                                        <img className="profileImg" src={profilePic} alt="pic" />
                                        <br />
                                        <div className={'profileInfo'}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={2} sm={2}><WorkIcon className={'alignRight'} /></Grid>
                                                <Grid item xs={10} sm={10} className={'alignLeft'}>{this.state.jobTitle}</Grid>
                                                <Grid item xs={2} sm={2}><PhoneIcon className={'alignRight'} /></Grid>
                                                <Grid item xs={10} sm={10} className={'alignLeft'}>{this.state.phone}</Grid>
                                                <Grid item xs={2} sm={2}><EmailIcon className={'alignRight'} /></Grid>
                                                <Grid item xs={10} sm={10} className={'alignLeft'}>{this.state.email}</Grid>
                                                <Grid item xs={2} sm={2}><ContactsIcon className={'alignRight'} /></Grid>
                                                <Grid item xs={10} sm={10} className={'alignLeft'}>{this.state.employeeno}</Grid>
                                            </Grid>

                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <Paper elevation={6}>
                                    <div className="ProfileCard">
                                        <div className='profileSubHeading'>Setting</div>
                                        <SettingsIcon className={'profileIcon'} />
                                        <br />
                                        <div>
                                            <div>Switch Theme</div>
                                            <br />
                                            <Switch
                                                onValueChanged={this.themeProvider}
                                                value={this.state.themeMode}
                                            />
                                        </div>
                                        <br />
                                        {/* <div>
                                            <Button  variant='outlined'>Update Info</Button>
                                        </div> */}
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <Paper elevation={6}>
                                    <div className="ProfileCard">
                                        <div className='profileSubHeading'>Clear Storage</div>
                                        <StorageIcon className={'profileIcon'} />
                                        <p>
                                            It will clear all the local storage which includes <strong>saved search</strong> and <strong>column chooser</strong>
                                        </p>
                                        <Button onClick={() => this.clearStorage()} variant='outlined'>
                                            Clear
                                        </Button>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div> :
                    <Spinner label="Loading" />
                }
                <Toast
                    visible={this.state.isVisible}
                    message={'Local Storage has been cleared'}
                    type={'info'}
                    onHiding={() => this.setState({ isVisible: false })}
                    displayTime={1500}
                />
            </div>
        );
    }
    public clearStorage() {
        localStorage.clear();
        var indexedDB = window.indexedDB;
        // Open (or create) the database

        var request = indexedDB.deleteDatabase("cw-worxOnline");
        request.onsuccess = () => {
            this.setState({
                alertMessage: 'Local storage has been cleared successfully.',
                isVisible: true,
                loading: false
            })
        };
        request.onerror = () => {
            this.setState({
                alertMessage: 'Cannot clear local storage.',
                isVisible: true,
                loading: false
            })
        };
        this.setState({
            alertMessage: 'Local storage has been cleared successfully.',
            isVisible: true,
            loading: false,
        });
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }



}
