import global from './global';

// import { authProvider } from './authProvider';

import { loginRequest, msalConfig } from "./authProvider";

// import * as Msal from 'msal'; 
import * as msal from "@azure/msal-browser";
// let client :any = HttpClient;
let utility = {

  getTokenUsingMsal() {
    let currentUserEmail = localStorage.getItem("currentDetail");
    // let currentUserEmail = 'akil@atensystems.com';

    return new Promise(function (resolve, reject) {
      let storagedata = localStorage.getItem(global.accessToken);
      let tokenPromise;
      if (storagedata !== null) {
        let data: any = JSON.parse(storagedata);
        let currentDateTime = new Date();

        let durationInMinutes = 5;

        let endDate = data.idTokenClaims.exp;

        let expiredate = new Date(0);
        expiredate.setUTCSeconds(endDate);
        expiredate = new Date(expiredate.getTime() - 30 * 60000);

        if (currentDateTime.getTime() >= expiredate.getTime()) {
          tokenPromise = utility.tokenAPI(currentUserEmail);
          tokenPromise.then(res => {
            if (res)
              resolve(res)
          })
        }
        else {
          resolve(data.idToken);
        }
      } else {
        tokenPromise = utility.tokenAPI(currentUserEmail);
        tokenPromise.then(res => {

          if (res)
            resolve(res)
        })

      }
    })
  },
  tokenAPI(currentUserEmail: any) {

    return new Promise(function (resolve) {
      const msalInstance = new msal.PublicClientApplication(msalConfig);
      const accounts: any = msalInstance.getAllAccounts();

      let ssoRequest = {
        scopes: [global.AzureScope],
        // scopes: ['https://graph.microsoft.com/Mail.Read'],
        loginHint: currentUserEmail
      };
      let tokenRequest = {
        scopes: [global.AzureScope]
      };

      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
      }

      let currentAccount: any = msalInstance.getActiveAccount();
      if (!currentAccount) {
        currentAccount = msalInstance.getAccountByUsername(currentUserEmail);
      }

      if (currentAccount) {
        msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: currentAccount
        }).then(response => {
          localStorage.setItem(global.accessToken, JSON.stringify(response));

          resolve(response.idToken);
        }).catch(err => {

          console.log(err);
          msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" })
        });
      } else {
        msalInstance.ssoSilent(ssoRequest).then(response => {
          localStorage.setItem(global.accessToken, JSON.stringify(response));
          resolve(response.idToken);
        }).catch(error => {
          return msalInstance.acquireTokenPopup(tokenRequest)
            .then(response => {
              localStorage.setItem(global.accessToken, JSON.stringify(response));
              resolve(response.idToken);
            })
            .catch(err => {
              if (err) {
                console.log("Error" + err);
              }
            })

        });
      }
    });
  },

  genericGetAPICall(apiname: string) {
    let promise = new Promise((resolve, reject) => {
      utility.getItem(apiname).then((result: any) => {

        if (result && result.length > 0) {

          resolve(result);
        } else {
          utility.genericGetAPICallResponse(apiname).then((data: any) => {

            utility.setItem(apiname, data);
            resolve(data);
          }, (err) => {
            console.log(err);
          });
        }
      });
    });
    return promise;
  },
  genericGetAPICallResponse(apiname: string) {

    let promise = new Promise((resolve, reject) => {
      utility.getTokenUsingMsal().then((token: any) => {

        fetch(`${global.spAPIURL}/api/${apiname}`, {
          method: 'GET',
          headers: {
            "authorization": `Bearer ${token}`
          }
        }).then((result: any) => {
          //console.log(result);
          if (result.ok) {
            resolve(result.json());

          } else {
            result.text().then((text: any) => { reject(text) })
            throw new Error('Network response was not ok.');
          }
        }).catch((error: any) => { reject(error); console.error(error) });


      },
        (err) => {
          { reject(err); console.error(err) }
        }
      );

    });
    return promise;
  },
  genericGetAPICallForList(apiname: string, method?: any, data?: any) {
    let promise = new Promise((resolve, reject) => {
      utility.getTokenUsingMsal().then((token: any) => {

        let header: any = {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${token}`
          }
        };
        if (method === 'POST') {
          header.body = data ? JSON.stringify(data) : ''
        }
        fetch(`${global.spAPIURL}/api/${apiname}`, header
        ).then((result: any) => {
          //console.log(result);
          if (result.ok) {
            resolve(result.json());
          } else {
            reject(result);
            throw new Error('Network response was not ok.');
          }
        }).catch((error: any) => console.error(error));


      },
        (err) => {
          console.log(err);
        }
      );

    });
    return promise;
  },
  genericGetDataByIdAPICall(apiname: string, id: any) {
    let promise = new Promise((resolve, reject) => {
      utility.getTokenUsingMsal().then((token: any) => {

        fetch(`${global.spAPIURL}/api/${apiname}/${id}`, {
          method: 'GET',
          headers: {
            "authorization": `Bearer ${token}`
          }
        })
          .then(result => {
            if (result.ok) {
              resolve(result.json());
            } else {
              reject(result);
              throw new Error('Network response was not ok.');
            }
          }).catch(error => console.error(error));

      },
        (err) => {
          console.log(err);
        }
      );

    });
    return promise;
  },

  genericDeleteAPICall(apiname: string, id: any) {

    let promise = new Promise((resolve, reject) => {
      utility.getTokenUsingMsal().then((token: any) => {
        //using client

        fetch(`${global.spAPIURL}/api/${apiname}/${id}`, {
          method: 'DELETE',
          headers: {
            "authorization": `Bearer ${token}`
          }
        })
          .then(result => {
            if (result.ok) {
              resolve(result);
            } else {
              reject(result);
              throw new Error('Network response was not ok.');
            }
          }).catch(error => console.error(error));

      },
        (err) => {
          console.log(err);
        }
      );

    });
    return promise;
  },

  deleteBulkWorkorder(apiname: string, data: any) {

    let promise = new Promise((resolve, reject) => {
      utility.getTokenUsingMsal().then((token: any) => {
        //using client

        fetch(`${global.spAPIURL}/api/${apiname}`, {
          method: 'DELETE',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${token}`
          }
        })
          .then(result => {
            if (result.ok) {
              resolve(result);
            } else {
              reject(result);
              throw new Error('Network response was not ok.');
            }
          }).catch(error => console.error(error));

      },
        (err) => {
          console.log(err);
        }
      );

    });
    return promise;
  },
  genericUpdateAPICall(apiname: string, id: any, data: any) {
    let promise = new Promise((resolve, reject) => {
      utility.getTokenUsingMsal().then((token: any) => {

        //using client
        fetch(`${global.spAPIURL}/api/${apiname}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${token}`
          }
        })
          .then(result => {
            if (result.ok) {
              resolve(result);
            } else {
              reject(result);
              throw new Error('Network response was not ok.');
            }
          }).catch(error => console.error(error));

      },
        (err) => {
          console.log(err);
        }
      );

    });
    return promise;
  },

  genericUpdatePortalAPICall(apiname: string, data: any) {
    let promise = new Promise((resolve, reject) => {
      utility.getTokenUsingMsal().then((token: any) => {

        //using client
        fetch(`${global.spAPIURL}/api/${apiname}`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${token}`
          }
        })
          .then(result => {
            if (result.ok) {
              resolve(result);
            } else {
              reject(result);
              throw new Error('Network response was not ok.');
            }
          }).catch(error => console.error(error));

      },
        (err) => {
          console.log(err);
        }
      );

    });
    return promise;
  },

  genericUpdateNatureStripAPICall(apiname: string, data: any) {
    let promise = new Promise((resolve, reject) => {
      utility.getTokenUsingMsal().then((token: any) => {

        //using client
        fetch(`${global.spAPIURL}/api/${apiname}`, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${token}`
          }
        })
          .then(result => {
            if (result.ok) {
              resolve(result);
            } else {
              reject(result.text());
              throw new Error('Network response was not ok.');
            }
          }).catch(error => console.error(error));

      },
        (err) => {
          console.log(err);
        }
      );

    });
    return promise;
  },



  genericPostAPIcall: (apiName: string, data: any) => {
    let promise = new Promise((resolve, reject) => {
      utility.getTokenUsingMsal().then((token: any) => {

        //using client
        fetch(`${global.spAPIURL}/api/${apiName}`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${token}`
          }
        })
          .then(result => {
            if (result.ok) {
              resolve(result.json())
            } else {
              reject(result.json());
              //throw new Error('Network response was not ok.');
            }
          }).catch(error => console.error(error));

      },
        (err) => {
          console.log(err);
        }
      );

    });
    return promise;
  },
  genericPostAPIcallForDowloadDoct(apiName: string, data: any) {
    let promise = new Promise((resolve, reject) => {
      utility.getTokenUsingMsal().then((token: any) => {

        //using client
        fetch(`${global.spAPIURL}/api/${apiName}`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${token}`
          }
        })
          .then(result => {
            if (result.ok) {
              resolve(result.text());
            } else {
              resolve(result.text());
              //throw new Error('Network response was not ok.');
            }
          }).catch(error => console.error(error));

      },
        (err) => {
          console.log(err);
        }
      );

    });
    return promise;
  },
  loadNaturalSearchFromApi: (relativeUrl: string, apiName: string): Promise<any> => {
    let promise = new Promise<any>((resolve, reject) => {
      fetch(`${global.spAPIURL}/api/${apiName}?utterance=${relativeUrl}`, {
        method: 'GET'
      }).then(result => {

        if (result.ok) {
          resolve(result.json());
        } else {
          reject(result);
          throw new Error('Network response was not ok.');
        }
      }).catch(error => console.error(error));
    });
    return promise;
  },
  cuniq: () => {
    let currentdate = new Date();
    let datetime = currentdate.getFullYear() + "-"
      + (currentdate.getMonth() + 1) + "-"
      + currentdate.getDate() + "-"
      + currentdate.getHours() + "-"
      + currentdate.getMinutes() + "-"
      + currentdate.getSeconds();
    return datetime;
  },

  goBack: () => {
    window.history.back();
  },
  _sortItems: (items: any[], sortBy: string, descending = false): any[] => {
    if (descending) {
      return items.sort((a: any, b: any) => {
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        return 0;
      });
    } else {
      return items.sort((a: any, b: any) => {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      });
    }
  },
  groupBy(xs: any, key: any) {
    if (xs && xs.length > 0) {

      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    } else {
      return false;
    }
  },
  validURL(str: any) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  },
  getItem: (storageName: any) => {
    let promise = new Promise((resolve, reject) => {
      var indexedDB = window.indexedDB;
      // Open (or create) the database
      let result;
      var request = indexedDB.open("cw-worxOnline", 2);

      request.onupgradeneeded = (event) => {
        let db = request.result;
        if (!db.objectStoreNames.contains("cw-worxOnline-Store")) { // if there's no "books" store
          db.createObjectStore("cw-worxOnline-Store", { keyPath: 'id' }); // create it
          resolve('');
        }
      };
      request.onsuccess = (event) => {
        var db = request.result;
        if (!db.objectStoreNames.contains("cw-worxOnline-Store")) {
          // db.createObjectStore("cw-worxOnline-Store", { keyPath: storageName });
          resolve('')
        } else {
          var transaction = db.transaction("cw-worxOnline-Store", "readonly"); // (1)     

          // get an object store to operate on it
          let stores = transaction.objectStore("cw-worxOnline-Store"); // (2)


          let requestItem = stores.get(storageName);
          requestItem.onsuccess = function () { // (4)
            if (requestItem.result && requestItem.result.items) {
              result = JSON.parse(requestItem.result.items);
              resolve(result);
            } else {
              resolve('')
            }
          }

          requestItem.onerror = function () {
            // reject(requestItem.error)
          };
          transaction.oncomplete = function () {
            db.close();
          };
        }
        // db.close();
      };
      request.onerror = (event) => {
        console.log('Finished doing something, now closing');
        // resolve(true);
        reject();
      };

    });
    return promise;
  },
  setItem: (storageName: any, data: any) => {
    let promise = new Promise((resolve, reject) => {
      var indexedDB = window.indexedDB;
      // Open (or create) the database

      var request = indexedDB.open("cw-worxOnline", 2);

      request.onupgradeneeded = (event) => {
        let db = request.result;
        if (!db.objectStoreNames.contains("cw-worxOnline-Store")) { // if there's no "books" store
          db.createObjectStore("cw-worxOnline-Store", { keyPath: 'id' }); // create it
        }
      };
      request.onsuccess = (event) => {
        var db = request.result;
        if (!db.objectStoreNames.contains("cw-worxOnline-Store")) { // if there's no "books" store
          db.createObjectStore("cw-worxOnline-Store", { keyPath: 'id' }); // create it
        }
        let transaction = db.transaction(["cw-worxOnline-Store"], "readwrite"); // (1)

        // get an object store to operate on it
        let stores = transaction.objectStore("cw-worxOnline-Store"); // (2)

        let store = {
          id: storageName,
          items: JSON.stringify(data)
        };
        let requestItem = stores.add(store);

        requestItem.onsuccess = function () {
          resolve(true);
        };

        requestItem.onerror = function () {
          console.log("Error", requestItem.error);
        };
        // db.close();
      };
      request.onerror = (event) => {
        console.log('Finished doing something, now closing');
      };
    });
    return promise;
  },
  removeItem: (storeId: any) => {
    try {
      var indexedDB = window.indexedDB;
      var open = indexedDB.open("cw-worxOnline", 2);
      open.onsuccess = function () {
        // Start a new transaction
        var db = open.result;
        var transaction = db.transaction(["cw-worxOnline-Store"], "readwrite");
        var store = transaction.objectStore("cw-worxOnline-Store");

        store.delete(storeId);

        transaction.oncomplete = function () {
          db.close();
        };
      };
      open.onerror = function () {
        return;
      }
    }
    catch (err: any) {
      console.error(err.message);
    }
  },
};

export default utility;
