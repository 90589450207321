import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditEmployee from './AddEditEmployee';


let _items: any = [];


export default class Employees extends React.Component<any, any> {


    public currentUser: any;
    public columns: any;
    constructor(props: any) {
        super(props);


        this._getItemData = this._getItemData.bind(this);
        this.DeleteDialog = this.DeleteDialog.bind(this);

        if (this.props.role !== global.worxOnline_Admin && this.props.role !== global.worxOnline_WorxAdmin && this.props.role !== global.worxOnline_ContractorAdmin) {
            window.open('#/AccessDenied', '_self');
        }
        // this.currentUser = {
        //     DisplayName: props.context.pageContext.user.displayName,
        //     Email: props.context.pageContext.user.email,
        //     LoginName: props.context.pageContext.user.loginName
        // };

        this.currentUser = this.props.currentUser;

        this.columns = [
            {
                field: 'id',
                title: 'ID',
                // width: 160,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'title',
                title: 'Title',
                // width: 160,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'employeeno',
                title: 'Code',
                // width: 160,
                type: 'string',
            },
            {
                field: 'loginName',
                title: 'Login Name',
                // width: 280,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'teamName',
                title: 'Team',
                // width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true,
            },
            {
                field: 'email',
                title: 'Email',
                // width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true,
            },
            {
                field: 'phone',
                title: 'Phone',
                // width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true,
            },
            {
                field: 'jobTitle',
                title: 'Job Title',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'userType',
                title: 'User Type',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'crewName',
                title: 'Crew',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'department',
                title: 'Organisation',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'active',
                title: 'Is Active',
                // width: 160,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'contractor',
                title: 'Is Contractor',
                // width: 160,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'updatedOn',
                title: 'Updated On',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true,
                sortOrder: 'desc'
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created On',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
        ];
        let showHideColumns = localStorage.getItem("usersColumns");
        let tempColumns;
        if (showHideColumns !== null) {
            tempColumns = JSON.parse(showHideColumns);
        } else {
            tempColumns = this.columns;
            localStorage.setItem("usersColumns", JSON.stringify(this.columns));
        }
        this.state = {
            items: _items,
            columns: tempColumns,
            disableTeam: true,
            disableCrew: true,
        };
    }

    public componentDidMount() {
        this._getItemData();

    }

    public DeleteItem(item: any) {

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' type={'converging-spinner'} /> <br /> Loading...</div>,
            hideAlertButton: true,
            showAlert: true,
            showDeleteAlert: false,
        });
        utility.genericDeleteAPICall(Config.employeelistname, item).then((data: any) => {
            this.setState({
                alertMessage: 'User has been deleted.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
                loading: false
            });
            this._getItemData();
        }, (err) => {
            console.log(err);
            this.setState({
                alertMessage: 'Cannot delete user. Please try again later.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
            });
        });

    }


    // edit activty modal

    private EditItemModal(item: any) {
        //console.log("item", item);
        this.setState({ showModal: true, details: item, mode: 'edit' });
    }
    private _showModal = (): void => {
        this.setState({ showModal: true, details: null, mode: 'add' });
    }
    public renderNewModal() {
        return (
            <AddEditEmployee
                showModal={this.state.showModal}
                onClose={() => this._closeModal()}
                onClick={() => this._getItemData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                details={this.state.details}
                role={this.props.role}
                mode={this.state.mode}
            />
        );
    }
    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }
    public message(message: any) {
        //console.log(message);
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }

    private _getItemData() {
        let dataToPassToService: any = {};
        this.setState({
            loading: true
        });
        if (this.props.role === global.worxOnline_WorxAdmin) {

            dataToPassToService.DepartmentId = this.props.Department ? this.props.Department.id : -1;
            dataToPassToService.TeamId = this.props.teamId ? this.props.teamId : -1;
        }
        if (this.props.role === global.worxOnline_ContractorAdmin) {

            dataToPassToService.DepartmentId = this.props.Department ? this.props.Department.id : -1;
        }
        utility.genericGetAPICallForList(Config.SearchEmployeeApi, 'POST', dataToPassToService).then((data: any) => {
            _items = [];
            //console.log(data);

            for (let r of data) {
                _items.push({
                    id: r.Id,
                    title: r.Title,
                    firstName: r.FirstName,
                    lastName: r.LastName,
                    employeeno: r.EmployeeNo,
                    loginName: r.LoginName,
                    departmentid: r.DepartmentId,
                    department: r.DepartmentName,
                    departmentCode: r.DepartmentCode,
                    crewId: r.CrewId,
                    crewName: r.CrewName,
                    teamId: r.TeamId,
                    teamName: r.TeamName,
                    email: r.Email,
                    phone: r.Phone,
                    jobTitle: r.JobTitle,
                    active: r.IsActive,
                    contractor: r.IsContractor,
                    userType: r.StaffType,
                    pin: r.Pin,
                    roleId: r.RoleId,
                    updatedBy: r.Userupdated,
                    updatedOn: r.Dateupdated,
                    createdBy: r.Usercreated,
                    createdOn: r.Datecreated,

                });
            }

            _items = utility._sortItems(_items, 'updatedOn', true);

            this.setState({ items: _items, callAfterInit: true, loading: false, preItems: _items });

        }, (err) => {
            console.log(err);
            this.setState({ loading: false, callAfterInit: true, });
        });
    }


    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }



    public render(): React.ReactElement<any> {
        return (
            <React.Fragment>
                {this.renderNewModal()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Users </span>
                    </div>
                </div>
                <div>
                    {!this.state.loading ?
                        <DataTable
                            style={{ width: '100%' }}
                            filteralbe={true}
                            groupable={true}
                            rows={this.state.items}
                            columns={this.state.columns}
                            fileName={'Users'}
                            columnChooser={'usersColumns'}
                            selectionMode={false}
                            showHideColumnMenu={true}
                            callAfterInit={this.state.callAfterInit}
                            stopCommsAfterInit={() => this.stopCommsAfterInit()}
                            loading={this.state.loading}
                            allowExportSelectedData={false}
                            showNewModal={() => this._showModal()}
                            deleteItem={(a: any) => this.DeleteDialog(a)}
                            editItem={(a: any) => this.EditItemModal(a)}
                            // viewFile={(e)=>this.viewFile(e)}
                            showCommandBtn={true}
                            hideCommandDeleteBtn={(this.props.role === global.worxOnline_WorxAdmin || this.props.role === global.worxOnline_ContractorAdmin) ? true : false}
                            showNewBtn={(this.props.role === global.worxOnline_WorxAdmin || this.props.role === global.worxOnline_ContractorAdmin) ? false : true}
                            showViewDetailBtn={false}
                            hideNewBtn={this.state.showModal}
                        />
                        :
                        <Spinner size='large' type={'converging-spinner'} className="loader" />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }
    public DeleteDialog(item: any) {
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            deleteItem: item
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.DeleteItem(this.state.deleteItem)}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }
}
