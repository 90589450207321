import * as React from 'react';

import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import utility from '../../classes/utility';
import global from '../../classes/global';
import { Config } from '../../classes/config';

import AlertDialog from '../common/alertDialog';
import DataTable from '../common/DataTable/DataTable';
import DateBoxInput from '../common/reusableComponents/DateBox';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import TextBoxInput from '../common/reusableComponents/TextBox';
import Spinner from '../common/reusableComponents/Spinner';
let _items: any = [];
const StatusListItem: any = [
  { id: 'Pass', text: 'Pass' },
  { id: 'Fail', text: 'Fail' }
];
export default class PrestartChecklist extends React.Component<any, any> {

  public plantitems: any = [];
  public UserItems: any = [];
  public departmentItems: any = [];
  public columns: any;

  constructor(props: any) {
    super(props);
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };
    this.plantitems = [];
    this.UserItems = [];
    this.departmentItems = [];
    this.getPrestartChecklistwithcriteria = this.getPrestartChecklistwithcriteria.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.clearFilter = this.clearFilter.bind(this);



    this.columns = [

      {
        field: 'fileName',
        title: 'File Name',
        // width: 160,
        type: 'string',
        allowHiding: false,
        link: 'file',
      },
      {
        field: 'title',
        title: 'Title',
        // width: 160,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'status',
        title: 'Status',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'driver',
        title: 'Driver',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'department',
        title: 'Organisation',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'user',
        title: 'User',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'PlantNumber',
        title: 'Plant Number',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'PlantName',
        title: 'Plant Name',
        // width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'fileURL',
        title: 'File Url',
        width: 160,
        type: 'string',
        visble: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created On',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
    ];
    let showHideColumns = localStorage.getItem("prestartchecklistColumns");
    let tempColumns;
    if (showHideColumns !== null) {
      tempColumns = JSON.parse(showHideColumns);
    } else {
      tempColumns = this.columns;
      localStorage.setItem("prestartchecklistColumns", JSON.stringify(this.columns));
    }
    this.state = {
      items: _items,
      columns: tempColumns,
      callAfterInit: false,
      loading: false,
      department: (this.props.role === global.worxOnline_ContractorAdmin || this.props.role === global.worxOnline_WorxAdmin) ? this.props.Department : '',
    };
  }


  public componentDidMount() {
    this._getPlantData();
    this._getUserData();
    this._getDepartmentData();

  }

  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      this.getPrestartChecklistwithcriteria();
    }
  }
  public clearFilter() {
    this.setState({
      items: '',
      dateCreatedTo: '',
      dateCreatedfrom: '',
      plant: '',
      status: '',
      user: '',
      plantNumber: '',
      department: (this.props.role === global.worxOnline_ContractorAdmin || this.props.role === global.worxOnline_WorxAdmin) ? this.state.department : ''
    });
    localStorage.removeItem('datagridFilterValue');
  }
  public viewFile(file: any) {
    let item = file;
    let dataToPassToService: any = {};
    if (!utility.validURL(item.fileURL) || !item.fileName) {
      this.setState({
        alertMessage: 'Unable to view this document. \n File URL or File name does exist',
        showAlert: true
      });
      return;
    }
    dataToPassToService.Name = item.fileName;
    dataToPassToService.FileURL = item.fileURL;
    //console.log("dataToPassToServiceForRWOusingId", dataToPassToService);
    //console.log("dataToPassToServiceForRWOusingId", JSON.stringify(dataToPassToService));
    utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {
      //console.log("doc",doc);
      window.open(doc, "_blank");
    });

  }
  private _getPlantData() {
    utility.genericGetAPICall(Config.plantListName).then((data: any) => {
      //console.log(data);
      this.plantitems = [];
      for (let r of data) {
        this.plantitems.push({ id: r.Id, text: r.Title });
      }
      this.setState({ plantitems: this.plantitems, allplantitems: this.plantitems });

    }, (err) => {
      console.log(err);
    });
  }
  private _getUserData() {
    utility.genericGetAPICall(Config.employeelistname).then((data: any) => {
      //console.log(data);
      this.UserItems = [];
      for (let r of data) {
        this.UserItems.push({ id: r.Id, text: r.Title });
      }
      this.setState({ UserItems: this.UserItems, allUserItems: this.UserItems });

    }, (err) => {
      console.log(err);
    });
  }
  private _getDepartmentData() {
    utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {

      this.departmentItems = [];

      for (let r of data) {
        this.departmentItems.push({ id: r.Id, text: r.Title });
      }
      this.departmentItems = utility._sortItems(this.departmentItems, 'text', false);
      this.setState({ departmentItems: this.departmentItems, alldepartmentItems: this.departmentItems });
    }, (err) => {
      console.log(err);
    });
  }

  public getPrestartChecklistwithcriteria() {
    let criteriaSelected = false;

    let dataToPassToService: any = {};

    if (this.state.title) {
      dataToPassToService.Title = this.state.title;
      criteriaSelected = true;
    }

    if (this.state.plant) {
      dataToPassToService.PlantId = this.state.plant.id;
      criteriaSelected = true;
    }
    if (this.state.status) {
      dataToPassToService.Status = this.state.status.id;
      criteriaSelected = true;
    }
    if (this.state.user) {
      dataToPassToService.UserId = this.state.user.id;
      criteriaSelected = true;
    }
    if (this.state.plantNumber) {
      dataToPassToService.PlantNumber = this.state.plantNumber;
      criteriaSelected = true;
    }

    if (this.state.department) {
      dataToPassToService.DepartmentId = this.state.department.id;
      criteriaSelected = true;
    }
    if (this.state.dateCreatedTo && this.state.dateCreatedfrom) {
      let dcreatedfrom = new Date(this.state.dateCreatedfrom);
      let dcreatedTo = new Date(this.state.dateCreatedTo);
      if (dcreatedTo.getTime() < dcreatedfrom.getTime()) {
        this.setState({
          alertMessage: 'Date To should be greater than Date From',
          showAlert: true
        });
        return;
      }
    }

    if (this.state.dateCreatedfrom || this.state.dateCreatedTo) {
      let dfrom;
      let dTo;
      if (this.state.dateCreatedfrom) {
        dfrom = new Date(this.state.dateCreatedfrom).toISOString();
        dataToPassToService.DateFrom = dfrom;
      }
      if (this.state.dateCreatedTo) {
        var extraday = new Date(this.state.dateCreatedTo);
        extraday.setDate(extraday.getDate() + 1);
        dTo = extraday.toISOString().substring(0, 10) + "T13:59:59.000Z";
        // dTo = this.state.dateCreatedTo.toISOString();
        dataToPassToService.DateTo = dTo;
      }

      criteriaSelected = true;
    }

    if (!criteriaSelected) {
      this.setState({
        alertMessage: 'Please select search criteria',
        showAlert: true
      });
      return;
    }

    //console.log("dataToPassToService", dataToPassToService);
    this.setState({
      callAfterInit: true,
      loading: true
    });

    utility.genericPostAPIcall(Config.SearchPrestartChecklist, dataToPassToService).then((data: any) => {
      _items = [];
      // console.log(data);
      if (data.length === null) {
        if (_items.length <= 0) {
          this.setState({
            alertMessage: 'No results found for this criteria',
            showAlert: true
          });
        }
        this.setState({ items: _items, loading: false });
        return;
      }
      else {
        data.forEach((item: any) => {

          _items.push({
            id: item.Id,
            fileName: item.Name,
            title: item.Title,
            fileURL: item.FileURL,
            department: item.Department,
            plantName: item.PlantName,
            driver: item.Driver,
            status: item.Status,
            PlantName: item.PlantName,
            PlantNumber: item.PlantNumber,
            user: item.User,
            createdOn: item.DateCreated ? item.DateCreated : null,
          });
        });

      }
      _items = utility._sortItems(_items, 'updatedOn', true);
      if (_items.length <= 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });
      }
      this.setState({ items: _items, loading: false });
      //console.log(_items);
    }, (err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }

  private handleValueChange = (event: any, fieldName: string) => {
    this.setState({ [fieldName]: event.value });
  }
  public handleDropdownChange(e: any, fieldName: string) {
    this.setState({ [fieldName]: e.selectedItem });
  }
  public render(): React.ReactElement<any> {

    return (
      <div >
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}>Prestart Checklist</span>
          </div>
        </div>
        <div className={"searchFieldDiv"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <DateBoxInput
                stylingMode='underlined'
                type="date"
                displayFormat={"dd/MM/yy"}
                label={'Date From'}
                showClearButton={true}
                value={this.state.dateCreatedfrom ? new Date(this.state.dateCreatedfrom) : null}
                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedfrom')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <DateBoxInput
                stylingMode='underlined'
                type="date"
                displayFormat={"dd/MM/yy"}
                label={'Date To'}
                showClearButton={true}
                value={this.state.dateCreatedTo ? new Date(this.state.dateCreatedTo) : null}
                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedTo')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='User'
                items={this.state.UserItems}
                selectedItem={this.state.user}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'user')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='Equipment and Vehicles'
                items={this.state.plantitems}
                selectedItem={this.state.plant}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'plant')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <TextBoxInput
                stylingMode='underlined'
                label="Equipment and Vehicles Number"
                // pattern={"[A-Za-z]+"}
                // minLength={2}
                value={this.state.plantNumber}
                onValueChanged={(e: any) => this.handleValueChange(e, 'plantNumber')}
                onKeyDown={this._handleKeyDown}

              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='Status'

                items={StatusListItem}
                selectedItem={this.state.status}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'status')}

              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='Organisation'

                items={this.state.departmentItems}
                selectedItem={this.state.department}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                disabled={(this.props.role === global.worxOnline_ContractorAdmin || this.props.role === global.worxOnline_WorxAdmin) ? true : false}

              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv"}>
                <Button onClick={this.clearFilter} variant='outlined'>
                  Clear
                </Button>
                <Button onClick={this.getPrestartChecklistwithcriteria} variant='outlined' color="primary" className={'button'}>
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>

          {!this.state.loading ?
            <div className='tabStripDiv'>
              {(this.state.items && this.state.items.length > 0) && (
                <DataTable
                  style={{ width: '100%' }}
                  filteralbe={true}
                  groupable={true}
                  rows={this.state.items}
                  columns={this.state.columns}
                  fileName={'Prestart Checklist'}
                  columnChooser={'prestartchecklistColumns'}
                  selectionMode={false}
                  showHideColumnMenu={true}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  // showNewModal = {() => this._showModal()}
                  // deleteItem = {(a:any) => this.DeleteDialog(a)}
                  // editItem = {(a:any) => this.EditItemModal(a)}
                  viewFile={(e: any) => this.viewFile(e)}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              )}
            </div>
            :
            // <CircularProgress className="loader" />
            <Spinner size='large' label={"Loading.."} />
          }
        </div>


      </div>
    );
  }

  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }

}

