import * as React from 'react';
import utility from '../../classes/utility';
// import UpdateComms from "../updateComms/updateComms";
import Grid from '@material-ui/core/Grid';
import { Config } from '../../classes/config';
import AlertDialog from '../common/alertDialog';
import Spinner from '../common/reusableComponents/Spinner';
import moment from 'moment';

export default class SRDetails extends React.Component<any, any> {
    public ServiceRequestID: any;

    constructor(props: any) {
        super(props);
        this.ServiceRequestID = '';


        this.state = {
            loading: false,
            requestDetail: {}
        };

    }

    public componentDidMount() {
        let search = this.props.location.search;
        let params = new URLSearchParams(search);
        let reqNo = params.get('ReqId');
        this.ServiceRequestID = reqNo;
        this.getServiceRequest();
    }

    public getServiceRequest() {
        this.setState({ loading: true });
        let reqData = '?requestNumber=' + this.ServiceRequestID;
        utility.genericGetDataByIdAPICall(Config.ServiceRequestDetail, reqData).then((item: any) => {

            let address = (item.AddressLot && 'lot' + item.AddressLot) + ' ' + item.AddressHousenumber + ' ' + item.AddressStreetname + ' ' + (item.StreettypeCode && item.StreettypeCode + ',') + ' ' + item.Suburb + ' ' + item.AddressState + ' - ' + item.AddressPostalcode;

            let srIitem = item;
            let Inspecteddatetime;
            if (item.Inspecteddatetime) {
                let year = new Date(item.Inspecteddatetime).getFullYear();
                if (year === 1 || year === undefined) {
                    Inspecteddatetime = '';
                }
                else {
                    Inspecteddatetime = item.Inspecteddatetime;
                }
            }
            let Initiateddatetime;
            if (item.Initiateddatetime) {
                let year = new Date(item.Initiateddatetime).getFullYear();
                if (year === 1 || year === undefined) {
                    Initiateddatetime = '';
                }
                else {
                    Initiateddatetime = item.Initiateddatetime;
                }
            }
            this.setState({
                requestDetail: srIitem,
                loading: false,
                address: address,
                Inspecteddatetime: Inspecteddatetime,
                Initiateddatetime: Initiateddatetime
            });
        }, (err) => {
            this.setState({
                alertMessage: "No service request detail found",
                hideAlertButton: false,
                showAlert: true,
                goBack: true,
                loading: false,
            });
        });
    }

    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={"worxOnline"}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this._onCloseDialog()}
            />
        );
    }

    public _onCloseDialog() {
        this.setState({ alertMessage: '', showAlert: false });
        if (this.state.goBack) {
            utility.goBack();
        }
    }
    // private _onShowNewWorkOrder = (): void => {

    //   this.setState({
    //     showNewWOModal: true
    //   });

    // }
    // private _closeNewWOModal = (): void => {
    //   this.setState({
    //     showNewWOModal: false
    //   });

    // }

    // public renderNewWOModal() {
    //   return (
    //     <Modal
    //       titleAriaId={"attendance"}
    //       subtitleAriaId={"no"}
    //       isOpen={this.state.showNewWOModal}
    //       onDismiss={this._closeNewWOModal}
    //       isBlocking={false}
    //       containerClassName={"modalStyleNewWO"}
    //     >
    //       <CreateWO
    //         newWOModal={true}
    //         context={this.props.context}
    //         currentUser={this.props.currentUser}
    //         onClose={() => this._closeNewWOModal()}
    //         onClick={() => this.getWorkOrdersForDepartment()}
    //         serviceReq={this.state.RequestDetails}
    //       />
    //     </Modal>
    //   );
    // }

    public render(): React.ReactElement<any> {
        const { requestDetail } = this.state;
        return (
            <div>
                {this.state.showAlert && this.alert()}

                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}>
                            Service Request Details
                        </span>
                    </div>
                </div>
                {(this.state.loading) && (
                    <div>
                        <Spinner size='large' label="Loading.." />
                        <br />
                    </div>
                )}
                {(!this.state.loading && requestDetail) && (
                    <div className={"detailFieldDiv"}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>Service Request No</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>{requestDetail.Number}</Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>Request Type</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>{requestDetail.Requestdescription}</Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>Scope</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>
                                        {requestDetail.Sourcetype}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>Category 2</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>
                                        {requestDetail.AreaCode + '-' + requestDetail.AreaDescription}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>Responsibility</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>
                                        {requestDetail.ResponsibilityCode + '-' + requestDetail.ResponsibilityDescription}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>LL Transfer Date</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.Inspecteddatetime && moment(this.state.Inspecteddatetime).format("DD/MM/YY h:mm a")}</Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>Location</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>
                                        {requestDetail.Location}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>Street Address</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.address}</Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>Resolution</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>
                                        {requestDetail.ResolutionCode}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>Reported Date</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.Initiateddatetime && moment(this.state.Initiateddatetime).format("DD/MM/YY h:mm a")}</Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>Taken By</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>
                                        {requestDetail.TakenbyName}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>Category 3</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>
                                        {requestDetail.SubareaCode + '-' + requestDetail.SubareaDescription}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>Priority</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>
                                        {requestDetail.PriorityCode + '-' + requestDetail.PriorityDescription}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>Issue #</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>
                                        {requestDetail.Referencenumber}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>After Hour Ref #</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>
                                        {requestDetail.Afthrsrefno}
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>Additional Information</Grid>
                                    <Grid item xs={12} sm={8} className={'semiBold'}>
                                        {requestDetail.Comments}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                    </div>
                )}

            </div>

        );
    }

}

