import * as React from 'react';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import {
  NavLink,
  HashRouter
} from "react-router-dom";
//kendo
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import utility from '../../classes/utility';
import AlertDialog from '../common/alertDialog';
import DataTable from '../common/DataTable/DataTable';
import { Config } from '../../classes/config';
import SearchIcon from '@material-ui/icons/Search';
import RoomIcon from '@material-ui/icons/Room';
import AssetMap from '../AssetSearch/AssetMap';
import AssetMapforDetail from '../AssetSearch/AssetMapforDetail';


import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Paper from '@material-ui/core/Paper';
//icon
import BuildIcon from '@material-ui/icons/Build';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import capitalize from '@material-ui/core/utils/capitalize';
import Spinner from '../common/reusableComponents/Spinner';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import MultiSelect from '../common/reusableComponents/MultiSelect';
import TextBoxInput from '../common/reusableComponents/TextBox';
import CheckBoxInput from '../common/reusableComponents/CheckBox';
import NumberBoxInput from '../common/reusableComponents/NumberBox';
let _items: any = [];
let selectedtab: any = 0;
const UnityTypeItems = [
  { id: 'Asset', text: 'Asset' },
  { id: 'Sewer', text: 'Sewer Main' },
  { id: 'Water', text: 'Water Main' },
];
export default class Assetsearch extends React.Component<any, any> {
  public assetitems: any = [];
  public selectedAsset: any = [];
  public AssetStaus: any = [];
  public UnityTypes: any = [];
  public AssetType: any = [];
  public suburbItems: any = [];
  public selectedAssets: any = [];
  public setupDataPromise: any = [];
  public columns: any;
  constructor(props: any) {
    super(props);
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };
    this.assetitems = [];
    this.selectedAsset = [];
    this.AssetStaus = [];
    this.UnityTypes = [];
    this.AssetType = [];
    this.suburbItems = [];
    this.setupDataPromise = [];
    this.getAssetwithcriteria = this.getAssetwithcriteria.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this._handleKeyDownforsearch = this._handleKeyDownforsearch.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.SearchClicked = this.SearchClicked.bind(this);
    this.SearchMap = this.SearchMap.bind(this);
    this.getNaturalSearchData = this.getNaturalSearchData.bind(this);
    // this.selectAllAsset = this.selectAllAsset.bind(this);
    this.selectButton = this.selectButton.bind(this);

    this.columns = [
      {
        field: 'AssetNumber',
        title: 'Unit Id',
        // width: 160,
        type: 'string',
        allowHiding: false,
        link: 'assetLink',
      },
      {
        field: 'UnitID2',
        title: 'Unit Id 2',
        // width: 160,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'Compkey',
        title: 'Compkey',
        // width: 160,
        type: 'string',
        allowHiding: true,
        visible: false
      },
      {
        field: 'ParallelLineNumber',
        title: 'Parellel Line #',
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'Description',
        title: 'Title',
        // width: 360,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'UnitType',
        title: 'Unit Type',
        // width: 110,
        type: 'string',
        allowHiding: true,
        showCodeWithDes: true,
        code: 'UnitTypeCode',
      },
      {
        field: 'AssetType',
        title: 'Type',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'Location',
        title: 'Location',
        // width: 120,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'Suburb',
        title: 'Town',
        width: 160,
        type: 'string',
        allowHiding: true,
        showCodeWithDes: true,
        code: 'SuburbCode',
      },
      {
        field: 'ServiceStatus',
        title: 'Status',
        // width: 180,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'siteId',
        title: 'Site Id',
        // width: 180,
        type: 'string',
        allowHiding: true,
      },
    ];
    let showHideColumns = localStorage.getItem("assetColumns");
    let tempColumns;
    if (showHideColumns !== null) {
      tempColumns = JSON.parse(showHideColumns);
    } else {
      tempColumns = this.columns;
      localStorage.setItem("assetColumns", JSON.stringify(this.columns));
    }
    this.state = {
      items: _items,
      assetitems: [],
      class: [],
      columns: tempColumns,
      selected: selectedtab,
      FromTypeItems: UnityTypeItems,
      allFromTypeItems: UnityTypeItems,
      showAllAsset: false,
      totalResults: 100,
      siteAssetId: '',
      parentId: '',
      asset: '',
      Compkey: '',
      AssetNumber: '',
      unitId2: '',
      unitId1: '',
      town: [],
      suburb: [],
      // heading: true
    };
  }


  public componentDidMount() {

    let tempstate = localStorage.getItem('assetState')
    this.setState({ loadingFields: true });
    this.setupDataPromise = [];
    this._getAssetStatusData();
    this._getAssetTypeData();
    this._getUnitTypeData();
    this._getsuburbDataAsync();
    this._getTownDataAsync();
    Promise.all(this.setupDataPromise).then(items => {
      //console.log('---all promise done---');
      this.setState({ loadingFields: false });
      if (tempstate) {
        // this.state = JSON.parse(tempstate)
        let allState = JSON.parse(tempstate);

        this.setState({
          asset: allState.asset ? allState.asset : '',
          SearchPath: allState.SearchPath ? allState.SearchPath : '',
          AssetNumber: allState.AssetNumber ? allState.AssetNumber : '',
          classItem: allState.classItem ? allState.classItem : '',
          assetTypeItem: allState.assetTypeItem ? allState.assetTypeItem : '',
          subAssetTypeItem: allState.subAssetTypeItem ? allState.subAssetTypeItem : '',
          unitId1: allState.unitId1 ? allState.unitId1 : '',
          unitId2: allState.unitId2 ? allState.unitId2 : '',
          parentDetail: allState.parentDetail ? allState.parentDetail : '',
          parentId: allState.parentId ? allState.parentId : '',
          unitType: allState.unitType ? allState.unitType : '',
          Compkey: allState.Compkey ? allState.Compkey : '',
          //town: allState.town ? allState.town : '',
          //suburb: allState.suburb ? allState.suburb : '',
          naturalSearch: allState.naturalSearch ? allState.naturalSearch : '',
          selectedTab: allState.selectedTab ? allState.selectedTab : 0,
          suburbItems: allState.suburbItems,
          townItems: allState.townItems,
          assetStatusItems: allState.assetStatusItems,
          tempUnitTypeItems: allState.tempUnitTypeItems,
          siteAssetId: allState.siteAssetId ? allState.siteAssetId : '',
          town: allState.town ? this.getMultiselectProps(allState.town, 'townItems') : '',
          suburb: allState.suburb ? this.getMultiselectProps(allState.suburb, 'suburbItems') : '',
          assetStatus: allState.assetStatus ? this.getMultiselectProps(allState.assetStatus, 'assetStatusItems') : ''
        })
        // this.setState(this.state= JSON.parse(tempstate));
        // localStorage.clear();
      }
    });
    this.setState({ heading: this.props.heading })

  }

  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      this.getAssetwithcriteria();
    }
  }
  public getMultiselectProps(data: any, listData: any) {
    let returnData: Array<any> = [];
    if (this.state[listData] && data) {
      data.forEach((item: any) => {
        let tempItem = this.state[listData].find((l: any) => l.id === item.id);
        returnData.push(tempItem);
      })
    }
    return returnData;
  }
  public _handleKeyDownforsearch = (e: any) => {

    if (e.key === 'Enter') {
      this.getNaturalSearchData();
    }
  }

  public selectButton() {
    let selectAssetfromItems = this.state.items;
    let tempSelectedAssets = selectAssetfromItems.filter((a: any) => a.Selected === true);
    // let columns = this.state.columns;
    this.props.onClick(tempSelectedAssets);

  }
  public clearFilter() {
    this.setState({
      items: '',
      asset: '',
      SearchPath: '',
      AssetNumber: '',
      classItem: '',
      assetTypeItem: '',
      unitId1: '',
      unitId2: '',
      parentDetail: '',
      parentId: '',
      unitType: '',
      Compkey: '',
      town: [],
      suburb: [],
      assetStatus: this.state.defaultAssetStatus,
      subAssetTypeItem: '',
      naturalSearch: '',
      tempUnitTypeItems: this.state.UnityTypeItems,
      siteAssetId: '',
      totalResults: 100
    });
    _items = []
    localStorage.removeItem('assetState');
  }
  public selectItem(i: any) {
    let item = i;
    if (this.props.selectMode) {
      this.props.onClick(item);
      // this.selectAsset(item);
      return;
    }
    if (this.props.selectScheduleMode) {
      this.props.onClick(item);
      return;
    }
    // if (this.props.selectCreateScheduleMode) {
    //   // let columns = this.state.columns;
    //   // this.props.onClick(item, columns);
    //   this.selectAsset(item);
    //   return;
    // }
  }


  public _getAssetStatusData() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.assetStatus).then((data: any) => {
        // console.log(data);

        // for class
        this.AssetStaus = [];
        let tempAssetStatus: any = [];
        for (let r of data) {
          // if(r.Title === 'Active'){
          //   let arg = { id: r.Title, text: (r.Shortdisplayname+' - '+r.Title),title:r.Title };
          //   tempAssetStatus.push(arg);
          // }
          this.AssetStaus.push({ id: r.Title, text: (r.Shortdisplayname + ' - ' + r.Title), title: r.Title, 'Select All': (r.Shortdisplayname + ' - ' + r.Title), });
        }
        this.AssetStaus.forEach((item: any) => {
          if (item.title === 'Active')
            tempAssetStatus.push(item);
        });
        this.AssetStaus = utility._sortItems(this.AssetStaus, 'title', false);

        this.setState({
          assetStatusItems: this.AssetStaus,
          allassetStatusItems: this.AssetStaus,
          assetStatus: tempAssetStatus,
          defaultAssetStatus: tempAssetStatus
        });

      }, (err) => {
        console.log(err);
      }));
  }
  //
  public _getUnitTypeData() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.unitType).then((data: any) => {
        // console.log(data);

        // for class
        this.UnityTypes = [];

        for (let r of data) {
          this.UnityTypes.push({ id: r.Shortdisplayname, text: r.Shortdisplayname + ' - ' + r.Title, title: r.Title, formType: r.Formtype });
        }

        this.UnityTypes = utility._sortItems(this.UnityTypes, 'title', false);
        //console.log('class', this.AssetStaus); Shortdisplayname
        this.setState({
          UnityTypeItems: this.UnityTypes,
          tempUnitTypeItems: this.UnityTypes,
          allUnityTypeItems: this.UnityTypes,
          alltempUnitTypeItems: this.UnityTypes,
        });

      }, (err) => {
        console.log(err);
      }));
  }
  private _getsuburbDataAsync() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.suburblistname).then((data: any) => {
        this.suburbItems = [];
        // console.log("suburb result", data);
        for (let r of data) {

          this.suburbItems.push({ id: r.Title, text: (r.Title), 'Select All': (r.Title), });

        }

        this.suburbItems = utility._sortItems(this.suburbItems, 'id', false);
        this.setState({ suburbItems: this.suburbItems, allsuburbItems: this.suburbItems });
      }));
  }
  private _getTownDataAsync() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.townlistname).then((data: any) => {
        let townItems = [];
        // console.log("suburb result", data);
        for (let r of data) {

          townItems.push({ id: r.Code, text: (r.Title), 'Select All': (r.Code + ' - ' + r.Title) });

        }

        townItems = utility._sortItems(townItems, 'id', false);
        this.setState({ townItems: townItems, alltownItems: townItems });
      }));
  }
  public _getAssetTypeData() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.assetType).then((data: any) => {
        // console.log(data);

        //for Asset Type dropdown
        this.AssetType = [];
        for (let c of data) {
          if (c.FormType === 'Asset') {
            this.AssetType.push({ id: c.Id, text: c.Title });
          }
        }
        //console.log('type', this.AssetType);

        this.AssetType = utility._sortItems(this.AssetType, 'text', false);
        this.setState({
          subAssetType: this.AssetType,
          allsubAssetType: this.AssetType,
        });

      }, (err) => {
        console.log(err);
      }));
  }


  public getNaturalSearchData() {
    if (!this.state.naturalSearch) {
      this.setState({
        alertMessage: 'Please enter your search',
        showAlert: true
      });
      return;
    }
    let utterance = this.state.naturalSearch.replace('&', '\\0026');

    let naturalSearchCriteria = `AssetSearchIntent?utterance=${utterance}`

    this.setState({ loading: true, loadingResult: true });

    utility.genericGetAPICall(naturalSearchCriteria).then((resultRequests: any) => {
      // console.log(resultRequests);

      if (resultRequests) {
        this.getAssetwithNaturalSearch(resultRequests);
        // setTimeout(this.getParentIdfromAsset(resultRequests), 2000);

      }
    }

    ).catch((err) => {
      console.log(err);

      this.setState({
        loading: false,
        loadingResult: false,
        alertMessage: "Utterance is not related to Search Asset",
        showAlert: true,
        assetSearchCount: 0
      });
    });

  }


  public getAssetwithNaturalSearch(assetCriteria: any) {

    let unitType = JSON.parse(assetCriteria.UnitType);
    unitType = unitType && unitType.join().replace("&", '\\0026');
    let pageNumber = 1;
    let pageSize = this.state.totalResults ? this.state.totalResults : 200;
    let assetsCriteia = `/Assets/Natural?unitType=${unitType}&site=${assetCriteria.Site}&addressLatLong=&distanceUnit=${assetCriteria.DistanceUnit}&distanceValue=${assetCriteria.DistanceValue}&PageNumber=${pageNumber}&PageSize=${pageSize}&showAll=${this.state.showAllAsset}`

    this.setState({
      loading: true
    });

    utility.genericGetAPICall(assetsCriteia).then((assetItems: any) => {

      _items = [];
      if (assetItems.length === 0) {
        this.setState({
          alertMessage: 'No results found for this search',
          showAlert: true,
          items: _items,
          loading: false,
          loadingResult: false
        });
        return;
      }
      else {
        assetItems.forEach((item: any) => {
          let address;
          if (item.AddressComponents) {
            // let tempAddressComp = JSON.parse(item.AddressComponents);
            let tempAddressComp = item.AddressComponents;

            address = tempAddressComp ? `${tempAddressComp.Lot} ${tempAddressComp.HouseNumber} ${tempAddressComp.StreetName !== 'NA' ? tempAddressComp.StreetName : ''} ${tempAddressComp.StreetType && tempAddressComp.StreetType.Description ? capitalize(tempAddressComp.StreetType.Description) + ',' : ''} ${tempAddressComp.Suburb !== 'NA' ? tempAddressComp.Suburb : ''} ${(tempAddressComp.State !== 'NA' && tempAddressComp.State) ? tempAddressComp.State.toUpperCase() : ''} ${tempAddressComp.PostalCode}` : item.Address
          }
          let ParentAsset = item.ParentAsset ? JSON.parse(item.ParentAsset) : '';
          _items.push({

            Register: "ASSET",
            AssetID: item.Id,
            siteId: ParentAsset.site ? ParentAsset.site.ID : '',
            id: item.Id,
            AssetNumber: item.AssetNumber,
            UnitID1: item.UnitIDAsset,
            UnitID2: item.UnitID2Asset,
            AssetType: item.TypeName,
            AssetTypeId: item.TypeId,
            AssetClass: item.Class,
            FormType: item.FormType,
            ServiceStatus: item.AssetStatus,
            ParallelLineNumber: item.ParallelLineNumber,
            Description: item.Desc,
            Suburb: item.SuburbTitle,
            SuburbCode: item.SuburbCode,
            SubUnitType: item.UnitSubType,
            SubUnitTypeCode: item.UnitSubTypeCode,
            UnitTypeId: item.UnitTypeId,
            UnitTypeCode: item.UnitTypeCode,
            UnitType: item.UnitType,
            Location: address,
            Address: item.AddressComponents,
            Compkey: item.CompKey,
            Selected: false,
            GISInfo: {
              type: "Feature",
              geometry: {
                type: "",
                coordinates: []
              },
              properties: {
                AssetID: item.Id,
                Name: item.Title,
                Type: item.Type,
                Hierarchy: "1.0",
                Zone: item.Suburb,
                RoadGroupName: item.Title,
                Link: null,
                GID: "",
                ExternalOSID: null,
                Location: item.Location,
                Ownership: "Council as CoM",
                ExtAssetID: "",
                subclass: item.Class
              }
            },
            ERPInfo: item.ErpInfo,
            AssetQuestions: [],
            AttachmentUrls: []

          });
        });
        this.setState({
          items: _items,
          loading: false,
          loadingResult: false,
          callAfterInit: true,
          assetSearchCount: assetItems.length
        });
        var stateData = { ...this.state };
        localStorage.setItem('assetState', JSON.stringify(stateData));
        // window.scrollTo(0, this.myRef.current.offsetTop);
      }

    }, (err: any) => {
      console.log(err);
      this.setState({
        loading: false,
        loadingResult: false,
        alertMessage: "No result found. Please try again with different search",
        showAlert: true,
        assetSearchCount: 0
      });
    });
  }

  public getAssetwithcriteria() {
    let criteriaSelected = false;

    let dataToPassToService: any = {};

    if (this.state.AssetNumber) {
      dataToPassToService.AssetNumber = this.state.AssetNumber;
      criteriaSelected = true;
    }

    if (this.state.asset) {
      dataToPassToService.Desc = this.state.asset;
      criteriaSelected = true;
    }
    if (this.state.Compkey) {
      dataToPassToService.CompKey = this.state.Compkey;
      criteriaSelected = true;
    }

    if (this.state.assetTypeItem) {
      dataToPassToService.FormType = this.state.assetTypeItem.text;
      criteriaSelected = true;
      if (this.state.subAssetTypeItem) {
        dataToPassToService.TypeId = this.state.subAssetTypeItem.id
      }
      if (this.state.unitId1) {
        dataToPassToService.UnitIdAsset = this.state.unitId1
      }
      if (this.state.unitId2) {
        dataToPassToService.UnitId2Asset = this.state.unitId2
      }
    }
    if (this.state.unitType) {
      dataToPassToService.UnitType = this.state.unitType.id;
      criteriaSelected = true;
    }
    if (this.state.parentId) {
      dataToPassToService.SiteId = this.state.parentId;
      criteriaSelected = true;
    }
    if (this.state.siteAssetId) {
      dataToPassToService.SiteAssetId = this.state.siteAssetId;
      criteriaSelected = true;
    }

    if (this.state.town && this.state.town.length > 0) {
      let tempTown: any = [];
      this.state.town.forEach((element: any) => {

        tempTown.push(`${element.id}|${element.text}`);
      });
      dataToPassToService.Town = tempTown.join();
      criteriaSelected = true;
    }
    if (this.state.suburb && this.state.suburb.length > 0) {
      let tempSuburb: any = [];
      this.state.suburb.forEach((element: any) => {

        tempSuburb.push(element.id);
      });
      dataToPassToService.Suburb = tempSuburb.join();
      criteriaSelected = true;
    }
    if (this.state.assetStatus && this.state.assetStatus.length > 0) {
      let tempStatus: any = [];
      this.state.assetStatus.forEach((element: any) => {

        tempStatus.push(element.id);
      });
      dataToPassToService.AssetStatuses = tempStatus.join();
      criteriaSelected = true;
    }

    if (!criteriaSelected) {
      this.setState({
        alertMessage: 'Please select search criteria',
        showAlert: true
      });
      return;
    }

    //console.log("data to send to service", dataToPassToService);
    this.setState({
      loading: true
    });

    let pageNumber = 1;
    let pageSize = this.state.totalResults ? this.state.totalResults : 100;
    let searchAssetAPi = `${Config.AssetSearchAPI}?PageNumber=${pageNumber}&PageSize=${pageSize}&showAll=${this.state.showAllAsset}`
    utility.genericPostAPIcall(searchAssetAPi, dataToPassToService).then((data: any) => {
      _items = [];
      if (!data || data.length <= 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true, items: _items, loading: false
        });
        return;
      }
      data.forEach((item: any) => {
        let address;
        if (item.Addresscomponents) {
          // let tempAddressComp = JSON.parse(item.AddressComponents);
          let tempAddressComp = item.Addresscomponents;

          address = tempAddressComp ? `${tempAddressComp.Lot} ${tempAddressComp.HouseNumber} ${tempAddressComp.StreetName !== 'NA' ? tempAddressComp.StreetName : ''} ${tempAddressComp.StreetType && tempAddressComp.StreetType.Description ? capitalize(tempAddressComp.StreetType.Description) + ',' : ''} ${tempAddressComp.Suburb !== 'NA' ? tempAddressComp.Suburb : ''} ${(tempAddressComp.State !== 'NA' && tempAddressComp.State) ? tempAddressComp.State.toUpperCase() : ''} ${tempAddressComp.PostalCode}` : item.Adress
        }

        let ParentAsset = item.Parentasset ? JSON.parse(item.Parentasset) : '';
        let tempSuburb = item.Suburb?.replace("|", ' - ')
        _items.push({
          Register: "ASSET",
          AssetID: item.Id,
          siteId: ParentAsset ? ParentAsset.site?.ID : ParentAsset?.ID,
          id: item.Id,
          AssetNumber: item.Code,
          UnitID1: item.Unitidasset,
          UnitID2: item.Unitid2asset,
          AssetType: item.Typename,
          AssetTypeId: item.Typeid,
          AssetClass: item.Class,
          FormType: item.Formtype,
          ServiceStatus: item.Status,
          ParallelLineNumber: item.Parallellinenumber,
          Description: item.Desc,
          Suburb: tempSuburb,
          UnitTypeId: item.Unittypeid,
          UnitTypeCode: item.Unittypecode,
          UnitType: item.Unittype,
          Location: address,
          Address: item.Addresscomponents,
          Compkey: item.Compkey,
          Selected: false,
        });
      });

      if (_items.length <= 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });
      }

      let tempSelectedAssets = _items.filter((a: any) => a.Selected === true);

      this.setState({
        items: _items,
        loading: false,
        callAfterInit: true,
        itemSelected: tempSelectedAssets.length
      });

      var stateDate = { ...this.state };
      localStorage.setItem('assetState', JSON.stringify(stateDate));
      //console.log(_items);
    }, (err) => {
      console.log(err);
      this.setState({ loading: false });
    });

  }

  private SearchMap() {
    //console.log("search map");
    this.setState(
      {
        iFramePanelOpenedforDetail: true,
        selectModeMap: false,
        selectParent: false,
        // selectMapDetail: true,
      });

  }
  public renderAssetMapforDetail() {
    return (
      <AssetMapforDetail
        // selectAssetsforParent={(Asset) => this.selectAssetsonMapforParent(Asset)}
        iFramePanelOpenedforDetail={this.state.iFramePanelOpenedforDetail}
        onClose={() => this._onDismissMapforDetail()}
        selectParent={this.state.selectParent}
        selectModeMap={this.state.selectModeMap}
      // selectMapDetail={this.state.selectMapDetail}
      />
    );
  }
  public _onDismissMapforDetail() {
    this.setState({
      iFramePanelOpenedforDetail: false
    });
  }

  private SearchClicked() {
    this.setState(
      {
        iFramePanelOpened: true,
        selectModeMap: false,
        selectParent: true,
        selectMapDetail: false,
      });

  }
  public renderAssetMap() {
    return (
      <AssetMap
        selectAssetsforParent={(Asset: any) => this.selectAssetsonMapforParent(Asset)}
        iFramePanelOpened={this.state.iFramePanelOpened}
        onClose={() => this._onDismissMap()}
        selectParent={this.state.selectParent}
        selectModeMap={this.state.selectModeMap}
      // selectMapDetail={this.state.selectMapDetail}
      />
    );
  }
  public _onDismissMap() {
    this.setState({
      iFramePanelOpened: false
    });
  }

  public selectAssetsonMapforParent(selectedAsset: any) {
    let Asset = selectedAsset[0];
    let properties = JSON.parse(Asset.properties);
    this.setState({
      parentId: Asset.id ? Asset.id : properties.ASSET_ID,
      parentDetail: Asset.name,
      iFramePanelOpened: false,
      selectModeMap: false,
      selectParent: false,
      selectMapDetail: false,
    });
  }




  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }
  private handleNaturalValueChange = (event: any, fieldName: string) => {
    this.setState({ [fieldName]: event.target.value });
  }
  private handleValueChange = (event: any, fieldName: string) => {

    this.setState({ [fieldName]: event.value });
  }
  public handleDropdownChange(e: any, fieldName: string) {

    if (fieldName === 'assetTypeItem') {
      let tempUnitTypeItems = this.state.UnityTypeItems.filter((u: any) => u.formType === (e.selectedItem && e.selectedItem.id));
      this.setState({
        tempUnitTypeItems: tempUnitTypeItems,
        alltempUnitTypeItems: tempUnitTypeItems,
      });

      if (e.selectedItem && e.selectedItem.id !== 'Asset') {
        this.setState({
          subAssetTypeItem: '',
          AssetNumber: ''
        });
      } else {
        this.setState({
          unitId2: '',
          unitId1: '',
        })
      }
      if (!e.selectedItem) {
        this.setState({
          siteAssetId: '',
          unitId2: '',
          unitId1: '',
        })
      }
    }
    this.setState({ [fieldName]: e.selectedItem });
  }
  private _multiFieldChanged = (event: any, fieldName: string) => {
    const values = event;

    this.setState({ [fieldName]: values });
  }
  public handleSelect = (event: any, newValue: any) => {
    selectedtab = newValue;
    this.setState({ selected: newValue });
    //this.clearFilter();
  }
  public render(): React.ReactElement<any> {

    return (

      <div>

        {this.renderAssetMap()}
        {this.state.showAlert && this.alert()}
        {!this.props.selectMode && (
          <div className={this.state.heading === undefined ? "SearchDiv" : ''}>
            <div className={'headingDiv'}>
              <span className={"heading"}> Assets</span>
            </div>
          </div>
        )}
        <div className={"tabsDiv"}>
          <Paper square>

            <Tabs
              value={this.state.selected ? this.state.selected : 0}
              onChange={this.handleSelect}
              indicatorColor="primary"
              // textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label={<span className='tabHeading'><SearchOutlinedIcon className={"subMenuLinkIcon"} />Natural Search</span>} {...a11yProps(0)} />
              <Tab label={<span className='tabHeading'><PageviewOutlinedIcon className={"subMenuLinkIcon"} />Advanced Search</span>}  {...a11yProps(1)} />
              <Tab label={<span className='tabHeading'><MapOutlinedIcon className={"subMenuLinkIcon"} />Map Search</span>}  {...a11yProps(2)} />
            </Tabs>
          </Paper>

          <TabPanel value={this.state.selected ? this.state.selected : 0} index={0}>
            <div className="schedulerDiv mt-20">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <br />

                  <div className="naturalSearchBar">

                    <input
                      className="searchbar"
                      type="text"
                      title="Search"
                      value={this.state.naturalSearch}
                      onChange={(e: any) => this.handleNaturalValueChange(e, 'naturalSearch')}
                      // onKeyDown={this._handleKeyDownforsearch}
                      onKeyDown={(e: any) => this._handleKeyDownforsearch(e)}
                      placeholder={`find all 'Type of Asset' in 'Suburb'/'Town'/'State'/'Complete Address'`}
                    />

                    <SearchIcon className='searchIcon' />
                  </div>
                </Grid>

              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <div style={{ margin: '7px auto', width: '70%', paddingLeft: '40px', fontSize: '13px' }} className="displayFlex">
                    <div style={{ width: '35%', textAlign: 'left' }}>
                      <strong>For e.g.</strong> <br /><i>find all tanks in Big Hill</i> <br /> <i>find all water mains in Epsom</i>
                    </div>
                    <div style={{ width: '65%', textAlign: 'left' }} className={"naturalSearchButtonDiv"}>
                      <Button onClick={this.clearFilter} variant='outlined'>
                        Clear
                      </Button>
                      <Button onClick={this.getNaturalSearchData} variant='outlined' color="primary" className={'button'}>
                        Search
                      </Button>
                      <CheckBoxInput value={this.state.showAllAsset} onValueChanged={(e: any) => this.handleValueChange(e, 'showAllAsset')} text={'Show All Assets'} />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value={this.state.selected ? this.state.selected : 0} index={1}>
            <br />
            <div className={"searchFieldDiv "}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <SelectBoxInput
                    stylingMode='underlined'
                    displayExpr="text"
                    valueExpr="id"
                    showClearButton={true}
                    items={this.state.FromTypeItems}
                    selectedItem={this.state.assetTypeItem}
                    label='Form Type'
                    onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'assetTypeItem')}
                  />

                </Grid>
                {(this.state.assetTypeItem && this.state.assetTypeItem.text === 'Asset') && (
                  <Grid item xs={12} sm={6} md={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.subAssetType}
                      selectedItem={this.state.subAssetTypeItem}
                      label='Asset Type'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'subAssetTypeItem')}
                    />
                  </Grid>
                )}
                {((this.state.assetTypeItem && this.state.assetTypeItem.text === 'Sewer Main') || (this.state.assetTypeItem && this.state.assetTypeItem.text === 'Water Main')) ?
                  <>
                    <Grid item xs={6} sm={3} md={2}>
                      <TextBoxInput
                        stylingMode='underlined'
                        label="Unit Id 1"
                        // pattern={"[A-Za-z]+"}
                        // minLength={2}
                        value={this.state.unitId1}
                        showClearButton={true}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'unitId1')}
                        onKeyDown={(e: any) => this._handleKeyDown(e)}
                      />
                    </Grid>

                    <Grid item xs={6} sm={3} md={2}>
                      <TextBoxInput
                        stylingMode='underlined'
                        label="Unit Id 2"
                        // pattern={"[A-Za-z]+"}
                        // minLength={2}
                        value={this.state.unitId2}
                        showClearButton={true}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'unitId2')}
                        onKeyDown={(e: any) => this._handleKeyDown(e)}

                      />
                    </Grid>
                  </> :
                  <Grid item xs={12} sm={6} md={4}>
                    <TextBoxInput
                      stylingMode='underlined'
                      label="Asset ID"
                      // pattern={"[A-Za-z]+"}
                      // minLength={2}
                      value={this.state.AssetNumber}
                      showClearButton={true}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'AssetNumber')}
                      onKeyDown={(e: any) => this._handleKeyDown(e)}
                    />
                  </Grid>
                }
                <Grid item xs={12} sm={6} md={4}>
                  <TextBoxInput
                    stylingMode='underlined'
                    label="Compkey"
                    // pattern={"[A-Za-z]+"}
                    // minLength={2}
                    value={this.state.Compkey}
                    showClearButton={true}
                    onValueChanged={(e: any) => this.handleValueChange(e, 'Compkey')}
                    onKeyDown={(e: any) => this._handleKeyDown(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextBoxInput
                    stylingMode='underlined'
                    label="Asset Description"
                    // pattern={"[A-Za-z]+"}
                    // minLength={2}
                    value={this.state.asset}
                    showClearButton={true}
                    onValueChanged={(e: any) => this.handleValueChange(e, 'asset')}
                    onKeyDown={(e: any) => this._handleKeyDown(e)}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <SelectBoxInput
                    stylingMode='underlined'
                    displayExpr="text"
                    valueExpr="id"
                    showClearButton={true}
                    items={this.state.tempUnitTypeItems}
                    selectedItem={this.state.unitType}
                    label='Unit Type'
                    onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'unitType')}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <MultiSelect
                    items={this.state.assetStatusItems}
                    onChange={(e: any) => this._multiFieldChanged(e, 'assetStatus')}
                    selectedItem={this.state.assetStatus}
                    label="Status"
                    stylingMode='underlined'
                    displayExpr="text"
                    showClearButton={true}
                    placeholder="Select a value..."
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <MultiSelect

                    items={this.state.suburbItems}
                    onChange={(e: any) => this._multiFieldChanged(e, 'suburb')}
                    selectedItem={this.state.suburb}
                    // opened={true}

                    label="Suburb"
                    stylingMode='underlined'
                    displayExpr="text"
                    showClearButton={true}
                    placeholder="Select a value..."
                  />

                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <MultiSelect

                    items={this.state.townItems}
                    onChange={(e: any) => this._multiFieldChanged(e, 'town')}
                    selectedItem={this.state.town}
                    // opened={true}

                    label="Town"
                    stylingMode='underlined'
                    displayExpr="text"
                    showClearButton={true}
                    placeholder="Select a value..."
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Grid container>
                    <Grid item xs={10} sm={10}>
                      <TextBoxInput
                        stylingMode='underlined'
                        label="Site Asset (Compkey)"
                        // pattern={"[A-Za-z]+"}
                        // minLength={2}
                        value={this.state.parentId}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'parentId')}
                        onKeyDown={this._handleKeyDown}
                        readOnly={true}
                        showClearButton={true}
                      />
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <div className="assetSearch">
                        <RoomIcon onClick={this.SearchClicked} className={'assetSearchButton pointer'} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextBoxInput
                    stylingMode='underlined'
                    label="Site Asset ID"
                    // pattern={"[A-Za-z]+"}
                    // minLength={2}
                    disabled={this.state.assetTypeItem && this.state.assetTypeItem.text ? false : true}
                    value={this.state.siteAssetId}
                    onValueChanged={(e: any) => this.handleValueChange(e, 'siteAssetId')}
                    onKeyDown={this._handleKeyDown}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <div className={"searchButtonDiv displayFlex"}>
                    {/* {this.state.totalRecords && <div className="totalRecordsDiv">Total records available for this search is {this.state.totalRecords}</div>} */}
                    <div>
                      <NumberBoxInput
                        stylingMode='underlined'
                        label="Total Results to Display"
                        min={1}
                        value={this.state.totalResults}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'totalResults')}
                      />
                    </div>
                    <div>
                      <Button onClick={this.clearFilter} variant='outlined'>
                        Clear
                      </Button>
                      <Button onClick={this.getAssetwithcriteria} variant='outlined' color="primary" className={'button'}>
                        Search
                      </Button>
                      <CheckBoxInput value={this.state.showAllAsset} onValueChanged={(e: any) => this.handleValueChange(e, 'showAllAsset')} text={'Show All Assets'} />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          {(!this.props.selectCreateScheduleMode && !this.props.selectScheduleMode) &&
            (<TabPanel value={this.state.selected ? this.state.selected : 0} index={2}>
              <div className='pb-20' style={{ height: '80vh' }}>
                <AssetMapforDetail
                  selectMode={this.props.selectMode}
                  selectAssets={(e: any) => this.props.onClick(e)}
                  location={this.props.location}
                />
              </div>
            </TabPanel>)}


          {!this.state.loading ?
            <div className=''>
              {(this.state.items && this.state.items.length > 0) && (this.state.selected !== 2) && (
                <DataTable
                  style={{ width: '100%' }}
                  filteralbe={true}
                  groupable={true}
                  rows={this.state.items}
                  columns={this.state.columns}
                  fileName={'Asstes'}
                  columnChooser={'assetColumns'}
                  selectionMode={false}
                  showHideColumnMenu={true}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  // viewItem={(a:any) => this.viewDetials(a)}
                  // showNewModal = {() => this._showModal()}
                  // deleteItem = {(a:any) => this.DeleteDialog(a)}
                  // editItem = {(a:any) => this.EditItemModal(a)}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                  selectItem={(e: any) => this.selectItem(e)}
                  selectMode={this.props.selectMode}
                  selectScheduleMode={this.props.selectScheduleMode}
                  selectCreateScheduleMode={this.props.selectCreateScheduleMode}
                />
              )}
            </div>
            :
            // <CircularProgress className="loader" />
            <Spinner size='large' label={'Loading..'} className="loader" />
          }
        </div>

      </div>

    );
  }


  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }



}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>

  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {

  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}