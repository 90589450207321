import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import TextAreaInput from '../../common/reusableComponents/TextArea';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';

export default class AddEditPrestartForms extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.updateForms = this.updateForms.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.state = {

        };
    }

    public componentDidMount() {
        //console.log('component load');
        // this.getMeetingReportData();
        // this.getReportTemplate();
    }
    public didMount() {
        //console.log(this.props.details);
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                isActive: true,
                hideAlertButton: false,
                mode: this.props.mode,
            })
        }

    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: details.id,
            title: details.title,
            premodel: details.premodel,
            // postmodel: details.postmodel,
            // postchecklist: details.postchecklist,
            header: details.header,
            isActive: details.active
        });

    }
    public updateForms() {
        //console.log(this.state.title);
        //console.log(this.props.currentUser);
        //genericPostAPIcallForAdminList
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.premodel) {
            this.setState({
                alertMessage: 'Please enter  premodel',
                showAlert: true
            });
            return;
        }
        // if (this.state.postchecklist && !this.state.postmodel) {
        //     this.setState({
        //         alertMessage: 'Please enter  postmodel',
        //         showAlert: true
        //     });
        //     return;
        // }
        if (!this.state.header) {
            this.setState({
                alertMessage: 'Please enter  header',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.Title = this.state.title;
        dataToPassToService.Premodel = this.state.premodel;
        // if(this.state.postmodel){
        dataToPassToService.Postmodel = "[]";
        // }
        // dataToPassToService.Postchecklist = this.state.postchecklist;
        dataToPassToService.Header = this.state.header;
        dataToPassToService.Tenantid = Config.tenantId;
        dataToPassToService.Isactive = this.state.isActive;
        if (this.props.mode === 'edit') {
            dataToPassToService.Id = this.state.id;
            dataToPassToService.UpdatedBy = this.props.currentUser.Email;
            utility.genericUpdateAPICall(Config.PrestartFormsListName, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Prestart form has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.Usercreated = this.props.currentUser.Email;
            dataToPassToService.Userupdated = this.props.currentUser.Email;
            utility.genericPostAPIcall(Config.PrestartFormsListName, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Prestart form has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
        utility.removeItem(Config.PrestartFormsListName);
    }

    public clearFilter() {
        this.setState({
            title: '',
            name: '',
            postmodel: '',
            premodel: '',
            header: '',
        });
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.props.mode && capitalize(this.props.mode)} Prestart Form`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                        <div className='popContentDiv'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Title *"
                                        value={this.state.title}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>

                                    <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />

                                </Grid>
                                <Grid item xs={12} sm={12}>

                                    <TextAreaInput
                                        stylingMode='underlined'
                                        label="Premodel"
                                        value={this.state.premodel}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'premodel')}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextAreaInput
                                        stylingMode='underlined'
                                        value={this.state.header}
                                        label="Header"
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'header')}

                                    />
                                </Grid>

                            </Grid>
                        </div>
                    </ScrollView>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.updateForms,
                        }}
                    />
                </Popup>
            </div>

        );
    }

}
