import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

import AlertDialog from '../../common/alertDialog';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';
import Spinner from '../../common/reusableComponents/Spinner';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import TextAreaInput from '../../common/reusableComponents/TextArea';
import MultiSelect from '../../common/reusableComponents/MultiSelect';

export default class AddEditItem extends React.Component<any, any> {


    public page: any;
    constructor(props: any) {
        super(props);

        this.save = this.save.bind(this);
        this.clearFilter = this.clearFilter.bind(this);

        this.state = {

        };
    }

    public componentDidMount() {
        //console.log('component load');
        // this.getMeetingReportData();
        // this.getReportTemplate();
    }
    public didMount() {

        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue();
        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode,
                isActive: true,
                configuration: this.props.configuration
            });
        }

    }
    public populateValue() {

        let configuration = this.props.configuration;

        configuration.AddEditField.forEach((field: any, i: any) => {

            if (this.props.details[field.field]) {

                if (field.Type === 'Choices') {
                    configuration.AddEditField[i].Value = this.getChoiceValue(field)
                } else {
                    configuration.AddEditField[i].Value = this.props.details[field.field]
                }
            }
        });
        this.setState({ configuration: configuration })
    }
    public getChoiceValue(field: any) {
        let returnValue: any;
        // console.log({ field });
        if (field.IsMultiple) {
            if (Array.isArray(this.props.details[field.field])) {
                let tempreturnValue: any = [];
                for (let m of this.props.details[field.field]) {

                    if (field.APIListNameItems.find((item: any) => item.id === m)) {
                        tempreturnValue.push(field.APIListNameItems.find((item: any) => item.id === m))
                    }
                }
                returnValue = tempreturnValue;

            }
        } else {
            returnValue = field.APIListNameItems.find((item: any) => item.id === this.props.details[field.field]);
        }
        return returnValue;
    }
    public save() {
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label='Loading...' /></div>,
            hideAlertButton: true,
            showAlert: true
        });

        let dataToPassToService: any = {};
        let validateField: any = this.validateFields();

        if (!validateField) {
            return;
        }
        this.state.configuration.AddEditField.forEach((field: any) => {

            if (field.Type === 'Choices' && !field.IsMultiple) {
                dataToPassToService[field.addeditfield] = field.Value.id;
            } else if (field.Type === 'Choices' && field.IsMultiple) {
                let tempArray: any = [];
                field.Value && field.Value.forEach((element: any) => {
                    tempArray.push(element.id);
                });
                dataToPassToService[field.addeditfield] = tempArray;

            } else {
                dataToPassToService[field.addeditfield] = field.Value;
            }
        })

        dataToPassToService.Userupdated = this.props.currentUser.Email;

        if (this.props.mode === 'edit') {
            let id = Object.values(this.props.details)[0]
            dataToPassToService[Object.keys(this.props.details)[0]] = id;
            dataToPassToService.Usercreated = this.props.details?.Usercreated ? this.props.details.Usercreated : this.props.currentUser.Email;
            // console.log({ dataToPassToService })
            utility.genericUpdateAPICall(this.state.configuration.apiInfo.create.name, id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.props.onClick();
                this.setState({ showAlert: false });
                this.props.message(`${this.props.configuration?.Title} has been updated`);
                this.clearFilter();
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.Usercreated = this.props.currentUser.Email;
            utility.genericPostAPIcall(this.state.configuration.apiInfo.update.name, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.props.onClick();
                this.props.message(`${this.state.configuration?.Title} has been created`);
                this.clearFilter();
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
        utility.removeItem(this.state.configuration.apiInfo.update.name);
    }
    public validateFields() {
        let tempValidateField: any = {};

        for (let field of this.state.configuration.AddEditField) {
            if (field.IsMandatory && !field.Value) {
                tempValidateField.isValid = false;
                tempValidateField.message = `Please add ${field.Label}`;

                // console.log({ tempValidateField })
                this.setState({
                    alertMessage: tempValidateField?.message,
                    showAlert: true,
                    hideAlertButton: false,
                });
                return false;
            }
        }
        return true;
    }
    public clearFilter() {
        this.setState({ configuration: null, id: '' });
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, item: string, i: any) => {

        let configuration: any = this.state.configuration;
        configuration.AddEditField[i].Value = event.value
        this.setState({ configuration: configuration });
    }
    public handleDropdownChange(e: any, item: string, i: any) {

        let configuration: any = this.state.configuration;
        configuration.AddEditField[i].Value = e.selectedItem
        this.setState({ configuration: configuration });
    }
    private _multiFieldChanged = (event: any, item: string, i: any) => {

        let configuration: any = this.state.configuration;
        configuration.AddEditField[i].Value = event
        this.setState({ configuration: configuration });
    };
    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}

                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.props.mode && capitalize(this.props.mode)} ${this.state.configuration?.Title}`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                        <div className='popContentDiv'>
                            <Grid container spacing={3}>
                                {this.state.configuration?.AddEditField && this.state.configuration?.AddEditField.map((item: any, i: any) => {
                                    return (this.returnField(item, i));
                                })}
                            </Grid>
                        </div>
                    </ScrollView>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.save,
                        }}
                    />
                </Popup>
            </div>

        );
    }

    public returnField(item: any, i: any, parentItem: any = "", isSectionField?: boolean, parentId?: any) {
        if (item.Type === 'TextField') {
            return this.returnTextField(item, i, parentItem, isSectionField, parentId);
        }
        if (item.Type === 'CheckBox') {
            return this.returnCheckBoxField(item, i, parentItem, isSectionField, parentId);
        }
        if (item.Type === 'Choices' && item.IsMultiple) {
            return this.returnMultipleChoiceField(item, i, parentItem, isSectionField, parentId);
        }
        if (item.Type === 'Choices' && !item.IsMultiple) {
            return this.returnChoiceField(item, i, parentItem, isSectionField, parentId);
        }
        if (item.Type === 'TextArea') {
            return this.returnTextareaField(item, i, parentItem, isSectionField, parentId);
        }
    }
    public returnTextField(item: any, i: any, parentItem?: any, isSectionField?: boolean, parentId?: any) {

        return (
            <Grid key={i} item sm={item.Grid.sm} md={item.Grid.md} lg={item.Grid.lg}>
                <TextBoxInput
                    stylingMode='underlined'
                    label={`${item.Label} ${item.IsMandatory ? '*' : ''}`}
                    // pattern={"[A-Za-z]+"}
                    // minLength={2}
                    value={item.Value}
                    onValueChanged={(e: any) => this.handleValueChange(e, item, i)}
                    onKeyDown={() => { }}
                    required={true}
                />
            </Grid>
        );
    }
    public returnTextareaField(item: any, i: any, parentItem?: any, isSectionField?: boolean, parentId?: any) {

        return (
            <Grid key={i} item sm={item.Grid.sm} md={item.Grid.md} lg={item.Grid.lg}>
                <TextAreaInput
                    stylingMode='underlined'
                    label={`${item.Label} ${item.IsMandatory ? '*' : ''}`}
                    value={item.Value}
                    onValueChanged={(e: any) => this.handleValueChange(e, item, i)}
                />
            </Grid>
        );
    }
    public returnCheckBoxField(item: any, i: any, parentItem?: any, isSectionField?: boolean, parentId?: any) {
        return (
            <Grid key={i} item sm={item.Grid.sm} md={item.Grid.md} lg={item.Grid.lg}>
                <CheckBoxInput
                    value={item.Value ? item.Value : false}
                    onValueChanged={(e: any) => this.handleValueChange(e, item, i)}
                    text={`${item.Label} ${item.IsMandatory ? '*' : ''}`} />
            </Grid>
        );
    }
    public returnChoiceField(item: any, i: any, parentItem?: any, isSectionField?: boolean, parentId?: any) {
        return (<Grid key={i} item sm={item.Grid.sm} md={item.Grid.md} lg={item.Grid.lg}>
            <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={item.APIListNameItems}
                selectedItem={item.Value}
                label={`${item.Label} ${item.IsMandatory ? '*' : ''}`}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, item, i)}
            />
        </Grid>
        );
    }
    public returnMultipleChoiceField(item: any, i: any, parentItem?: any, isSectionField?: boolean, parentId?: any) {
        return (<Grid key={i} item sm={item.Grid.sm} md={item.Grid.md} lg={item.Grid.lg}>
            <MultiSelect
                items={item.APIListNameItems}
                onChange={(e: any) => this._multiFieldChanged(e, item, i)}
                selectedItem={item.Value}
                label={`${item.Label} ${item.IsMandatory ? '*' : ''}`}
                stylingMode='underlined'
                displayExpr="text"
                showClearButton={true}
                placeholder="Select a value..."
            />
        </Grid>
        );
    }
}