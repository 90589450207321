import * as React from 'react';
import moment from 'moment';
import * as PropTypes from 'prop-types';

import {
  NavLink
} from "react-router-dom";
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import global from '../../classes/global';
import AddAttachment from '../AddAttachment/AddAttachment';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import RoomIcon from '@material-ui/icons/Room';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import AssetSearch from '../AssetSearch/AssetSearch';
import MapModal from "../map/map";
import AlertDialog from '../common/alertDialog';
import DisplayDetailsModal from '../common/DisplayDetailModal/DisplayDetailModal';
import Spinner from '../common/reusableComponents/Spinner';
import DataTable from '../common/DataTable/DataTable';
import LoadIndicator from 'devextreme-react/load-indicator';
import EditWorkOrder from '../EditWorkOrder/EditWO';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import ConfirmDialog from '../common/ConfirmDialog';
import Modal from '@material-ui/core/Modal';
let _WOhistoryItems: any[] = [];
let _WOoffsitecompleteItems: any[] = [];
let _DocPhotosItems: any[] = [];
let _worktimeItems: any[] = [];
let _plantusageItems: any[] = [];
let _relatedWOItems: any[] = [];
let _AssetWOItems: any[] = [];
let _DefectsWOItems: any[] = [];
let _DefectsResolvedWOItems: any[] = [];
let WOquestionsItems: any[] = [];
let lateststatusdate: any = '';
let disableeditWO = false;

export default class SearchWODetail extends React.Component<any, any> {
  public allocationReferences: any = '';
  public WorkOrderID: any;
  public interval: any;
  public WorkOrderNumber: any;
  public source: any;
  public SRID: any;
  public currentUser: any;
  public _WOhistorycolumns: any;
  public _Docphotoscolumns: any;
  public _worktimescolumns: any;
  public _plantusagecolumns: any;
  public _relatedWOcolumns: any;
  public _AssetWOcolumns: any;
  public _MaterialUsageWOcolumns: any;
  public _DefectsWOcolumns: any;
  public _DefectsResolvedWOcolumns: any;
  public _purchaseRequisitioncolumns: any;
  public _WOquestionscolumns: any;
  public crewItems: any = [];

  constructor(props: any) {
    super(props);
    // if (!this.props.Department) {
    //   window.open('#/AccessRequest', '_self');
    // };
    this.currentUser = this.props.currentUser;
    this.crewItems = [];
    _WOhistoryItems = [];
    _DocPhotosItems = [];
    _worktimeItems = [];
    _plantusageItems = [];
    _relatedWOItems = [];
    _AssetWOItems = [];
    _DefectsWOItems = [];
    WOquestionsItems = [];
    _DefectsResolvedWOItems = [];
    this.viewDetials = this.viewDetials.bind(this);
    this.ConfirmDialog = this.ConfirmDialog.bind(this);

    this._WOhistorycolumns = [
      // {
      //   field: 'id',
      //   title: 'ID',
      //   // width: 160,
      //   type: 'string'
      // },
      {
        field: 'Status',
        title: 'Status',
        width: 260,
        type: 'string'
      },
      {
        field: 'Created',
        title: 'Log Date',
        width: 160,
        type: 'datetime'
      },

      {
        field: 'CreatedInDB',
        title: 'Added Date',
        width: 160,
        type: 'datetime'
      },
      {
        field: 'Comments',
        title: 'Comments',
        // width: 160,
        type: 'string'
      },
    ];
    this._Docphotoscolumns = [
      {
        field: 'id',
        title: 'ID',
        width: 80,
        type: 'string'
      },
      {
        field: 'document',
        title: 'Document',
        // width: 160,
        type: 'string',
        link: 'file',
      },
      {
        field: 'comments',
        title: 'Comments',
        // width: 160,
        type: 'string'
      },
      {
        field: 'uploadedon',
        title: 'Uploaded On',
        // width: 160,
        type: 'datetime'
      },
      {
        field: 'uploadedby',
        title: 'Uploaded By',
        // width: 160,
        type: 'string'
      },
    ];
    this._worktimescolumns = [
      {
        field: 'employee',
        title: 'Employee',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'type',
        title: 'Type',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'starttime',
        title: 'Start Time',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'endtime',
        title: 'End Time',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'hoursworked',
        title: 'Hours Worked',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'updatedOn',
        title: 'Updated On',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created On',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        allowHiding: true
      },
    ];

    this._plantusagecolumns = [
      {
        field: 'plantnumber',
        title: 'Equipment and Vehicles #',
        // width: 160,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'title',
        title: 'Title',
        // width: 160,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'Regonumber',
        title: 'Rego #',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'starttime',
        title: 'Start Time',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'endtime',
        title: 'End Time',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'hoursworked',
        title: 'Hours Worked',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'updatedOn',
        title: 'Updated On',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created On',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        allowHiding: true
      }
    ];
    this._relatedWOcolumns = [

      {
        field: 'Workordernumber',
        title: 'Work Order #',
        // width: 160,
        type: 'string',
        allowHiding: false,
        link: 'workorderLink',
      },
      {
        field: 'title',
        title: 'Title',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'WOPriority',
        title: 'Priority',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'activitycode',
        title: 'Activity',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'responsibleperson',
        title: 'Crew',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'status',
        title: 'status',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'schstartdate',
        title: 'Scheduled Start Date',
        // width: 280,
        type: 'datetime',
        allowHiding: false,
      },
      // {
      //   field: 'isParent',
      //   title: 'Is Parent WO',
      //   // width: 280,
      //   type: 'string',
      //   allowHiding: false,
      // },
    ];
    this._WOquestionscolumns = [

      {
        field: 'title',
        title: 'Title',
        // width: 160,
        type: 'string',
        allowHiding: false
      },
      {
        field: 'value',
        title: 'Value',
        // width: 280,
        type: 'string',
        allowHiding: false,
      }
    ];


    this.state = {
      WOhistoryItems: [],
      WOhistorycolumns: this._WOhistorycolumns,
      WOquestionscolumns: this._WOquestionscolumns,
      WOhistoryCount: 0,
      DocPhotosItems: [],
      DocPhotoscolumns: this._Docphotoscolumns,
      DocPhotosCount: 0,
      plantusageItems: [],
      plantusagecolumns: this._plantusagecolumns,
      plantusageCount: 0,
      relatedWOItems: [],
      relatedWOcolumns: this._relatedWOcolumns,
      relatedWOCount: 0,
      worktimeItems: [],
      worktimecolumns: this._worktimescolumns,
      worktimeCount: 0,
      materialUsageItems: [],
      materialUsagecolumns: this._MaterialUsageWOcolumns,
      MatUsageCount: 0,
      AssetWOItems: [],
      WOquestionsItems: [],
      isOpen: false,
      AssetWOcolumns: this._AssetWOcolumns,
      AssetWOCount: 0,
      DefectsWOItems: [],
      DefectsWOcolumns: this._DefectsWOcolumns,
      DefectsWOCount: 0,
      DefectsResolvedWOItems: [],
      DefectsResolvedWOcolumns: this._DefectsResolvedWOcolumns,
      DefectsResolvedWOCount: 0,
      purchaseRequisitionItems: [],
      purchaseRequisitioncolumns: this._purchaseRequisitioncolumns,
      purchaseRequisitionCount: 0,
      selected: 0
    };
  }

  public componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let workid = params.get('WOID');
    //console.log("workid", workid);
    let SR = params.get('SR');
    this.WorkOrderID = workid;
    this.SRID = SR;
    let wonum = params.get('WONum');

    this.WorkOrderNumber = wonum;
    this.allocationReferences = '';
    //work order history
    this._getworkTypeDataAsync();
    this.getWODocument();
    this.getWorkOrderHistory();
    this.getTimesheetdata();
    this.getPlantUsageData();
    this.interval = setInterval(() => this.GetWODetails(), 120000);

  }

  public GetWODetails() {
    this._getworkTypeDataAsync();
    this.getWODocument();
    this.getWorkOrderHistory();
    this.getTimesheetdata();
    this.getPlantUsageData();
    this.loadWorkOrderDetails();
  }
  public componentWillUnmount() {
    clearInterval(this.interval);
  }
  private _getworkTypeDataAsync() {
    let dataToPassToService: any = {};
    dataToPassToService.DepartmentId = this.props.Department ? this.props.Department.id : "";

    utility.genericPostAPIcall(Config.SearchActivitiesApi, dataToPassToService).then((data: any) => {
      const worktypeItems = data.map((r: any) => ({
        id: r.Id,
        text: (r.Code + ' - ' + r.Title),
        DepartmentId: r.DepartmentId
      }));
      this.setState({ worktypeItems: worktypeItems });
      this.loadWorkOrderDetails();
    }, (err) => {
      console.log(err);
    });
  }
  public viewDefectDetials(item: any, mode: any) {
    // console.log(item);
    this.setState({
      defectDetails: item,
      showDefectDetailsModal: true,
      mode: mode
    });
  }

  public viewDefectResolvedDetials(item: any) {
    // console.log(item);
    this.setState({
      defectResolvedDetails: item,
      showDefectResolvedDetailsModal: true
    });
  }
  public loadWODetailafterEdit() {
    let dataToPassToService: any = {};
    dataToPassToService.WorkOrderNumber = this.WorkOrderNumber;

    utility.genericPostAPIcall(Config.WOdetailsSearchAPI, dataToPassToService).then((data: any) => {
      //console.log(data);

      data.forEach((item: any) => {
        if (item.LastStatusDate) {
          let year = new Date(item.LastStatusDate).getFullYear();
          if (year === 1 || year === undefined) {
            lateststatusdate = '';
          }
          else {
            lateststatusdate = item.LastStatusDate;
          }
        }
        this.setState({
          workorderdetails: item
        });

      });
    });
  }
  public loadWorkOrderDetails() {
    this.setState({ loading: true, callAfterInit: true });
    let parentId;

    let dataToPassToService: any = {};
    dataToPassToService.WorkOrderNumber = this.WorkOrderNumber;

    utility.genericPostAPIcall(Config.WOdetailsSearchAPI, dataToPassToService).then((data: any) => {
      _DefectsResolvedWOItems = [];
      if (data && data.length > 0 && data[0].Defectsinfo) {
        _DefectsResolvedWOItems = [JSON.parse(data[0].Defectsinfo)];
        _DefectsResolvedWOItems = utility._sortItems(_DefectsResolvedWOItems, 'Resolved On', true);
        this.setState({
          DefectsResolvedWOCount: _DefectsResolvedWOItems.length,
          DefectsResolvedWOItems: _DefectsResolvedWOItems,
        });
      }

      _AssetWOItems = [];
      let sapDetails = [];
      WOquestionsItems = [];
      if (data.length !== null || data.length !== 0) {
        let item = data[0];
        parentId = item.Parentwo;
        var JSONAssetData;
        if (item.Assetsinfo !== null) {
          JSONAssetData = JSON.parse(item.Assetsinfo);
          if (!Array.isArray(JSONAssetData)) {

            JSONAssetData = [JSONAssetData];
          }
          // console.log("JSONAssetData", JSONAssetData);
          if (JSONAssetData && JSONAssetData.length > 0) {
            this.setState({ formattedAssetId: JSONAssetData[0].FormattedAssetId, assetId: JSONAssetData[0].assetId ? JSONAssetData[0].assetId : JSONAssetData[0].FormattedAssetId })

          }
          JSONAssetData.forEach(data => {
            _AssetWOItems.push({
              assetnum: data.AssetNumber ? data.AssetNumber : data.assetId,
              assetId: data.assetId ? data.assetId : data.FormattedAssetId,
              compkey: item.CompKey ? item.CompKey : '',
              AssetTitle: data.Description ? data.Description : '',
              GISID: data.GisId ? data.GisId : '',
              assetsuburb: data.Suburb ? data.Suburb : '',
              assettype: data.AssetType ? data.AssetType : data.type,
              assettypeId: data.AssetTypeId ? data.AssetTypeId : '',
              assetLocation: data.Address ? data.Address.displayText : ''
            });
          });
        }
        let submittedWOfields;
        if (item.Submittedworkorderfields !== null) {
          submittedWOfields = JSON.parse(item.Submittedworkorderfields);
          if (!Array.isArray(submittedWOfields)) {

            submittedWOfields = [submittedWOfields];
          }
          if (submittedWOfields && submittedWOfields.length > 0) {
            submittedWOfields.forEach(data => {
              WOquestionsItems.push({
                title: data.Title ? data.Title : '',
                value: data.Value ? data.Value : '',
                Controlname: item.ControlName ? item.ControlName : '',
                Sequenceno: data.SequenceNo ? data.SequenceNo : '',
                Ipsfield: data.IPSField ? data.IPSField : '',
                TriggerType: data.Trigger ? data.Trigger : '',
                id: data.DbId
              });
            });
          }
        }
        this.getRelatedWO(parentId);

        let tempAddress;
        if (item.LocationGeom && item.LocationGeom !== null) {
          let tempLocation = JSON.parse(item.LocationGeom);

          tempAddress = `${tempLocation.streetNumber ? tempLocation.streetNumber : ''} ${tempLocation.streetName ? tempLocation.streetName : ''} ${tempLocation.suffix ? tempLocation.suffix : ''} ${tempLocation.city ? tempLocation.city : ''} ${tempLocation.region ? tempLocation.region : ''} ${tempLocation.postalCode ? tempLocation.postalCode : ''}`;

        }

        if (item.LastStatusDate) {
          let year = new Date(item.LastStatusDate).getFullYear();
          if (year === 1 || year === undefined) {
            lateststatusdate = '';
          }
          else {
            lateststatusdate = item.LastStatusDate;
          }
        }
        let ContractorOrg = false;
        this.state.worktypeItems && this.state.worktypeItems.forEach((i: any) => {
          let activity = item.Worktypecode + " - " + item.Worktypename;
          if (i.text === activity) {
            ContractorOrg = true;
          }
        });
        if ((this.props.role === global.worxOnline_Reader) || item.Workorderstatus === "Closed") {
          disableeditWO = true;
        }
        else if ((this.props.role === global.worxOnline_ContractorAdmin) && item.Ishandledinworx && item.Workorderstatus !== "Closed") {
          disableeditWO = false;
        }
        else if (this.props.role === global.worxOnline_WorxAdmin && (item.Responsibility === this.props.team) && item.Ishandledinworx && item.Workorderstatus !== "Closed") {
          disableeditWO = false;
        }
        else if (this.props.role === global.worxOnline_Admin && item.Ishandledinworx && item.Workorderstatus !== "Closed") {
          disableeditWO = false;
        }
        else {
          disableeditWO = true;
        }

        this.setState({
          workorderdetails: item,
          WOquestionsItems: WOquestionsItems,
          address: tempAddress ? tempAddress : '',
          marker: item.Latlong ? { lng: item.Latlong.X, lat: item.Latlong.Y } : { lat: 0, lng: 0 },
          AssetWOCount: _AssetWOItems.length,
          AssetWOItems: _AssetWOItems,
          link: "/EditScheduleWork?ID=" + item.ScheduleId,
          ServReqlink: "/SRDetails?ReqId=" + item.Servicerequestid,
          Servicerequestinfo: item.Servicerequestinfo ? JSON.parse(item.Servicerequestinfo) : '',
          callAfterInit: true,
          loading: false
        });
        this.getcrew(item);


      }
    });

    let dataToPassToSearchDefects = {
      WorkOrderId: +this.WorkOrderID
    };
    utility.genericPostAPIcall(Config.searchDefects, dataToPassToSearchDefects).then((data: any) => {
      _DefectsWOItems = [];
      if (data && data.length > 0) {
        _DefectsWOItems = data;
        _DefectsWOItems = utility._sortItems(_DefectsWOItems, 'Datecreated', true);
        this.setState({
          DefectsWOCount: data.length,
          DefectsWOItems: _DefectsWOItems
        });
      }
    });


  }
  public getPlantUsageData() {
    this.setState({ plantUsageLoading: true });
    let dataToPassToService: any = {};
    dataToPassToService.WorkOrderNumber = this.WorkOrderNumber;
    utility.genericPostAPIcall(Config.WOPlantsSearchAPI, dataToPassToService).then((woplant: any) => {
      _plantusageItems = [];
      if (woplant.length !== null || woplant.length !== 0) {
        woplant.forEach((item: any) => {
          let plantLastStatusDate;
          if (item.LastStatusDate) {
            let year = new Date(item.LastStatusDate).getFullYear();
            if (year === 1 || year === undefined) {
              plantLastStatusDate = '';
            }
            else {
              plantLastStatusDate = item.LastStatusDate;
            }
          }
          _plantusageItems.push({
            Workordernumber: item.Wonumber,
            techoneWorkordernumber: item.Erpwonumber,
            title: item.Title,
            // planttype: item.Title,
            id: item.Id,
            plantnumber: item.Resourcecode,
            Category: item.Category,
            Regonumber: item.Regonumber,
            Plantid: item.Plantid,
            Comments: item.Comments,
            starttime: item.Starttime,
            endtime: item.Endtime,
            viewTimesheet: '',
            hoursworked: item.Hoursworked,
            Editedhoursworked: item.EditedHoursworked ? item.EditedHoursworked : item.hoursworked,
            Editedstarttime: item.Editedstarttime,
            Editedendtime: item.Editedfinishtime,
            woid: item.Workorderid,
            updatedBy: item.Userupdated,
            updatedOn: plantLastStatusDate,
            createdBy: item.Usercreated,
            createdOn: item.Datecreated,
          });
        });
        _plantusageItems = utility._sortItems(_plantusageItems, 'createdOn', true);
        this.setState({
          plantusageCount: woplant.length,
          plantusageItems: _plantusageItems,
          callAfterInit: true,
          plantUsageLoading: false
        });
      }
    });
  }
  public getTimesheetdata() {
    this.setState({ timesheetLoading: true });
    let dataToPassToService: any = {};
    dataToPassToService.WorkOrderNumber = this.WorkOrderNumber;
    utility.genericPostAPIcall(Config.WOTimesheetSearchAPI, dataToPassToService).then((wotimesheet: any) => {
      _worktimeItems = [];
      //console.log("wotimesheet", wotimesheet);
      if (wotimesheet.length !== null || wotimesheet.length !== 0) {
        wotimesheet.forEach((item: any) => {
          if (item.Endofdayorcomplete === false) {
            _worktimeItems.push({
              id: item.Id,
              Workordernumber: item.Wonumber,
              techoneworkordernumber: item.Erpwonumber,
              woid: item.Workorderid,
              type: item.Timesheettype,
              employee: item.Fullname,
              starttime: item.Starttime,
              status: item.Status,
              endtime: item.Endtime,
              viewTimesheet: '',
              hoursworked: item.Hoursworked,
              Editedhoursworked: item.EditedHoursworked ? item.EditedHoursworked : item.hoursworked,
              Editedstarttime: item.Editedstarttime,
              Editedendtime: item.Editedfinishtime,
              updatedBy: item.Userupdated,
              updatedOn: item.Dateupdated,
              createdBy: item.Usercreated,
              createdOn: item.Datecreated,

            });
          }

        });
        _worktimeItems = utility._sortItems(_worktimeItems, 'createdOn', true);
        this.setState({
          worktimeCount: wotimesheet.length,
          worktimeItems: _worktimeItems,
          callAfterInit: true,
          timesheetLoading: false
        });
      }

    });

  }
  public getWorkOrderHistory() {
    this.setState({ historyLoading: true });
    let dataToPassToServiceForWOHistory: any = {};
    dataToPassToServiceForWOHistory.WorkOrderId = +this.WorkOrderID;
    //console.log("dataToPassToServiceForWOHistory", dataToPassToServiceForWOHistory);

    utility.genericPostAPIcall(Config.WOHistorySearchAPI, dataToPassToServiceForWOHistory).then((wohistory: any) => {
      _WOhistoryItems = [];
      _WOoffsitecompleteItems = [];

      //console.log("wohistory", wohistory);
      if (wohistory.length !== null || wohistory.length !== 0) {

        wohistory.forEach((item: any) => {
          let usercomments = item.Comments;
          usercomments = usercomments.replace(/<br ?\/?>/g, "\n");
          if (item.Status && item.Status === "OffsiteComplete") {
            let userupdated = item.Userupdated;
            let date = item.Datecreated;
            date = moment(date).format("DD/MM/YY h:mm a")
            userupdated = userupdated.split('@')[0];
            //let usercomments = "Changed the status to OffsiteComplete by ["+userupdated+" "+date+"]: "+item.Comments;

            _WOoffsitecompleteItems.push({
              id: item.Id,
              Status: item.Status ? item.Status : '',
              Created: item.Datecreated,
              CreatedInDB: item.Daterecordcreatedindb,
              Comments: usercomments ? usercomments : ' ',
              usercomments: usercomments
            });
          }
          _WOhistoryItems.push({
            id: item.Id,
            Status: item.Status ? item.Status : '',
            Created: item.Datecreated,
            CreatedInDB: item.Daterecordcreatedindb,
            Comments: usercomments ? usercomments : ' ',
          });
        });
        _WOhistoryItems = utility._sortItems(_WOhistoryItems, 'Created', true);
        this.setState({
          WOhistoryCount: wohistory.length,
          WOhistoryItems: _WOhistoryItems,
          WOoffsitecompleteItems: _WOoffsitecompleteItems,
          callAfterInit: true,
          historyLoading: false
        });
      }

      let dataToPassToServiceForWOHistories: any = {};
      dataToPassToServiceForWOHistories.id = this.WorkOrderID;
    });
  }
  public viewDetials(item: any) {

    this.setState({
      details: item,
      showModal: true
    });
  }
  public getRelatedWO(parentId: any) {
    this.setState({ loadingrelatedWO: true });
    let dataToPassToServiceForRWO: any = {};
    dataToPassToServiceForRWO.Id = +this.WorkOrderID;
    if (parentId && parentId > 0) {
      dataToPassToServiceForRWO.ParentWoId = parentId;
    }
    utility.genericPostAPIcall(Config.SearchRelatedWOAPI, dataToPassToServiceForRWO).then((relatedWO: any) => {
      _relatedWOItems = [];
      // console.log("relatedWO", relatedWO);
      if (relatedWO.length !== null || relatedWO.length !== 0) {
        relatedWO.forEach((item: any) => {
          if (item.Id !== this.state.workorderdetails.Id) {
            _relatedWOItems.push({
              id: item.Id,
              woid: item.Id,
              Workordernumber: item.Wonumber,
              title: item.Title ? item.Title : '',
              createdOn: item.Datecreated,
              responsibleperson: item.Crewtitle ? item.Crewtitle : '',
              activitycode: item.Worktypename ? item.Worktypename : '',
              WOPriority: item.Priority ? item.Priority : '',
              status: item.Workorderstatus ? item.Workorderstatus : '',
              schstartdate: item.Datescheduledstart,
              servicerequest: item.Servicerequestid ? item.Servicerequestid : '',
              isParent: item.Parentwo ? 'No' : 'Yes',
              relatedWO: "Yes"
              //workorderLink : "/WorkOrderDetail?WOID=" + item.Id + "&WONum=" + item.Wonumber
            });
          }
        });
        _relatedWOItems = utility._sortItems(_relatedWOItems, 'createdOn', true);

        this.setState({
          relatedWOCount: relatedWO.length,
          relatedWOItems: _relatedWOItems,
          callAfterInit: true,
          loadingrelatedWO: false
        });
      }

    });


  }
  public getWODocument() {
    this.setState({ documentloading: true })
    utility.genericGetDataByIdAPICall(Config.workorderDocumentListGet, this.WorkOrderID).then((wodoc: any) => {
      // console.log("WO Doc", wodoc);
      _DocPhotosItems = [];
      if (wodoc.length !== null || wodoc.length !== 0) {
        wodoc.forEach((item: any) => {
          const filext = item.Name.split('.');
          _DocPhotosItems.push({
            document: item.Name ? item.Name : '',
            uploadedon: item.CreatedOn,
            id: item.ID ? item.ID : ' ',
            uploadedby: item.UploadedBy ? item.UploadedBy : null,
            fileURL: item.FileURL ? item.FileURL : '',
            title: item.Title ? item.Title : '',
            comments: item.Comments ? item.Comments : '',
            fileExt: filext && filext.length > 0 ? filext[1] : ''
          });
        });
        _DocPhotosItems = utility._sortItems(_DocPhotosItems, 'uploadedon', true);
        this.setState({
          DocPhotosCount: wodoc.length,
          DocPhotosItems: _DocPhotosItems,
          callAfterInit: true,
          documentloading: false
        });
      }

    });
  }
  public ConfirmDialog(item: any) {
    this.setState({
      ConfirmalertMessage: 'Are you sure you want to edit Asset for the work order?',
      showConfirmAlert: true,
      Asset: item
    });
  }
  public ConfirmAlert() {
    return (
      <ConfirmDialog
        showDialog={this.state.showConfirmAlert}
        message={this.state.ConfirmalertMessage}
        title={"worxOnline"}
        onClose={() => this.setState({ ConfirmalertMessage: '', showConfirmAlert: false })}
        onOkButton={() => this.selectAssets(this.state.Asset)}
        onCancelButton={() => this.setState({ ConfirmalertMessage: '', showConfirmAlert: false })}
      />
    );
  }

  private alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
        hideAlertButton={this.state.hideAlertButton}
      />
    );
  }


  public viewdocument(a: any) {
    let item = a;
    let dataToPassToService: any = {};
    // let fileurl = (item.fileURL).replace("=\"", '="');
    // fileurl = (fileurl).replace("\" target=", '" target=');

    if (!item.document) {
      this.setState({
        alertMessage: 'Unable to view this document. \n File URL or File name does exist',
        showAlert: true
      });
      return;
    }
    dataToPassToService.Name = item.document;
    dataToPassToService.FileURL = item.fileURL;
    if ((item.fileURL).includes(global.isAzurefile)) {
      utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {
        window.open(doc, "_blank");
      });
    }
    else {
      let fileurl = item.fileURL.toLowerCase();

      var pattern = /.*href="(.*)".*/;
      var href = fileurl.replace(pattern, '$1');

      let docurl = "<a href=" + href + " target='_blank' rel='noopener noreferrer'></a>"
      window.open(docurl, "_blank");
    }

    dataToPassToService.Name = item.document;
    dataToPassToService.FileURL = item.fileURL;


  }
  public handleOpen(item: any) {
    this.setState({ ImageURL: item.fileURL, isOpen: true });
  }
  public openImageModal() {
    return (
      <Popup
        width={'45vw'}
        maxHeight={'85vh'}
        minHeight={200}
        height={'auto'}
        showTitle={true}
        showCloseButton={true}
        dragEnabled={true}
        hideOnOutsideClick={false}
        visible={this.state.isOpen}
        onVisibleChange={() => this.setState({ isOpen: false })}
      >
        <ScrollView width='100%' height='100%' >
          <div className='popContentDiv'>
            <img src={this.state.ImageURL} style={{ width: '100%', height: '100' }} />
          </div>
        </ScrollView>
      </Popup>
    )

  }
  public viewTimeDetails(item: any) {
    this.setState({
      timeEditdetails: item,
      showTimesheetModal: true
    });
  }
  public renderTimesheetDetailsModal() {
    return (
      <DisplayDetailsModal
        columns={this._worktimescolumns}
        details={this.state.timeEditdetails}
        showModal={this.state.showTimesheetModal}
        onClick={() => this.setState({ showTimesheetModal: false })}
      />
    );
  }

  public viewTimeForPlantDetails(item: any) {
    this.setState({
      timeEditForPlantdetails: item,
      showPlantEditTimeModal: true
    });
  }
  public renderPlantEditTimeModall() {
    return (
      <DisplayDetailsModal
        columns={this._plantusagecolumns}
        details={this.state.timeEditForPlantdetails}
        showModal={this.state.showPlantEditTimeModal}
        onClick={() => this.setState({ showPlantEditTimeModal: false })}
      />
    );
  }
  public openWOModal() {
    this.setState({
      showCommsModal: true
    });
  }


  private _closeCommsModal = (): void => {
    this.setState({ showCommsModal: false, callAfterInit: false });
  }

  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }
  public renderMapModal() {
    return (
      <MapModal
        polylines={false}
        center={this.state.marker}
        showModal={this.state.openMapModal}
        onClick={() => this._closeMapModal()}
        address={this.state.address}
        showMarker={true}
        singlemarker={this.state.marker}
        zoom={19}

      />
    );
  }
  private _closeMapModal = (): void => {
    this.setState({ openMapModal: false, });
  }


  public workorderUpdated() {
    this.setState({
      showCommsModal: false
    });
    setTimeout(this.loadWODetailafterEdit.bind(this), 500);
    setTimeout(this.getWorkOrderHistory.bind(this), 500);
  }
  public getcrew(data: any) {

    let dataToPassToService: any = {};
    dataToPassToService.DepartmentId = data.Departmentid;
    utility.genericPostAPIcall(Config.SearchCrewApi, dataToPassToService).then((data: any) => {
      this.crewItems = [];
      for (let r of data) {
        this.crewItems.push({ id: (r.Id), text: (r.CrewID + ' - ' + r.Title) });
      }
      let filteredcrewname = data && data.filter((c: any) => c.Id === data.Crewid);
      if (filteredcrewname && filteredcrewname.length > 0) {
        this.setState({ WOcrew: { id: filteredcrewname[0].Id, text: filteredcrewname[0].CrewID + ' - ' + filteredcrewname[0].Title } });
      }
      else {
        this.setState({ WOcrew: '' });
      }
    });
  }
  public renderCommsPanel() {
    var aadHttpClient = this.props.aadHttpClient;
    return (
      <EditWorkOrder
        workorder={this.state.workorderdetails}
        showModal={this.state.showCommsModal}
        crew={this.state.crewItems}
        selectedcrew={this.state.WOcrew}
        callAfterInit={this.state.callAfterInit}
        onClick={() => this.workorderUpdated()}
        onClose={() => this._closeCommsModal()}
        currentUser={this.props.currentUser}
        context={this.props.context}
        aadHttpClient={this.props.aadHttpClient}
      //  aadHttpClient={aadHttpClient}
      />
    );
  }

  // Attachement model fun
  private _showModalofAttachment = (): void => {
    this.setState({
      showModalofAttachment: true
    });
  }
  private _closeModalofAttachment = (item: any): void => {
    //console.log(item);
    this.setState({
      showModalofAttachment: false
    });
    if (item === true) {
      this.setState({
        alertMessage: 'Attachment(s) uploaded successfully.',
        showAlert: true,
      });
    }
  }
  public renderAttachmentModal() {
    return (
      <AddAttachment
        showModal={this.state.showModalofAttachment}
        onClose={(item: any) => this._closeModalofAttachment(item)}
        workorderID={this.WorkOrderID}
        context={this.props.context}
        details={this.state.workorderdetails}
        aadHttpClient={this.props.aadHttpClient}
        currentUser={this.currentUser}
        message={() => this.message()}
        updateDoc={() => setTimeout(this.getWODocument.bind(this), 2000)}
      />
    );
  }
  public message() {
    this.setState({
      alertMessage: 'Work order document has been added',
      showAlert: true
    });
  }
  public handleSelect = (event: any, newValue: any) => {
    this.setState({ selected: newValue });
  }
  public renderDetialsModal() {
    return (
      <DisplayDetailsModal
        columns={this._DefectsWOcolumns}
        details={this.state.defectDetails}
        mode={this.state.mode}
        showModal={this.state.showDefectDetailsModal}
        onClick={() => this.setState({ showDefectDetailsModal: false, defectDetails: null })}
      />
    );
  }
  // for purchase requistion detail lineitems

  public viewRequistionDetail(item: any) {
    this.setState({
      showModalofPurchaseReq: true,
      purchaseReqDetails: item
    });
  }
  public renderPurchaseRequistionModal() {
    return (
      <DisplayDetailsModal
        columns={this._purchaseRequisitioncolumns}
        details={this.state.purchaseReqDetails}
        showModal={this.state.showModalofPurchaseReq}
        onClick={() => this.setState({ showModalofPurchaseReq: false, purchaseReqDetails: null })}
      />
    );
  }
  public renderAssetPanel() {
    return (

      <div>
        <Popup
          width={'80vw'}
          maxHeight={'85vh'}
          minHeight={200}
          height={'auto'}
          showTitle={true}
          showCloseButton={true}
          title={'Select Asset'}
          dragEnabled={true}
          hideOnOutsideClick={false}
          visible={this.state.showAssetPanel}
          onVisibleChange={this._onClosePanel}
        //closeOnOutsideClick={true}
        >
          <ScrollView width='100%' height='100%'>
            <AssetSearch
              selectMode={true}
              onClick={(Asset: any) => this.ConfirmDialog(Asset)}
              heading={false}
              hideAssetMapButton={true}
              Department={this.props.Department}
              location={this.state.workorderdetails && this.state.workorderdetails.Location ? this.state.workorderdetails.Location : this.state.address}
            />
          </ScrollView>
        </Popup>

      </div>
    );
  }
  public selectAssets(Asset: any) {
    let compkey = Asset.Compkey ? Asset.Compkey : Asset.ASSET_ID;
    let unitId = Asset.UnitID2 ? Asset.UnitID2 : "";
    this.setState({ newasset: Asset.AssetNumber, newassetunitId: unitId, newParrallellinenumber: Asset.ParallelLineNumber });
    let dataToPassToService: any = {};
    dataToPassToService.CompKey = compkey.toString();
    dataToPassToService.WorkOrderNumber = this.WorkOrderNumber;
    utility.genericUpdatePortalAPICall(Config.AddEditAset, dataToPassToService).then((data: any) => {
      this.updateHistoryAsset();



    }, (err: any) => {
      console.log(err);
      this.setState({
        alertMessage: 'Something went wrong. Please try again',
        showAlert: true,
        hideAlertButton: false,
        loading: false,
        ConfirmalertMessage: '', showConfirmAlert: false
      });
    });


  }
  public updateHistoryAsset() {
    let currentDateTime = new Date();

    let dataToPassToServiceForHistory: any = {};
    dataToPassToServiceForHistory.Statusid = "77";
    dataToPassToServiceForHistory.Usercreated = this.currentUser.Email;
    dataToPassToServiceForHistory.Userupdated = this.currentUser.Email;
    dataToPassToServiceForHistory.Comments = "Asset changed from (" + this.state.assetId + ` ${this.state.workorderdetails.UnitId2Asset ? ' - ' + this.state.workorderdetails.UnitId2Asset : ''} ${this.state.workorderdetails.ParallelLineNumber ? '#' + this.state.workorderdetails.ParallelLineNumber : ""}` + ') to (' + this.state.newasset + ` ${this.state.unitId ? ' - ' + this.state.unitId : ''} ${this.state.newParrallellinenumber ? '#' + this.state.newParrallellinenumber : ""}` + ')';
    dataToPassToServiceForHistory.Tenantid = 8;
    dataToPassToServiceForHistory.Workorderid = this.WorkOrderID;
    dataToPassToServiceForHistory.Datecreated = currentDateTime.toISOString();
    dataToPassToServiceForHistory.Dateupdated = currentDateTime.toISOString();
    dataToPassToServiceForHistory.Daterecordcreatedindb = currentDateTime.toISOString();
    utility.genericPostAPIcall(Config.workorderHistoryList, dataToPassToServiceForHistory).then((data: any) => {

      this.setState({
        alertMessage: 'Asset has been added!',
        showAlert: true,
        hideAlertButton: false,
        showAssetPanel: false,
        assetcommentsID: data.Id
      });
      this.loadWorkOrderDetails();
      this.getWorkOrderHistory();
      //console.log(data);
    }, (err: any) => {
      console.log(err);
    });
  }
  private _onClosePanel = (): void => {
    this.setState({ showAssetPanel: false });
  }
  public loaderTab(title: any, items: any, counts: any, isLoading: any) {
    if (isLoading) {
      return <span className='tabHeading'>{title} <LoadIndicator id="small-indicator" height={15} width={15} /></span>
    } else {
      return <span className='tabHeading'>{title} ({counts})</span>
    }
  }
  public render(): React.ReactElement<any> {
    const { worktimeItems, worktimecolumns,
      WOhistoryItems, WOhistorycolumns, WOquestionsItems, WOquestionscolumns,
      DocPhotoscolumns, DocPhotosItems,
      plantusageItems, plantusagecolumns,
      relatedWOItems, relatedWOcolumns, workorderdetails,
      WOoffsitecompleteItems } = this.state;
    return (
      <div >
        {this.state.isOpen && this.openImageModal()}
        {this.state.showDefectDetailsModal && this.renderDetialsModal()}
        {this.state.showTimesheetModal && this.renderTimesheetDetailsModal()}
        {this.state.showPlantEditTimeModal && this.renderPlantEditTimeModall()}
        {this.state.showModalofPurchaseReq && this.renderPurchaseRequistionModal()}
        {(this.state.openMapModal) && (this.renderMapModal())}
        {this.state.showAlert && this.alert()}

        {/* {this.state.openCloseWOModal && this.renderCloseWOModal()} */}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}>
              Work Order Details
              {(this.state.workorderdetails) && (
                <>
                  <span>- {this.state.workorderdetails.Wonumber} ({this.state.workorderdetails.Title})</span>
                  <IconButton aria-label="view" color="primary" onClick={() => this.GetWODetails()} >
                    <RefreshIcon titleAccess='Refresh the data' className={'primary'} />
                  </IconButton>
                </>
              )} </span>

          </div>
        </div>
        {(!this.state.workorderdetails ?
          <div>
            <Spinner size='large' label={'Loading details'} />
            <br />
          </div> : null
        )}

        {(this.state.workorderdetails &&
          <div className={"detailFieldDiv"}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={5}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Work Order No (IPS):</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.Erpwonumber}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Priority:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.Priority}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Work Order Status:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.Workorderstatus}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Current Contractor Status:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.Contractorstatus}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Latest Status Date:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{lateststatusdate ?
                    moment(lateststatusdate).format("DD/MM/YYYY h:mm a") : null}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Asset:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>
                    {this.state.AssetWOItems.map((asset: any, i: any) => {
                      let link = "/AssetDetail?ASSETNO=" + asset.assetnum + "&compkey=" + asset.compkey;
                      return (
                        <NavLink className="WOlink" to={link}>
                          {asset.assetId ? `${asset.assetId}${this.state.workorderdetails.UnitId2Asset ? '-' + this.state.workorderdetails.UnitId2Asset : ''}` + `${this.state.workorderdetails.ParallelLineNumber ? "#" + this.state.workorderdetails.ParallelLineNumber : ""}` : `${asset.assetnum}${this.state.workorderdetails.UnitId2Asset ? '-' + this.state.workorderdetails.UnitId2Asset : ''}` + `${this.state.workorderdetails.ParallelLineNumber ? " #" + this.state.workorderdetails.ParallelLineNumber : ""}`}
                        </NavLink>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Activity:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.Worktypecode + " - " + this.state.workorderdetails.Worktypename}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Location:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.Location}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Address:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.address}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Scope:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.Scope}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={2} className='borderLeft'>
                <div>
                  {/* <span className={'floatRight mr-10'}>
                    <EditIcon className={'woDetailIcon'} onClick={() => this.openWOModal()} />
                    <AttachFileOutlinedIcon className={'woDetailIcon'} onClick={() => this._showModalofAttachment()} />
                    <RoomIcon className={'woDetailIcon'} onClick={() => this.setState({ openMapModal: true })} />
                    <BuildIcon className={'woDetailIcon'} onClick={() => this.setState({ openPlantAssignModal: true })} />
                  </span> */}
                  <List>
                    <Divider />
                    <ListItem disabled={disableeditWO} button onClick={() => this.openWOModal()}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText primary="Edit Work Order" />
                    </ListItem>
                    <Divider />
                    <ListItem disabled={disableeditWO} button onClick={() => this.setState({ showAssetPanel: true })}>
                      <ListItemIcon>
                        <EmojiTransportationIcon />
                      </ListItemIcon>
                      <ListItemText primary="Add/Edit Asset" />
                    </ListItem>
                    <Divider />
                    <ListItem disabled={disableeditWO} button onClick={() => this._showModalofAttachment()} >
                      <ListItemIcon>
                        <AttachFileOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Attach Document" />
                    </ListItem>
                    <Divider />
                    {this.state.address &&
                      <ListItem button onClick={() => this.setState({ openMapModal: true })}>
                        <ListItemIcon>
                          <RoomIcon />
                        </ListItemIcon>
                        <ListItemText primary="Show WO on Map" />
                      </ListItem>
                    }
                    <Divider />
                  </List>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        <br />
        <br />
        <br />
        <div className={'tabsDiv'}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
              <Paper square>

                <Tabs
                  value={this.state.selected}
                  onChange={this.handleSelect}
                  indicatorColor="primary"
                  // textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label={this.loaderTab('History', WOhistoryItems, WOhistoryItems.length, this.state.historyLoading)} {...a11yProps(0)} />
                  <Tab label={this.loaderTab('Documents', DocPhotosItems, DocPhotosItems.length, this.state.documentloading)} {...a11yProps(1)} />
                  <Tab disabled={this.props.role !== global.worxOnline_Admin} label={this.loaderTab('Timesheets', worktimeItems, worktimeItems.length, this.state.timesheetLoading)} {...a11yProps(2)} />
                  <Tab disabled={this.props.role !== global.worxOnline_Admin} label={this.loaderTab('Plant Usage', plantusageItems, plantusageItems.length, this.state.plantUsageLoading)} {...a11yProps(3)} />
                  <Tab label={<span className='tabHeading'>Extra Detail</span>}  {...a11yProps(4)} />
                  <Tab label={this.loaderTab('Related Work Orders ', relatedWOItems, relatedWOItems.length, this.state.loadingrelatedWO)}{...a11yProps(5)} />
                  <Tab label={this.loaderTab('Work Order Questions', WOquestionsItems, WOquestionsItems.length, this.state.loading)} {...a11yProps(6)} />
                  <Tab label={<span className='tabHeading'>Comment</span>}  {...a11yProps(7)} />
                </Tabs>
              </Paper>
            </Grid>
          </Grid>

          <TabPanel value={this.state.selected} index={0}>
            {!this.state.loading ?
              <>
                {(WOhistoryItems && WOhistoryItems.length > 0) && (
                  <div className="tabStripDiv">
                    <DataTable
                      style={{ width: '100%' }}
                      filteralbe={false}
                      groupable={false}
                      rows={WOhistoryItems}
                      columns={WOhistorycolumns}
                      // fileName={'Timesheet'}
                      // columnChooser={'prestartchecklistColumns'}
                      selectionMode={false}
                      showHideColumnMenu={false}
                      callAfterInit={this.state.callAfterInit}
                      stopCommsAfterInit={() => this.stopCommsAfterInit()}
                      loading={this.state.loading}
                      allowExportSelectedData={false}
                      showNewBtn={false}
                      hideExport={true}
                      hideSearchPanel={true}
                      // showNewModal = {() => this._showModal()}
                      // deleteItem = {(a:any) => this.DeleteDialog(a)}
                      // editItem = {(a:any) => this.EditItemModal(a)}
                      // viewFile={(e:any)=>this.viewFile(e)}
                      showCommandBtn={false}
                      showViewDetailBtn={false}
                    />
                  </div>
                )}
              </>
              :
              <>
                <br />
                <Spinner size='large' type={'converging-spinner'} className="loader" />
              </>
            }
          </TabPanel>
          <TabPanel value={this.state.selected} index={1}>
            {!this.state.loading ?
              <>
                {(DocPhotosItems && DocPhotosItems.length > 0) && (
                  <div className="tabStripDiv">
                    <DataTable
                      style={{ width: '100%' }}
                      filteralbe={false}
                      groupable={false}
                      rows={DocPhotosItems}
                      columns={DocPhotoscolumns}
                      // fileName={'Timesheet'}
                      // columnChooser={'prestartchecklistColumns'}
                      selectionMode={false}
                      showHideColumnMenu={false}
                      callAfterInit={this.state.callAfterInit}
                      stopCommsAfterInit={() => this.stopCommsAfterInit()}
                      loading={this.state.loading}
                      allowExportSelectedData={false}
                      showNewBtn={false}
                      hideExport={true}
                      hideSearchPanel={true}
                      // showNewModal = {() => this._showModal()}
                      // deleteItem = {(a:any) => this.DeleteDialog(a)}
                      // editItem = {(a:any) => this.EditItemModal(a)}
                      // viewFile={(e:any)=>this.viewFile(e)}
                      showCommandBtn={false}
                      showViewDetailBtn={false}
                      viewFile={(e: any) => this.viewdocument(e)}
                      handleOpen={(e: any) => this.handleOpen(e)}
                    />
                  </div>
                )}
              </>
              :
              <Spinner size='large' type={'converging-spinner'} className="loader" />
            }
          </TabPanel>
          <TabPanel value={this.state.selected} index={2}>
            {!this.state.loading ?
              <>
                {(worktimeItems && worktimeItems.length > 0) && (
                  <div className="tabStripDiv">
                    <DataTable
                      style={{ width: '100%' }}
                      filteralbe={false}
                      groupable={false}
                      rows={worktimeItems}
                      columns={worktimecolumns}
                      // fileName={'Timesheet'}
                      // columnChooser={'prestartchecklistColumns'}
                      selectionMode={false}
                      showHideColumnMenu={false}
                      callAfterInit={this.state.callAfterInit}
                      stopCommsAfterInit={() => this.stopCommsAfterInit()}
                      loading={this.state.loading}
                      allowExportSelectedData={false}
                      showNewBtn={false}
                      hideExport={true}
                      hideSearchPanel={true}
                      // showNewModal = {() => this._showModal()}
                      // deleteItem = {(a:any) => this.DeleteDialog(a)}
                      // editItem = {(a:any) => this.EditItemModal(a)}
                      // viewFile={(e:any)=>this.viewFile(e)}
                      showCommandBtn={false}
                      showViewDetailBtn={false}
                    />
                  </div>
                )}
              </>
              :
              <Spinner size='large' type={'converging-spinner'} className="loader" />
            }
          </TabPanel>
          <TabPanel value={this.state.selected} index={3}>
            {(plantusageItems && plantusageItems.length > 0) && (
              <div className="tabStripDiv">
                <DataTable
                  style={{ width: '100%' }}
                  filteralbe={false}
                  groupable={false}
                  rows={plantusageItems}
                  columns={plantusagecolumns}
                  // fileName={'Timesheet'}
                  // columnChooser={'prestartchecklistColumns'}
                  selectionMode={false}
                  showHideColumnMenu={false}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  hideExport={true}
                  hideSearchPanel={true}
                  // showNewModal = {() => this._showModal()}
                  // deleteItem = {(a:any) => this.DeleteDialog(a)}
                  // editItem = {(a:any) => this.EditItemModal(a)}
                  // viewFile={(e:any)=>this.viewFile(e)}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.selected} index={5}>
            {(relatedWOItems && relatedWOItems.length > 0) && (
              <div className="tabStripDiv">
                <DataTable
                  style={{ width: '100%' }}
                  filteralbe={false}
                  groupable={false}
                  rows={relatedWOItems}
                  columns={relatedWOcolumns}
                  selectionMode={false}
                  showHideColumnMenu={false}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loadingrelatedWO}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  hideExport={true}
                  hideSearchPanel={true}
                  // showNewModal = {() => this._showModal()}
                  // deleteItem = {(a:any) => this.DeleteDialog(a)}
                  // editItem = {(a:any) => this.EditItemModal(a)}
                  // viewFile={(e)=>this.viewFile(e)}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.selected} index={4}>
            {(workorderdetails) && (
              <div className="m-20">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>Contractor ID:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>{workorderdetails.Contractorrefno}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>SalesForce Case No:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>{workorderdetails.Salesforcecaseno}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>WorxOnline WO Number:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.Wonumber}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>Service Request #</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>
                        {this.state.workorderdetails.Servicerequestid > 1 ? <NavLink className="WOlink" to={this.state.ServReqlink}>{this.state.workorderdetails.Servicerequestid}</NavLink> : ''}
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>Reported On:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.Datereported !== "" && this.state.workorderdetails.Datereported !== null ? moment(this.state.workorderdetails.Datereported).format("DD/MM/YY h:mm a") : ''}</Grid>

                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>Schedule Start Date:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>
                        {workorderdetails.Datescheduledstart ?
                          moment(workorderdetails.Datescheduledstart).format("DD/MM/YY h:mm a") : null}
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>Completion Date:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>
                        {workorderdetails.Datecompleted ?
                          moment(workorderdetails.Datecompleted).format("DD/MM/YY h:mm a") : null}
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>Time on Site:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>
                        {workorderdetails.TimeOnSite ?
                          moment(workorderdetails.TimeOnSite).format("DD/MM/YY h:mm a") : null}
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>Responsibility:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.Responsibility ? this.state.workorderdetails.Responsibility.replace("|", " - ") : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>Closed By:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.ClosedBy ? this.state.workorderdetails.ClosedBy.replace("|", " - ") : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>Final Priority:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.FinalPriorityDescription ? `${this.state.workorderdetails.FinalPriorityCode} - ${this.state.workorderdetails.FinalPriorityDescription}` : ''}</Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>Crew:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.Crewtitle}</Grid>
                    </Grid>
                    {/* <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>After Hour Ref No:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>{workorderdetails.Afterhourrefno}</Grid>
                    </Grid> */}
                    {this.props.role === global.worxOnline_Admin && (
                      <>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={4}>Risk Assessment Required:</Grid>
                          <Grid item xs={12} sm={8} className={'semiBold'}>{(this.state.workorderdetails.Riskassessmentrequired === true) ? "Yes" : "No"}</Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={4}>Before Photo Required:</Grid>
                          <Grid item xs={12} sm={8} className={'semiBold'}>{workorderdetails.Photorequiredbefore ? 'Yes' : 'No'}</Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={4}>After Photo Required:</Grid>
                          <Grid item xs={12} sm={8} className={'semiBold'}>{workorderdetails.Photorequiredafter ? 'Yes' : 'No'}</Grid>
                        </Grid>
                      </>
                    )}
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>Reason to Attend:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.Reasontoattend}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>Reason Cause Effect Action:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.Reasoncauseeffectaction}</Grid>
                    </Grid>
                    {(workorderdetails.FormType === 'Sewer Main' || workorderdetails.FormType === 'Asset') &&
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>Containment Time:</Grid>
                        <Grid item xs={12} sm={8} className={'semiBold'}>
                          {workorderdetails.ContainmentTime ?
                            moment(workorderdetails.ContainmentTime).format("DD/MM/YY h:mm a") : null}
                        </Grid>
                      </Grid>
                    }
                    {(workorderdetails.FormType === 'Sewer Main' || workorderdetails.FormType === 'Asset') &&
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>Sewer Restored Time:</Grid>
                        <Grid item xs={12} sm={8} className={'semiBold'}>
                          {workorderdetails.SewerRestoredTime ?
                            moment(workorderdetails.SewerRestoredTime).format("DD/MM/YY h:mm a") : null}
                        </Grid>
                      </Grid>
                    }
                    {(workorderdetails.FormType === 'Water Main' || workorderdetails.FormType === 'Asset') &&
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>Water Interruption Time:</Grid>
                        <Grid item xs={12} sm={8} className={'semiBold'}>
                          {workorderdetails.WaterInterruptionTime ?
                            moment(workorderdetails.WaterInterruptionTime).format("DD/MM/YY h:mm a") : null}
                        </Grid>
                      </Grid>
                    }
                    {(workorderdetails.Formtype === 'Water Main' || workorderdetails.Formtype === 'Asset') &&
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>Water Restored Time:</Grid>
                        <Grid item xs={12} sm={8} className={'semiBold'}>
                          {workorderdetails.WaterRestoredTime ?
                            moment(workorderdetails.WaterRestoredTime).format("DD/MM/YY h:mm a") : null}
                        </Grid>
                      </Grid>
                    }
                  </Grid>

                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={4}>Standard Operating Procedure:</Grid>
                    <Grid item xs={12} sm={12} className={''}>{this.state.workorderdetails.Sop && this.state.workorderdetails.Sop !== null ? <div dangerouslySetInnerHTML={{ __html: `${this.state.workorderdetails.Sop.replace(/\n/g, "<br />")}` }}></div> : ""}</Grid>
                  </Grid>
                </Grid>
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.selected} index={6}>
            {(WOquestionsItems && WOquestionsItems.length > 0) && (
              <div className="tabStripDiv">
                <DataTable
                  style={{ width: '100%' }}
                  filteralbe={false}
                  groupable={false}
                  rows={WOquestionsItems}
                  columns={WOquestionscolumns}
                  fileName={'WOquestions'}
                  columnChooser={'workorderColumns'}
                  selectionMode={false}
                  showHideColumnMenu={false}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  hideExport={false}
                  hideSearchPanel={true}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.selected} index={7}>
            <div className="m-20">
              {workorderdetails && workorderdetails.WOComments ?
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        {/* <div>{workorderdetails.WOComments}</div> */}
                        <div dangerouslySetInnerHTML={{ __html: `${workorderdetails.WOComments.replace(/\n/g, "<br />")}` }}></div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                :
                <p className={'alignCenter'}>No comment found</p>}
            </div>
            <div className="m-20">
              {WOoffsitecompleteItems && WOoffsitecompleteItems.map((item: any, Index: any) => {
                return (
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          {/* <div>{item.usercomments}</div> */}

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          </TabPanel>

        </div>
        <div>{this.state.workorderdetails && this.renderCommsPanel()}</div>
        {this.state.showConfirmAlert && this.ConfirmAlert()}
        <div>{this.renderAttachmentModal()} </div>
        <div>{this.renderAssetPanel()}</div>
      </div>

    );
  }
}


function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}