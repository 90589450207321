import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';

import Grid from '@material-ui/core/Grid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

import AlertDialog from '../../common/alertDialog';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import Spinner from '../../common/reusableComponents/Spinner';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import capitalize from '@material-ui/core/utils/capitalize';

export default class AddEditTeam extends React.Component<any, any> {

    public departmentItems: any = [];
    public userItems: any = [];

    constructor(props: any) {
        super(props);

        this.updateTeam = this.updateTeam.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.departmentItems = [];
        this.userItems = [];
        this.state = {
            allowReassignCrew: false
        };
    }

    public componentDidMount() {
        this._getDepartmentData();

    }
    public didMount() {
        //console.log(this.props.details);
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode,
                isActive: true,
                allowReassignCrew: false
            });
        }

    }
    public populateValue(details: any, mode?: any) {
        let selectedTeamLeader: any = [];
        let tempTeamLeaderid: any = details.teamLeaderIds;
        //console.log("tempTeamLeaderid",tempTeamLeaderid);
        if (tempTeamLeaderid !== null) {
            tempTeamLeaderid.forEach((item: any, index: any) => {
                let tempTeamLeader = this.userItems.filter((r: any) => r.id === item);
                //console.log("tempTeamLeader",tempTeamLeader)
                tempTeamLeader.forEach((item: any) => {
                    selectedTeamLeader.push(item);
                });
            })
        }
        this.setState({
            id: details.id,
            title: details.title,
            code: details.code,
            department: { id: details.departmentId, text: details.department },
            disabled: false,
            allowReassignCrew: details.allowReassignCrew,
            hideAlertButton: false,
            teamLeader: selectedTeamLeader
        });
    }
    private _getDepartmentData() {

        utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {
            //console.log(data);
            this.departmentItems = [];

            for (let r of data) {
                this.departmentItems.push({ id: r.Id, text: r.Title });
            }
            this.setState({
                departmentItems: this.departmentItems,
                alldepartmentItems: this.departmentItems,
            });
        }, (err) => {
            console.log(err);
        });
    }
    public updateTeam() {
        //console.log(this.state.title);
        //console.log(this.props.currentUser);
        //genericPostAPIcallForAdminList
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.code) {
            this.setState({
                alertMessage: 'Please enter  code',
                showAlert: true
            });
            return;
        }
        if (!this.state.department) {
            this.setState({
                alertMessage: 'Please select a department',
                showAlert: true
            });
            return;
        }

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' lable='Loading..' /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        let teamLeader = [];
        if (this.state.teamLeader) {
            this.state.teamLeader.forEach((element: any) => {
                teamLeader.push(element.id);
            });
        }
        dataToPassToService.Title = this.state.title;
        dataToPassToService.Code = this.state.code;
        dataToPassToService.Tenantid = Config.tenantId;
        dataToPassToService.Departmentid = this.state.department.id;
        dataToPassToService.IsActive = true;
        dataToPassToService.Allowreassigncrew = this.state.allowReassignCrew;
        dataToPassToService.Userupdated = this.props.currentUser.Email;
        //console.log("dataToPassToService", dataToPassToService);
        if (this.props.mode === 'edit') {
            dataToPassToService.ID = this.state.id;
            utility.genericUpdateAPICall(Config.TeamList, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Team has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.Usercreated = this.props.currentUser.Email;
            utility.genericPostAPIcall(Config.TeamList, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Team has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
        utility.removeItem(Config.TeamList);
    }

    public clearFilter() {
        this.setState({
            title: '',
            code: '',
            department: '',
            manager: '',
            allowReassignCrew: false,
            coordinator: '',
            teamLeader: '',
        });
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }

    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }


    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.props.mode && capitalize(this.props.mode)} Team`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                        <div className='popContentDiv'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Title *"
                                        // pattern={"[A-Za-z]+"}
                                        // minLength={2}
                                        value={this.state.title}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                        required={true}
                                        onKeyDown={() => { }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Code *"
                                        // pattern={"[A-Za-z]+"}
                                        // minLength={2}
                                        value={this.state.code}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'code')}
                                        required={true}
                                        onKeyDown={() => { }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SelectBoxInput
                                        stylingMode='underlined'
                                        displayExpr="text"
                                        valueExpr="id"
                                        showClearButton={true}
                                        items={this.state.departmentItems}
                                        selectedItem={this.state.department}
                                        label='Organisation *'
                                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CheckBoxInput value={this.state.allowReassignCrew} onValueChanged={(e: any) => this.handleValueChange(e, 'allowReassignCrew')} text={'Allow Reassign Crew'} />
                                </Grid>

                            </Grid>
                        </div>
                    </ScrollView>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.updateTeam,
                        }}
                    />
                </Popup>
            </div>

        );
    }

}
