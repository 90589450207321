import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';


import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditCrew from './AddEditCrew';


let _items: any = [];

export default class Crews extends React.Component<any, any> {

    public departmentItems: any = [];
    public teamsItems: any = [];
    public currentUser: any;
    public columns: any;

    constructor(props: any) {
        super(props);

        this.departmentItems = [];
        this.teamsItems = [];
        this.DeleteDialog = this.DeleteDialog.bind(this);

        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        }
        // this.currentUser = {
        //     DisplayName: props.context.pageContext.user.displayName,
        //     Email: props.context.pageContext.user.email,
        //     LoginName: props.context.pageContext.user.loginName
        // };

        this.currentUser = this.props.currentUser;



        this.columns = [
            {
                field: 'id',
                title: 'ID',
                width: 80,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'title',
                title: 'Title',
                // width: 160,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'teamName',
                title: 'Team',
                // width: 280,
                type: 'string',
            },
            {
                field: 'departmentName',
                title: 'Organisation',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'mapColor',
                title: 'Color',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'prestartReq',
                title: 'Prestart Required',
                width: 120,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'updatedOn',
                title: 'Updated On',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true,
                sortOrder: 'desc'
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created On',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
        ];
        let showHideColumns = localStorage.getItem("crewsColumns");
        let tempColumns;
        if (showHideColumns !== null) {
            tempColumns = JSON.parse(showHideColumns);
        } else {
            tempColumns = this.columns;
            localStorage.setItem("crewsColumns", JSON.stringify(this.columns));
        }
        this.state = {
            items: _items,
            columns: tempColumns,
        };
    }

    public componentDidMount() {
        this._getItemData();
        this._getTeamDataAsync();
        this._getDepartmentData();

    }


    public DeleteItem(item: any) {
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true,
            showDeleteAlert: false,
        });
        utility.genericDeleteAPICall(Config.crewlistname, item).then((data: any) => {
            this.setState({
                alertMessage: 'Crew has been deleted.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
                loading: false
            });
            this._getItemData();
        }, (err) => {
            console.log(err);
            this.setState({
                alertMessage: 'Cannot delete crew. Please try again later.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
            });
        });

    }

    // edit activty modal

    private EditItemModal(item: any) {
        //console.log("item", item);
        this.setState({ showModal: true, details: item, mode: 'edit' });
    }
    public renderNewModal() {
        return (
            <AddEditCrew
                showModal={this.state.showModal}
                onClose={() => this._closeModal()}
                onClick={() => this._getItemData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                details={this.state.details}
                mode={this.state.mode}
            />
        );
    }

    private _showModal = (): void => {
        this.setState({ showModal: true, details: null, mode: 'add' });
    }

    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }
    public message(message: any) {
        //console.log(message);
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }
    private _getDepartmentData() {

        utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {
            //console.log(data);
            this.departmentItems = [];

            for (let r of data) {
                this.departmentItems.push({ id: r.Id, text: r.Title });
            }
            this.setState({ departmentItems: this.departmentItems });
        }, (err) => {
            console.log(err);
        });
    }
    private _getTeamDataAsync() {

        utility.genericGetAPICall(Config.TeamList).then((data: any) => {
            //console.log(data);
            this.teamsItems = [];

            for (let r of data) {
                this.teamsItems.push({ id: r.Id, text: (r.Code + ' - ' + r.Title) });
            }
            this.setState({ teamsItems: this.teamsItems });
        }, (err) => {
            console.log(err);
        });
    }
    private _getItemData() {
        let dataToPassToService: any = {};
        this.setState({
            loading: true
        });
        //console.log("dataToPassToService", dataToPassToService);
        utility.genericGetAPICallForList(Config.SearchCrewApi, 'POST', dataToPassToService).then((data: any) => {
            _items = [];
            if (data.length === null) {
                if (_items.length <= 0) {
                    this.setState({
                        alertMessage: 'No results found for this criteria',
                        showAlert: true
                    });
                }
                this.setState({ items: _items, loading: false });
                return;
            }
            else {
                for (let r of data) {
                    _items.push({
                        id: r.Id,
                        title: r.Title,
                        code: r.Code,
                        departmentName: r.DepartmentName,
                        departmentID: r.DepartmentID,
                        teamName: r.TeamName,
                        teamID: r.TeamID,
                        mapColor: r.MapColour,
                        prestartReq: r.PrestartRequired,
                        isActive: true,
                        updatedBy: r.Userupdated,
                        updatedOn: r.Dateupdated ? r.Dateupdated : null,
                        createdBy: r.Usercreated,
                        createdOn: r.Datecreated ? r.Datecreated : null,

                    });
                }
            }
            _items = utility._sortItems(_items, 'updatedOn', true);

            this.setState({ items: _items, callAfterInit: true, loading: false, preItems: _items });

            //console.log(_items);
        }, (err) => {
            console.log(err);
            this.setState({ loading: false });
        });
    }

    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }

    public render(): React.ReactElement<any> {

        return (
            <React.Fragment>
                {this.renderNewModal()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Crews </span>
                    </div>
                </div>
                <div>
                    {!this.state.loading ?
                        <DataTable
                            style={{ width: '100%' }}
                            filteralbe={true}
                            groupable={true}
                            rows={this.state.items}
                            columns={this.state.columns}
                            fileName={'Crews'}
                            columnChooser={'crewsColumns'}
                            selectionMode={false}
                            showHideColumnMenu={true}
                            callAfterInit={this.state.callAfterInit}
                            stopCommsAfterInit={() => this.stopCommsAfterInit()}
                            loading={this.state.loading}
                            allowExportSelectedData={false}
                            showNewModal={() => this._showModal()}
                            deleteItem={(a: any) => this.DeleteDialog(a)}
                            editItem={(a: any) => this.EditItemModal(a)}
                            // viewFile={(e:any)=>this.viewFile(e)}
                            showCommandBtn={true}
                            showNewBtn={true}
                            hideNewBtn={this.state.showModal || this.state.showModal || this.props.hideNewBtn}
                            showViewDetailBtn={false}
                        />
                        :
                        <Spinner size='large' type={'converging-spinner'} className="loader" />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }
    public DeleteDialog(item: any) {
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            deleteItem: item
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.DeleteItem(this.state.deleteItem)}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }
}