import * as React from 'react';
import TextArea from 'devextreme-react/text-area';

interface Props {
    label?: string;
    stylingMode?: string|any;
    placeholder?: string|any;
    value: string;
    showClearButton?: Boolean|any;
    readOnly?: Boolean|any;
    disabled?: Boolean|any;
    onValueChanged:any;
    spellcheck?:Boolean|any;
  }

export default class TextAreaInput extends React.Component<Props, any> {

    public state = {
    };

    public render(): React.ReactElement<any> {
        return (            
            <TextArea
                placeholder={this.props.placeholder}
                value={this.props.value}
                // stylingMode={this.props.stylingMode}
                // height={90}
                minHeight={90}
                maxHeight={290}
                spellcheck={this.props.spellcheck}
                onValueChanged={(e:any) => this.props.onValueChanged(e)}
                showClearButton={this.props.showClearButton}
                labelMode={'floating'}
                label={this.props.label}
                readOnly={this.props.readOnly}
                disabled={this.props.disabled}
                autoResizeEnabled={true}
            />
        );
    }
}


