import * as React from 'react';

import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import global from '../../classes/global';
import AlertDialog from '../common/alertDialog';
// import AssetSearch from '../AssetSearch/AssetSearch';
// import AssetMap from '../AssetSearch/AssetMap';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import DeleteIcon from '@material-ui/icons/Delete';
import MapIcon from '@material-ui/icons/Map';

import Paper from '@material-ui/core/Paper';
import { DropzoneComponent } from 'react-dropzone-component';
import Spinner from '../common/reusableComponents/Spinner';
import DateBoxInput from '../common/reusableComponents/DateBox';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import TextBoxInput from '../common/reusableComponents/TextBox';
import CheckBoxInput from '../common/reusableComponents/CheckBox';
import TextAreaInput from '../common/reusableComponents/TextArea';
import { Label } from 'devextreme-react/form';


let myDropzone: any;
export default class CreateWO extends React.Component<any, any> {

  public suburbItems: any = [];
  public StatusItems: any = [];
  public crewItems: any = [];
  public worktypeItems: any = [];
  public employeeItems: any = [];
  public priorityItems: any = [];
  public Assets: any;

  public uploadedFiles: any = [];
  public dataURL: any = [];
  public currentUser: any;
  public responseData: any = [];
  public setupDataPromise: any = [];

  constructor(props: any) {
    super(props);

    this.suburbItems = [];
    this.priorityItems = [];
    this.employeeItems = [];
    this.StatusItems = [];
    this.crewItems = [];
    this.worktypeItems = [];
    this.uploadedFiles = [];
    this.dataURL = [];
    this.Assets = '';
    this.setupDataPromise = [];

    this.clearFilter = this.clearFilter.bind(this);
    this.createWorkOrder = this.createWorkOrder.bind(this);

    this.SearchClicked = this.SearchClicked.bind(this);


    if (this.props.role !== global.worxOnline_Admin && this.props.role !== global.worxOnline_Writer) {
      window.open('#/AccessDenied', '_self');
    };

    let currentDetail = localStorage.getItem("currentDetail");
    // 
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };
    this.currentUser = {
      //DisplayName: props.context.pageContext.user.displayName,
      Email: currentDetail
      //LoginName: props.context.pageContext.user.loginName
    };


    this.state = {
      serviceRequest: '',
      location: '',
      suburb: '',
      woworktype: null,
      title: '',
      Eststartdate: null,
      Estenddate: null,
      befpicreq: false,
      aftpicreq: false,
      riskassessment: false,
      crewteam: '',
      comments: '',
      description: '',
      Assets: this.Assets,
      //  AssetsonMap: this.AssetsonMap,
      showPanel: false,
      folderName: "",
      departmentID: "",
    };
  }



  public componentDidMount() {

    this.setState({ loadingFields: true });
    this.setupDataPromise = [];
    this._getsuburbDataAsync();
    this._getEmployeeData();
    this.getWOSchedulerDate();
    this._getStatusDataAsync();
    this._getPrioritiesData();
    Promise.all(this.setupDataPromise).then(items => {
      //console.log('---all promise done---');
      this.setState({ loadingFields: false });
    });
    this.setState({
      serviceRequest: this.props.serviceReq,
      newWOModal: this.props.newWOModal
    });
    //console.log(this.props.myhttpclient);
  }



  public clearFilter() {
    //console.log(myDropzone);
    myDropzone.removeAllFiles(true);
    this.setState({
      serviceRequest: '',
      location: '',
      suburb: '',
      woworktype: '',
      priority: null,
      Eststartdate: null,
      Estenddate: null,
      befpicreq: '',
      aftpicreq: '',
      crewteam: '',
      comment: '',
      uploadedFiles: '',
      Assets: '',
      lat: '',
      lng: ''
    });
    this.Assets = '';
    if (this.props.newWOModal) {
      this.props.onClose();
    }
  }


  public _getEmployeeData() {
    let dataToPassToService: any = {};

    dataToPassToService.Email = this.currentUser.Email;

    this.setupDataPromise.push(
      utility.genericPostAPIcall(Config.SearchEmployeeApi, dataToPassToService).then((data: any) => {
        //console.log(data);
        if (this.props.role === global.worxOnline_Admin) {
          this._getCrewDataAsync();
          this._getworkTypeDataAsync();
        } else {
          this._getCrewDataAsync(data[0].DepartmentId);
          this._getworkTypeDataAsync(data[0].DepartmentId);
        }
      }, (err: any) => {
        console.log(err);
      }));
  }

  public getWOSchedulerDate() {
    if (this.props.startDate && this.props.endDate) {
      this.setState({
        crewteam: { id: this.props.crewId, text: this.props.crewName },
        Eststartdate: new Date(this.props.startDate),
        Estenddate: new Date(this.props.endDate),
      });
    }
  }
  private _getPrioritiesData() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.PriorityList).then((data: any) => {
        //console.log(data);
        this.priorityItems = [];

        for (let r of data) {
          this.priorityItems.push({ id: (r.Id), text: (r.Title) });
          if (r.Title === "Priority 3") {
            this.setState({ priority: { id: r.Title, text: r.Title } });
          }
        }
        this.setState({
          priorityItems: this.priorityItems,
          allpriorityItems: this.priorityItems,
        });
      }, (err) => {
        console.log(err);
      }));
  }
  private _getStatusDataAsync() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.statuslist).then((data: any) => {
        //console.log(data);
        this.StatusItems = [];
        //wostatus: { id: 10, text: "Created" }
        for (let r of data) {
          // if (r.IsContractorStatus  === false) {
          this.StatusItems.push({ id: r.Id, text: r.Title });
          if (r.Title === "Open") {
            this.setState({ wostatus: { id: r.Id, text: r.Title } });
          }
          // }
        }

        this.setState({
          StatusItems: this.StatusItems,
          allStatusItems: this.StatusItems,
        });
      }, (err) => {
        console.log(err);
      }));
  }
  private _getsuburbDataAsync() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.suburblistname).then((data: any) => {
        //console.log(data);
        this.suburbItems = [];
        for (let r of data) {
          this.suburbItems.push({ id: r.Id, text: r.Title, lat: r.Latitude, lng: r.Longitude });
        }
        this.setState({
          suburbItems: this.suburbItems,
          allsuburbItems: this.suburbItems,
        });
      }, (err) => {
        console.log(err);
      }));
  }

  private _getCrewDataAsync(departmentId?: any) {
    let dataToPassToService: any = {};
    if (departmentId) {
      dataToPassToService.DepartmentId = departmentId;
    }
    this.setupDataPromise.push(
      utility.genericPostAPIcall(Config.SearchCrewApi, dataToPassToService).then((data: any) => {
        //console.log(data);
        this.crewItems = [];

        for (let r of data) {
          this.crewItems.push({ id: (r.Id), text: (r.Title + ' - ' + r.TeamName) });
        }
        this.setState({
          crewItems: this.crewItems,
          allcrewItems: this.crewItems,
        });
      }, (err) => {
        console.log(err);
      }));
  }

  private _getworkTypeDataAsync(departmentId?: any) {
    let dataToPassToService: any = {};
    if (departmentId) {
      dataToPassToService.DepartmentId = departmentId;
    }
    this.setupDataPromise.push(
      utility.genericPostAPIcall(Config.SearchActivitiesApi, dataToPassToService).then((data: any) => {
        //console.log(data);
        this.worktypeItems = [];

        for (let r of data) {
          this.worktypeItems.push({
            id: r.Id,
            text: (r.Title + ' - ' + r.Code),
            befpicreqvalue: r.IsBeforePhotoRequired,
            aftpicreqvalue: r.IsAfterPhotoRequired,
            riskassessment: r.IsRiskAssessmentRequired,
            DepartmentId: r.DepartmentId
          });
        }
        this.setState({ worktypeItems: this.worktypeItems, allworktypeItems: this.worktypeItems });
      }, (err) => {
        console.log(err);
      }));
  }


  private _closeDialog = (): void => {
    this.setState({ alertMessage: '', showAlert: false });
    if (this.state.previousPage) {
      if (this.props.newWOModal) {
        this.props.onClick();
      } else {
        let location = window.location.origin + window.location.pathname
        let SearchWO = `${location}#/WorkOrderDetail?WOID=${this.state.woData.Id}&SR=&WONum=${this.state.woData.Wonumber}`;
        window.open(SearchWO, "_self");

      }
    }
  }

  public handleEvent(file: any) {

    this.onDropGeneral(file);
    this.uploadedFiles.push({ file });
    this.setState({ uploadedFile: this.uploadedFiles });
  }
  public onDropGeneral(file: any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      this.dataURL.push({ file: file.name, dataUrl: event ? event.currentTarget : "" });

    };
  }

  public createWorkOrder() {
    let accpeted;
    if (this.uploadedFiles) {
      this.uploadedFiles.forEach((item: any) => {
        if (item.file.accepted === false) {
          accpeted = false;
        }
      });
      if (accpeted === false) {
        this.setState({
          alertMessage: 'Selected file is not accepted. Please select another file.',
          showAlert: true
        });
        return;
      }
    }
    if (!this.state.woworktype) {
      this.setState({
        alertMessage: 'Please select Activity',
        showAlert: true
      });
      return;
    }
    if (!this.state.location) {
      this.setState({
        alertMessage: 'Please enter Location',
        showAlert: true
      });
      return;
    }
    if (!this.state.suburb) {
      this.setState({
        alertMessage: 'Please select Suburb',
        showAlert: true
      });
      return;
    }
    if (!this.state.priority) {
      this.setState({
        alertMessage: 'Please select Priority',
        showAlert: true
      });
      return;
    }
    if (!this.state.Eststartdate) {
      this.setState({
        alertMessage: 'Please select Start date',
        showAlert: true
      });
      return;
    }
    if (!this.state.Estenddate) {
      this.setState({
        alertMessage: 'Please select Finish date',
        showAlert: true
      });
      return;
    }
    if (this.state.Eststartdate && this.state.Estenddate) {
      let Eststartdate = new Date(this.state.Eststartdate);
      let Estenddate = new Date(this.state.Estenddate);
      if (Estenddate.getTime() < Eststartdate.getTime()) {
        this.setState({
          alertMessage: 'Scheduled Start Date should be greater than Scheduled Finish Date',
          showAlert: true
        });
        return;
      }
    }
    this.setState({
      alertMessage: <Spinner label='Creating work order' />,
      hideAlertButton: true,
      showAlert: true
    });
    let wostatus;
    if (!this.state.crewteam) {
      let tempstatusList = this.state.StatusItems;
      let tempStatus = tempstatusList.find((r: any) => r.text === "Proposed");
      wostatus = tempStatus;
    } else {
      wostatus = this.state.wostatus;
    }
    let JSONAsset = "";
    let dataToPassToService: any = {};
    dataToPassToService.Title = this.state.woworktype.text;
    dataToPassToService.Description = this.state.comment ? this.state.comment : '';
    dataToPassToService.Workordertypeid = this.state.woworktype.id;

    dataToPassToService.DepartmentId = this.state.departmentId;
    dataToPassToService.Location = this.state.location;
    dataToPassToService.Suburbid = this.state.suburb.id;
    dataToPassToService.Priority = this.state.priority.text;
    dataToPassToService.Datescheduledstart = new Date(this.state.Eststartdate).toISOString();
    dataToPassToService.Datescheduledend = new Date(this.state.Estenddate).toISOString();
    dataToPassToService.CrewId = this.state.crewteam ? this.state.crewteam.id : null;
    dataToPassToService.Riskassessmentrequired = this.state.riskassessment ? true : false;
    dataToPassToService.Photorequiredbefore = this.state.befpicreq ? true : false;
    dataToPassToService.Photorequiredafter = this.state.aftpicreq ? true : false;
    if (this.state.Assets) {
      JSONAsset = JSON.stringify(this.state.Assets);
      dataToPassToService.Assetsinfo = JSONAsset;
    }
    dataToPassToService.Tenantid = Config.tenantId;
    // dataToPassToService.Wonumber = "WO" + this.IDGenerator(); 
    dataToPassToService.Category = "";
    // "{\"lat\":144.25737908,\"lng\":-36.74769994}
    dataToPassToService.Locationgeom = this.state.Address ? this.state.Address : '';
    dataToPassToService.LatLng = { X: this.state.lat ? this.state.lat : 0, Y: this.state.lng ? this.state.lng : 0 };
    dataToPassToService.Workorderstatusid = wostatus.id;
    dataToPassToService.ErpWoStatus = wostatus.text;
    dataToPassToService.Usercreated = this.currentUser.Email;
    dataToPassToService.Userupdated = this.currentUser.Email;
    utility.genericPostAPIcall(Config.workorderlistname, dataToPassToService).then((data: any) => {

      this.setState({ woData: data });
      if (this.uploadedFiles.length > 0) {
        this.getWorkOrderData(data);
      } else {
        this.setState({
          alertMessage: 'Work order (' + data.Wonumber + ') has been created',
          showAlert: true,
          hideAlertButton: false,
          previousPage: true,
        });
        this.clearFilter();
      }
    }, (err: any) => {
      console.log(err);
      this.setState({
        alertMessage: 'Something went wrong. Please try again',
        showAlert: true,
        hideAlertButton: false,
        loading: false
      });
    });

  }
  public getWorkOrderData(data: any) {

    let dataToPassToService: any = {};
    dataToPassToService.Id = data.Id;
    this.setState({
      alertMessage: <Spinner label='Uploading documents...' />,
      hideAlertButton: true,
      showAlert: true
    });
    utility.genericPostAPIcall(Config.WOdetailsSearchAPI, dataToPassToService).then((result: any) => {
      let wodata = result[0];
      let d = new Date();
      let amOrPm = (d.getHours() < 12) ? "AM" : "PM";
      let hour = (d.getHours() < 12) ? d.getHours() : d.getHours() - 12;
      let date = d.getDate() + "_" + d.getMonth() + "_" + d.getFullYear() + "_" + hour + "_" + amOrPm;
      let file = this.uploadedFiles;
      for (var i = 0; i < file.length; i++) {
        let fileExtension;
        let tempFileExt = file[i].file.name.split('.');
        tempFileExt.reverse();
        fileExtension = `.${tempFileExt[0]}`
        let fileName = tempFileExt[1]
        // let data = this.dataURL[i].dataUrl.result.replace("data:" + file[i].file.type + ";base64,", "");

        let tempData = this.dataURL[i].dataUrl.result.split("base64,");
        let data = tempData[1];
        let dataToPassToServiceForDoc: any = {};
        dataToPassToServiceForDoc.ID = wodata.Id;
        dataToPassToServiceForDoc.Name = fileName + "_" + wodata.Wonumber + "_" + date + "_" + fileExtension;
        dataToPassToServiceForDoc.Type = 'Document';
        // dataToPassToService.Title = "WorkOrderPhoto_" +data.Id+"_"+this.props.details.Wonumber + "_" + date;
        // dataToPassToServiceForDoc.LatLng = null;
        // dataToPassToService.Comments = this.state.comments;
        dataToPassToServiceForDoc.WorkOrderNumber = wodata.Wonumber;
        dataToPassToServiceForDoc.Data = data;
        dataToPassToServiceForDoc.UploadedBy = this.currentUser.Email;

        //console.log("dataToPassToService", dataToPassToServiceForDoc);
        utility.genericPostAPIcall(Config.workorderDocumentList, dataToPassToServiceForDoc).then((data: any) => {
          //console.log(data);
          this.setState({
            alertMessage: 'Work order (' + wodata.Wonumber + ') has been created',
            showAlert: true,
            hideAlertButton: false,
            loading: false,
            previousPage: true,
          });
          this.clearFilter();

        }, (err: any) => {
          console.log(err);
          this.setState({
            alertMessage: 'Something went wrong. Please try again',
            showAlert: true,
            hideAlertButton: false,
          });
        });
      }
    });
  }

  public deleteFile(file: any) {
    var deletingIndex = 0;
    this.uploadedFiles.forEach((element: any, index: any) => {
      if (element.file.name === file.name) {
        deletingIndex = index;

      }
    });
    this.uploadedFiles.splice(deletingIndex, 1);
    this.dataURL.forEach((element: any, index: any) => {
      if (element.file.name === file.name) {
        deletingIndex = index;

      }
    });
    this.dataURL.splice(deletingIndex, 1);
  }

  public renderAssetPanel() {
    return (
      <div>
        <Dialog
          open={this.state.showPanel}
          onClose={this._onClosePanel}
          // className={'addEditModal'}
          fullWidth={true}
          maxWidth={'lg'}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent dividers={true}>
            {/* <AssetSearch
              selectMode={true}
              onClick={(Asset:any) => this.selectAssets(Asset)}
              heading={false}
              hideAssetMapButton={true}
              myhttpclient={this.props.myhttpclient}
              aadHttpClient={this.props.aadHttpClient}
            /> */}
          </DialogContent>

        </Dialog>

      </div>
    );
  }


  public selectAssets(Asset: any) {

    let suburbFound;
    let locationFound;
    locationFound = Asset.Location;
    let Address = JSON.stringify(Asset.Address);
    suburbFound = this.state.suburbItems.find((s: any) => s.text === Asset.Suburb);
    this.setState({
      Assets: Asset,
      Address: Address,
      location: locationFound,
      suburb: suburbFound,
      showPanel: false,
      lat: Asset.latLng && Asset.latLng.Y,
      lng: Asset.latLng && Asset.latLng.X,
    });
  }

  private SearchClicked() {
    this.setState(
      {
        iFramePanelOpened: true,
        selectModeMap: true,
        selectParent: false,
      });

  }
  public renderAssetMap() {
    // return (
    // <AssetMap
    //   selectAssets={(Asset:any) => this.selectAssetsonMap(Asset)}
    //   iFramePanelOpened={this.state.iFramePanelOpened}
    //   onClose={() => this._onDismissMap()}
    //   selectParent={this.state.selectParent}
    //   selectModeMap={this.state.selectModeMap}
    // />
    // );
  }


  public selectAssetsonMap(selectedAssetArray: any) {
    let flatCoordinates: any = [];
    let tempAsset;
    selectedAssetArray.forEach((selectedAsset: any) => {

      let Asset = JSON.parse(selectedAsset.properties);
      // get cordinates in lat/lng format 
      let flatCoordinatesArray = Asset.geometry.flatCoordinates;

      flatCoordinatesArray.forEach((element: any, i: any) => {
        if (i % 2 === 0) {
          flatCoordinates.push({
            "lat": flatCoordinatesArray[i + 1],
            "lng": flatCoordinatesArray[i]
          });
        }
      })
      // console.log("flatCoordinatesArray", flatCoordinates);
      // let checkassetonMap = this.Assets.find(a => a.AssetNumber === selectedAsset.id);
      // if (checkassetonMap) {
      //   this.setState({
      //     alertMessage: 'Asset: (' + selectedAsset.name + ') already added once',
      //     showAlert: true
      //   });
      //   return;
      // } else {
      tempAsset = {
        Register: "Asset",
        AssetID: selectedAsset.id,
        AssetNumber: Asset.UFI ? Asset.UFI : Asset.UFI_1 ? Asset.UFI_1 : Asset.UFI_US,
        AssetType: Asset.ASSET_TYPE,
        AssetClass: Asset.LAYER_NAME,
        Description: selectedAsset.name,
        Suburb: Asset.Suburb,
        Location: Asset.BUILDING_N,
        GISInfo: {
          type: "Feature",
          geometry: {
            type: selectedAsset.shape,
            coordinates: flatCoordinates
          },
          properties: {
            AssetID: selectedAsset.id,
            Name: selectedAsset.name,
            Type: Asset.BUILDING_T,
            Hierarchy: "1.0",
            Zone: Asset.Suburb,
            RoadGroupName: selectedAsset.name,
            Link: null,
            GID: Asset.GIS_ID,
            ExternalOSID: null,
            Location: Asset.BUILDING_N,
            Ownership: "Council as CoM",
            ExtAssetID: "",
            subclass: Asset.LAYER_NAME
          }
        },
        ERPInfo: {
          MaintenanceScheduleCode: "NR_BWT_M_INSP",
          MaintenanceHistoryUniqueID: "0",
          PercentAllocation: "1.000000",
          AttrCode: "ASSET_TYPE",
          SearchPath: "Buildings"
        },
        AssetQuestions: [],
        AttachmentUrls: []
      };
      // get locality and use is for location
      if (selectedAsset.Location || Asset.BUILDING_N) {
        //console.log("locality found");
        this.setState({ location: Asset.Location ? Asset.Location : Asset.BUILDING_N });
      }
      // }
    });
    // console.log({flatCoordinates});
    this.setState({
      Assets: tempAsset,
      //showPanel: false
      // suburb: { key: "Stawell", text: "Stawell", lat: "-37.05637", lng: "142.780442" },
      lat: selectedAssetArray[0].center[1].toString(),
      lng: selectedAssetArray[0].center[0].toString(),
      latLong: flatCoordinates[0],
      // latLong:flatCoordinates[0].lng+','+flatCoordinates[0].lat,
      iFramePanelOpened: false,
      selectParent: false,
      selectModeMap: false,
    });
    //console.log(this.Assets);

  }

  public _onDismissMap() {
    this.setState({
      iFramePanelOpened: false
    });
  }
  private _onShowPanel = (): void => {
    this.setState({ showPanel: true });
  }
  private _onClosePanel = (): void => {
    this.setState({ showPanel: false });
  }
  public removeAssetNumber() {
    // this.Assets.splice(index, 1);
    this.setState({
      Assets: ''
    });
  }
  private handleValueChange = (event: any, fieldName: string) => {

    if (fieldName === 'Eststartdate') {
      let endaDate = new Date(event.value);
      endaDate.setHours(event.value.getHours() + 2);
      this.setState({
        Estenddate: endaDate
      });
    }

    this.setState({ [fieldName]: event.value });
  }
  public handleDropdownChange(e: any, fieldName: string) {
    if (fieldName === 'woworktype') {
      let tempCrews = this.state.crewItems;
      let filteredCrew = tempCrews.filter((t: any) => t.departmentId === e.selectedItem.departmentId);
      this.setState({
        departmentId: e.selectedItem.DepartmentId,
        riskassessment: e.selectedItem.riskassessment,
        aftpicreq: e.selectedItem.aftpicreqvalue,
        befpicreq: e.selectedItem.befpicreqvalue,
        tempCrewItems: filteredCrew,
        alltempCrewItems: filteredCrew,
        crewteam: '',
      })
    }
    this.setState({ [fieldName]: e.selectedItem });
  }

  public _textAreaChanged = (e: any, fieldName: string) => {

    this.setState({ [fieldName]: e.value });
  }
  private _ondateChanged(newValue: any, fieldName: string): void {
    this.setState({ [fieldName]: newValue });
    if (fieldName === 'Eststartdate' && newValue) {
      let endaDate = new Date(newValue);
      endaDate.setHours(newValue.getHours() + 2);
      this.setState({ Estenddate: endaDate });
    }
  }

  private _checkBoxChanged = (event: any, fieldName: string) => {
    this.setState({ [fieldName]: event.value });
  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        hideAlertButton={this.state.hideAlertButton}
        onClose={() => this._closeDialog()}
      />
    );
  }
  public render(): React.ReactElement<any> {
    //console.log("this.Assets", this.Assets);
    const { columns, items, selectionDetails, selectedItem } = this.state;
    const buttonStyle = { color: 'white', textDecoration: 'none' };
    const componentConfig = {
      showFiletypeIcon: true,
      postUrl: 'no-url'
    };
    const djsConfig = {
      autoProcessQueue: false,
      addRemoveLinks: true,
      // maxFiles: 5,
      maxFilesize: 5,
      // acceptedFiles: 'image/*,'
      // acceptedFiles: 'image/*,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/pdf'
    }

    const eventHandlers = {
      init: function (dz: any) {
        myDropzone = dz;
      },
      addedfile: (file: any) => {
        //console.log(file)
        this.handleEvent(file);
      },
      removedfile: (file: any) => {
        this.deleteFile(file);
      }
    }
    return (
      <div className={'dashboardHome'}>
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}> Create Workorder </span>
          </div>
        </div>
        <div className={"searchFieldDiv"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Paper elevation={6} className={"assetPaper"} onClick={() => this._onShowPanel()}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={2} className={"AssetIconDiv"}>
                    <EmojiTransportationIcon className={"AssetIcon"} />
                  </Grid>
                  <Grid item xs={12} sm={10} className={"AssetLable"}>
                    <p className={"AssetboxTitle"}>Search Asset from List</p>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper elevation={6} className={"assetPaper"} onClick={this.SearchClicked} >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={2} className={"AssetIconDiv"}>
                    <MapIcon className={"AssetIcon"} />
                  </Grid>
                  <Grid item xs={12} sm={10} className={"AssetLable"}>
                    <p className={"AssetboxTitle"}>Search Asset from Map</p>
                  </Grid>
                </Grid>
              </Paper>

            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1}>
            {(this.state.Assets) && (
              <Grid item xs={12} sm={12}>

                <Paper elevation={6} className={"assetSelectPaper"}>
                  <span className={''}>{this.state.Assets.AssetNumber}</span> <br />
                  <span className="floatRight">
                    <DeleteIcon className={'danger'} onClick={() => this.removeAssetNumber()} />
                  </span>
                  <span className="mt-0 mb-3">{this.state.Assets.Description}</span>
                </Paper>

              </Grid>
            )}
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='Activity *'
                items={this.state.worktypeItems}
                selectedItem={this.state.woworktype}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'woworktype')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='Crew '
                items={this.state.tempCrewItems}
                selectedItem={this.state.crewteam}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'employeename')}

              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextBoxInput
                stylingMode='underlined'
                label="Location *"
                // pattern={"[A-Za-z]+"}
                // minLength={2}
                value={this.state.location}
                onValueChanged={(e: any) => this.handleValueChange(e, 'location')}

              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='Suburb *'
                selectedItem={this.state.suburb}
                items={this.state.suburbItems}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'suburb')}
              />
            </Grid>

            <Grid item xs={12} sm={6}>

              <DateBoxInput
                stylingMode='underlined'
                label="Schedule Start Date"
                type="datetime"
                displayFormat={"dd/MM/yy hh:mm a"}
                showClearButton={true}
                value={this.state.Eststartdate ? new Date(this.state.Eststartdate) : null}
                onValueChanged={(e: any) => this._ondateChanged(e, 'Eststartdate')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateBoxInput
                stylingMode='underlined'
                label="Schedule End Date"
                value={this.state.Estenddate ? new Date(this.state.Estenddate) : null}
                type="datetime"
                displayFormat={"dd/MM/yy hh:mm a"}
                showClearButton={true}
                onValueChanged={(e: any) => this._ondateChanged(e, 'Estenddate')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextBoxInput
                stylingMode='underlined'
                label="Service Request #"
                // pattern={"[A-Za-z]+"}
                // minLength={2}
                value={this.state.serviceRequest}
                onValueChanged={(e: any) => this.handleValueChange(e, 'serviceRequest')}

              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='Priority *'
                items={this.state.priorityItems}
                selectedItem={this.state.priority}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'priority')}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={1}>

                <Grid item xs={12} sm={4}>
                  <CheckBoxInput value={this.state.befpicreq} onValueChanged={(e: any) => this.handleValueChange(e, 'befpicreq')} text={'Before Photo Required'} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CheckBoxInput value={this.state.aftpicreq} onValueChanged={(e: any) => this.handleValueChange(e, 'aftpicreq')} text={'After Photo Required'} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CheckBoxInput value={this.state.riskassessment} onValueChanged={(e: any) => this.handleValueChange(e, 'riskassessment')} text={'Site Risk Assessment'} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>

                  <TextAreaInput
                    label="Comments"
                    value={this.state.comment}

                    onValueChanged={(e: any) => this._textAreaChanged(e, 'comment')}

                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Label>Upload attachment related to work order</Label>
                  <DropzoneComponent
                    eventHandlers={eventHandlers}
                    djsConfig={djsConfig}
                    config={componentConfig}
                    className="dz-message icon ion-upload"
                    action='Drop files here or click to upload.'
                  />
                  <span>Max no. of file: 5. Max file size: 5Mb</span>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv"}>
                <Button onClick={this.clearFilter} variant='outlined'>
                  Clear
                </Button>
                <Button onClick={this.createWorkOrder} variant='outlined' color="primary" className={'button'}>
                  Create
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>


        {this.renderAssetPanel()}
        {this.renderAssetMap()}
        <br />

      </div>

    );
  }



}

