
import * as React from 'react';
import { receiveMessages } from './Receive';
import global from '../../classes/global';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Badge from '@material-ui/core/Badge';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import NotificationSound from "./notificationSound.wav";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
// import NotificationSound from '../../images/notificationSound.mp3';
let dataToPassToServiceFornotification: any = {};
export default class Notification extends React.Component<any, any> {
    public interval: any;
    public audioPlayer: any;
    constructor(props: any) {
        super(props);
        this.audioPlayer = React.createRef();
        this.handleShowPopOver = this.handleShowPopOver.bind(this);
        this.handleClosePopOver = this.handleClosePopOver.bind(this);
        let notifications: any = localStorage.getItem('newWorkorders');
        this.state = {
            notifications: notifications ? notifications : 0,
            load: false
        };
    }
    public componentDidUpdate(previousProps: any, previousState: any) {
    }

    public componentDidMount() {
        this.interval = setInterval(() => this.receivedbMessages(), 120000);
    }
    public receivedbMessages() {
        let fromdate: any;
        let toDate: any;

        let LSfromdate = localStorage.getItem("fromdate");
        if (LSfromdate) {
            fromdate = new Date(LSfromdate);
        }
        else {
            fromdate = new Date();
            fromdate.setMinutes(fromdate.getMinutes() - 2);
        }

        toDate = new Date();
        dataToPassToServiceFornotification.fromDate = fromdate.toISOString();
        dataToPassToServiceFornotification.toDate = toDate.toISOString();
        utility.genericGetAPICallForList(Config.Notificationapi, 'POST', dataToPassToServiceFornotification).then((notifications: any) => {
            if (notifications) {
                localStorage.setItem("newWorkorders", notifications.length);
                localStorage.setItem("fromdate", toDate.toString());
                this.setState({ notificationsReceived: notifications, load: false });
                this.getNotification();

            }
        });
    }
    public componentWillUnmount() {
        clearInterval(this.interval);
    }
    public getNotification() {
        let oldworkordercount = 0;
        let notifications: any = localStorage.getItem('newWorkorders');
        if (localStorage.getItem('newWorkorders') !== "0" && localStorage.getItem('newWorkorders') !== null && (this.props.role === global.worxOnline_Admin || this.props.role === global.worxOnline_ContractorAdmin)) {
            this.audioPlayer.current.play();
            this.audioPlayer.current.currentTime = 0;
        }

        let oldworkorder = localStorage.getItem('oldWorkorders');
        if (notifications !== null) {
            if (Number.isNaN(oldworkorder) || oldworkorder === null) {
                oldworkordercount = parseInt(notifications);
            }
            else {
                oldworkordercount = parseInt(oldworkorder) + parseInt(notifications);
            }
        }
        localStorage.setItem("oldWorkorders", oldworkordercount.toString());
        this.setState({ notifications: oldworkordercount });
    }
    public removeDuplicates(originalArray: any, prop: any) {
        var newArray: any = [];
        var lookupObject: any = {};

        for (var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }
    public afterInit() {
        ////console.log('init');

    }


    public render() {

        return (
            <>
                {!this.props.hideIcon && (
                    <IconButton
                        aria-label="view"
                        color="inherit"
                        onClick={this.handleShowPopOver}
                    >

                        <Badge badgeContent={this.state.notifications} color="primary" >
                            <NotificationsIcon className={this.props.isMobile ? 'navIconMobile ' : 'navIcon'} />
                        </Badge>
                    </IconButton>

                )}
                {this.state.showNotificationList && this.renderShowNotificationList()}
                <audio ref={this.audioPlayer} src={NotificationSound} />
            </>
        );
    }
    public renderShowNotificationList() {
        return (
            <Popover
                id={"i"}
                open={this.state.showNotificationList}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClosePopOver}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

            >
                <>
                    <div className={'notificationHeading'}>New WO Notifications</div>
                    <div>
                        {this.state.notifications && this.state.notifications !== 0 ?
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={`${this.state.notifications} work order(s) created`}
                                    // secondary={secondary ? 'Secondary text' : null}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete">
                                            <DeleteIcon className={'danger '} onClick={() => this.deleteMsgInQueque()} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>

                            </List> :
                            <p className='alignCenter m-20'>
                                No Notification
                            </p>
                        }

                    </div>
                </>
            </Popover>
        );
    }
    public deleteMsgInQueque() {
        this.setState({ notifications: 0 })
        localStorage.removeItem('newWorkorders')
        localStorage.removeItem('oldWorkorders')

    }
    public handleShowPopOver(event: React.MouseEvent<HTMLButtonElement>) {

        this.setState({
            anchorEl: event.currentTarget,
            showNotificationList: true
        })
    }
    public handleClosePopOver(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            anchorEl: null,
            showNotificationList: false
        })
    }
}

