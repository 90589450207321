import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';
import Grid from '@material-ui/core/Grid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';


import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import capitalize from '@material-ui/core/utils/capitalize';



const UserTypeItems: any = [
    { id: 'Portal', text: 'Portal' },
    { id: 'App', text: 'App' },
    { id: 'Portal and App', text: 'Portal and App' },
];
export default class EditEmployee extends React.Component<any, any> {

    public departmentItems: any = [];
    public crewItems: any = [];
    public teamsItems: any = [];
    public userRoleItems: any = [];
    constructor(props: any) {
        super(props);

        this.saveData = this.saveData.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.crewItems = [];
        this.departmentItems = [];
        this.teamsItems = [];
        this.state = {
            disableUserrole: true
        };
    }

    public didMount() {

        this._getDepartmentData();
        this._getTeamDataAsync();
        if (this.props.details && this.props.mode === 'edit') {
            this.setState({ loading: true })
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                disableTeam: true,
                disableCrew: true,
                isRequired: false,
                hideAlertButton: false,
                mode: this.props.mode
            })
        }
        this._getCrewDataAsync();

    }
    public populateValue(details: any, mode?: any) {

        this._getUserRoleData();
        this.setState({
            id: details.id,
            title: details.title,
            firstName: details.firstName,
            lastName: details.lastName,
            loginName: details.loginName,
            employeeNo: details.employeeno,
            email: details.email,
            phone: details.phone,
            JobTitle: details.jobTitle,
            pin: details.pin,
            isActive: details.active,
            isContractor: details.contractor,
            userType: details.userType ? { id: details.userType, text: details.userType } : '',
            // userRole:this.props.details && this.props.details.roleId? { key: this.props.details.roleId, text: this.props.details.roleId }:null,
            previuosuserRoleId: this.props.details.roleId,
            hideAlertButton: false,
            mode: mode,
            showRole: details.userType !== "App" ? true : false,
            loading: false
        });


        if (this.props.role === global.worxOnline_WorxAdmin) {
            this.setState({ disableTeam: true });
        }
        else {
            this.setState({
                disableTeam: false,
                disableCrew: false,
                // tempTeamItems: filteredTeam,
                // tempCrewItems: filteredCrew
            });
        }
    }
    private _getUserRoleData() {

        utility.genericGetAPICall(Config.roleList).then((data: any) => {

            this.userRoleItems = [];

            for (let r of data) {
                this.userRoleItems.push({ id: (r.Id), text: (r.Title) });
            }
            if (this.props.details && this.props.details.roleId) {
                this.setState({
                    userRole: this.userRoleItems.find((user: any) => user.id === this.props.details.roleId)
                });
            }
            this.setState({ userRoleItems: this.userRoleItems });
        }, (err) => {
            console.log(err);
        });
    }
    private _getCrewDataAsync() {

        utility.genericGetAPICall(Config.crewlistname).then((data: any) => {

            this.crewItems = [];
            let selectedcrew = [];
            for (let r of data) {
                this.crewItems.push({ id: (r.Id), text: (r.Code + ' - ' + r.Title), title: (r.Title), departmentId: r.DepartmentID, teamId: r.TeamID });
            }
            if (this.props.mode === 'edit' && this.props.details) {
                selectedcrew = this.crewItems.filter((c: any) => c.id === this.props.details.crewId);
            }

            this.setState({ crewItems: this.crewItems, tempCrewItems: this.crewItems, crew: selectedcrew && selectedcrew.length > 0 ? { id: selectedcrew[0].id, text: selectedcrew[0].text } : '' });
        }, (err) => {
            console.log(err);
        });
    }
    private _getDepartmentData() {

        utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {

            this.departmentItems = [];
            let selecteddepartment = [];
            for (let r of data) {
                this.departmentItems.push({ id: r.Id, text: r.Title });
            }
            if (this.props.mode === 'edit' && this.props.details) {
                selecteddepartment = this.departmentItems.filter((c: any) => c.id === this.props.details.departmentid);
            }
            this.setState({
                departmentItems: this.departmentItems,
                alldepartmentItems: this.departmentItems
                , department: selecteddepartment && selecteddepartment.length > 0 ? { id: selecteddepartment[0].id, text: selecteddepartment[0].text } : ''
            });
        }, (err) => {
            console.log(err);
        });
    }
    private _getTeamDataAsync() {

        utility.genericGetAPICall(Config.TeamList).then((data: any) => {

            this.teamsItems = [];
            let filteredTeams = [];
            for (let r of data) {
                this.teamsItems.push({ id: r.Id, text: (r.Code + ' - ' + r.Title), departmentId: r.Departmentid });
            }
            if (this.props.mode === 'edit' && this.props.details) {
                filteredTeams = this.teamsItems.filter((t: any) => t.id === this.props.details.teamId);
            }
            this.setState({ teamsItems: this.teamsItems, tempTeamItems: this.teamsItems, teams: filteredTeams && filteredTeams.length > 0 ? { id: filteredTeams[0].id, text: filteredTeams[0].text } : '' });
        }, (err) => {
            console.log(err);
        });
    }

    public saveData() {
        //console.log(this.props.currentUser);
        //genericPostAPIcallForAdminList
        let dataToPassToService: any = {};

        if (!this.state.firstName) {
            this.setState({
                alertMessage: 'Please enter first name',
                showAlert: true
            });
            return;
        }
        if (!this.state.lastName) {
            this.setState({
                alertMessage: 'Please enter last name',
                showAlert: true
            });
            return;
        }
        if (!this.state.employeeNo) {
            this.setState({
                alertMessage: 'Please enter  user code',
                showAlert: true
            });
            return;
        }
        if (!this.state.loginName) {
            this.setState({
                alertMessage: 'Please enter login name',
                showAlert: true
            });
            return;
        } if (!this.state.email) {
            this.setState({
                alertMessage: 'Please enter email',
                showAlert: true
            });
            return;
        }

        if (!this.state.department) {
            this.setState({
                alertMessage: 'Please select organisation',
                showAlert: true
            });
            return;
        }
        if (!this.state.userType) {
            this.setState({
                alertMessage: 'Please select user type',
                showAlert: true
            });
            return;
        }
        if (this.state.userType && this.state.userType.id !== 'Portal') {
            if (!this.state.crew) {
                this.setState({
                    alertMessage: 'Please select crew',
                    showAlert: true
                });
                return;
            } if (!this.state.pin) {
                this.setState({
                    alertMessage: 'Please enter pin',
                    showAlert: true
                });
                return;
            }
        }

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' /> <br /> Loading...</div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.Fullname = this.state.firstName + ' ' + this.state.lastName;
        dataToPassToService.Firstname = this.state.firstName;
        dataToPassToService.Lastname = this.state.lastName;
        dataToPassToService.Resourcecode = this.state.employeeNo;
        dataToPassToService.Loginname = this.state.loginName;
        dataToPassToService.Email = this.state.email;
        dataToPassToService.Phone = this.state.phone;
        dataToPassToService.Jobtitle = this.state.JobTitle;
        dataToPassToService.Departmentid = this.state.department.id;
        dataToPassToService.Crewid = this.state.crew ? this.state.crew.id : null;
        dataToPassToService.Teamid = this.state.teams ? this.state.teams.id : null;
        dataToPassToService.Pin = this.state.pin ? this.state.pin : '';
        dataToPassToService.Usertype = this.state.userType.id;
        dataToPassToService.Isactive = this.state.isActive ? this.state.isActive : false;
        dataToPassToService.Iscontractor = this.state.isContractor ? this.state.isContractor : false;
        dataToPassToService.Tenantid = Config.tenantId;
        dataToPassToService.Userupdated = this.props.currentUser.Email;
        if (this.props.mode === 'edit') {
            dataToPassToService.Id = this.state.id;
            dataToPassToService.Dateupdated = new Date();
            utility.genericUpdateAPICall(Config.employeelistname, this.state.id, dataToPassToService).then((data: any) => {
                // console.log(data);
                if ((this.state.previuosuserRole !== this.state.userRole?.id)) {

                    this.saveUserRole();
                } else {
                    this.props.onClick();
                    this.props.message("User has been updated");
                    this.clearFilter();
                    this.setState({ showAlert: false, hideAlertButton: false, });
                }
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });

            });
        } else {
            dataToPassToService.Usercreated = this.props.currentUser.Email;
            dataToPassToService.Datecreated = new Date();
            utility.genericPostAPIcall(Config.employeelistname, dataToPassToService).then((data: any) => {
                //console.log(data);
                if ((this.state.previuosuserRole?.id !== this.state.userRole?.id)) {
                    console.log("created");
                    this.saveUserRole();
                } else {
                    this.props.onClick();
                    this.props.message("User has been created");
                    this.clearFilter();
                    this.setState({ hideAlertButton: false, showAlert: false });
                }
            }, (err: any) => {
                // console.log(err);
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
                // err.then((e)=>{
                //     console.log(e);
                //     this.setState({
                //         alertMessage: e,
                //         showAlert: true,
                //         hideAlertButton: false,
                //     });
                // });
            });
        }
        utility.removeItem(Config.employeelistname);
    }
    public saveUserRole() {
        let dataToPassToService: any = {};

        dataToPassToService.Userid = this.state.id;
        dataToPassToService.Roleid = this.state.userRole.id;
        dataToPassToService.Isactive = this.state.isActive ? this.state.isActive : false;
        dataToPassToService.Tenantid = Config.tenantId;
        dataToPassToService.Usercreated = this.props.currentUser.Email;
        //console.log("dataToPassToService", dataToPassToService);
        if (this.props.mode === 'edit') {
            dataToPassToService.Userupdated = this.props.currentUser.Email;
            utility.genericUpdateAPICall(Config.userRoleList, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.props.onClick();
                this.props.message("User has been updated");
                this.clearFilter();
                this.setState({ showAlert: false });
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: false
                });
            });
        } else {
            utility.genericPostAPIcall(Config.userRoleList, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.props.onClick();
                this.props.message("User has been created");
                this.clearFilter();
                this.setState({ showAlert: false });
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false, showAlert: false
                });
            });
        }
    }
    public clearFilter() {
        this.setState({
            firstName: '',
            lastName: '',
            employeeNo: '',
            loginName: '',
            email: '',
            department: '',
            pin: '',
            userType: '',
            phone: '',
            teams: '',
            crew: '',
            JobTitle: '',
            wbsGroup: '',
            isPlantOperator: false,
            isContractor: false,
            userRole: ''
        });
        this.props.onClose();
    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ loading: true })
        if (fieldName === "userType") {
            if (e.selectedItem.id === "Portal") {
                this.setState({
                    isReqired: false
                })
            } else {
                this.setState({
                    isReqired: true
                })
            }
            if (e.selectedItem && (e.selectedItem.id === "Portal" || e.selectedItem.id === "Portal and App")) {
                this.setState({
                    showRole: true
                })
            } else {
                this.setState({
                    showRole: false
                })
            }
            setTimeout(() => this.setState({ loading: false }), 1000);
        }
        if (fieldName === 'department') {
            let tempTeams = this.state.teamsItems;
            let filteredTeam: Array<[]> = tempTeams && tempTeams.filter((t: any) => t.departmentId === e.selectedItem?.id);

            this.setState({
                disableTeam: false,
                tempTeamItems: filteredTeam,
                alltempTeamItems: filteredTeam,
                disableCrew: true,
                tempCrewItems: [],
                teams: '',
                crew: ''
                // [fieldName]: newValue
            });
            setTimeout(() => this.setState({ loading: false }), 1000);
        }
        if (fieldName === 'teams') {
            let tempCrew = this.state.crewItems;
            let filteredCrew: any = [];
            e.selectedItem && tempCrew && tempCrew.forEach((c: any) => {
                if (c.teamId === e.selectedItem.id) {
                    filteredCrew.push(c);
                }
            });

            this.setState({
                disableCrew: false,
                tempCrewItems: filteredCrew,
                alltempCrewItems: filteredCrew,
                crew: ''
            });
            setTimeout(() => this.setState({ loading: false }), 1000);
        }
        if (fieldName === 'crew') {
            this.setState({ [fieldName]: e.selectedItem });
            setTimeout(() => this.setState({ loading: false }), 1000);

        }
        if (fieldName === 'userRole') {
            this.setState({ [fieldName]: e.selectedItem });
        }
        this.setState({ [fieldName]: e.selectedItem });

        setTimeout(() => this.setState({ loading: false }), 1000);



    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });

    }

    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                < Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.props.mode && capitalize(this.props.mode)} User`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                        <div className='popContentDiv'>
                            {!this.state.loading ?
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="First Name *"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.firstName}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'firstName')}
                                            onKeyDown={() => { }}
                                            required={true}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Last Name *"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.lastName}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'lastName')}
                                            onKeyDown={() => { }}
                                            required={true}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectBoxInput
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            showClearButton={true}
                                            items={UserTypeItems}
                                            selectedItem={this.state.userType}
                                            label='User Type *'
                                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'userType')}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="User Code *"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.employeeNo ? this.state.employeeNo : ''}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'employeeNo')}
                                            onKeyDown={() => { }}
                                            required={true}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Login Name*"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.loginName ? this.state.loginName : ''}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'loginName')}
                                            onKeyDown={() => { }}
                                            required={true}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Email*"
                                            mode="email"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.email ? this.state.email : ''}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'email')}
                                            onKeyDown={() => { }}
                                            required={true}
                                            disabled={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Phone"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.phone ? this.state.phone : ''}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'phone')}
                                            onKeyDown={() => { }}
                                            required={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectBoxInput
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            showClearButton={true}
                                            items={this.state.departmentItems}
                                            selectedItem={this.state.department}
                                            label='Organisation *'
                                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}

                                            disabled={((this.props.role === global.worxOnline_WorxAdmin || this.props.role === global.worxOnline_ContractorAdmin) && this.state.mode === 'edit') ? true : false}
                                        />
                                    </Grid>
                                    {/* {this.state.userType && this.state.userType.id !== 'Portal'?
                                <> */}
                                    <Grid item xs={12} sm={6}>
                                        <SelectBoxInput
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            disabled={this.state.disableTeam}
                                            showClearButton={true}
                                            items={this.state.tempTeamItems}
                                            selectedItem={this.state.teams}
                                            label='Teams *'
                                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'teams')}
                                        />
                                    </Grid>
                                    {this.state.tempCrewItems &&
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.tempCrewItems}
                                                disabled={this.state.disableCrew}
                                                selectedItem={this.state.crew}
                                                label='Crew *'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'crew')}

                                            // disabled={this.props.role  === global.worxOnline_Contractor ? true:this.state.disableCrew}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Pin"
                                            mode="password"
                                            required={true}
                                            value={this.state.pin}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'pin')}
                                            onKeyDown={() => { }}
                                        />
                                    </Grid>
                                    {/* </>:
                               null } */}
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Job Title"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.JobTitle}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'JobTitle')}
                                            onKeyDown={() => { }}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} disabled={true} />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <CheckBoxInput value={this.state.isContractor} disabled={((this.props.role === global.worxOnline_WorxAdmin || this.props.role === global.worxOnline_ContractorAdmin) && this.state.mode === 'edit') ? true : this.state.disableCrew} onValueChanged={(e: any) => this.handleValueChange(e, 'isContractor')} text={'Is Contractor'} />
                                    </Grid>
                                    {this.state.showRole ?
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.userRoleItems}
                                                selectedItem={this.state.userRole}
                                                label='User Role *'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'userRole')}
                                                disabled={this.props.role !== global.worxOnline_Admin ? true : false}
                                            />
                                        </Grid>
                                        : null}
                                </Grid>
                                :
                                <Spinner size="large" label="Loading all component. Please wait..." />
                            }
                        </div>
                    </ScrollView>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.saveData,
                        }}
                    />
                </Popup >
            </div >

        );
    }

}

