import * as React from 'react';
import global from '../../classes/global';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AtenLogo from '../../images/AtenLogo.png';
import AtenLogoLight from '../../images/AtenLogoLight.png';

import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';

import {
  NavLink,
  HashRouter
} from "react-router-dom";

import Navigation from "../Navigation/Navigation";
//icons
import LockIcon from '@material-ui/icons/Lock';
import ListIcon from '@material-ui/icons/List';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder'
import BuildIcon from '@material-ui/icons/Build';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import GavelIcon from '@material-ui/icons/Gavel';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PersonIcon from '@material-ui/icons/Person';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import WorkIcon from '@material-ui/icons/Work';
import WarningIcon from '@material-ui/icons/Warning';

const Theme = localStorage.getItem('theme') || 'theme-light';
export default class Home extends React.Component<any, any> {

  public itemsPerPage = 4;

  public state: any = {
  };


  constructor(props: any) {
    super(props);
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };
    this.handleManagementMenu = this.handleManagementMenu.bind(this);
    this.state = {
      openWorkManagement: false,
      openUserManagement: false,
      openRiskManagement: false,
      onHold: [],
      inProgress: [],
      completed: [],
      assigned: [],
    }
  }


  public componentDidMount() {
  }
  public handleManagementMenu(stateName: any, value: any) {
    this.setState({ [stateName]: !value })
  }
  public render(): React.ReactElement<any> {


    return (
      <div>
        <div className={"root"}>
          <Navigation
            hideDrawer={true}
            role={this.props.role}
          />
          <div className={"homeDiv"}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={this.props.role === global.worxOnline_Admin || this.props.role === global.worxOnline_ContractorAdmin || this.props.role === global.worxOnline_WorxAdmin ? 8 : 12} className={"TopDiv"}>
                <div className={"boxDiv"}>
                  <br />

                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={3}>
                      <NavLink exact className="sideMenu" to="/Dashboard">
                        <Paper className={"paper"} elevation={5} >
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                              <DashboardIcon className={"homeIcon"} />
                            </Grid>
                            <Grid item xs={12} sm={12} className={"homeLable"}>
                              <p className={"boxTitle"}>Dashboard</p>
                            </Grid>
                          </Grid>
                        </Paper>
                      </NavLink>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                      <NavLink exact className="sideMenu" to="/Searchworkorder">
                        <Paper className={"paper"} elevation={5} >
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                              <GavelIcon className={"homeIcon"} />
                            </Grid>
                            <Grid item xs={12} sm={12} className={"homeLable"}>
                              <p className={"boxTitle"}>Search Work Orders</p>
                            </Grid>
                          </Grid>
                        </Paper>
                      </NavLink>
                    </Grid>
                    {(this.props.role === global.worxOnline_Admin || this.props.role === global.worxOnline_Writer || this.props.role === global.worxOnline_ContractorAdmin || this.props.role === global.worxOnline_WorxAdmin) && (
                      <Grid item xs={6} sm={6} md={3}>
                        <NavLink exact className="sideMenu" to="/Scheduler">
                          <Paper className={"paper"} elevation={5} >
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                                <EventNoteIcon className={"homeIcon"} />
                              </Grid>
                              <Grid item xs={12} sm={12} className={"homeLable"}>
                                <p className={"boxTitle"}>Scheduler</p>
                              </Grid>
                            </Grid>
                          </Paper>
                        </NavLink>
                      </Grid>
                    )}
                    <Grid item xs={6} sm={6} md={3}>
                      <NavLink exact className="sideMenu" to="/AssetSearch">
                        <Paper className={"paper"} elevation={5} >
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                              <EmojiTransportationIcon className={"homeIcon"} />
                            </Grid>
                            <Grid item xs={12} sm={12} className={"homeLable"}>
                              <p className={"boxTitle"}>Search Assets</p>
                            </Grid>
                          </Grid>
                        </Paper>
                      </NavLink>
                    </Grid>
                    {(this.props.role === global.worxOnline_Admin || this.props.role === global.worxOnline_Writer) && (
                      <Grid item xs={6} sm={6} md={3}>
                        {this.props.role === global.worxOnline_Admin ?
                          <NavLink exact className="sideMenu" to="/SearchTimesheet">
                            <Paper className={"paper"} elevation={5} >
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                                  <AvTimerIcon className={"homeIcon"} />
                                </Grid>
                                <Grid item xs={12} sm={12} className={"homeLable"}>
                                  <p className={"boxTitle"}>Timesheets</p>
                                </Grid>
                              </Grid>
                            </Paper>
                          </NavLink>
                          :
                          <Paper className={"paperDisable"} elevation={5} >
                            <div className="lockedTileDiv"><LockIcon className="lockedTile" /></div>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                                <AvTimerIcon className={"homeIcon"} />
                              </Grid>
                              <Grid item xs={12} sm={12} className={"homeLable"}>
                                <p className={"boxTitle"}>Timesheets</p>
                              </Grid>
                            </Grid>
                          </Paper>
                        }
                      </Grid>
                    )}
                    {(this.props.role === global.worxOnline_Admin || this.props.role === global.worxOnline_Writer) && (
                      <Grid item xs={6} sm={6} md={3}>
                        {this.props.role === global.worxOnline_Admin ?
                          <NavLink exact className="sideMenu" to="/SearchPlant">
                            <Paper className={"paper"} elevation={5} >
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                                  <BuildIcon className={"homeIcon"} />
                                </Grid>
                                <Grid item xs={12} sm={12} className={"homeLable"}>
                                  <p className={"boxTitle"}>Equipment and Vehicles Usage</p>
                                </Grid>
                              </Grid>
                            </Paper>
                          </NavLink>
                          :
                          <Paper className={"paperDisable"} elevation={5} >
                            <div className="lockedTileDiv"><LockIcon className="lockedTile" /></div>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                                <BuildIcon className={"homeIcon"} />
                              </Grid>
                              <Grid item xs={12} sm={12} className={"homeLable"}>
                                <p className={"boxTitle"}>Equipment and Vehicles Usage</p>
                              </Grid>
                            </Grid>
                          </Paper>
                        }
                      </Grid>
                    )}

                    {/* <Grid item xs={6} sm={6} md={3}>
                      <NavLink exact className="sideMenu" to="/SearchMaterials">
                      <Paper className={"paper"} elevation={5} >
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                            <InboxIcon className={"homeIcon"} />
                          </Grid>
                          <Grid item xs={12} sm={12} className={"homeLable"}>
                            <p className={"boxTitle"}>Search Material</p>
                          </Grid>
                        </Grid>
                      </Paper>
                      </NavLink>
                    </Grid> */}

                    {(this.props.role === global.worxOnline_Admin || this.props.role === global.worxOnline_Writer) && (
                      <Grid item xs={6} sm={6} md={3}>
                        <NavLink exact className="sideMenu" to="/PrestartChecklist">
                          <Paper className={"paper"} elevation={5} >
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                                <PlaylistAddCheckIcon className={"homeIcon"} />
                              </Grid>
                              <Grid item xs={12} sm={12} className={"homeLable"}>
                                <p className={"boxTitle"}>Prestart Checklist</p>
                              </Grid>
                            </Grid>
                          </Paper>
                        </NavLink>
                      </Grid>
                    )}
                    {(this.props.role === global.worxOnline_Admin || this.props.role === global.worxOnline_Writer) && (
                      <Grid item xs={6} sm={6} md={3}>
                        <NavLink exact className="sideMenu" to="/InspectionForm">
                          <Paper className={"paper"} elevation={5} >
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                                <LibraryBooksIcon className={"homeIcon"} />
                              </Grid>
                              <Grid item xs={12} sm={12} className={"homeLable"}>
                                <p className={"boxTitle"}>Inspection Form</p>
                              </Grid>
                            </Grid>
                          </Paper>
                        </NavLink>
                      </Grid>
                    )}
                    {(this.props.role === global.worxOnline_Admin || this.props.role === global.worxOnline_Writer) && (
                      <Grid item xs={6} sm={6} md={3}>
                        <NavLink exact className="sideMenu" to="/PlantsInspection">
                          <Paper className={"paper"} elevation={5} >
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                                <BuildIcon className={"homeIcon"} />
                              </Grid>
                              <Grid item xs={12} sm={12} className={"homeLable"}>
                                <p className={"boxTitle"}>Equipment and Vehicles</p>
                              </Grid>
                            </Grid>
                          </Paper>
                        </NavLink>
                      </Grid>
                    )}
                    {(this.props.role === global.worxOnline_Admin || this.props.role === global.worxOnline_Writer) && (
                      <Grid item xs={6} sm={6} md={3}>
                        {/* <NavLink exact className="sideMenu" to="/SearchScheduleWork"> */}
                        <Paper className={"paperDisable"} elevation={5} >
                          <div className="lockedTileDiv"><LockIcon className="lockedTile" /></div>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                              <ScheduleIcon className={"homeIcon"} />
                            </Grid>
                            <Grid item xs={12} sm={12} className={"homeLable"}>
                              <p className={"boxTitle"}>Schedule Work</p>
                            </Grid>
                          </Grid>
                        </Paper>
                        {/* </NavLink> */}
                      </Grid>
                    )}

                    {(this.props.role === global.worxOnline_Admin || this.props.role === global.worxOnline_Writer) && (
                      <Grid item xs={6} sm={6} md={3}>
                        <Paper className={"paperDisable"} elevation={5} >
                          <div className="lockedTileDiv"><LockIcon className="lockedTile" /></div>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                              <GavelIcon className={"homeIcon"} />
                            </Grid>
                            <Grid item xs={12} sm={12} className={"homeLable"}>
                              <p className={"boxTitle"}>Create Work Order</p>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    )}

                  </Grid>

                </div>
              </Grid>
              {this.props.role === global.worxOnline_Admin ?
                <Grid item xs={12} sm={12} md={4} className={"TopDiv"}>
                  <div className={"ListDiv"}>
                    <p className={"headingAdmin"}>Admin</p>
                    <Divider />
                    <List component="nav" aria-label="main mailbox folders">
                      <ListItem button onClick={() => { this.handleManagementMenu('openUserManagement', this.state.openUserManagement) }}>
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="User Management" />
                        {this.state.openUserManagement ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={this.state.openUserManagement} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <NavLink exact to="/Employees">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Users" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/Crews">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Crews" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/Teams">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Teams" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/Departments">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Organisations" />
                            </ListItem>
                          </NavLink>
                          {/* <NavLink exact to="/Roles">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Roles" />
                            </ListItem>
                          </NavLink> */}
                        </List>
                      </Collapse>
                      <Divider />
                      <ListItem button onClick={() => { this.handleManagementMenu('openWorkManagement', this.state.openWorkManagement) }}>
                        <ListItemIcon>
                          <WorkIcon />
                        </ListItemIcon>
                        <ListItemText primary="Work Management" />
                        {this.state.openWorkManagement ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={this.state.openWorkManagement} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <NavLink exact to="/Activities">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Activities" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/WorkTypeField">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Activity Field" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/Plants">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Equipment and Vehicles" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/PlantCategory">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Plant Category" />
                            </ListItem>
                          </NavLink>

                          <NavLink exact to="/AssetQuestion">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Asset Question" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/DefectQuestion">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Defect Question" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/DefectCode">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Defect Code" />
                            </ListItem>
                          </NavLink>

                          <NavLink exact to="/Forms">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Forms" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/PrestartForms">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="PrestartForms" />
                            </ListItem>
                          </NavLink>
                        </List>
                      </Collapse>
                      <Divider />
                      {/* <ListItem button onClick={() => { this.handleManagementMenu('openRiskkManagement', this.state.openRiskkManagement) }}>
                        <ListItemIcon>
                          <WarningIcon />
                        </ListItemIcon>
                        <ListItemText primary="Risk Management" />
                        {this.state.openRiskkManagement ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={this.state.openRiskkManagement} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <NavLink exact to="/ShowListItems?page=Risks">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Risk" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/ShowListItems?page=Risk Form">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Risk Form" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/Swms">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="SWMS" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/ShowListItems?page=Risk Area">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Risk Area" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/ShowListItems?page=Risk Category">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Risk Category" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/ShowListItems?page=Risk Control Measure">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Risk Control Measure" />
                            </ListItem>
                          </NavLink>
                        </List>
                      </Collapse>
                      <Divider /> */}
                      <ListItem button onClick={() => { this.handleManagementMenu('openRiskkManagement', this.state.openRiskkManagement); }}>
                        <ListItemIcon>
                          <WarningIcon />
                        </ListItemIcon>
                        <ListItemText primary="Risk Management V2" />
                        {this.state.openRiskkManagement ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={this.state.openRiskkManagement} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <NavLink exact to="/RiskCategory">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Risk Categories" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/RiskGroup">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Risk Group" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/Risk">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Risks" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/RiskControlMeasure">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Risk Control Measure" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/RiskForm">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Risk Forms" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/Swms">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Safety Documents" />
                            </ListItem>
                          </NavLink>
                        </List>
                      </Collapse>
                      <Divider />
                      <ListItem button onClick={() => { this.handleManagementMenu('openOtherManagement', this.state.openOtherManagement) }}>
                        <ListItemIcon>
                          <ListIcon />
                        </ListItemIcon>
                        <ListItemText primary="Other Management Lists" />
                        {this.state.openOtherManagement ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={this.state.openOtherManagement} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <NavLink exact to="/Status">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Status" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/Suburbs">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Suburbs" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/Counters">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Counters" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/FailedWorkorders">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Failed Workorders" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/Priority">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Priority" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/Tcp">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="TCP" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/OnHoldReasons">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="On Hold Reasons" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/Suppliers">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Suppliers" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/IntegrationStatus">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Integration Status" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/ClearStorage">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Clear Storage" />
                            </ListItem>
                          </NavLink>

                        </List>
                      </Collapse>

                    </List>
                  </div>
                </Grid>
                : (this.props.role === global.worxOnline_Admin) ?
                  <Grid item xs={12} sm={12} md={4} className={"TopDiv"}>
                    <div className={"ListDiv"}>
                      <p className={"headingAdmin"}>Admin</p>
                      <Divider />
                      <List component="nav" aria-label="main mailbox folders">
                        <ListItem >
                          <ListItemIcon>
                            <PersonIcon />
                          </ListItemIcon>
                          <ListItemText primary="User Management" />

                        </ListItem>
                        <Divider />
                        <List component="div" disablePadding>
                          <NavLink exact to="/Employees">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Users" />
                            </ListItem>
                          </NavLink>
                          <NavLink exact to="/Crews">
                            <ListItem button className={"nested"}>
                              <ListItemIcon>
                                <StarBorder />
                              </ListItemIcon>
                              <ListItemText primary="Crews" />
                            </ListItem>
                          </NavLink>

                        </List>
                      </List>
                    </div>
                  </Grid>
                  : null}
            </Grid>
            <br />
            <br />
            <div className="footerlogo">
              <div className="atenLogoContainer">
                <h4 className="poweredBy">Powered By </h4>
                <img className="atenLogo" src={Theme === 'theme-light' ? AtenLogoLight : AtenLogo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

