import * as React from 'react';
import DateBox from 'devextreme-react/date-box';
interface Props {
    label?: string;
    stylingMode: string|any;
    placeholder?: string|any;
    value: any;
    showClearButton?: Boolean|any;
    readOnly?: Boolean|any;
    disabled?: Boolean|any;
    onValueChanged:any;
    required?:any;
    displayFormat:string;
    type:string|any;
  }
export default class DateBoxInput extends React.Component<Props, any> {

    public state = {
    };

    public render(): React.ReactElement<any> {
        return (
            <DateBox
                stylingMode={this.props.stylingMode}
                placeholder={this.props.placeholder}
                value={this.props.value}
                onValueChanged={(e:any) => this.props.onValueChanged(e)}
                showClearButton={this.props.showClearButton}
                labelMode={'floating'}
                label={this.props.label}
                displayFormat={this.props.displayFormat}
                type={this.props.type}
                readOnly={this.props.readOnly}
                disabled={this.props.disabled}
            />
        );
    }
}


