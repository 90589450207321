import * as React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import utility from '../../classes/utility';
import global from '../../classes/global';
import { Config } from '../../classes/config';

import AlertDialog from '../common/alertDialog';
import DataTable from '../common/DataTable/DataTable';
import DateBoxInput from '../common/reusableComponents/DateBox';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import TextBoxInput from '../common/reusableComponents/TextBox';
import Spinner from '../common/reusableComponents/Spinner';
import DisplayDetailsModal from '../common/DisplayDetailModal/DisplayDetailModal';

let _items: any = [];

export default class SearchPlant extends React.Component<any, any> {

  public plantItems: any = [];
  public departmentItems: any = [];
  public columns: any;

  constructor(props: any) {
    super(props);
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };

    this.plantItems = [];
    this.departmentItems = [];

    this.getPlantnumberwithcriteria = this.getPlantnumberwithcriteria.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.viewDetials = this.viewDetials.bind(this);

    this.columns = [

      {
        field: 'plantnumber',
        title: 'Equipment and Vehicles #',
        // width: 160,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'title',
        title: 'Title',
        // width: 160,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'Regonumber',
        title: 'Rego #',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'Workordernumber',
        title: 'Workorder #',
        // width: 160,
        type: 'string',
        link: 'workorderLink',
        allowHiding: true
      },
      {
        field: 'starttime',
        title: 'Start Time',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'endtime',
        title: 'End Time',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'hoursworked',
        title: 'Hours Worked',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'department',
        title: 'Organisation',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'updatedOn',
        title: 'Updated On',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created On',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
    ];
    let showHideColumns = localStorage.getItem("plantUsageColumns");
    let tempColumns;
    if (showHideColumns !== null) {
      tempColumns = JSON.parse(showHideColumns);
    } else {
      tempColumns = this.columns;
      localStorage.setItem("plantUsageColumns", JSON.stringify(this.columns));
    }
    this.state = {
      items: _items,
      columns: tempColumns,
      callAfterInit: false,
      loading: false,
      department: (this.props.role === global.worxOnline_ContractorAdmin || this.props.role === global.worxOnline_WorxAdmin) ? this.props.Department : '',
    };

  }


  public componentDidMount() {

    let tempstate = localStorage.getItem('plantUsageState')
    this._getPlanttypeData();
    this._getDepartmentData();
    if (tempstate) {
      // this.state = JSON.parse(tempstate)
      let allState = JSON.parse(tempstate);

      this.setState({
        dateCreatedfrom: allState.dateCreatedfrom ? allState.dateCreatedfrom : '',
        dateCreatedTo: allState.dateCreatedTo ? allState.dateCreatedTo : '',
        plantno: allState.plantno ? allState.plantno : '',
        plantType: allState.plantType ? allState.plantType : '',
        workorderno: allState.workorderno ? allState.workorderno : '',
        department: allState.department ? allState.department : '',


      })
      // this.setState(this.state= JSON.parse(tempstate));
      // localStorage.clear();
    }

  }

  // public componentDidUpdate() {
  //   if (this.state.plantItems <= 0) {
  //     this._getPlanttypeData();

  //   }
  // }

  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      this.getPlantnumberwithcriteria();
    }
  }
  public clearFilter() {
    this.setState({
      items: '',
      workorderno: '',
      plantType: '',
      plantno: '',
      dateCreatedfrom: '',
      dateCreatedTo: '',
      department: (this.props.role === global.worxOnline_ContractorAdmin || this.props.role === global.worxOnline_WorxAdmin) ? this.state.department : ''
    });
    localStorage.removeItem('datagridFilterValue');
  }

  public viewDetials(item: any) {
    this.setState({
      details: item,
      showModal: true
    });
  }
  public renderDetailsModal() {
    return (
      <DisplayDetailsModal
        details={this.state.details}
        columns={this.columns}
        showModal={this.state.showModal}
        onClick={() => this._closeModal()}
      />
    );
  }
  private _closeModal = (): void => {
    this.setState({ showModal: false });
  }

  private _getPlanttypeData() {

    utility.genericGetAPICall(Config.plantListName).then((data: any) => {
      //console.log(data);
      this.plantItems = [];
      for (let r of data) {
        this.plantItems.push({ id: r.Id, text: r.Title });
      }
      this.setState({
        plantItems: this.plantItems,
        allplantItems: this.plantItems
      });

    }, (err) => {
      console.log(err);
    });
  }

  private _getDepartmentData() {
    utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {

      this.departmentItems = [];

      for (let r of data) {
        this.departmentItems.push({ id: r.Id, text: r.Title });
      }
      this.departmentItems = utility._sortItems(this.departmentItems, 'text', false);
      this.setState({ departmentItems: this.departmentItems, alldepartmentItems: this.departmentItems });
    }, (err) => {
      console.log(err);
    });
  }
  public getPlantnumberwithcriteria() {
    let criteriaSelected = false;

    let dataToPassToService: any = {};

    if (this.state.workorderno) {
      dataToPassToService.WorkOrderNumber = this.state.workorderno;
      criteriaSelected = true;
    }

    if (this.state.plantType) {
      dataToPassToService.PlantId = this.state.plantType.id;
      criteriaSelected = true;
    }

    if (this.state.plantno) {
      dataToPassToService.PlantNumber = this.state.plantno;
      criteriaSelected = true;
    }
    if (this.state.techoneworkorderno) {
      dataToPassToService.PlantNumber = this.state.techoneworkorderno;
      criteriaSelected = true;
    }
    if (this.state.department) {
      dataToPassToService.DepartmentId = this.state.department.id;
      criteriaSelected = true;
    }

    if (this.state.dateCreatedTo && this.state.dateCreatedfrom) {
      let dcreatedfrom = new Date(this.state.dateCreatedfrom);
      let dcreatedTo = new Date(this.state.dateCreatedTo);
      if (dcreatedTo.getTime() < dcreatedfrom.getTime()) {
        this.setState({
          alertMessage: 'Date To should be greater than Date From',
          showAlert: true
        });
        return;
      }
    }

    if (this.state.dateCreatedfrom || this.state.dateCreatedTo) {
      let dfrom;
      let dTo;
      if (this.state.dateCreatedfrom) {
        dfrom = new Date(this.state.dateCreatedfrom).toISOString();
        dataToPassToService.UsedFrom = dfrom;
      }
      if (this.state.dateCreatedTo) {
        var extraday = new Date(this.state.dateCreatedTo);
        extraday.setDate(extraday.getDate() + 1);
        dTo = extraday.toISOString().substring(0, 10) + "T13:59:59.000Z";
        // dTo = this.state.dateCreatedTo.toISOString();
        dataToPassToService.UsedTo = dTo;
      }

      criteriaSelected = true;
    }

    if (!criteriaSelected) {
      this.setState({
        alertMessage: 'Please select search criteria',
        showAlert: true
      });
      return;
    }

    //console.log("dataToPassToService", dataToPassToService);
    this.setState({
      callAfterInit: true,
      loading: true
    });

    // utility.getWOPlantwithcriteria(this.rootWeb, Config.WOPlantslistname, dataToPassToService).then((results) => {
    utility.genericPostAPIcall(Config.WOPlantsSearchAPI, dataToPassToService).then((data: any) => {
      _items = [];
      // console.log(data);
      if (data.length === null) {
        if (_items.length <= 0) {
          this.setState({
            alertMessage: 'No results found for this criteria',
            showAlert: true
          });
        }
        this.setState({ items: _items, loading: false });
        return;
      }
      else {
        data.forEach((item: any) => {

          _items.push({
            Workordernumber: item.Wonumber,
            techoneWorkordernumber: item.Erpwonumber,
            title: item.Title,
            // planttype: item.Title,
            id: item.Id,
            plantnumber: item.Resourcecode,
            Category: item.Category,
            Regonumber: item.Regonumber,
            department: item.Department,
            Plantid: item.Plantid,
            Comments: item.Comments,
            starttime: item.Starttime ? item.Starttime : '',
            endtime: item.Endtime ? item.Endtime : '',
            viewTimesheet: '',
            hoursworked: item.Hoursworked,
            woid: item.Workorderid,
            updatedBy: item.Userupdated,
            updatedOn: item.Dateupdated ? item.Dateupdated : null,
            createdBy: item.Usercreated,
            createdOn: item.Datecreated ? item.Datecreated : null,

            // servicerequest: item.WorkOrder.ServiceRequest
          });
        });

      }
      if (_items.length <= 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });
      }
      _items = utility._sortItems(_items, 'updatedOn', true);

      this.setState({ items: _items, loading: false });
      var stateData = { ...this.state };
      localStorage.setItem('plantUsageState', JSON.stringify(stateData));
    }, (err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }

  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }

  public handleValueChange(e: any, fieldName: string) {
    this.setState({ [fieldName]: e.value });
  }

  public handleDropdownChange(e: any, fieldName: string) {
    this.setState({ [fieldName]: e.selectedItem });
  }
  public render(): React.ReactElement<any> {
    return (
      <div>
        {this.state.showModal && this.renderDetailsModal()}
        {/* {this.state.showTimeEditModal && this.renderTimeEditDetailsModal()} */}

        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}>Plant Usage</span>
          </div>
        </div>
        <div className={"searchFieldDiv"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <DateBoxInput
                stylingMode='underlined'
                type="date"
                displayFormat={"dd/MM/yy"}
                label={'Date From'}
                showClearButton={true}
                value={this.state.dateCreatedfrom ? new Date(this.state.dateCreatedfrom) : null}
                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedfrom')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <DateBoxInput
                stylingMode='underlined'
                type="date"
                displayFormat={"dd/MM/yy"}
                label={'Date To'}
                showClearButton={true}
                value={this.state.dateCreatedTo ? new Date(this.state.dateCreatedTo) : null}
                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedTo')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} className={''}>
              <TextBoxInput
                stylingMode='underlined'
                label="Equipment and Vehicles #"
                // pattern={"[A-Za-z]+"}
                // minLength={2}
                value={this.state.plantno}
                onValueChanged={(e: any) => this.handleValueChange(e, 'plantno')}
                onKeyDown={this._handleKeyDown}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <SelectBoxInput
                stylingMode='underlined'
                label='Type'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.plantItems}
                selectedItem={this.state.plantType}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'plantType')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <TextBoxInput
                stylingMode='underlined'
                label="Work Order #"
                // pattern={"[A-Za-z]+"}
                // minLength={2}
                value={this.state.workorderno}
                onValueChanged={(e: any) => this.handleValueChange(e, 'workorderno')}
                onKeyDown={this._handleKeyDown}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='Organisation'
                items={this.state.departmentItems}
                selectedItem={this.state.department}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                disabled={(this.props.role === global.worxOnline_ContractorAdmin || this.props.role === global.worxOnline_WorxAdmin) ? true : false}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv"}>
                {(this.state.itemSelected && this.state.itemSelected.length > 0) && (
                  <Button onClick={() => this.setState({ submitPlantUsageModal: true })} variant='contained' color="primary" className={'button'}>
                    Submit
                  </Button>
                )}
                <Button onClick={this.clearFilter} variant='outlined'>
                  Clear
                </Button>
                <Button onClick={this.getPlantnumberwithcriteria} variant='outlined' color="primary" className={'button'}>
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          {!this.state.loading ?
            <div className='tabStripDiv'>
              {(this.state.items && this.state.items.length > 0) && (
                <DataTable
                  style={{ width: '100%' }}
                  filteralbe={true}
                  groupable={true}
                  rows={this.state.items}
                  columns={this.state.columns}
                  fileName={'Equipment and Vehicles Usage'}
                  columnChooser={'plantUsageColumns'}
                  selectionMode={false}
                  showHideColumnMenu={true}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  viewItem={(a: any) => this.viewDetials(a)}
                  // showNewModal = {() => this._showModal()}
                  // deleteItem = {(a:any) => this.DeleteDialog(a)}
                  // editItem = {(a:any) => this.EditItemModal(a)}
                  showCommandBtn={false}
                  showViewDetailBtn={true}
                />
              )}
            </div>
            :
            // <CircularProgress className="loader" />
            <Spinner size='large' label={"Loading.."} />
          }
        </div>


      </div>
    );
  }

  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }

}

