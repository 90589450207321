import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import { TagBox } from 'devextreme-react/tag-box';
import TreeView from 'devextreme-react/tree-view';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
export default class AddEditRisk extends React.Component<any, any> {
    public riskCategoryItems: any = [];
    public swmsItems: any = [];
    public HierarchyItems: any = [];
    public treeView: any;
    public dataSource: any = [];
    public treeViewRef: any;
    constructor(props: any) {
        super(props);
        this.treeViewRef = React.createRef();
        this.HierarchyItems = [];
        this.riskCategoryItems = [];
        this.swmsItems = [];
        this.dataSource = [];
        this.AddEditRisk = this.AddEditRisk.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.treeViewSelectionChanged = this.treeViewSelectionChanged.bind(this);
        this.treeViewSelectionChanged = this.treeViewSelectionChanged.bind(this);
        this.treeViewContentReady = this.treeViewContentReady.bind(this);
        this.valueChanged = this.valueChanged.bind(this);
        this.state = {
            value: 'contains',
            isActive: true,
            Tcp: true,
            allRiskCategories: [],
            selectedItems: [],
            HierarchyLoading: true,
            swms: []
        };
    }

    public getRiskRelations() {
        const riskGroupId: any = [];
        if (this.state.seletedItems && this.state.seletedItems.length > 0) {
            this.state.seletedItems.forEach((riskCat: any) => {
                if (!riskCat.items) {
                    if (riskCat.selected) {
                        riskGroupId.push(riskCat.id);
                    }
                }

            });
        }
        return riskGroupId;
    }
    private _getRiskCategoriesData() {
        utility.genericGetAPICallForList(Config.RiskCategorieswithGroups, 'GET').then(
            (data: any) => {
                data.forEach((arr: any) => {
                    //The Treeview accepts the data in particular format. From the data
                    arr['items'] = arr['riskGroups'];
                    delete arr['riskGroups'];
                    arr['id'] = arr['id'] + '_1';
                });
                let allRiskCategories = data.filter((f: any) => f.items && f.items.length > 0);
                if (this.props.details && this.props.mode === 'edit') {
                    if (this.props.details.riskGroup && this.props.details.riskGroup.length > 0) {
                        const result = this.props.details.riskGroup.map((a: any) => a.id);

                        allRiskCategories.forEach((element: any) => {
                            if (element.items && element.items.length > 0) {
                                element.items.forEach((ChildItem: any) => {
                                    result.forEach((selectedId: any) => {
                                        if (ChildItem.id === selectedId) {
                                            element.expanded = true;
                                            ChildItem.selected = true;
                                        }
                                    });
                                });

                            }
                        });
                    } else {
                        const result = this.props.details.riskGroupId;
                        allRiskCategories.forEach((element: any) => {
                            if (element.items && element.items.length > 0) {
                                element.items.forEach((ChildItem: any) => {
                                    if (ChildItem.id === result[0]) {

                                        element.expanded = true;
                                        ChildItem.selected = true;
                                    }
                                });

                            }
                        });
                    }

                }
                this.setState({ allRiskCategories: allRiskCategories });
            }, (err: any) => {
                console.log(err);
                this.setState({ loading: false });
            });

    }

    public didMount() {
        this.setState({ hideAlertButton: false });
        this._getRiskHierarchyData();
        this._getRiskCategoriesData();
        this._getSwmsData();
        if (this.props.details && this.props.mode === 'edit') {
            this.setState({
                id: this.props.details.id,
                title: this.props.details.title,
                isActive: this.props.details.Isactive,
                Tcp: this.props.details.Tcp,
                created: this.props.details.created,
                createdBy: this.props.details.createdBy,
                swms: this.props.details.swmsid,
                loading: false
            });

        }

    }
    private _getRiskHierarchyData() {
        utility.genericGetAPICallForList(`${Config.RiskHierarchy}`, 'GET').then(
            (data: any) => {
                this.HierarchyItems = [];
                let Hierarchytext: any;

                for (let r of data) {
                    if (r.type === "risk") {
                        this.HierarchyItems.push({ id: r.id, text: r.title });
                        if (this.props.details && this.props.mode === 'edit') {
                            if (r.id === this.props.details.hierarchyId) {
                                Hierarchytext = this.props.details.hierarchyId ? { id: r.id, text: r.title } : '';
                            }
                        }
                    }

                }
                this.HierarchyItems = this._sortItems(this.HierarchyItems, 'id', false);
                this.setState({ HierarchyItems: this.HierarchyItems, allHierarchyItems: this.HierarchyItems, Hierarchy: Hierarchytext, HierarchyLoading: false });
            }, (err: any) => {
                console.log(err);
                this.setState({ HierarchyLoading: false });
            });
    }
    private _sortItems = (items: any[], sortBy: string, descending = false): any[] => {
        if (descending) {
            return items.sort((a: any, b: any) => {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        } else {
            return items.sort((a: any, b: any) => {
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                return 0;
            });
        }
    }
    private _getSwmsData() {

        utility.genericGetAPICallForList(`${Config.swmsDocumentList}`, 'GET').then((data: any) => {
            //console.log(data);
            this.swmsItems = [];

            for (let r of data) {
                this.swmsItems.push({ id: r.Id, text: r.Title });
            }
            const sortedItems = utility._sortItems(this.swmsItems, 'text', false);
            // this.dataSource = new DataSource({
            //     store: sortedItems,
            //     key: 'id'
            // });
            this.setState({
                swmsItems: sortedItems, allswmsItems: sortedItems
            });
        }, (err) => {
            console.log(err);
        });
    }
    public AddEditRisk() {
        const dataToPassToService: any = {};
        const swms: any = [];
        if (this.state.swms) {
            this.state.swms.forEach((element: any) => {
                swms.push(element);
            });
        }
        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' type={'converging-spinner'} /> <br /> Loading...</div>,
            hideAlertButton: true,
            showAlert: true
        });

        dataToPassToService.title = this.state.title;
        dataToPassToService.swmsIds = swms;
        dataToPassToService.tenantId = Config.tenantId;
        dataToPassToService.isActive = this.state.isActive;
        dataToPassToService.tcp = this.state.Tcp;
        dataToPassToService.updatedBy = this.props.currentUser.Email;
        dataToPassToService.riskGroupIds = this.getRiskRelations();
        dataToPassToService.hierarchyId = this.state.Hierarchy ? this.state.Hierarchy.id : '';
        dataToPassToService.updated = new Date();
        if (this.props.mode === 'edit') {
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            dataToPassToService.id = this.state.id;
            dataToPassToService.createdBy = this.props.details.createdBy;
            dataToPassToService.created = this.props.details.created;
            utility.genericUpdateAPICall(Config.NewRiskList, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.clearFilter();
                this.props.message("Risk has been updated");
                this.props.onClick();
                this.setState({ showAlert: false });
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: false
                });
            });
        } else {
            dataToPassToService.created = new Date();
            dataToPassToService.createdBy = this.props.currentUser.Email;
            utility.genericPostAPIcall(Config.NewRiskList, dataToPassToService).then((data: any) => {
                this.setState({
                    hideAlertButton: false,
                    showAlert: false
                });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Risk has been created");
            }, (err: any) => {
                console.log(err);
                let errorMsg = "Cannot add risk. Please try again later.";
                if (errorMsg.toLowerCase().indexOf("unique constraint violation")) {
                    errorMsg = "Cannot add risk with given title as it already exists.";
                }
                this.setState({
                    alertMessage: errorMsg,
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
    }

    public clearFilter() {
        if (this.props.mode !== 'edit') {
            this.setState({
                title: '',
                swms: [],
                Hierarchy: '',

            });
        }
        this.props.onClose();

    }
    private _fieldChanged = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });

    }
    private _checkBoxChanged = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }

    treeViewSelectionChanged(e: any) {
        this.syncSelection(e.component);
    }

    treeViewContentReady(e: any) {
        this.syncSelection(e.component);
    }

    syncSelection(treeView: any) {
        const seletedItems = treeView.getSelectedNodes()
            .map((node: any) => node.itemData);

        this.setState(() => ({ seletedItems: seletedItems }));
    }
    valueChanged(e: any) {
        this.setState({ value: e.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }

    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Dialog
                    open={this.props.showModal}
                    onClose={() => !this.props.showModal ? this.clearFilter() : () => { }}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Risk`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Title *"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.title}
                                            onValueChanged={(e: any) => this._fieldChanged(e, 'title')}

                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TagBox
                                            items={this.state.swmsItems}
                                            value={this.state.swms ? this.state.swms : ''}
                                            label="Safety Documents"
                                            showSelectionControls={true}
                                            searchEnabled={true}
                                            displayExpr="text"
                                            valueExpr="id"
                                            onValueChanged={(e: any) => this._fieldChanged(e, 'swms')}
                                            selectAllMode="allPages"
                                        />
                                    </Grid>
                                    {!this.state.HierarchyLoading &&
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.HierarchyItems}
                                                selectedItem={this.state.Hierarchy ? this.state.Hierarchy : ''}
                                                label='Hierarchy'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'Hierarchy')}

                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={3}>
                                        <br />
                                        <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this._checkBoxChanged(e, 'isActive')} text={'Active'} />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <br />
                                        <CheckBoxInput value={this.state.Tcp} onValueChanged={(e: any) => this._checkBoxChanged(e, 'Tcp')} text={'TCP'} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <div className='workTypeListContainer' >
                                            <h3>Risks Category-  Select Risk Groups</h3>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12}>
                                                    {!this.state.loading ?
                                                        <>
                                                            {(this.state.allRiskCategories && this.state.allRiskCategories.length > 0) &&
                                                                <>
                                                                    <TreeView
                                                                        id="treeview"
                                                                        ref={this.treeViewRef}
                                                                        width={600}
                                                                        height={320}
                                                                        items={this.state.allRiskCategories ? this.state.allRiskCategories : ''}
                                                                        selectNodesRecursive={true}
                                                                        selectByClick={false}
                                                                        showCheckBoxesMode={'normal'}
                                                                        selectionMode={'multiple'}
                                                                        onSelectionChanged={this.treeViewSelectionChanged}
                                                                        onContentReady={this.treeViewContentReady}
                                                                        itemRender={renderTreeViewItem}
                                                                        searchMode={this.state.value}
                                                                        searchEnabled={true}
                                                                        searchExpr={'title'}
                                                                    />

                                                                </>
                                                            }
                                                        </> :
                                                        <Spinner size='large' label="Loading..." />
                                                    }

                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.AddEditRisk()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>

            </div >
        );
    }

}
function renderTreeViewItem(item: any) {
    return `${item.title}`;
}


