import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { Divider } from '@material-ui/core';
export default class DetailsModal extends React.Component<any, any> {

    public state = {
        details: this.props.details,
        showModal: this.props.showModal
    };

    public componentDidMount() {
        ////console.log('component load');

    }

    public afterInit() {
        ////console.log('init');
        this.setState({ showModal: this.props.showModal })
    }


    public render(): React.ReactElement<any> {
        return (
            <div>

                <Popup
                    width={'60vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={'Work Order Details'}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    visible={this.state.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={() => this.setState({ showModal: false })}
                    onShowing={() => this.afterInit()}

                >
                    <ScrollView width='100%' height='100%' >
                        <div className='popContentDiv'>
                            {
                                (!this.props.details) && (
                                    <div>
                                        <h4 className="alignCenter">No data availabe.</h4>
                                        <br />
                                    </div>
                                )
                            }

                            {(this.props.details) && (
                                <div>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Work Order Number</Grid>
                                        <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.Workordernumber}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Activity Description</Grid>
                                        <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.text}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Location</Grid>
                                        <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.location}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Address</Grid>
                                        <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.address}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Suburb</Grid>
                                        <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.suburb}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Priority</Grid>
                                        <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.priority}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Contractor Status</Grid>
                                        <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.fieldstatus}</Grid>
                                    </Grid>
                                    {this.props.details.crewtitle && (
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} sm={3}>Crew</Grid>
                                            <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.crewtitle}</Grid>
                                        </Grid>
                                    )}
                                    {this.props.details.startDate && (
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} sm={3}>Schedule Date/Time</Grid>
                                            <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.startDate ? moment(this.props.details.startDate).format("DD/MM/YY h:mm a") : ''}</Grid>
                                        </Grid>
                                    )}
                                    {this.props.details.endDate && (
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} sm={3}>End Date</Grid>
                                            <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.endDate ? moment(this.props.details.endDate).format("DD/MM/YY h:mm a") : ''}</Grid>
                                        </Grid>
                                    )}
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Comments</Grid>
                                        <Grid item xs={6} sm={9}>
                                            <div className=" ms-fontWeight-semibold serReqText" dangerouslySetInnerHTML={{ __html: `${this.props.details.WOComments.replace(/\n/g, "<br />")}` }}></div></Grid>
                                    </Grid>

                                </div>
                            )}
                        </div>
                    </ScrollView>
                    <Divider />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: () => this.props.onClick(),
                        }}
                    />
                </Popup>
            </div>
        );
    }
}

