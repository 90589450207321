import * as React from 'react';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import Grid from '@material-ui/core/Grid';
import AlertDialog from '../common/alertDialog';

import { DropzoneComponent } from 'react-dropzone-component';
import Spinner from '../common/reusableComponents/Spinner';
import TextAreaInput from '../common/reusableComponents/TextArea';
import TextBoxInput from '../common/reusableComponents/TextBox';
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { Divider } from '@material-ui/core';
export default class AddAttachment extends React.Component<any, any> {

    public uploadedFiles: any = [];
    public dataURL: any = [];
    public details: any = this.props.details;
    public currentUser: any;

    constructor(props: any) {
        super(props);

        this.uploadAttachment = this.uploadAttachment.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.uploadedFiles = [];
        this.dataURL = [];
        this.currentUser = this.props.currentUser;
        this.state = {
            details: this.props.details,
        };
    }

    public componentDidMount() {
        //console.log('component load');
    }
    public didMount() {
        this.uploadedFiles = [];
        this.dataURL = [];
        this.setState({ hideAlertButton: false, uploadedFiles: [] });
    }

    public uploadAttachment() {
        let file = this.uploadedFiles;
        let WOID = this.props.workorderID;
        let accpeted;
        if (this.uploadedFiles) {
            this.uploadedFiles.forEach((item: any) => {
                if (item.file.accepted === false) {
                    accpeted = false;
                }
            });
            if (accpeted === false) {
                this.setState({
                    alertMessage: 'Selected file is not accepted. Please select another file.',
                    showAlert: true
                });
                return;
            }
        }
        if (this.uploadedFiles.length > 5) {
            this.setState({
                alertMessage: 'You cannot select more than 5 files.',
                showAlert: true
            });
            return;
        }
        if (!this.state.fileTitle) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        // if (!this.state.docType) {

        //     this.setState({
        //         alertMessage: 'Please select document type',
        //         showAlert: true
        //     });
        //     return;
        // }



        // if (!this.state.comments) {
        //     this.setState({
        //         alertMessage: 'Please enter comments',
        //         showAlert: true
        //     });
        //     return;
        // }

        if (this.uploadedFiles.length === 0) {
            this.setState({
                alertMessage: 'Please attach a document',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <Spinner label='Uploading Document(s)' />,
            hideAlertButton: true,
            showAlert: true
        });

        let d = new Date();
        let amOrPm = (d.getHours() < 12) ? "AM" : "PM";
        let hour = (d.getHours() < 12) ? d.getHours() : d.getHours() - 12;
        let date = d.getDate() + "_" + d.getMonth() + "_" + d.getFullYear() + "_" + hour + "_" + amOrPm;

        for (var i = 0; i < file.length; i++) {

            let fileExtension;
            let tempFileExt = file[i].file.name.split('.');
            tempFileExt.reverse();
            fileExtension = `.${tempFileExt[0]}`
            let fileName = tempFileExt[1];
            let tempData = this.dataURL[i].dataUrl.result.split("base64,");
            let data = tempData[1];
            let dataToPassToService: any = {};
            dataToPassToService.ID = +WOID;
            dataToPassToService.Name = fileName + "_" + this.props.details.Wonumber + "_" + date + "_" + fileExtension;
            dataToPassToService.Type = 'Document';
            dataToPassToService.Title = this.state.fileTitle;
            dataToPassToService.WorkOrderNumber = this.props.details.Wonumber;
            dataToPassToService.Comments = this.state.comments;
            dataToPassToService.Data = data;
            dataToPassToService.UploadedBy = this.props.currentUser.DisplayName;
            utility.genericPostAPIcall(Config.workorderDocumentList, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false, hideAlertButton: false, });
                this.props.updateDoc();
                this.props.message();
                this.clearFilter(true);

            }, (err: any) => {
                console.log(err);
                this.setState({ showAlert: false });
            });
        }


    }

    public handleEvent(file: any) {
        this.onDropGeneral(file);
        this.uploadedFiles.push({ file });
        this.setState({ uploadedFiles: this.uploadedFiles });
    }
    public onDropGeneral(file: any) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            this.dataURL.push({ file: file.name, dataUrl: event ? event.currentTarget : "" });

        };
    }
    public clearFilter(item: any) {
        //console.log("clear filter");
        this.setState({ comments: '', fileTitle: '', docType: '' });
        this.props.onClose(item);

    }
    public deleteFile(file: any) {
        var deletingIndex = 0;
        this.uploadedFiles.forEach((element: any, index: any) => {
            if (element.file.name === file.name) {
                deletingIndex = index;

            }
        });
        this.uploadedFiles.splice(deletingIndex, 1);
        this.dataURL.forEach((element: any, index: any) => {
            if (element.file.name === file.name) {
                deletingIndex = index;

            }
        });
        this.dataURL.splice(deletingIndex, 1);
    }

    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private _fieldChanged = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public _textAreaChanged = (e: any, fieldName: string) => {

        this.setState({ [fieldName]: e.value });
    }

    public render(): React.ReactElement<any> {
        const componentConfig = {
            showFiletypeIcon: true,
            postUrl: 'no-url'
        };
        const djsConfig = {
            autoProcessQueue: false,
            addRemoveLinks: true,
            // maxFiles: 5,
            maxFilesize: 5,
            // acceptedFiles: 'image/*',
            //   acceptedFiles: 'image/*,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/pdf,application/vnd.ms-outlook'

        }
        let myDropzone;
        const eventHandlers = {
            // accept: (file, done) => {
            //     //console.log(file);
            //     if (file.type !== "image/jpeg" && file.type !== "image/png") {
            //         done("Error! Files of this type are not accepted");
            //     }
            //     else { done(); }
            // },
            init: function (dz: any) {
                //console.log(dz)
                myDropzone = dz;
            },
            addedfile: (file: any) => {
                //console.log(file);
                this.handleEvent(file);
            },
            removedfile: (file: any) => {
                this.deleteFile(file);
            }

        }
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={'Add Attachment'}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShown={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' >
                        <div className='popContentDiv'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Title *"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.fileTitle}
                                                onValueChanged={(e: any) => this._fieldChanged(e, 'fileTitle')}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>

                                            <TextAreaInput
                                                value={this.state.comment}

                                                label="Comment"
                                                onValueChanged={(e: any) => this._textAreaChanged(e, 'comment')}

                                            />
                                        </Grid>
                                        {/*  <Grid item xs={12} sm={12}>

                                            <SelectBoxInput                                               
                                                stylingMode='underlined'
                                                label='Document Type *'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={DocType}
                                                selectedItem={this.state.docType}
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'docType')}
                                            />
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <DropzoneComponent
                                                eventHandlers={eventHandlers}
                                                djsConfig={djsConfig}
                                                config={componentConfig}
                                                className="dz-message icon ion-upload"
                                                action='Drop files here or click to upload.'
                                            />
                                            <span>Max no. of file: 5. Max file size: 5Mb</span>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </div>
                    </ScrollView>
                    <Divider />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.uploadAttachment,
                        }}
                    />
                </Popup>
            </div>

        );
    }

}
