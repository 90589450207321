import { HashRouter, Route } from 'react-router-dom';
import { CacheSwitch } from 'react-router-cache-route'
import AtenLogo from './images/AtenLogo.png';
import AtenLogoLight from './images/AtenLogoLight.png';
// main pages routing
import Home from './components/Home/Home';
import SearchTimesheet from './components/SearchTimesheet/SearchTimesheet';
import SignInOld from './components/SignIn/SignIn-old';
import Navigation from './components/Navigation/Navigation';
import Scheduler from './components/Scheduler/Scheduler';
import Profile from './components/Profile/Profile';
import Dashboard from './components/Dashboard/Dashboard';
import AccessDenied from './components/AccessDenied/AccessDenied';
import AccessRequest from './components/AccessDenied/AccessRequest';
import CreateWO from './components/CreateWorkOrder/CreateWO';
import SearchMaterials from './components/MaterialSearch/MaterialSearch';
import PrestartChecklist from './components/PrestartChecklist/PrestartChecklist';
import InspectionForm from './components/InspectionForm/InspectionForm';
import SearchPlant from './components/SearchPlant/Plantsearch';
import PlantsInspection from "./components/Plants/Plants";
import Searchworkorder from './components/Searchworkorder/Searchworkorder';
import Assetsearch from './components/AssetSearch/AssetSearch';
import SearchWODetail from './components/SearchWODetail/SearchWODetail';
import AssetDetail from "./components/SearchAssetDetail/SearchAssetDetail";
import SRDetails from "./components/SRDetails/srDetails";
import SearchPage from "./components/SearchPage/SearchPage";
import CreateScheduleWork from "./components/ScheduledWork/CreateScheduleWork";
import SearchScheduleWork from "./components/ScheduledWork/SearchScheduleWork";
import EditScheduleWork from "./components/ScheduledWork/EditScheduleWork";
import NotFound from "./components/NotFound/NotFound";

// admin pages routing
import AdminHomePage from './components/Administrator/AdminHome/AdminHome';
import AdminListBuilder from './components/Administrator/AdminListBuilder/AdminListBuilder';

import Employees from './components/Administrator/Employee/Employee';
import Crews from './components/Administrator/Crew/Crew';
import Departments from './components/Administrator/Department/Department';
import Teams from './components/Administrator/Teams/Teams';
import Roles from './components/Administrator/Role/Role';
import Activities from './components/Administrator/Activities/Activities';
import Plants from './components/Administrator/Plant/Plant';
import PlantCategory from './components/Administrator/PlantCategory/PlantCategory';
import Suburbs from './components/Administrator/Suburbs/Suburbs';
import Status from './components/Administrator/Status/Status';
import Priority from './components/Administrator/Priority/Priority';
import Forms from "./components/Administrator/Forms/Forms";
import PrestartForms from "./components/Administrator/PrestartForms/PrestartForms";

import Swms from "./components/Administrator/SWMS/swms";
import Tcp from "./components/Administrator/TCP/tcp";
import WorkTypeField from "./components/Administrator/WorkTypeFields/WorkTypeField";
import DefectCode from "./components/Administrator/DefectCodes/DefectCode";
import DefectQuestion from "./components/Administrator/DefectQuestions/DefectQuestion";
import AssetQuestion from "./components/Administrator/AssetQuestions/AssetQuestion";
// import RiskCategory from "./components/Administrator/RiskCategories/Riskcategory";
// import RiskForm from "./components/Administrator/RiskForms/RiskForm";
// import RiskArea from "./components/Administrator/RiskArea/RiskArea";
import ShowListItems from "./components/Administrator/RenderListItems/ShowListItems";
// import RiskControlMeasure from "./components/Administrator/ControlMeasures/RiskControlMeasure";
import Counters from "./components/Administrator/Counter/Counter";
// import InventoryLocation from "./components/Administrator/InventoryLocation/InventoryLocation";
import Suppliers from "./components/Administrator/Suppliers/Suppliers";
import OnHoldReasons from "./components/Administrator/OnHoldReasons/OnHoldReason";
import IntegrationStatus from "./components/Administrator/IntegrationStatus/IntegrationStatus";
import FailedWorkorders from "./components/Administrator/FailedWorkorders/FailedWorkorders";
import RiskCategory from "./components/Administrator/RiskCategories/Riskcategory";
import RiskForm from "./components/Administrator/RiskForms/RiskForm";
import RiskGroup from "./components/Administrator/RiskGroup/RiskGroup";
import Risk from "./components/Administrator/Risks/Risk";
import RiskControlMeasure from "./components/Administrator/RiskControlMeasure/RiskControlMeasure";

import ClearStorage from './components/Administrator/ClearStorage/ClearStorage';
const WorxOnline = (homeprops: any) => {
  const Theme = localStorage.getItem('theme') || 'theme-light';
  let gridWidth = (window.innerWidth - (73 + 15)) + 'px';
  if (window.document.getElementsByClassName('MuiDrawer-paper').length > 0) {

    var drawerWidth = window.document.getElementsByClassName('MuiDrawer-paper')[0].clientWidth;

    gridWidth = (window.innerWidth - (drawerWidth + 15)) + 'px';
  }

  return (
    <HashRouter>
      <CacheSwitch>
        <Route exact path="/SignInOld" render={(props: any) => (<SignInOld {...props} {...homeprops} />)} />
        <Route exact path="/" render={(props: any) => (<Home {...props} {...homeprops} />)} />
        <Route exact path="/AccessRequest" render={(props: any) => (<AccessRequest {...props} {...homeprops} />)} />
        <Route exact path="/AccessDenied" render={(props: any) => (<AccessDenied {...props} {...homeprops} />)} />
        <div className="maindiv">
          <div className={'displayFlex'}>
            <Navigation
              hideDrawer={false}
              role={homeprops.role}
            />

            <main className="content" style={{ width: gridWidth }}>
              <div className={"toolbar"}>
                {/* Main page routing */}
                <CacheSwitch>
                  <Route exact path="/SearchTimesheet" render={(props: any) => (<SearchTimesheet {...props} {...homeprops} />)} />
                  <Route exact path="/Scheduler" render={(props: any) => (<Scheduler {...props} {...homeprops} />)} />
                  <Route exact path="/Profile" render={(props: any) => (<Profile {...props} {...homeprops} />)} />
                  <Route exact path="/Dashboard" render={(props: any) => (<Dashboard {...props} {...homeprops} />)} />
                  <Route exact path="/CreateWO" render={(props: any) => (<CreateWO {...props} {...homeprops} />)} />
                  <Route exact path="/SearchMaterials" render={(props: any) => (<SearchMaterials {...props} {...homeprops} />)} />
                  <Route exact path="/PrestartChecklist" render={(props: any) => (<PrestartChecklist {...props} {...homeprops} />)} />
                  <Route exact path="/InspectionForm" render={(props: any) => (<InspectionForm {...props} {...homeprops} />)} />
                  <Route exact path="/SearchPlant" render={(props: any) => (<SearchPlant {...props} {...homeprops} />)} />
                  <Route exact path="/Searchworkorder" render={(props: any) => (<Searchworkorder {...props} {...homeprops} />)} />
                  <Route exact path="/Assetsearch" render={(props: any) => (<Assetsearch {...props} {...homeprops} />)} />
                  <Route exact path="/WorkOrderDetail" render={(props: any) => (<SearchWODetail {...props} {...homeprops} />)} />
                  <Route exact path="/AssetDetail" render={(props: any) => (<AssetDetail {...props} {...homeprops} />)} />
                  <Route exact path="/SRDetails" render={(props: any) => (<SRDetails {...props} {...homeprops} />)} />
                  <Route exact path="/SearchPage" render={(props: any) => (<SearchPage {...props} {...homeprops} />)} />
                  <Route exact path="/CreateScheduleWork" render={(props: any) => (<CreateScheduleWork {...props} {...homeprops} />)} />
                  <Route exact path="/EditScheduleWork" render={(props: any) => (<EditScheduleWork {...props} {...homeprops} />)} />
                  <Route exact path="/SearchScheduleWork" render={(props: any) => (<SearchScheduleWork {...props} {...homeprops} />)} />
                  <Route exact path="/PlantsInspection" render={(props: any) => (<PlantsInspection {...props} {...homeprops} />)} />

                  {/* Admin Pages Routing */}
                  <Route exact path="/AdminListBuilder" render={(props: any) => (<AdminListBuilder {...props} {...homeprops} />)} />
                  <Route exact path="/AdminHomePage" render={(props: any) => (<AdminHomePage {...props} {...homeprops} />)} />
                  <Route exact path="/Employees" render={(props: any) => (<Employees {...props} {...homeprops} />)} />
                  <Route exact path="/Crews" render={(props: any) => (<Crews {...props} {...homeprops} />)} />
                  <Route exact path="/Departments" render={(props: any) => (<Departments {...props} {...homeprops} />)} />
                  <Route exact path="/Teams" render={(props: any) => (<Teams {...props} {...homeprops} />)} />
                  <Route exact path="/Roles" render={(props: any) => (<Roles {...props} {...homeprops} />)} />
                  <Route exact path="/Activities" render={(props: any) => (<Activities {...props} {...homeprops} />)} />
                  <Route exact path="/Plants" render={(props: any) => (<Plants {...props} {...homeprops} />)} />
                  <Route exact path="/PlantCategory" render={(props: any) => (<PlantCategory {...props} {...homeprops} />)} />
                  <Route exact path="/Suburbs" render={(props: any) => (<Suburbs {...props} {...homeprops} />)} />
                  <Route exact path="/Status" render={(props: any) => (<Status {...props} {...homeprops} />)} />
                  <Route exact path="/Priority" render={(props: any) => (<Priority {...props} {...homeprops} />)} />
                  <Route exact path="/Forms" render={(props: any) => (<Forms {...props} {...homeprops} />)} />
                  <Route exact path="/PrestartForms" render={(props: any) => (<PrestartForms {...props} {...homeprops} />)} />
                  <Route exact path="/Tcp" render={(props: any) => (<Tcp {...props} {...homeprops} />)} />
                  <Route exact path="/Swms" render={(props: any) => (<Swms {...props} {...homeprops} />)} />
                  <Route exact path="/Counters" render={(props: any) => (<Counters {...props} {...homeprops} />)} />
                  <Route exact path="/FailedWorkorders" render={(props: any) => (<FailedWorkorders {...props} {...homeprops} />)} />

                  <Route exact path="/ClearStorage" render={(props) => (<ClearStorage {...props} {...homeprops} />)} />
                  <Route exact path="/WorkTypeField" render={(props: any) => (<WorkTypeField {...props} {...homeprops} />)} />
                  <Route exact path="/Suppliers" render={(props: any) => (<Suppliers {...props} {...homeprops} />)} />
                  <Route exact path="/OnHoldReasons" render={(props: any) => (<OnHoldReasons {...props} {...homeprops} />)} />
                  <Route exact path="/DefectCode" render={(props: any) => (<DefectCode {...props} {...homeprops} />)} />
                  <Route exact path="/DefectQuestion" render={(props: any) => (<DefectQuestion {...props} {...homeprops} />)} />
                  <Route exact path="/AssetQuestion" render={(props: any) => (<AssetQuestion {...props} {...homeprops} />)} />
                  <Route exact path="/ShowListItems" render={(props: any) => (<ShowListItems {...props} {...homeprops} />)} />
                  <Route exact path="/IntegrationStatus" render={(props: any) => (<IntegrationStatus {...props} {...homeprops} />)} />

                  <Route exact path="/RiskCategory" render={(props) => (<RiskCategory {...props} {...homeprops} />)} />
                  <Route exact path="/RiskForm" render={(props) => (<RiskForm {...props} {...homeprops} />)} />
                  <Route exact path="/Risk" render={(props) => (<Risk {...props} {...homeprops} />)} />
                  <Route exact path="/RiskControlMeasure" render={(props) => (<RiskControlMeasure {...props} {...homeprops} />)} />
                  <Route exact path="/RiskGroup" render={(props) => (<RiskGroup {...props} {...homeprops} />)} />
                  {/* 
          <Route exact path="/RiskCategory" render={(props) => (<RiskCategory {...props}/>)} />
          <Route exact path="/RiskForm" render={(props) => (<RiskForm {...props}/>)} />
          <Route exact path="/RiskArea" render={(props) => (<RiskArea {...props}/>)} />
          <Route exact path="/RiskControlMeasure" render={(props) => (<RiskControlMeasure {...props}/>)} />
          */}


                  <Route path='*' component={NotFound} />
                </CacheSwitch>
              </div>
              <div className="footerlogo">
                <div className="atenLogoContainer">
                  <h4 className="poweredBy">Powered By </h4>
                  <img className="atenLogo" src={Theme === 'theme-light' ? AtenLogoLight : AtenLogo} alt="" />
                </div>
              </div>
            </main>
          </div>
        </div>

      </CacheSwitch>
    </HashRouter>
  );

}
export default WorxOnline;