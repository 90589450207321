import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';

import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';

import Spinner from '../../common/reusableComponents/Spinner';
import AddEditForms from './AddEditForms';

let _items: any = [];

export default class Forms extends React.Component<any, any> {

  public currentUser: any;
  public columns: any;

  constructor(props: any) {
    super(props);

    this.DeleteDialog = this.DeleteDialog.bind(this);

    if (this.props.role !== global.worxOnline_Admin) {
      window.open('#/AccessDenied', '_self');
    }
    // this.currentUser = {
    //   DisplayName: props.context.pageContext.user.displayName,
    //   Email: props.context.pageContext.user.email,
    //   LoginName: props.context.pageContext.user.loginName,
    // };

    this.currentUser = this.props.currentUser;

    this.columns = [
      {
        field: 'id',
        title: 'ID',
        width: 80,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'title',
        title: 'Title',
        // width: 160,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'model',
        title: 'Model',
        width: 280,
        type: 'string',
      },
      {
        field: 'updatedOn',
        title: 'Updated On',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true,
        sortOrder: 'desc'
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created On',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
    ];
    let showHideColumns = localStorage.getItem("formsColumns");
    let tempColumns;
    if (showHideColumns !== null) {
      tempColumns = JSON.parse(showHideColumns);
    } else {
      tempColumns = this.columns;
      localStorage.setItem("formsColumns", JSON.stringify(this.columns));
    }
    this.state = {
      items: _items,
      columns: tempColumns,
    };
  }

  public componentDidMount() {
    this._getItemData();
  }

  public DeleteItem(item: any) {
    //console.log("item", item);

    this.setState({
      alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
      hideAlertButton: true,
      showAlert: true,
      showDeleteAlert: false,
    });
    utility.genericDeleteAPICall(
      Config.FormsListName,
      item
    )
      .then(
        (data: any) => {
          this.setState({
            alertMessage: "Form has been deleted.",
            showAlert: true,
            showDeleteAlert: false,
            hideAlertButton: false,
            loading: false
          });
          this._getItemData();
        },
        (err) => {
          console.log(err);
          this.setState({
            alertMessage: "Cannot delete form. Please try again later.",
            showAlert: true,
            showDeleteAlert: false,
            hideAlertButton: false,
          });

        });

  }

  // edit activty modal

  private EditItemModal(item: any) {
    //console.log("item", item);
    this.setState({ showModal: true, details: item, mode: 'edit' });
  }
  public renderNewModal() {
    return (
      <AddEditForms
        showModal={this.state.showModal}
        onClose={() => this._closeModal()}
        onClick={() => this._getItemData()}
        message={(e: any) => this.message(e)}
        currentUser={this.currentUser}
        mode={this.state.mode}
        details={this.state.details}
      />
    );
  }


  private _showModal = (): void => {
    this.setState({ showModal: true, details: null, mode: 'add' });
  };


  private _closeModal = (): void => {
    this.setState({ showModal: false });
  };

  public message(message: any) {
    //console.log(message);
    this.setState({
      alertMessage: message + " successfully",
      showAlert: true,
    });
  }

  private _getItemData() {
    this.setState({
      loading: true,
    });
    utility.genericGetAPICallForList(Config.FormsListName, 'GET')
      .then(
        (data: any) => {
          _items = [];
          //console.log(data);

          for (let r of data) {
            _items.push({
              id: r.Id,
              title: r.Title,
              name: r.Formname,
              model: r.Model,
              updatedBy: r.Userupdated,
              updatedOn: r.Dateupdated,
              createdBy: r.Usercreated,
              createdOn: r.Datecreated,

            });
          }
          _items = utility._sortItems(_items, 'updatedOn', true);

          this.setState({ items: _items, callAfterInit: true, loading: false });
          //console.log(_items);
        },
        (err) => {
          console.log(err);
          this.setState({ loading: false });
        }
      );
  }
  public setSelection = (id: any) => {
    this.setState({
      selectedRow: id
    });
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }


  public render(): React.ReactElement<any> {
    return (
      <React.Fragment>
        {this.renderNewModal()}
        {this.state.showDeleteAlert && this.DeleteAlert()}
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}> Forms </span>
          </div>
        </div>
        <div>
          {!this.state.loading ?
            <DataTable
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              rows={this.state.items}
              columns={this.state.columns}
              fileName={'Forms'}
              columnChooser={'formsColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              callAfterInit={this.state.callAfterInit}
              stopCommsAfterInit={() => this.stopCommsAfterInit()}
              loading={this.state.loading}
              allowExportSelectedData={false}
              showNewModal={() => this._showModal()}
              deleteItem={(a: any) => this.DeleteDialog(a)}
              editItem={(a: any) => this.EditItemModal(a)}
              // viewFile={(e)=>this.viewFile(e)}
              showCommandBtn={true}
              showNewBtn={true}
              hideNewBtn={this.state.showModal || this.state.showModal || this.props.hideNewBtn}
              showViewDetailBtn={false}
            />
            :
            <Spinner size='large' label="Loading.." />
          }

        </div>
      </React.Fragment>
    );
  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        hideAlertButton={this.state.hideAlertButton}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }
  public DeleteDialog(item: any) {
    this.setState({
      DeletealertMessage: 'Are you sure you want to delete?',
      showDeleteAlert: true,
      deleteItem: item
    });
  }
  public DeleteAlert() {
    return (
      <DeleteDialog
        showDialog={this.state.showDeleteAlert}
        message={this.state.DeletealertMessage}
        title={"worxOnline"}
        onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
        onOkButton={() => this.DeleteItem(this.state.deleteItem)}
        onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
      />
    );
  }
}