import * as React from 'react';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import {
  NavLink
} from "react-router-dom";

import global from "../../classes/global";
import { Config } from '../../classes/config';
import utility from '../../classes/utility';

import AlertDialog from '../common/alertDialog';
import AddAssetAttachment from '../AddAttachment/AddAssetAttachment';
import Spinner from '../common/reusableComponents/Spinner';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DataTable from '../common/DataTable/DataTable';
import LoadIndicator from 'devextreme-react/load-indicator';
import capitalize from '@material-ui/core/utils/capitalize';
import MyMapComponent from '../common/MapComponent/GoogleMapComponent';
import Geocode from "react-geocode";

let _WorkOrderItems: any[] = [];
let _scheduleWOItems: any[] = [];
let _defectsItems: any[] = [];
let _DocPhotosItems: any[] = [];

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(global.googleAPIKey);

// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
Geocode.setRegion("au");

export default class SearchAssetDetail extends React.Component<any, any> {
  public allocationReferences: any = '';
  public AssetSearchNumber: any;
  public AssetCateogry: any;
  public currentUser: any;
  public AssetType: any;
  public AssetTypeName: any;
  public Compkey: any;
  public _WOcolumns: any;
  public _scheduleWOcolumns: any;
  public _Docphotoscolumns: any;
  public _DefectsWOcolumns: any;
  public _otherDetailColumns: any;
  constructor(props: any) {
    super(props);
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };
    this.currentUser = this.props.currentUser;

    this.reloadIFrame = this.reloadIFrame.bind(this);
    this.viewdocument = this.viewdocument.bind(this);
    this.viewDefectDetials = this.viewDefectDetials.bind(this);


    _WorkOrderItems = [];
    _scheduleWOItems = [];
    _defectsItems = [];
    _DocPhotosItems = [];
    this._WOcolumns = [
      {
        field: 'Workordernumber',
        title: 'Work Order #',
        // width: 160,
        type: 'string',
        allowHiding: false,
        link: 'workorderLink',
      },
      {
        field: 'worktype',
        title: 'Activity',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'scope',
        title: 'Scope',
        // width: 160,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'priority',
        title: 'Priority',
        // width: 110,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'crew',
        title: 'Crew',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'WOStatus',
        title: 'Status',
        // width: 120,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'ContractorStatus',
        title: 'Contractor Status',
        // width: 120,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'servicerequest',
        title: 'Service Req #',
        width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'location',
        title: 'Location',
        // width: 180,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'suburb',
        title: 'Suburb',
        // width: 160,
        type: 'string',
        allowHiding: true
      },

      {
        field: 'eststartdate',
        title: 'Scheduled On',
        width: 180,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'createdon',
        title: 'Created On',
        width: 180,
        type: 'datetime',
        allowHiding: true
      },
    ];
    this._scheduleWOcolumns = [

      {
        field: 'ScheduleID',
        title: 'Schedule ID',
        width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'ScheduleType',
        title: 'Schedule Type',
        width: 250,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'ActivityDescription',
        title: 'Activity',
        width: 500,
        type: 'string',
        allowHiding: true
      },

      {
        field: 'scope',
        title: 'Scope',
        width: 250,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'Interval',
        title: 'Interval',
        width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'ScheduledDateTime',
        title: 'Scheduled On',
        width: 250,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'ExpireDate',
        title: 'Expire Date',
        width: 100,
        type: 'datetime',
        allowHiding: true
      },
      // {
      //   field: 'updateBy',
      //   title: 'Update By',
      //   width: 180,
      //   type: 'string',
      //   allowHiding: true
      // },
      // {
      //   field: 'updatedOn',
      //   title: 'Updated On',
      //   width: 180,
      //   type: 'datetime',
      //   allowHiding: true
      // },
    ]

    this._Docphotoscolumns = [
      {
        field: 'id',
        title: 'ID',
        // width: 160,
        type: 'string'
      },
      {
        field: 'document',
        title: 'Document',
        // width: 160,
        type: 'string',
        link: 'file',
      },
      {
        field: 'comments',
        title: 'Comments',
        // width: 160,
        type: 'string'
      },
      {
        field: 'uploadedon',
        title: 'Uploaded On',
        // width: 160,
        type: 'datetime'
      },
      {
        field: 'uploadedby',
        title: 'Uploaded By',
        // width: 160,
        type: 'string'
      },
    ];
    this._DefectsWOcolumns = [
      {
        field: 'defectsCode',
        title: 'Defect Code',
        // width: 160,
        type: 'string'
      },
      {
        field: 'description',
        title: 'Defect',
        // width: 160,
        type: 'string'
      },
      {
        field: 'workOrderNo',
        title: 'Work Order #',
        // width: 160,
        type: 'string'
      },
      {
        field: 'created',
        title: 'Created On',
        width: 160,
        type: 'datetime'
      },

    ];
    this.state = {
      WOItems: [],
      WOcolumns: this._WOcolumns,
      WOCount: 0,
      scheduleWPColumns: this._scheduleWOcolumns,
      scheduleWOItems: [],
      scheduleWOCount: 0,
      AssetWOItems: [],
      AssetWOcolumns: [],
      AssetWOCount: 0,
      DocPhotosItems: [],
      DocPhotoscolumns: [],
      DocPhotosCount: 0,
      defectsItems: [],
      otherDetailsItems: [],
      otherDetailsColumns: [],
      otherDeatilsCount: 0,
      iFrameUrl: global.AssetMapURL,
      selected: 0,
      selectedTopTab: 0
    };

    ////console.log(this.props.location.query.CusID);
  }

  public componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let assetno = params.get('ASSETNO');
    let assetType = params.get('type');
    let assetTypeName = params.get('assetType');
    let compkey = params.get('compkey');
    this.AssetSearchNumber = assetno;
    this.AssetType = assetType;
    this.Compkey = compkey;
    this.AssetTypeName = assetTypeName;
    this.allocationReferences = '';

    this.loadAssetDetails();
    this.getMaintenanceSchedule();
  }

  public reloadIFrame() {
    window.addEventListener('message', this.handleIframeTaskfordetail);

  }
  public handleIframeTaskfordetail = (e: any) => {


    if (e.data.command === 'asset selected') {
      let mapProps: any = JSON.parse(e.data.properties);
      //console.log("detail page");
      //     // this.props.selectAssetsforParent(assetData);
      let AssetDetail = Config.AssetdetailURL + mapProps.ref;
      //console.log("AssetDetail page", AssetDetail);
      window.removeEventListener("message", this.handleIframeTaskfordetail, false);
      window.open(AssetDetail, "_self");
      // location.reload();
      window.removeEventListener("message", this.handleIframeTaskfordetail, false);

    }


  };
  public getLatlong(place: any) {
    let address = place.toLowerCase();
    if (address.includes('street')) {
      address = address.replace("street", "st");
    }

    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;

        if (lat && lng) {
          let marker = { lat: lat, lng: lng };
          this.setState({ marker: marker });
        }
      },
      (error) => {
        console.error(error);
      }
    );

  }
  public loadAssetDetails() {
    this.setState({ loadingAsset: true });
    let dataToPassToService: any = {};
    dataToPassToService.Compkey = this.Compkey;


    utility.genericPostAPIcall(Config.AssetSearchAPIForDetail, dataToPassToService).then((data: any) => {

      let otherDetails: any = [];
      let AssetPhoto: any;
      let marker: any;
      if (data.length > 0) {
        data.forEach((item: any) => {
          let ParentAsset = item.ParentAsset ? JSON.parse(item.ParentAsset) : '';
          // console.log({ParentAsset})
          let parentAssetLink = `/AssetDetail?ASSETNO=${ParentAsset?.parent ? ParentAsset?.parent?.ID : ParentAsset?.ID}&compkey=${ParentAsset?.parent ? ParentAsset?.parent?.AssetKey : ParentAsset?.AssetKey}&type=`;
          let siteAssetLink = `/AssetDetail?ASSETNO=${ParentAsset?.site ? ParentAsset?.site?.ID : ParentAsset.ID}&compkey=${ParentAsset?.site ? ParentAsset?.site?.AssetKey : ParentAsset?.AssetKey}&type=`;

          marker = item.Latlong ? { lat: item.Latlong.Y, lng: item.Latlong.X } : null;
          if (item.AssetPhoto) {
            let tempUrl = item.AssetPhoto.split('"');

            tempUrl.forEach((item: any) => {
              let url = item.includes('http');
              if (url) {
                AssetPhoto = item
              }
            });
          }
          let group = item.Group?.replace("|", ' - ');
          let System = item.System?.replace("|", ' - ');
          let AttributeInfo = item.AttributeInfo ? JSON.parse(item.AttributeInfo) : '';
          otherDetails.push({
            id: item.Id,
            siteId: ParentAsset ? ParentAsset.site?.ID : ParentAsset?.ID,
            parentId: ParentAsset ? ParentAsset.parent?.ID : item.ParentAssetId,
            installationDate: item.InstallationDate,
            material: item.Material,
            type: item.TypeName,
            unitSubType: item.UnitSubType ? item.UnitSubTypeCode + " - " + item.UnitSubType : '',
            modelNo: item.ModelNo,
            serialNo: item.SerialNo,
            compkey: item.CompKey,
            location: item.Location,
            suburb: item.SuburbCode + " - " + item.SuburbTitle,
            group: group ? group : '',
            system: System ? System : '',
            length: AttributeInfo && AttributeInfo.Length ? AttributeInfo.Length + ' ' + AttributeInfo.LengthUOM : '',
            diameter: AttributeInfo && AttributeInfo.Diameter ? AttributeInfo.Diameter + ' ' + AttributeInfo.DiameterUOM : '',
            manufacturer: AttributeInfo ? AttributeInfo.Manufacturer : '',
          });
          this.setState({
            parentAssetLink: parentAssetLink,
            siteAssetLink: siteAssetLink,
            marker: marker
          })
          this.getAssetDocument(data[0].Id);
          this.getDefects(data[0].Id);
        });
        let address;
        // let tempAddressComp = JSON.parse(data[0].AddressComponents);
        let tempAddressComp = data[0].AddressComponents;
        address = tempAddressComp ? `${tempAddressComp.HouseNumber} ${tempAddressComp.Lot} ${tempAddressComp.StreetName}, ${tempAddressComp.Suburb} ${tempAddressComp.State} ${tempAddressComp.PostalCode}` : data[0].Address
        address = tempAddressComp ? `${tempAddressComp.Lot} ${tempAddressComp.HouseNumber} ${tempAddressComp.StreetName !== 'NA' ? tempAddressComp.StreetName : ''} ${tempAddressComp.StreetType && tempAddressComp.StreetType.Description ? capitalize(tempAddressComp.StreetType.Description) + ',' : ''} ${tempAddressComp.Suburb !== 'NA' ? tempAddressComp.Suburb : ''} ${(tempAddressComp.State !== 'NA' && tempAddressComp.State) ? tempAddressComp.State.toUpperCase() : ''} ${tempAddressComp.PostalCode}` : data[0].Address
        if ((marker !== null && marker.lat === 0) || marker === null) {
          this.getLatlong(address.trim());
        } else {
          this.setState({
            marker: marker
          });
        }
        this.setState({
          AssetPhoto: AssetPhoto,
          assetdetails: data,
          otherDetailsItems: otherDetails,
          otherDetailsColumns: this._otherDetailColumns,
          loadingAsset: false,
          address: address
        })
      }

      this.getworkorderFromAsset();
      // });
    }, (err: any) => {
      console.log(err);
      this.setState({ assetdetails: [], loadingAsset: false });
    });




  }
  public getworkorderFromAsset() {
    let dataToPassToServiceforWO: any = {};
    //AssetSearchID
    this.setState({ loadingWO: true });
    dataToPassToServiceforWO.CompKey = this.Compkey;
    utility.genericPostAPIcall(Config.WOdetailsSearchAPI, dataToPassToServiceforWO).then((workorder: any) => {
      _WorkOrderItems = [];
      // console.log(workorder);
      if (workorder.length !== null || workorder.length !== 0) {
        workorder.forEach((item: any) => {
          _WorkOrderItems.push({
            id: item.Id,
            Workordernumber: item.Wonumber,
            WOStatus: item.Workorderstatus,
            worktype: item.Worktypename,
            createdon: item.Datecreated,
            ContractorStatus: item.Contractorstatus,
            eststartdate: item.Datescheduledstart ? item.Datescheduledstart : null,
            completedDate: item.Datecompleted,
            comments: item.Description,
            title: item.Title,
            priority: item.Priority,
            crew: item.Crewtitle,
            suburb: item.Suburb,
            location: item.Location,
            department: item.Department,
            servicerequest: item.Servicerequestid && item.Servicerequestid > 1 ? item.Servicerequestid : '',
            woid: item.Id,
            scope: item.Scope

          });
        });
        _WorkOrderItems = utility._sortItems(_WorkOrderItems, 'createdon', true);
        this.setState({
          WOCount: _WorkOrderItems.length,
          WOItems: _WorkOrderItems,
          WOcolumns: this._WOcolumns,
          loadingWO: false
        });
      } else {
        this.setState({
          WOCount: _WorkOrderItems.length,
          WOItems: _WorkOrderItems,
          WOcolumns: this._WOcolumns,
          loadingWO: false,
          callAfterInit: true
        });
      }
    }, (err: any) => {
      console.log(err);
      this.setState({ loadingWO: false });
    });

  }
  public getMaintenanceSchedule() {
    this.setState({ scheduleLoading: true });
    let assetID = `?AssetID=${this.Compkey}`;
    utility.genericGetDataByIdAPICall(Config.MaintenanceSchedule, assetID).then((items: any) => {
      //moment(item.Datecreated).format("DD/MM/YYYY h:mm a")
      _scheduleWOItems = [];
      items.forEach((item: any) => {
        _scheduleWOItems.push({
          id: item.ScheduleKey,
          ScheduleID: item.ScheduleKey ? item.ScheduleKey : "",
          ScheduleType: item.ScheduleType ? item.ScheduleType : "",
          IntervalUnitOfMeasure: item.IntervalUnitOfMeasure,
          TimeInterval: item.TimeInterval,
          Interval: item.TimeInterval + " " + item.IntervalUnitOfMeasure,
          ScheduledDateTime: this.validateDate(item.ScheduledDateTime),
          ExpireDate: this.validateDate(item.ExpireDate),
          updateBy: item.lastModifiedBy,
          updatedOn: this.validateDate(item.lastModifiedDateTime),
          scope: item.Scope ? item.Scope : "",
          ActivityDescription: item.Activity
        })
      });

      this.setState({
        scheduleWOCount: _scheduleWOItems.length,
        scheduleWOItems: _scheduleWOItems,
        scheduleWOcolumns: this._scheduleWOcolumns,
        scheduleLoading: false,
        callAfterInit: true
      });
    }, (err: any) => {
      this.setState({ scheduleLoading: false })
    });
  }

  public getDefects(id: any) {
    this.setState({ defectLoading: true })
    let dataToPassToService: any = {};
    dataToPassToService.AssetId = id;
    utility.genericPostAPIcall(Config.SearchDefect, dataToPassToService).then((defects: any) => {
      _defectsItems = [];
      //console.log("defects", defects);
      if (defects.length !== null || defects.length !== 0) {
        defects.forEach((item: any) => {
          _defectsItems.push({
            id: item.Id,
            defectsCode: item.DefectCode,
            workOrderNo: item.WorkOrderNumber,
            description: item.DefectTitle,
            WorkOrder: item.WorkOrder,
            defectQuestions: item.DefectQuestions,
            created: item.DateCreated,
          });
        });
        _defectsItems = utility._sortItems(_defectsItems, 'createdon', true);
        this.setState({
          defectsCount: defects.length,
          defectsItems: _defectsItems,
          defectsColumns: this._DefectsWOcolumns,
          defectLoading: false,
          callAfterInit: true
        });
      }
    }, (err: any) => {
      this.setState({ defectLoading: false })
    });
  }
  public getAssetDocument(id: any) {
    //console.log("get ASset Doc");
    //console.log("AssetSearch id", id);
    this.setState({ documentLoading: true });
    utility.genericGetDataByIdAPICall(Config.assetDocumentList, id).then((assetdoc: any) => {
      //console.log("asset Doc", assetdoc);
      _DocPhotosItems = [];
      if (assetdoc.length !== null || assetdoc.length !== 0) {
        assetdoc.forEach((item: any) => {
          _DocPhotosItems.push({
            document: item.Name ? item.Name : '',
            comments: item.Comments,
            uploadedon: item.CreatedOn ? item.CreatedOn : '',
            id: item.ID ? item.ID : ' ',
            uploadedby: item.UploadedBy ? item.UploadedBy : '',
            fileURL: item.FileURL ? item.FileURL : ''
          });
        });
        _DocPhotosItems = utility._sortItems(_DocPhotosItems, 'uploadedon', true);
        this.setState({
          DocPhotosCount: assetdoc.length,
          DocPhotosItems: _DocPhotosItems,
          DocPhotoscolumns: this._Docphotoscolumns,
          documentLoading: false,
          callAfterInit: true
        });
      }
    }, (err: any) => {
      this.setState({ documentLoading: false })
    });
  }
  public validateDate(date: any) {
    let d = '';
    let validDateFrom = new Date("1950-01-01")

    if (date) {
      let tempdate = new Date(date);
      if (tempdate.getTime() > validDateFrom.getTime()) {
        d = date;
      }
    }
    return d
  }

  private alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }
  public viewdocument(a: any) {
    let Asset = a;
    //console.log("Document", Asset);

    let dataToPassToService: any = {};
    if (!utility.validURL(Asset.fileURL) || !Asset.document) {
      this.setState({
        alertMessage: 'Unable to view this document. \n File URL or File name does exist',
        showAlert: true
      });
      return;
    }
    dataToPassToService.Name = Asset.document;
    dataToPassToService.FileURL = Asset.fileURL;
    //console.log("dataToPassToServiceForRWOusingId", dataToPassToService);
    //console.log("dataToPassToServiceForRWOusingId", JSON.stringify(dataToPassToService));
    utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {
      //console.log("doc", doc);
      window.open(doc, "_blank");
    });

  }
  public viewDefectDetials() {
    let selected;
    //console.log(selected);
    this.setState({
      details: selected,
      showDefectModal: true
    });
  }
  public _closeDefectModal = (): void => {
    this.setState({
      showDefectModal: false
    });
  }
  public renderDefectdetailModal() {
    // return (
    //   <DefectDetailModal
    //     showModal={this.state.showDefectModal}
    //     onClick={() => this._closeDefectModal()}
    //     details={this.state.details}
    //   />
    // );
  }

  private _showModalofAttachment = (): void => {
    //console.log(this.state.assetdetails.Id);
    this.setState({
      showModalofAttachment: true
    });
  }
  private _closeModalofAttachment = (item: any): void => {
    //console.log(item);
    this.setState({
      showModalofAttachment: false
    });
    if (item === true) {
      this.setState({
        alertMessage: 'Attachment(s) uploaded successfully.',
        showAlert: true,
      });
    }
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }

  public handleSelect = (event: any, newValue: any) => {
    this.setState({ selected: newValue });
  }
  public handleSelectTopTab = (event: any, newValue: any) => {
    this.setState({ selectedTopTab: newValue });
  }
  public renderAttachmentModal() {
    return (
      <AddAssetAttachment
        showModal={this.state.showModalofAttachment}
        onClose={(item: any) => this._closeModalofAttachment(item)}
        AssetId={this.state.assetdetails ? this.state.assetdetails.Id : ""}
        updateDoc={() => setTimeout(this.getAssetDocument.bind(this), 2000)}
        details={this.state.assetdetails}
        currentUser={this.currentUser}
        context={this.props.context}
        message={() => this.message()}
      />
    );
  }
  public message() {
    this.setState({
      alertMessage: 'Asset document has been added',
      showAlert: true
    });
  }
  public loaderTab(title: any, items: any, counts: any, isLoading: any) {
    if (isLoading) {
      return <span className='tabHeading'>{title} <LoadIndicator id="small-indicator" height={15} width={15} /></span>
    } else {
      return <span className='tabHeading'>{title} ({counts})</span>
    }
  }
  public render(): React.ReactElement<any> {
    const {
      WOItems, WOcolumns, scheduleWOItems, scheduleWOcolumns,
      DocPhotoscolumns, DocPhotosItems,
      defectsItems, defectsColumns,
      otherDetailsItems, } = this.state;
    return (
      <div>
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}>
              Asset Details
            </span>
          </div>
        </div>
        {!this.state.assetdetails ?
          <div>
            <Spinner size='large' label="Loading" />
            <br />
          </div> :
          !this.state.assetdetails && this.state.assetdetails.length < 0 ?
            <div className="alignCenter p-l-r-20">
              <br />
              {/* <Spinner label='Please wait while we load asset details...' /> */}
              <p>Asset detail not found</p>
              <br />
            </div> :
            null
        }


        <div className={"detailFieldDiv"}>
          {this.state.assetdetails && this.state.assetdetails.length > 0 &&
            (<Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Asset #:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetdetails[0].AssetNumber}</Grid>
                </Grid>
                {this.state.assetdetails[0].UnitID2Asset && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Unit ID 2:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetdetails[0].UnitID2Asset}</Grid>
                  </Grid>
                )}
                {this.state.assetdetails[0].ParallelLineNumber && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Parallel Line #:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetdetails[0].ParallelLineNumber}</Grid>
                  </Grid>
                )}
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Description:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetdetails[0].Desc}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Asset Status:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetdetails[0].AssetStatus}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Unit Type:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetdetails[0].UnitTypeCode + " - " + this.state.assetdetails[0].UnitType}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Address Qualifier:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetdetails[0].Address}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Address:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.address}</Grid>
                </Grid>
                {/* <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Suburb:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetdetails.Suburb}</Grid>
                </Grid> */}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Paper square>

                  <Tabs
                    value={this.state.selectedTopTab}
                    onChange={this.handleSelectTopTab}
                    indicatorColor="primary"
                    // textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label={<span className='tabHeading'>Map</span>} {...a11yPropsTop(0)} />
                    <Tab label={<span className='tabHeading'>Photo</span>}  {...a11yPropsTop(1)} />
                  </Tabs>
                </Paper>

                <TabPanel value={this.state.selectedTopTab} index={0}>

                  {this.state.marker && this.state.marker.lat !== 0 ?
                    <MyMapComponent
                      singleMarkers={this.state.marker}
                      center={this.state.marker}
                      useStyle={this.state.useStyle}
                      iconColor={'33cc33'}
                      zoom={15}
                      height="35vh"
                      showMarker={true}

                    /> :
                    <h3>No Position Data</h3>}
                </TabPanel>
                <TabPanel value={this.state.selectedTopTab} index={1}>
                  {this.state.AssetPhoto ?
                    <a href={this.state.AssetPhoto} target="_blank" rel="noreferrer">
                      <img className="assetImagePivot" src={this.state.AssetPhoto} />
                    </a> :
                    <h3>No Photo Found</h3>}
                </TabPanel>
              </Grid>
            </Grid>
            )}
        </div>

        <br />
        <br />
        <br />
        <div className={'tabsDiv'}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
              <Paper square>

                <Tabs
                  value={this.state.selected}
                  onChange={this.handleSelect}
                  indicatorColor="primary"
                  // textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label={this.loaderTab('Workorders ', WOItems, WOItems.length, this.state.loadingWO)} {...a11yProps(0)} />
                  <Tab label={this.loaderTab('Maintenance and Inspection', scheduleWOItems, scheduleWOItems.length, this.state.scheduleLoading)} {...a11yProps(1)} />
                  <Tab label={this.loaderTab('Documents', DocPhotosItems, DocPhotosItems.length, this.state.documentLoading)} {...a11yProps(2)} />
                  <Tab disabled={this.props.role !== global.worxOnline_Admin} label={this.loaderTab('Defects', defectsItems, defectsItems.length, this.state.defectLoading)} {...a11yProps(3)} />
                  <Tab label={<span className='tabHeading'>Extra Detail</span>}  {...a11yProps(4)} />
                </Tabs>
              </Paper>
            </Grid>
          </Grid>
          <TabPanel value={this.state.selected} index={0}>
            {!this.state.loading ?
              <>
                {(WOItems && WOItems.length > 0) && (
                  <div className="tabStripDiv">

                    <DataTable
                      style={{ width: '100%' }}
                      filteralbe={false}
                      groupable={false}
                      rows={WOItems}
                      columns={WOcolumns}
                      // fileName={'Timesheet'}
                      // columnChooser={'prestartchecklistColumns'}
                      selectionMode={false}
                      showHideColumnMenu={false}
                      callAfterInit={this.state.callAfterInit}
                      stopCommsAfterInit={() => this.stopCommsAfterInit()}
                      loading={this.state.loading}
                      allowExportSelectedData={false}
                      showNewBtn={false}
                      hideExport={true}
                      hideSearchPanel={true}
                      // showNewModal = {() => this._showModal()}
                      // deleteItem = {(a:any) => this.DeleteDialog(a)}
                      // editItem = {(a:any) => this.EditItemModal(a)}
                      // viewFile={(e)=>this.viewFile(e)}
                      showCommandBtn={false}
                      showViewDetailBtn={false}
                    />
                  </div>
                )}
              </>
              :
              // <CircularProgress className="loader" />
              <Spinner size='large' label="Loading" />
            }
          </TabPanel>
          <TabPanel value={this.state.selected} index={1}>
            {(scheduleWOItems && scheduleWOItems.length > 0) && (
              <div className="tabStripDiv">
                <DataTable
                  style={{ width: '100%' }}
                  filteralbe={false}
                  groupable={false}
                  rows={scheduleWOItems}
                  columns={scheduleWOcolumns}
                  // fileName={'Timesheet'}
                  // columnChooser={'prestartchecklistColumns'}
                  selectionMode={false}
                  showHideColumnMenu={false}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  hideExport={true}
                  hideSearchPanel={true}
                  // showNewModal = {() => this._showModal()}
                  // deleteItem = {(a:any) => this.DeleteDialog(a)}
                  // editItem = {(a:any) => this.EditItemModal(a)}
                  // viewFile={(e)=>this.viewFile(e)}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              </div>
            )}
          </TabPanel>

          <TabPanel value={this.state.selected} index={2}>
            {(DocPhotosItems && DocPhotosItems.length > 0) && (
              <div className="tabStripDiv">
                <DataTable
                  style={{ width: '100%' }}
                  filteralbe={false}
                  groupable={false}
                  rows={DocPhotosItems}
                  columns={DocPhotoscolumns}
                  // fileName={'Timesheet'}
                  // columnChooser={'prestartchecklistColumns'}
                  selectionMode={false}
                  showHideColumnMenu={false}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  hideExport={true}
                  hideSearchPanel={true}
                  // showNewModal = {() => this._showModal()}
                  // deleteItem = {(a:any) => this.DeleteDialog(a)}
                  // editItem = {(a:any) => this.EditItemModal(a)}
                  // viewFile={(e)=>this.viewFile(e)}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                  viewFile={(e: any) => this.viewdocument(e)}
                />
              </div>
            )}
          </TabPanel>
          {this.props.role === global.worxOnline_Admin && (
            <TabPanel value={this.state.selected} index={3}>
              {(defectsItems && defectsItems.length > 0) && (
                <div className="tabStripDiv">
                  <DataTable
                    style={{ width: '100%' }}
                    filteralbe={false}
                    groupable={false}
                    rows={defectsItems}
                    columns={defectsColumns}
                    // fileName={'Timesheet'}
                    // columnChooser={'prestartchecklistColumns'}
                    selectionMode={false}
                    showHideColumnMenu={false}
                    callAfterInit={this.state.callAfterInit}
                    stopCommsAfterInit={() => this.stopCommsAfterInit()}
                    loading={this.state.loading}
                    allowExportSelectedData={false}
                    showNewBtn={false}
                    hideExport={true}
                    hideSearchPanel={true}
                    // showNewModal = {() => this._showModal()}
                    // deleteItem = {(a:any) => this.DeleteDialog(a)}
                    // editItem = {(a:any) => this.EditItemModal(a)}
                    // viewFile={(e)=>this.viewFile(e)}
                    showCommandBtn={false}
                    showViewDetailBtn={false}
                  />
                </div>
              )}
            </TabPanel>
          )}
          <TabPanel value={this.state.selected} index={4}>
            <div className='p-20'>
              {(otherDetailsItems && otherDetailsItems.length > 0) && (

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Parent Id:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>
                        <NavLink to={this.state.parentAssetLink} className={"WOlink"} target="_blank">
                          {this.state.otherDetailsItems[0].parentId}
                        </NavLink>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Site Id:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>
                        <NavLink to={this.state.siteAssetLink} className={"WOlink"} target="_blank">
                          {this.state.otherDetailsItems[0].siteId}
                        </NavLink>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Compkey:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.otherDetailsItems[0].compkey ? this.state.otherDetailsItems[0].compkey : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Asset Type:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.otherDetailsItems[0].type ? this.state.otherDetailsItems[0].type : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Unit Sub Type:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.otherDetailsItems[0].unitSubType ? this.state.otherDetailsItems[0].unitSubType : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Installation Date:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>
                        {this.state.otherDetailsItems[0].installationDate ?
                          moment(this.state.otherDetailsItems[0].installationDate).format("DD/MM/YY h:mm a") : null}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    {/* <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>Material:</Grid>
                        <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.otherDetailsItems[0].material}</Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>Model No:</Grid>
                        <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.otherDetailsItems[0].modelNo}</Grid>
                      </Grid> */}
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Length:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.otherDetailsItems[0].length ? this.state.otherDetailsItems[0].length : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Diameter:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.otherDetailsItems[0].diameter ? this.state.otherDetailsItems[0].diameter : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Manufacturer:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.otherDetailsItems[0].manufacturer ? this.state.otherDetailsItems[0].manufacturer : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Serial No:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.otherDetailsItems[0].serialNo ? this.state.otherDetailsItems[0].serialNo : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Group:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.otherDetailsItems[0].group ? this.state.otherDetailsItems[0].group : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>System:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.otherDetailsItems[0].system ? this.state.otherDetailsItems[0].system : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Town:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.otherDetailsItems[0].suburb ? this.state.otherDetailsItems[0].suburb : ''}</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          </TabPanel>

        </div>
        {this.renderAttachmentModal()}
        {this.renderDefectdetailModal()}
      </div>

    );
  }
}


function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function a11yPropsTop(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}