import * as React from 'react';
import LoadIndicator from 'devextreme-react/load-indicator';


export default class Spinner extends React.Component<any, any> {

    public state = {
    };

    public render(): React.ReactElement<any> {
        return (
        <div className='loader'>
            <LoadIndicator id={`${this.props.size?this.props.size:'medium'}-indicator`} height={this.props.height?this.props.height:40} width={this.props.width?this.props.width:40} /><br/>
           {this.props.label && <span>{this.props.label}</span>}
        </div>
            
        );
    }
}


