import * as React from 'react';
//import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import moment from 'moment';

import utility from '../../classes/utility';
import { Config } from '../../classes/config';

import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

import Grid from '@material-ui/core/Grid';

import AlertDialog from '../common/alertDialog';
import CheckBoxInput from '../common/reusableComponents/CheckBox';
import TextBoxInput from '../common/reusableComponents/TextBox';
import Spinner from '../common/reusableComponents/Spinner';
import MultiSelect from '../common/reusableComponents/MultiSelect';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import DateBoxInput from '../common/reusableComponents/DateBox';
import TextAreaInput from '../common/reusableComponents/TextArea';
import global from '../../classes/global';
export default class EditWorkOrder extends React.Component<any, any> {

  public workorder = this.props.workorder;
  private rootWeb: any;
  public StatusItems: any = [];
  public crewItems: any = [];
  public priorityItems: any = [];
  public currentUser: any;

  constructor(props: any) {
    super(props);

    this.StatusItems = [];
    this.crewItems = [];
    this.priorityItems = [];
    //console.log(this.props.context);
    // this.currentUser = {
    //   DisplayName: props.context.pageContext.user.displayName,
    //   Email: props.context.pageContext.user.email,
    //   LoginName: props.context.pageContext.user.loginName
    // };

    this.currentUser = this.props.currentUser;

    //console.log(this.currentUser);
    this.state = {
      showPanel: false,
      workorder: this.props.workorder,
      crew: '',
      status: '',
      WOpriority: '',
      location: '',
      Eststartdate: null,
      Estenddate: null,
      befpicreq: false,
      aftpicreq: false,
      riskassessment: false,
      //wodesc: '',
      WOComments: '',
      comments: '',
      updating: false,
      showAlert: false,
      alertMessage: '',
      itemID: '',
      historyTitle: '',
      departmentID: '',
      show1: false,
      show2: false,
    };
  }


  public componentDidMount() {
    // utility.getWorkOrder(this.rootWeb, Config.WOlistname, this.state.itemID)
    //   .then((resp) => {
    //     resp.forEach(item => {
    //       this.setState({
    //         workorderdetails: item,
    //         departmentID: item.DepartmentID
    //       });
    //     });
    //   });
    this._getPrioritiesData();
    this._getStatusData();
    this._getEmployeeData();
  }
  // public handleEventListner(){
  //   document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
  // }
  // public handleClickOutside(event) {

  //   if (!event.classList.value.includes('ondateClick')) {
  //     setTimeout(()=>this.setState({ 
  //       show1: false,
  //       show2:false
  //      }),500)
  //     }
  //     document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
  // }

  public _getEmployeeData() {
    let dataToPassToService: any = {};

    dataToPassToService.Email = this.currentUser.Email;
    utility.genericPostAPIcall(Config.SearchEmployeeApi, dataToPassToService).then((data: any) => {

    }, (err) => {
      console.log(err);
    });
  }

  private _getStatusData() {
    utility.genericGetAPICall(Config.statuslist).then((data: any) => {
      // console.log(data);
      this.StatusItems = [];
      let contractorStatusItems = [];
      for (let r of data) {
        if (r.Statustype === 'WorkOrder') {
          this.StatusItems.push({ id: r.Id, text: r.Title });
        } else if (r.Statustype === 'Contractor') {
          contractorStatusItems.push({ id: r.Id, text: r.Title });

        }
      }
      this.setState({
        StatusItems: this.StatusItems,
        allStatusItems: this.StatusItems,
        contractorStatusItems: contractorStatusItems,
        allcontractorStatusItems: contractorStatusItems
      });

    }, (err) => {
      console.log(err);
    });
    this.update = this.update.bind(this);
  }
  private _getPrioritiesData() {

    utility.genericGetAPICall(Config.PriorityList).then((data: any) => {
      //console.log(data);
      this.priorityItems = [];

      for (let r of data) {
        this.priorityItems.push({ id: (r.Title), text: (r.Title), title: (r.Code + "|" + r.Title) });
      }
      this.setState({ priorityItems: this.priorityItems });
    }, (err) => {
      console.log(err);
    });
  }
  public update() {
    //console.log("this.state.status", this.state.status);
    if (this.state.status && this.state.status.text !== 'Proposed') {
      // if (!this.state.WOcrew) {
      //   this.setState({
      //     alertMessage: 'Please Enter Crew/Team',
      //     showAlert: true
      //   });
      //   return;
      // }
      if (!this.state.Eststartdate) {
        this.setState({
          alertMessage: 'Please select Schedule Start Date',
          showAlert: true
        });
        return;
      }

      if (!this.state.Estenddate) {
        this.setState({
          alertMessage: 'Please select Schedule End Date',
          showAlert: true
        });
        return;
      }
      if (!this.state.status) {
        this.setState({
          alertMessage: 'Please select status',
          showAlert: true
        });
        return;
      }
      if (!this.state.contractorStatus) {
        this.setState({
          alertMessage: 'Please select contractor status',
          showAlert: true
        });
        return;
      }
    }
    if (this.state.Eststartdate && this.state.Estenddate) {
      var dfrom = new Date(this.state.Eststartdate);
      var dTo = new Date(this.state.Estenddate);
      if (dTo.getTime() < dfrom.getTime()) {
        this.setState({
          alertMessage: 'Schedule End Date/time should be greater than Schedule Start Date/time',
          showAlert: true
        });
        return;
      }
    }
    // if (this.state.Eststartdate) {
    //   var now = new Date();
    //   var startdate = new Date(this.state.Eststartdate);
    //   if (startdate.getTime() < now.getTime()) {
    //     this.setState({
    //       alertMessage: 'Schedule start date/time should not be less than present date/time',
    //       showAlert: true
    //     });
    //     return;
    //   }
    // }

    this.setState({ updating: true });
    this.setState({
      alertMessage: <Spinner label='Updating work order' />,
      hideAlertButton: true,
      showAlert: true
    });
    let currentdate = new Date();
    let dataToPassToService: any = {};
    let description = this.state.WOComments;
    //ActivityDescr: this.state.wodesc,
    dataToPassToService.Id = this.state.itemID;
    dataToPassToService.Location = this.state.location;
    dataToPassToService.WorkOrderStatusID = this.state.status.id;
    dataToPassToService.ContractorStatusId = this.state.contractorStatus.id;
    dataToPassToService.Priority = this.state.WOpriority.title;
    dataToPassToService.CrewId = this.state.WOcrew ? this.state.WOcrew.id : -1;

    if (this.state.comments &&
      (((this.state.status && this.state.status.text) !== (this.state.previousStatus && this.state.previousStatus.text))) ||
      ((this.state.contractorStatus && this.state.contractorStatus.text) !== (this.state.previouscontractorStatus && this.state.previouscontractorStatus.text))
    ) {
      // let Created = `${this.currentUser.DisplayName} ${utility.cuniq()}`
      description = `${this.state.WOComments}<br /> <b>${this.state.comments}</b>`;
      dataToPassToService.Comments = this.state.comments;
    }
    dataToPassToService.Datescheduledstart = this.state.Eststartdate ? new Date(this.state.Eststartdate) : null;
    dataToPassToService.Datescheduledend = this.state.Estenddate ? new Date(this.state.Estenddate) : null;
    dataToPassToService.PhotoRequiredAfter = this.state.aftpicreq;
    dataToPassToService.PhotoRequiredBefore = this.state.befpicreq;
    dataToPassToService.Datecreated = currentdate.toISOString();
    dataToPassToService.Dateupdated = currentdate.toISOString();
    dataToPassToService.RiskAssessmentRequired = this.state.riskassessment;
    if (this.state.contractorStatus?.text  === 'OffsiteComplete') {
      dataToPassToService.WOComments = description;
    }

    dataToPassToService.UpdatedBy = this.currentUser.Email;
    // console.log("dataToPassToService", dataToPassToService);
    utility.genericUpdatePortalAPICall(Config.WorkOrderUpdateList, dataToPassToService).then((results: any) => {
      //console.log(results);
      this.setState({
        hideAlertButton: false,
        alertMessage: 'Work order has been updated',
        showAlert: true
      });
      this.props.onClick();
      // this.updateHistoryWO();
      // this.setState({ alertMessage: "" });
    }, (err: any) => {
      console.log(err);
      this.setState({
        alertMessage: 'An error occoured. Unable to update details.',
        showAlert: true,
        hideAlertButton: false,
      });
    });
  }

  // public updateHistoryWO(){
  //  let dataToPassToServiceForHistory: any = {};
  //     // dataToPassToServiceForHistory.Crewid = this.state.WOcrew ? this.state.WOcrew.key : "";
  //     dataToPassToServiceForHistory.Statusid = this.state.status.key;
  //     dataToPassToServiceForHistory.Workorderid = this.state.itemID;
  //     dataToPassToServiceForHistory.Usercreated = this.currentUser.Email;
  //     dataToPassToServiceForHistory.Userupdated = this.currentUser.Email;
  //     dataToPassToServiceForHistory.Tenantid = 8;
  //     utility.genericPostAPIcallForAdminList( Config.workorderHistoryList, dataToPassToServiceForHistory).then((data: any) => {
  //       //console.log(data);
  //     }, (err: any) => {
  //       console.log(err);
  //     });
  // }

  public afterInit() {
    this.setState({ loading: true })
    this.workorder = this.props.workorder;
    this.setState({
      workorder: this.props.workorder,
      // WOcrew:this.props.selectedcrew,
      // crewItems:this.props.crew
    });
    if (!Array.isArray(this.workorder)) {
      this.workorder = new Array(this.workorder);
    }
    this.workorder.forEach((item: any) => {
      let statustext = item.Workorderstatusid ? { id: item.Workorderstatusid, text: item.Workorderstatus } : '';
      let prioritytext = item.Priority ? { id: item.Priority, text: item.Priority } : '';
      let crewtext;
      if (item.Crewid  === -1 || item.Crewid  === '') {
        crewtext = '';
      }
      else {
        crewtext = { id: item.Crewid, text: item.Crewtitle };
      }

      let contractorStatus = item.Contractorstatusid ? { id: item.Contractorstatusid, text: item.Contractorstatus } : ''
      this.setState({
        location: item.Location,
        status: statustext ? statustext : '',
        contractorStatus: contractorStatus ? contractorStatus : '',
        previouscontractorStatus: contractorStatus ? contractorStatus : '',
        previousStatus: statustext ? statustext : '',
        WOpriority: prioritytext ? prioritytext : '',
        //WOcrew: crewtext?crewtext:'',
        WOComments: item.WOComments ? item.WOComments : '',
        aftpicreq: item.Photorequiredafter,
        befpicreq: item.Photorequiredbefore,
        riskassessment: item.Riskassessmentrequired,
        Eststartdate: item.Datescheduledstart ? new Date(item.Datescheduledstart) : null,
        Estenddate: item.Datescheduledend ? new Date(item.Datescheduledend) : null,
        itemID: item.Id,
        historyTitle: item.Wonumber,
        comments: ''

      });
      let dataToPassToService: any = {};
      dataToPassToService.DepartmentId = item.Departmentid;
      // var departmentID = item.Departmentid;
      // utility.getlistwithCriteria(this.rootWeb,Config.crewlistname,'CrewDepartment',"CrewDepartment/DepartmentID eq '"+departmentID+"' and ItemActive eq 1",'Title,CrewID,CrewDepartment,CrewDepartment/DepartmentID,ID').then((results) => {
      utility.genericPostAPIcall(Config.SearchCrewApi, dataToPassToService).then((data: any) => {
        this.crewItems = [];

        for (let r of data) {
          this.crewItems.push({ id: (r.Id), text: (r.CrewID + ' - ' + r.Title), TeamID: r.TeamID });
        }
        let filteredcrewname = this.crewItems && this.crewItems.filter((c: any) => c.TeamID  === item.Teamid);
        if (filteredcrewname && filteredcrewname.length > 0) {
          this.setState({ WOcrew: { id: filteredcrewname[0].id, text: filteredcrewname[0].title } });
        }
        else {
          this.setState({ WOcrew: '' });
        }
        this.setState({ crewItems: this.crewItems, allcrewItems: this.crewItems });
        setTimeout(() => this.setState({ loading: false }), 2000);
      });
    });
  }

  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this._closeDialog()}
        hideAlertButton={this.state.hideAlertButton}
      />
    );
  }
  private _closeDialog = (): void => {
    this.setState({ alertMessage: '', showAlert: false });
  }
  private handleValueChange = (event: any, fieldName: string) => {

    this.setState({
      [fieldName]: event.value,
    });

  }
  public handleDropdownChange(e: any, fieldName: string) {

    if (fieldName  === 'status' && e.selectedItem.text  === 'Proposed') {
      this.setState({
        WOcrew: null
      });
    }
    if (fieldName  === 'WOcrew' && (this.state.status && this.state.status.text  === 'Proposed')) {
      let assignedStatus = this.state.StatusItems.find((a: any) => a.text  === 'Open');
      if (assignedStatus) {
        this.setState({ status: assignedStatus });
      } else {
        this.setState({ status: { id: 55, text: 'Open' } });
      }
    }
    this.setState({ [fieldName]: e.selectedItem });
  }
  public render(): React.ReactElement<any> {

    let showComment = false;
    if (this.state.status && this.state.contractorStatus && this.state.previousStatus && this.state.previouscontractorStatus) {
      if (this.state.status.text !== this.state.previousStatus.text) {
        showComment = true;
      }
      if (this.state.contractorStatus.text !== this.state.previouscontractorStatus.text) {
        showComment = true;
      }
    }
    return (
      <div>
        {this.state.showAlert && this.alert()}
        <Popup
          width={'80vw'}
          maxHeight={'85vh'}
          minHeight={200}
          height={'auto'}
          showTitle={true}
          showCloseButton={true}
          title={`Edit Workorder`}
          dragEnabled={true}
          hideOnOutsideClick={false}
          visible={this.props.showModal}
          // onContentReady={() => this.didMount()}
          //closeOnOutsideClick={true}
          onVisibleChange={() => this.props.onClose()}
          onShowing={() => this.afterInit()}

        >
          <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
            <div className='popContentDiv'>
              {!this.state.loading ?
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>

                    <DateBoxInput
                      stylingMode='underlined'
                      label="Schedule Start Date"
                      type="datetime"
                      displayFormat={"dd/MM/yy hh:mm a"}
                      showClearButton={true}
                      value={this.state.Eststartdate ? new Date(this.state.Eststartdate) : null}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'Eststartdate')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DateBoxInput
                      stylingMode='underlined'
                      label="Schedule End Date"
                      value={this.state.Estenddate ? new Date(this.state.Estenddate) : null}
                      type="datetime"
                      displayFormat={"dd/MM/yy hh:mm a"}
                      showClearButton={true}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'Estenddate')}
                    />
                  </Grid>
                  {this.state.crewItems &&
                    <Grid item xs={12} sm={6} >
                      <SelectBoxInput
                        stylingMode='underlined'
                        displayExpr="text"
                        valueExpr="id"
                        showClearButton={true}
                        items={this.state.crewItems}
                        selectedItem={this.state.WOcrew}
                        label='Crew'
                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'WOcrew')}
                      />
                    </Grid>
                  }
                  <Grid item xs={12} sm={6} >
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      label='Priority *'
                      items={this.state.priorityItems}
                      selectedItem={this.state.WOpriority}
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'WOpriority')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <TextBoxInput
                      stylingMode='underlined'
                      label="Location *"
                      // pattern={"[A-Za-z]+"}
                      // minLength={2}
                      value={this.state.location}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'location')}
                      disabled={true}

                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.StatusItems}
                      selectedItem={this.state.status}
                      label='Status'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'status')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.contractorStatusItems}
                      selectedItem={this.state.contractorStatus}
                      label='Contractor Status'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'contractorStatus')}

                    />
                  </Grid>
                  {(showComment) && (
                    <Grid item xs={12} sm={6}>

                      <TextAreaInput
                        label="Status Comments"
                        value={this.state.comments}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'comments')}

                      />
                    </Grid>
                  )}
                  {/* <Grid item xs={12} sm={6}  >
                      <Label>Description</Label>
                      <TextAreaInput
                        value={this.state.WOComments}
                      label=""
                        onChange={(e:any) => this._textAreaChanged(e, 'WOComments')}
                        rows={2}
                      />
                    </Grid> */}
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <CheckBoxInput value={this.state.befpicreq} onValueChanged={(e: any) => this.handleValueChange(e, 'befpicreq')} text={'Before Photo Required'} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <CheckBoxInput value={this.state.aftpicreq} onValueChanged={(e: any) => this.handleValueChange(e, 'aftpicreq')} text={'After Photo Required'} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <CheckBoxInput value={this.state.riskassessment} onValueChanged={(e: any) => this.handleValueChange(e, 'riskassessment')} text={'Site Risk Assessment'} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                :
                <Spinner size="large" label="Loading all componenent. Please wait..." />
              }

            </div>
            {/* </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.onClose()} >
              Cancel
            </Button>
            <Button onClick={this.update} variant='outlined' color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog> */}
          </ScrollView>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
              icon: 'close',
              text: 'Cancel',
              stylingMode: "outlined",
              onClick: () => this.props.onClose(),
            }}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
              icon: 'save',
              text: 'Save',
              stylingMode: "contained",
              onClick: this.update,
            }}
          />
        </Popup>
      </div>

    );
  }

}
