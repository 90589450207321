import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import Spinner from '../../common/reusableComponents/Spinner';
import TreeView from 'devextreme-react/tree-view';
export default class ViewControlMeasure extends React.Component<any, any> {
    public departmentItems: any = [];
    public HierarchyItems: any = [];
    public treeViewRef: any;
    constructor(props: any) {
        super(props);
        this.treeViewRef = React.createRef();
        this.clearFilter = this.clearFilter.bind(this);
        this.treeViewSelectionChanged = this.treeViewSelectionChanged.bind(this);
        this.treeViewContentReady = this.treeViewContentReady.bind(this);
        this.valueChanged = this.valueChanged.bind(this);
        this.departmentItems = [];
        this.HierarchyItems = [];
        this.state = {
            isActive: true,
        };
    }

    public didMount() {
        this.setState({ loading: true })
        this._getRiskHierarchyData();
        this.populateValue(this.props.details);
    }
    private _getRiskHierarchyData() {
        utility.genericGetAPICallForList(`${Config.RiskHierarchy}`, 'GET').then((data: any) => {
            for (let r of data) {
                if (r.type === "controlmeasure" && r.id === this.props.details.hierarchyId) {
                    this.setState({
                        Hierarchy: r.title
                    });
                }

            }
        }, (err) => {
            console.log(err);
        });
    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: this.props.details.id,
            title: this.props.details.title,
            isActive: this.props.details.isActive
        });
        this._getAreaAndRisksData();

    }

    private _getAreaAndRisksData() {
        utility.genericGetAPICallForList(Config.RiskGroupWithRisks, 'GET').then((data: any) => {
            data.forEach((arr: any) => {
                //The Treeview accepts the data in particular format. So renaming risks object key name to items
                //https://js.devexpress.com/Demos/WidgetsGallery/Demo/TreeView/ItemSelectionAndCustomization/React/Light/
                arr['items'] = arr['risks'];
                delete arr['risks'];
            });
            data.filter((f: any) => f.items && f.items.length > 0);
            data.forEach((element: any) => {
                if (element.items && element.items.length > 0) {
                    element.items.forEach((ChildItem: any) => {
                        ChildItem.riskId = ChildItem.id;
                        ChildItem.id = ChildItem.id + "_" + element.id;
                        if (this.props.details.risksRelations && this.props.details.risksRelations.length > 0) {
                            this.props.details.risksRelations.forEach((selectedId: any) => {
                                if (selectedId.riskIds.length > 0) {
                                    selectedId.riskIds.forEach((riskID: any) => {
                                        if (ChildItem.riskId === riskID && element.id === selectedId.riskGroupId) {
                                            //To display the selected items and expand the selection in treeview it is required to pass the below values to array
                                            element.expanded = true;
                                            ChildItem.selected = true;
                                        }

                                    });
                                }

                            });


                        }


                    });


                }


            });
            data = data.filter((s: any) => s.expanded === true);
            if (data.length > 0) {
                data.forEach((element: any) => {
                    element.items.filter((s: any) => s.selected === true);

                });
            }
            this.setState({ allRiskCategories: data });
            setTimeout(() => this.setState({ loading: false }), 1000);


        }, (err) => {
            console.log(err);
        });


    }

    treeViewSelectionChanged(e: any) {
        this.syncSelection(e.component);
    }

    treeViewContentReady(e: any) {
        this.syncSelection(e.component);
    }

    syncSelection(treeView: any) {
        const seletedItems = treeView.getSelectedNodes()
            .map((node: any) => node.itemData);
        const ParentseletedItems = treeView.getSelectedNodes()
            .map((node: any) => node.parent);
        this.setState(() => ({ seletedItems: seletedItems, ParentseletedItems: ParentseletedItems }));
    }
    valueChanged(e: any) {
        this.setState({ value: e.value });
    }
    public clearFilter() {
        this.props.onClose();

    }
    public render(): React.ReactElement<any> {
        return (
            <div>
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`View Risk Control Measure`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showViewModal}
                    onShowing={() => this.didMount()}
                    onVisibleChange={this.clearFilter}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>

                        <div className='popContentDiv'>
                            {!this.state.loading ?
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={5}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>Title:</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.title}</Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>Hierarchy:</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.Hierarchy}</Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>Is Active:</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>{(this.state.isActive === true) ? "Yes" : "No"}</Grid>
                                        </Grid>
                                        <br />

                                        <Grid item xs={12} sm={12}>
                                            <Grid item xs={12} sm={4}>Risk Group and Risks:</Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12}>
                                                    {!this.state.loading ?
                                                        <>
                                                            {(this.state.allRiskCategories && this.state.allRiskCategories.length > 0) ?
                                                                <>
                                                                    <TreeView
                                                                        id="treeview"
                                                                        ref={this.treeViewRef}
                                                                        width={600}
                                                                        height={320}
                                                                        items={this.state.filteredRiskCategories ? this.state.filteredRiskCategories : this.state.allRiskCategories}
                                                                        onSelectionChanged={this.treeViewSelectionChanged}
                                                                        onContentReady={this.treeViewContentReady}
                                                                        itemRender={renderTreeViewItem}
                                                                    />
                                                                </>
                                                                :
                                                                <Grid item xs={12} sm={4}><b>No Risks found.</b></Grid>
                                                            }
                                                        </> :
                                                        <Spinner size='large' label="Loading..." />
                                                    }

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                <Spinner size="large" label="Loading all component. Please wait..." />
                            }
                        </div>
                    </ScrollView>
                </Popup>
            </div >
        );
    }

}
function renderTreeViewItem(item: any) {
    return `${item.title}`;
}


