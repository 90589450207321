import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';
import Grid from '@material-ui/core/Grid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

import AlertDialog from '../../common/alertDialog';
import { DropzoneComponent } from 'react-dropzone-component';
import Spinner from '../../common/reusableComponents/Spinner';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';
let myDropzone: any;
export default class AddEditSwms extends React.Component<any, any> {

    public uploadedFiles: any = [];
    public dataURL: any = [];
    public departmentItems: any = [];
    constructor(props: any) {
        super(props);

        this.uploadAttachment = this.uploadAttachment.bind(this);
        this.veiwDocument = this.veiwDocument.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.departmentItems = [];
        this.uploadedFiles = [];
        this.dataURL = [];
        this.state = {
            uploadedFile: ''
        };
    }

    public componentDidMount() {
        //console.log('component load');
        this._getDepartmentData();
    }
    public didMount() {

        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                isActive: true,
                hideAlertButton: false,
                mode: this.props.mode,
            })
        }


    }
    public populateValue(details: any, mode?: any) {
        var ext = details.name;
        ext = ext.split(".").pop();
        this.setState({
            id: details.id,
            fileTitle: details.title,
            name: details.name,
            docNum: details.docNum,
            fileURL: details.fileURL,
            data: details.thumbnailData,
            department: { id: details.departmentId, text: details.department },
            externalUrl: details.isExternalUrl ? this.props.details.fileURL : '',
            ext: ext,
            uploadedFile: ''
        });
    }
    private _getDepartmentData() {

        utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {

            this.departmentItems = [];

            for (let r of data) {
                this.departmentItems.push({ id: r.Id, text: r.Title });
            }
            this.setState({
                departmentItems: this.departmentItems,
                alldepartmentItems: this.departmentItems,
            });
        }, (err) => {
            console.log(err);
        });
    }

    public uploadAttachment() {
        let file = this.uploadedFiles;
        let WOID = this.props.workorderID;
        let accpeted;
        if (this.uploadedFiles) {
            this.uploadedFiles.forEach((item: any) => {

                if (item.file.accepted === false) {
                    accpeted = false;
                }
            });
            if (accpeted === false) {
                this.setState({
                    alertMessage: 'Selected file is not accepted. Please select another file.',
                    showAlert: true
                });
                return;
            }
        }
        if (!this.state.fileTitle) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.department) {
            this.setState({
                alertMessage: 'Please select department',
                showAlert: true
            });
            return;
        }

        if (!this.state.docNum) {
            this.setState({
                alertMessage: 'Please enter document number',
                showAlert: true
            });
            return;
        }

        if (this.uploadedFiles.length === 0 && this.props.mode !== 'edit') {
            this.setState({
                alertMessage: 'Please attach a document',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });

        let fileType;
        let data;


        let dataToPassToService: any = {};
        if (this.state.externalUrl) {
            dataToPassToService.ExternalUrl = this.state.externalUrl;
            dataToPassToService.Name = this.state.fileTitle;
        } else {
            if (this.state.uploadedFile) {

                if (file[0].file.type === "application/msword") {
                    fileType = ".doc";
                } else {
                    fileType = ".docx";
                }

                data = this.dataURL[0].dataUrl.result.replace("data:" + file[0].file.type + ";base64,", "");
                dataToPassToService.Name = this.dataURL[0].file;
            }
        }

        dataToPassToService.Id = this.state.id;
        dataToPassToService.Title = this.state.fileTitle;
        dataToPassToService.IsActive = true;
        dataToPassToService.DocumentNumber = this.state.docNum;
        dataToPassToService.DepartmentId = this.state.department.id;
        dataToPassToService.Data = data;
        dataToPassToService.UploadedBy = this.props.currentUser.Email;
        if (this.props.mode === 'edit') {
            utility.genericUpdateAPICall(Config.swmsDocumentList, this.state.id, dataToPassToService).then((data: any) => {

                this.setState({ showAlert: false });
                this.props.onClick();
                this.props.message("SWMS has been updated");
                this.clearFilter(true);

            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            utility.genericPostAPIcall(Config.swmsDocumentList, dataToPassToService).then((data: any) => {

                this.setState({ showAlert: false });
                this.props.onClick();
                this.props.message("SWMS has been uploaded");
                this.clearFilter(true);

            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
        utility.removeItem(Config.swmsDocumentList);
    }
    public veiwDocument() {
        let dataToPassToService: any = {};
        if (!utility.validURL(this.props.details.fileURL) || !this.props.details.name) {
            this.setState({
                alertMessage: 'Unable to view this document. \n File URL or File name does exist',
                showAlert: true
            });
            return;
        }
        dataToPassToService.Name = this.props.details.name;
        dataToPassToService.FileURL = this.props.details.fileURL;
        utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {

            window.open(doc, "_blank");
        });
    }
    public handleEvent(file: any) {
        this.onDropGeneral(file);
        this.uploadedFiles.push({ file });
        this.setState({ uploadedFile: this.uploadedFiles });
    }

    public onDropGeneral(file: any) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            this.dataURL.push({ file: file.name, dataUrl: event ? event.currentTarget : "" });
        };
    }
    public clearFilter(item: any) {
        myDropzone.removeAllFiles(true);
        this.setState({
            docNum: '',
            fileTitle: '',
            externalUrl: '',
            docType: '',
            department: '',
            uploadedFiles: []
        });
        this.uploadedFiles = [];
        this.dataURL = [];
        this.props.onClose(item);

    }
    public deleteFile(file: any) {
        var deletingIndex = 0;
        this.uploadedFiles.forEach((element: any, index: any) => {
            if (element.file.name === file.name) {
                deletingIndex = index;
            }
        });

        this.uploadedFiles.splice(deletingIndex, 1);
        this.setState({ uploadedFile: this.uploadedFiles });

    }

    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    public render(): React.ReactElement<any> {
        const componentConfig = {
            showFiletypeIcon: true,
            postUrl: 'no-url'
        };
        const djsConfig = {
            autoProcessQueue: false,
            addRemoveLinks: true,
            maxFiles: 1,
            acceptedFiles: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,,application/pdf',

        };

        const eventHandlers = {
            accept: (file: any, done: any) => {
                if (file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && file.type !== "application/doc") {
                    done("Error! Files of this type are not accepted");
                }
                else { done(); }
            },
            init: function (dz: any) {

                myDropzone = dz;
            },
            addedfile: (file: any) => {

                this.handleEvent(file);
            },
            removedfile: (file: any) => {
                this.deleteFile(file);
            }

        };
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.props.mode && capitalize(this.props.mode)} SWMS`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                        <div className='popContentDiv'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={3}>
                                        {this.state.name && this.props.mode === 'edit' && <Grid item xs={12} sm={12}>
                                            <span className="detailedListText link" onClick={this.veiwDocument}><strong>{this.state.name}</strong></span>
                                        </Grid>}
                                        <Grid item xs={12} sm={12}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Title *"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.fileTitle}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'fileTitle')}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.departmentItems}
                                                selectedItem={this.state.department}
                                                label='Organisation *'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                                                disabled={(this.props.role === global.worxOnline_WorxAdmin || this.props.role === global.worxOnline_ContractorAdmin) ? true : false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Document Number *"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.docNum}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'docNum')}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="External URL"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.externalUrl}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'externalUrl')}
                                                required={true}
                                            />

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <DropzoneComponent
                                                eventHandlers={eventHandlers}
                                                djsConfig={djsConfig}
                                                config={componentConfig}
                                                className="dz-message icon ion-upload"
                                                action='Drop files here or click to upload.'

                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </div>
                    </ScrollView>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.uploadAttachment,
                        }}
                    />
                </Popup>
            </div>

        );
    }

}
