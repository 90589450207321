import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';

import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';

import Spinner from '../../common/reusableComponents/Spinner';
import AddEditTeam from './AddEditTeams';

let _items: any = [];

export default class Teams extends React.Component<any, any> {

  public currentUser: any;
  public columns: any;

  constructor(props: any) {
    super(props);

    this.DeleteDialog = this.DeleteDialog.bind(this);

    if (this.props.role !== global.worxOnline_Admin) {
      window.open('#/AccessDenied', '_self');
    }

    this.currentUser = this.props.currentUser;
    //console.log(this.currentUser);


    this.columns = [
      {
        field: 'id',
        title: 'ID',
        width: 80,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'code',
        title: 'Code',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'title',
        title: 'Title',
        // width: 160,
        type: 'string',
      },
      {
        field: 'department',
        title: 'Organisation',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'allowReassignCrew',
        title: 'Allow Reassign Crew',
        // width: 160,
        type: 'boolean',
        allowHiding: true
      },

      {
        field: 'updatedOn',
        title: 'Updated On',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true,
        sortOrder: 'desc'
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created On',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
    ];
    let showHideColumns = localStorage.getItem("teamsColumns");
    let tempColumns;
    if (showHideColumns !== null) {
      tempColumns = JSON.parse(showHideColumns);
    } else {
      tempColumns = this.columns;
      localStorage.setItem("teamsColumns", JSON.stringify(this.columns));
    }
    this.state = {
      items: _items,
      columns: this.columns,
    };
  }

  public componentDidMount() {
    this._getItemData();

  }

  public DeleteItem(item: any) {
    //console.log("item", item);

    this.setState({
      alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
      hideAlertButton: true,
      showAlert: true,
      showDeleteAlert: false,
    });
    utility.genericDeleteAPICall(Config.TeamList, item).then((data: any) => {
      this.setState({
        alertMessage: 'Team has been deleted.',
        showAlert: true,
        showDeleteAlert: false,
        hideAlertButton: false,
        loading: false
      });
      this._getItemData()
    }, (err) => {
      console.log(err);
      this.setState({
        alertMessage: 'Cannot delete crew. Please try again later.',
        showAlert: true,
        showDeleteAlert: false,
        hideAlertButton: false,
      });
    });

  }

  // edit activty modal

  private EditItemModal(item: any) {
    //console.log("item", item);
    this.setState({ showModal: true, details: item, mode: 'edit' });
  }
  public renderNewModal() {
    return (
      <AddEditTeam
        showModal={this.state.showModal}
        onClose={() => this._closeModal()}
        onClick={() => this._getItemData()}
        message={(e: any) => this.message(e)}
        currentUser={this.currentUser}
        details={this.state.details}
        mode={this.state.mode}
      />
    );
  }

  private _showModal = (): void => {
    this.setState({ showModal: true, details: null, mode: 'add' });
  }

  private _closeModal = (): void => {
    this.setState({ showModal: false });
  }


  public message(message: AnalyserNode) {
    //console.log(message);
    this.setState({
      alertMessage: message + ' successfully',
      showAlert: true
    });
  }
  private _getItemData() {
    let dataToPassToService: any = {};
    this.setState({
      loading: true
    });
    //console.log("dataToPassToService", dataToPassToService);
    utility.genericGetAPICallForList(Config.SearchTeamApi, 'POST', dataToPassToService).then((data: any) => {
      _items = [];
      //console.log(data);

      for (let r of data) {
        let tempTeamLeader = [];
        r.TeamLeadersInfo && r.TeamLeadersInfo.forEach((r: any) => {
          tempTeamLeader.push(r.FullName);
        })
        _items.push({
          id: r.Id,
          title: r.Title,
          code: r.Code,
          department: r.Department,
          departmentId: r.Departmentid,
          allowReassignCrew: r.Allowreassigncrew ? true : false,
          updatedBy: r.Userupdated,
          updatedOn: r.Dateupdated ? r.Dateupdated : null,
          createdBy: r.Usercreated,
          createdOn: r.Datecreated ? r.Datecreated : null,

        });
      }

      _items = utility._sortItems(_items, 'updatedOn', true);
      this.setState({ items: _items, callAfterInit: true, loading: false });

      //console.log(_items);
    }, (err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }
  public setSelection = (id: any) => {
    this.setState({
      selectedRow: id
    });
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }



  public render(): React.ReactElement<any> {
    return (
      <React.Fragment>
        {this.renderNewModal()}
        {this.state.showDeleteAlert && this.DeleteAlert()}
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}> Teams </span>
          </div>
        </div>
        <div>
          {!this.state.loading ?
            <DataTable
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              rows={this.state.items}
              columns={this.state.columns}
              fileName={'Teams'}
              columnChooser={'teamsColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              callAfterInit={this.state.callAfterInit}
              stopCommsAfterInit={() => this.stopCommsAfterInit()}
              loading={this.state.loading}
              allowExportSelectedData={false}
              showNewModal={() => this._showModal()}
              deleteItem={(a: any) => this.DeleteDialog(a)}
              editItem={(a: any) => this.EditItemModal(a)}
              // viewFile={(e:any)=>this.viewFile(e)}
              showCommandBtn={true}
              showNewBtn={true}
              hideNewBtn={this.state.showModal || this.state.showModal || this.props.hideNewBtn}
              showViewDetailBtn={false}
            /> :
            <Spinner size='large' label="Loading..." />
          }

        </div>
      </React.Fragment>
    );
  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        hideAlertButton={this.state.hideAlertButton}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }
  public DeleteDialog(item: any) {

    this.setState({
      DeletealertMessage: 'Are you sure you want to delete?',
      showDeleteAlert: true,
      deleteItem: item
    });
  }
  public DeleteAlert() {
    return (
      <DeleteDialog
        showDialog={this.state.showDeleteAlert}
        message={this.state.DeletealertMessage}
        title={"worxOnline"}
        onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
        onOkButton={() => this.DeleteItem(this.state.deleteItem)}
        onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
      />
    );
  }
}