import * as React from 'react';

import {
    NavLink
} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';


export default class SearchDrop extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            resultCount: 0
        };
    }



    public componentDidMount() {
        this.getGlobalSearchResult();
        document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
        // this.getTimesheet();/
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
    }
    public handleClickOutside(event: any) {

        if (!event.target.classList.value.includes('menuClickListner')) {
            // setTimeout(()=>this.onOutterClick(), 500);
            this.props.onOutterClick(false);
        }
    }
    public onOutterClick() {
        // this.setState({
        //   adminMenu: false,
        //   userMenu: false,
        //   workMenu: false
        // });
        this.props.onOutterClick(false);
    }
    public componentDidUpdate(previousProps: any) {
        if (previousProps.searchProps !== this.props.searchProps) {

            this.getGlobalSearchResult();
            // this.getTimesheet();
            // this.getPlantUsage();
            // this.getMaterialUsage();
        }
    }


    public getGlobalSearchResult() {
        this.setState({ loading: true });
        let searchTerm = Config.GloabSearchAPI + this.props.searchProps
        utility.genericGetAPICall(searchTerm).then((data: any) => {

            let tempWorkorder: any = [];
            data.forEach((item: any) => {
                let link = item.Entity === 'Work Order' ? `/WorkOrderDetail?WOID=${item.Id}+&SR=${item.Wonumber}&WONum=${item.Wonumber}` : item.Enitity === "Asset" ? `/AssetDetail?ASSETNO=${item.Code}` : `/Search${item.Entity}?Code=${item.Code}`;

                tempWorkorder.push({
                    code: item.Wonumber ? item.Wonumber : item.Code,
                    title: item.Title,
                    discription: item.Description,
                    Entity: item.Entity,
                    link: link
                })
            });

            let resultInGroup = utility.groupBy(tempWorkorder, 'Entity');
            this.setState({ results: resultInGroup, resultCount: tempWorkorder.length, loading: false });
        }, (err: any) => {
            console.log(err);
            this.setState({ loading: false });
        });
    }

    public render(): React.ReactElement<any> {
        var keys: any;
        if (this.state.results) {
            keys = Object.keys(this.state.results);
        }
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} >

                        <div className="searchResultDrop">
                            {this.state.resultCount > 0 ?
                                <div>
                                    {keys.map((key: any) => {
                                        return (
                                            <div className={'searchDropList'}>
                                                <div className={'searchDropHeading'}>{key}</div>
                                                {this.state.results[key].map((item: any, i: any) => {
                                                    return (
                                                        <div key={i} className={'searchDropContent'}>
                                                            <NavLink to={item.link}> <span className="WOlink">{item.code}</span> - {item.title}</NavLink>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}

                                </div> :
                                <div className="alignCenter p-10">
                                    No Result Found
                                </div>}
                        </div>
                    </Grid>
                </Grid>

                {/* // <Spinner size='large' label="Loading..." /> */}


            </div>
        );
    }


}

