import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';

import Grid from '@material-ui/core/Grid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

import AlertDialog from '../../common/alertDialog';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import Spinner from '../../common/reusableComponents/Spinner';
import capitalize from '@material-ui/core/utils/capitalize';

export default class EditPlant extends React.Component<any, any> {

    public categoryItems: any = [];
    public departmentItems: any = [];
    public prestartFormItems: any = [];

    constructor(props: any) {
        super(props);

        this.EditPlant = this.EditPlant.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.departmentItems = [];
        this.prestartFormItems = [];
        this.categoryItems = [];
        this.state = {

        };
    }

    public componentDidMount() {
        //console.log('component load');

        this._getPlantCategory();
        this._getDepartmentData();
        this._getPrestartFormData();
    }
    public didMount() {
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode,
                isActive: true,
            });
        }

    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: details.id,
            title: details.title,
            rCode: details.resourceCode,
            rego: details.regonumber,
            isActive: details.active,
            inspectionFailed: details.inspectionFailed,
            prestartForm: details.prestartformid ? { id: details.prestartformid, text: details.prestartformName } : '',
            category: details.categoryId ? { id: details.categoryId, text: details.category } : '',
            department: details.departmentId ? { id: details.departmentId, text: details.department } : ''
        });
    }

    public EditPlant() {
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.rego) {
            this.setState({
                alertMessage: 'Please enter rego number',
                showAlert: true
            });
            return;
        }
        if (!this.state.category) {
            this.setState({
                alertMessage: 'Please enter category',
                showAlert: true
            });
            return;
        }
        if (!this.state.rCode) {
            this.setState({
                alertMessage: 'Please enter resource code',
                showAlert: true
            });
            return;
        }
        if (!this.state.department) {
            this.setState({
                alertMessage: 'Please select department',
                showAlert: true
            });
            return;
        }
        // if (!this.state.prestartForm) {
        //     this.setState({
        //         alertMessage: 'Please select prestart form',
        //         showAlert: true
        //     });
        //     return;
        // }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.Title = this.state.title;
        dataToPassToService.Resourcecode = this.state.rCode;
        dataToPassToService.Categoryid = this.state.category.id;
        dataToPassToService.Regonumber = this.state.rego;
        dataToPassToService.Isactive = this.state.isActive;
        dataToPassToService.Tenantid = Config.tenantId;
        dataToPassToService.DepartmentId = this.state.department.id;
        dataToPassToService.Prestartformid = this.state.prestartForm ? this.state.prestartForm.id : '';
        dataToPassToService.Userupdated = this.props.currentUser.Email;
        dataToPassToService.inspectionFailed = this.state.inspectionFailed;
        //console.log("dataToPassToService", dataToPassToService);
        if (this.props.mode === 'edit') {
            dataToPassToService.ID = this.state.id;
            utility.genericUpdateAPICall(Config.plantListName, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Plant has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.Usercreated = this.props.currentUser.Email;
            utility.genericPostAPIcall(Config.plantListName, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Plant has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
        utility.removeItem(Config.plantListName);
    }

    private _getPrestartFormData() {

        utility.genericGetAPICall(Config.PrestartFormsListName).then((data: any) => {
            //console.log(data);
            this.prestartFormItems = [];

            for (let r of data) {
                this.prestartFormItems.push({ id: r.Id, text: r.Title });
            }
            this.setState({ prestartFormItems: this.prestartFormItems, allprestartFormItems: this.prestartFormItems });
        }, (err) => {
            console.log(err);
        });
    }
    private _getDepartmentData() {

        utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {
            //console.log(data);
            this.departmentItems = [];

            for (let r of data) {
                this.departmentItems.push({ id: r.Id, text: r.Title });
            }
            this.setState({ departmentItems: this.departmentItems, alldepartmentItems: this.departmentItems });
        }, (err) => {
            console.log(err);
        });
    }
    public _getPlantCategory() {
        utility.genericGetAPICall(Config.PlantCategoryApi).then((data: any) => {
            //console.log(data);
            this.categoryItems = [];
            for (let r of data) {
                if (r) {
                    this.categoryItems.push({ id: r.Id, text: r.Title });
                }
            }
            this.setState({ categoryItems: this.categoryItems, allcategoryItems: this.categoryItems });

        }, (err) => {
            console.log(err);
        });
    }
    public clearFilter() {
        this.setState({
            title: '',
            rego: '',
            category: '',
            rCode: '',
            department: '',
            prestartForm: '',
        });
        this.props.onClose();

    }


    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }

    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.props.mode && capitalize(this.props.mode)} Equipment and Vehicles`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                        <div className='popContentDiv'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Title *"
                                        value={this.state.title}
                                        onKeyDown={() => { }}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Resource Code *"
                                        value={this.state.rCode}
                                        onKeyDown={() => { }}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'rCode')}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SelectBoxInput
                                        stylingMode='underlined'
                                        displayExpr="text"
                                        valueExpr="id"
                                        showClearButton={true}
                                        items={this.state.categoryItems}
                                        selectedItem={this.state.category}
                                        label='Category *'
                                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'category')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Rego Number *"
                                        value={this.state.rego}
                                        onKeyDown={() => { }}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'rego')}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SelectBoxInput
                                        stylingMode='underlined'
                                        displayExpr="text"
                                        valueExpr="id"
                                        showClearButton={true}
                                        items={this.state.departmentItems}
                                        selectedItem={this.state.department}
                                        label='Organisation *'
                                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}

                                        disabled={(this.props.role === global.worxOnline_ContractorAdmin || this.props.role === global.worxOnline_WorxAdmin) ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SelectBoxInput
                                        stylingMode='underlined'
                                        displayExpr="text"
                                        valueExpr="id"
                                        showClearButton={true}
                                        items={this.state.prestartFormItems}
                                        selectedItem={this.state.prestartForm}
                                        label='Prestart Form '
                                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'prestartForm')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <CheckBoxInput value={this.state.inspectionFailed} onValueChanged={(e: any) => this.handleValueChange(e, 'inspectionFailed')} text={'Inspection Failed'} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                </Grid>
                            </Grid>
                        </div>
                    </ScrollView>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.EditPlant,
                        }}
                    />
                </Popup>
            </div>
        );
    }

}

