import NumberBox from 'devextreme-react/number-box';
import * as React from 'react';

interface Props {
    label?: string;
    stylingMode: string|any;
    placeholder?: string|any;
    mask?: string|any;
    useMaskedValue?: string|any;
    mode?: string|any;
    value: number;
    showClearButton?: Boolean|any;
    readOnly?: Boolean|any;
    disabled?: Boolean|any;
    onValueChanged:any;
    onKeyDown?:any;
    required?:any;
    spellcheck?:Boolean|any;
    min?:any;
    max?:any;
  }
export default class NumberBoxInput extends React.Component<Props, any> {

    public state = {
    };

    public render(): React.ReactElement<Props> {
        return (
        
                <NumberBox
                    labelMode={'floating'}
                    label={this.props.label}
                    stylingMode={this.props.stylingMode}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onValueChanged={(e:any) => this.props.onValueChanged(e)}
                    showClearButton={this.props.showClearButton}
                  
                    readOnly={this.props.readOnly}
                    disabled={this.props.disabled}
                    min={this.props.min}
                    max={this.props.max}
                    // showSpinButtons={true}
                   >
                    {/* {this.props.showButton && (
                        <Button
                        name='test'
                            location={this.props.buttonLocation}
                            options={{
                                icon: 'map',
                                type: 'default',
                                onClick: () => this.props.onClickButton()
                            }}
                        />
                    )} */}
                   </NumberBox>
        );
    }
}


