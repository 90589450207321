import React, { useState, useEffect } from "react";

import utility from './classes/utility';
import global from './classes/global';
import { Config } from './classes/config';

import './App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";


import SignIn from './components/SignIn/SignIn';
import Spinner from "./components/common/reusableComponents/Spinner";
import WorxOnline from "./worxOnline";
const HomePage = () => {


  const { instance } = useMsal();
  const { accounts } = useMsal();

  localStorage.setItem("currentDetail", accounts[0]?.username);

  let currentUser = {
    DisplayName: accounts[0]?.name,
    Email: accounts[0]?.username,
    LoginName: accounts[0]?.username
  };

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      currentUser = {
        DisplayName: accounts[0]?.name,
        Email: accounts[0]?.username,
        LoginName: accounts[0]?.username
      }
      onInit();
    }
  }, [accounts]);
  const [loading, setLoading] = useState(true);
  const [Department, setDepartment] = useState('');
  const [Role, setRole] = useState('');
  const [isContractor, setisContractor] = useState('');
  const [Team, setteam] = useState('');
  const [TeamId, setTeamId] = useState('');
  const [TenantConfig, setTenantConfig] = useState('');
  const [pageListItems, setPageListItems] = useState([]);
  const handleSetLoading = () => {
    setLoading(false);
  };
  const handleDepartment = (item) => {
    setDepartment(item);
  };
  const handleteam = (item) => {
    setteam(item);
  };
  const handleRole = (item) => {
    setRole(item);
  };
  const handleteamId = (item) => {
    setTeamId(item);
  };

  const handleisContractor = (item) => {
    setisContractor(item);
  };
  const handleTenantConfig = (item) => {
    setTenantConfig(item);
  };
  const handlePageListItems = (item) => {
    setPageListItems(item);
  };
  // let loading =true;
  const onInit = () => {
    localStorage.removeItem('woState');
    localStorage.removeItem('dashboardState');
    localStorage.removeItem('plantUsageState');
    localStorage.removeItem('servReqState');
    localStorage.removeItem('timesheetState');
    localStorage.removeItem('assetState');
    localStorage.removeItem('openIDState');
    localStorage.removeItem('formattedAddressItems');
    // localStorage.removeItem('newWorkorders');
    localStorage.removeItem('datagridFilterValue');
    localStorage.removeItem('datagridSearchPanelValue');
    return new Promise((resolve, reject) => {

      let promises = [];
      let dataToPassToService = {};
      let isContractor = false
      let Team = '';
      dataToPassToService.Email = accounts[0]?.username;
      let promise1 = utility.genericPostAPIcall(Config.SearchEmployeeApi, dataToPassToService).then((data) => {

        if (data && data.length > 0) {
          // Department = { id: data[0].DepartmentId, text: data[0].DepartmentName }
          if (data[0].DepartmentId) {
            handleDepartment({ id: data[0].DepartmentId, text: data[0].DepartmentName });
          }
          // let tempRoles = 'Reader';
          let tempRoles = data[0]?.RoleName;
          if (data[0]?.IsContractor === true) {
            isContractor = true;
            handleisContractor(isContractor)
          }
          if (data[0].TeamId) {
            handleteamId(data[0].TeamId)
          }
          if (data[0].TeamName) {
            Team = data[0].TeamCode + '|' + data[0].TeamName;
            handleteam(Team)
          }

          if (tempRoles) {
            handleRole(tempRoles)
          }
        } else {
          window.open('#/AccessRequest', '_self');
        }

      }, (err) => {
        console.log(err);
        handleRole(global.worxOnline_Reader);
        handleSetLoading()

      });
      promises.push(promise1);
      let promise2 = utility.genericGetDataByIdAPICall(Config.TenantApi, Config.tenantId).then((data) => {

        handleTenantConfig(data)
        document.title = data?.Config?.SiteTitle ? data.Config.SiteTitle : 'Coliban Water';
      }, (err) => {
        console.log(err);
      });
      promises.push(promise2);

      let promise3 = utility.genericGetAPICallForList(Config.ListConfiguration, 'GET').then((data) => {
        let tempPageListItems = [];
        if (data && data.length > 0) {

          data.forEach((element) => {
            tempPageListItems.push({
              Id: element.Id,
              Title: element.Title,
              Category: element.Category,
              Isactive: element.Isactive ? true : false,
              AddEditField: JSON.parse(element.Addeditfield),
              apiInfo: JSON.parse(element.Apiinfo),
              Columns: JSON.parse(element.Columns),
              DataTable: JSON.parse(element.Datatable),
            });
            localStorage.removeItem(element.Title);
          });

          handlePageListItems(tempPageListItems)

        }
      }, (err) => {
        console.log(err);

      });
      promises.push(promise3);
      Promise.all(promises).then(result => {
        handleSetLoading()
        resolve(true)
      });
    });
  }

  return (
    <>
      <UnauthenticatedTemplate>
        <SignIn
          instance={instance}
        />
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        {!loading ?
          <div className="App">
            <WorxOnline
              role={Role}
              Department={Department}
              tenantConfig={TenantConfig}
              currentUser={currentUser}
              pageListItems={pageListItems}
              isContractor={isContractor}
              team={Team}
              teamId={TeamId}
            />

          </div>
          : <div> <br /> <Spinner size='large' label="Please wait. Component is loading" /></div>
        }
      </AuthenticatedTemplate>
    </>
  )
}

export default HomePage;


