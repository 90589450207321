import * as React from 'react';

import { Popup, ToolbarItem } from 'devextreme-react/popup';

export class AlertDialogProps{
  public showDialog:any;
  public message:string | any;
  public title:string | any;
  public onClose:any;
  public hideAlertButton?: any;
  public showCreateButton?: any;
  public link?: any;
}
export default class AlertDialog extends React.Component<AlertDialogProps, any> {

  public state = {
    showDialog: this.props.showDialog    
  };

  public render(): React.ReactElement<any> {    
    return (      
      <div>        
         {/* <Dialog
        open={this.state.showDialog}
        // onClose={this._closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={'theme'}>
           {this.props.message}
          </DialogContentText>
        </DialogContent>
        {(!this.props.hideAlertButton) && (
        <DialogActions>
          
          <Button  variant="outlined" onClick={this._closeDialog}  color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
        )}
        {(this.props.showCreateButton) && (
          <DialogActions>
          <NavLink to={this.props.link}>
          <Button  variant="outlined"   color="primary" onClick={this._closeDialog} autoFocus>
            Ok
          </Button>
          </NavLink>
        </DialogActions>
        )}
      </Dialog> */}
        <Popup
          width={300}
          maxHeight={300}
          minHeight={200}
          height={'auto'}
          showTitle={true}
          showCloseButton={false}
          title={this.props.title}
          dragEnabled={false}
          hideOnOutsideClick={false}
          visible={this.state.showDialog}
          // onContentReady={() => this.didMount()}
          onVisibleChange={this._closeDialog}


        >
          <div className='popContentDiv' style={{fontSize:'16px'}}> {this.props.message}</div>
          {(!this.props.hideAlertButton) && (
           
              <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                  text: 'Close',
                  stylingMode: "outlined",
                  onClick: this._closeDialog,
                }}
              />
              )}
              {/* {(!this.props.showCreateButton) && (
              <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                  icon: 'close',
                  text: 'Ok',
                  stylingMode: "contained",
                  onClick: this._OKcloseDialog,
                }}
              />
         )} */}
        </Popup>
      </div>      
    );
  }  

  private _closeDialog = (): void => {
    this.setState({ showDialog: false });
    this.props.onClose();
  }
}


