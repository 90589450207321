import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
    NavLink,
    HashRouter
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import global from '../../../classes/global';
import { Toast } from 'devextreme-react/toast';
import DeleteDialog from '../../common/DeleteAlert';
export default class ClearStorage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            details: this.props.details,
            isVisible: false
        };
    }
    public componentDidMount() {
        ////console.log('component load');

    }

    public clearStorage() {
        localStorage.clear();
        var indexedDB = window.indexedDB;
        // Open (or create) the database

        var request = indexedDB.deleteDatabase("cw-worxOnline");
        request.onsuccess = ()=>{
            this.setState({
                alertMessage: 'Local storage has been cleared successfully.',
                isVisible: true,
                loading: false
            })
        };
        request.onerror = ()=>{
            this.setState({
                alertMessage: 'Cannot clear local storage.',
                isVisible: true,
                loading: false
            })
        };
        this.setState({
            alertMessage: 'Local storage has been cleared successfully.',
            isVisible: true,
            loading: false,
        });
    }
    public render(): React.ReactElement<any> {
        //console.log(this.props.details);

        return (
            <div className="alignCenter">
                {this.state.showDeleteAlert && this.DeleteAlert()}
                <div className="accessDeniedDiv" >
                    <Paper elevation={3} className={'p-20'}>
                        <h2 >Clear Storage</h2>
                        <p>
                            It will clear all the local storage which includes <strong>saved search</strong> and <strong>column chooser</strong>
                        </p>
                        <Button onClick={() => this.clearStorage()} variant='outlined'>
                            Clear
                        </Button>
                    </Paper>
                </div>
                <Toast
                    visible={this.state.isVisible}
                    message={this.state.alertMessage}
                    type={'info'}
                    onHiding={() => this.setState({ isVisible: false })}
                    displayTime={1500}
                />
            </div>
        );
    }
    public DeleteDialog() {

        this.setState({
            DeletealertMessage: 'Are you sure you want to clear local storage?',
            showDeleteAlert: true,
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.clearStorage()}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }
}

