import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
export default class AddRiskArea extends React.Component<any, any> {

    public departmentItems = [];

    constructor(props: any) {
        super(props);

        this.AddEditRiskGroup = this.AddEditRiskGroup.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.departmentItems = [];
        this.state = {
            riskCategoryItems: this.props.riskCategoryItems,
            allriskCategoryItems: this.props.riskCategoryItems,
            isActive: true,
            riskCategoryLoading: true
        };
    }
    public didMount() {
        this._getItemCategoryData();
        if (this.props.details && this.props.mode === 'edit') {
            this.setState({
                loading: false,
                id: this.props.details.id,
                title: this.props.details.title,
                isActive: this.props.details.Isactive
            });

        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode,
                loading: false,

            })
        }

    }
    private _getItemCategoryData() {
        utility.genericGetAPICallForList(`${Config.NewRiskCategoryList}`, 'GET')
            .then(
                (data: any) => {
                    const _items = [];
                    for (let r of data) {
                        _items.push({ text: r.title, id: r.id });
                        if (this.props.mode === 'edit' && this.props.details.RiskCategories && this.props.details.RiskCategories === r.id) {
                            this.setState({ riskCategories: { id: r.id, text: r.title } });
                        }

                    }
                    this.setState({ riskCategoryItems: _items, riskCategoryLoading: false });

                },
                (err) => {
                    console.log(err);
                    this.setState({ riskCategoryLoading: false });
                }
            );

    }


    public AddEditRiskGroup() {
        const dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.riskCategories) {
            this.setState({
                alertMessage: 'Please select risk category',
                showAlert: true
            });
            return;
        }

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' type={'converging-spinner'} /> <br /> Loading...</div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.title = this.state.title;
        dataToPassToService.code = this.state.code;
        dataToPassToService.isActive = this.state.isActive;
        dataToPassToService.riskCategoryId = this.state.riskCategories ? this.state.riskCategories.id : '';
        dataToPassToService.tenantId = Config.tenantId;
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updated = new Date();
            dataToPassToService.createdBy = this.props.details.createdBy;
            dataToPassToService.created = this.props.details.created;
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            utility.genericUpdateAPICall(Config.NewRiskAreaList, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Risk Group has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.NewRiskAreaList, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Risk Group has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }

    }
    public clearFilter() {
        if (this.props.mode !== 'edit') {
            this.setState({
                title: '',
                code: '',
                department: '',
                model: ''
            });
        }
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }

    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}

                <Dialog
                    open={this.props.showModal}
                    onClose={() => !this.props.showModal ? this.clearFilter() : () => { }}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Risk Group`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>

                            <div className='popContentDiv'>
                                {!this.state.loading ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Title *"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.title}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            />
                                        </Grid>
                                        {!this.state.riskCategoryLoading &&
                                            <Grid item xs={12} sm={6}>
                                                <SelectBoxInput
                                                    stylingMode='underlined'
                                                    displayExpr="text"
                                                    valueExpr="id"
                                                    showClearButton={true}
                                                    items={this.state.riskCategoryItems}
                                                    selectedItem={this.state.riskCategories}
                                                    label='Risk Category *'
                                                    onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'riskCategories')}

                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={6}>
                                            <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />

                                        </Grid>
                                    </Grid>
                                    :
                                    <Spinner size="large" label="Loading all component. Please wait..." />
                                }
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.AddEditRiskGroup()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>
            </div >
        );
    }

}
