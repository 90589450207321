import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';

import Grid from '@material-ui/core/Grid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';


import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import MultiSelect from '../../common/reusableComponents/MultiSelect';
import TreeView from 'devextreme-react/tree-view';

const GISLayersCategoryItems: any = [
    { "id": "ATEN_Sewer_Assets", "text": 'ATEN_Sewer_Assets', "Select All": 'ATEN_Sewer_Assets' },
    { "id": "ATEN_Water_Assets", "text": 'ATEN_Water_Assets', "Select All": 'ATEN_Water_Assets' }
];
const worksystemItems: any = [
    { "id": "IPS", "text": 'IPS' },
    { "id": "worx", "text": 'worx' }
]
const GISLayersItems: any = [
    {
        "text": "Water Pumping Station",
        "Select All": "Water Pumping Station",
        "id": "Water_Pumping_Station",
        "category": "ATEN_Water_Assets"
    },
    {
        "text": "Water System Valve",
        "Select All": "Water System Valve",
        "id": "Water_System_Valve",
        "category": "ATEN_Water_Assets"
    },
    {
        "text": "Water Control Valve",
        "Select All": "Water Control Valve",
        "id": "Water_Control_Valve",
        "category": "ATEN_Water_Assets"
    },
    {
        "text": "Water Fireplug",
        "Select All": "Water Fireplug",
        "id": "Water_Fireplug",
        "category": "ATEN_Water_Assets"
    },
    {
        "text": "Water Standpipe",
        "Select All": "Water Standpipe",
        "id": "Water_Standpipe",
        "category": "ATEN_Water_Assets"
    },
    {
        "text": "Rural Channel",
        "Select All": "Rural Channel",
        "id": "Rural_Channel",
        "category": "ATEN_Water_Assets"
    },
    {
        "text": "Water Pipe",
        "Select All": "Water Pipe",
        "id": "Water_Pipe",
        "category": "ATEN_Water_Assets"
    },
    {
        "text": "Sewer Inspection Shaft",
        "Select All": "Sewer Inspection Shaft",
        "id": "Sewer_Inspection_Shaft",
        "category": "ATEN_Sewer_Assets"
    },
    {
        "text": "Sewer Maintenance Hole",
        "Select All": "Sewer Maintenance Hole",
        "id": "Sewer_Maintenance_Hole",
        "category": "ATEN_Sewer_Assets"
    },
    {
        "text": "Sewer Maintenance Shaft",
        "Select All": "Sewer Maintenance Shaft",
        "id": "Sewer_Maintenance_Shaft",
        "category": "ATEN_Sewer_Assets"
    },
    {
        "text": "Sewer Valves",
        "Select All": "Sewer Valves",
        "id": "Sewer_Valves",
        "category": "ATEN_Sewer_Assets"
    },
    {
        "text": "Sewer Pumping Station",
        "Select All": "Sewer Pumping Station",
        "id": "Sewer_Pumping_Station",
        "category": "ATEN_Sewer_Assets"
    },
    {
        "text": "Sewer Private Drain",
        "Select All": "Sewer Private Drain",
        "id": "Sewer_Private_Drain",
        "category": "ATEN_Sewer_Assets"
    },
    {
        "text": "Sewer Pipe",
        "Select All": "Sewer Pipe",
        "id": "Sewer_Pipe",
        "category": "ATEN_Sewer_Assets"
    }

];
export default class AddEditActivities extends React.Component<any, any> {

    public departmentItems: any = [];
    public riskFormItems: any = [];
    public riskAreaItems: any = [];
    public swmsItems: any = [];
    public treeViewRef: any;
    public treeView: any;

    constructor(props: any) {
        super(props);
        this.addUpdateItem = this.addUpdateItem.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.treeViewSelectionChanged = this.treeViewSelectionChanged.bind(this);
        this.treeViewContentReady = this.treeViewContentReady.bind(this);
        this.treeViewRef = React.createRef();
        this.departmentItems = [];
        this.riskFormItems = [];
        this.riskAreaItems = [];
        this.swmsItems = [];
        this.state = {
            GISLayersItems: GISLayersItems,
            allGISLayersItems: GISLayersItems,
            GISLayersCategoryItems: GISLayersCategoryItems,
            allGISLayersCategoryItems: GISLayersCategoryItems,
            tempGISLayersItems: GISLayersItems,
            alltempGISLayersItems: GISLayersItems,
            allRiskCategories: [],
            riskgroup: [],
            riskFormloading: true
        };
    }

    public componentDidMount() {


    }
    public async didMount() {
        this._getDepartmentData();
        this._getWorkTyepSubcategoriesData();
        await this._getRiskCategoriesData();
        if (this.props.details && this.props.mode === 'edit') {
            console.log("this.props.details", this.props.details);
            this.setState({ loading: true })
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this._getFormData();
            this._getSwmsData();
            this._getRiskAreaData();
            this._getRiskFormData();
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode,
                isActive: true,
                riskassessment: false,
                highRisk: false,
                fastCompleted: false,
                fastCreated: false,
                befpicreq: false,
                aftpicreq: false,
                tcp: false,
                allowWorkorderReassignment: false
            })
        }

    }
    public populateValue(details: any, mode?: any) {
        this._getFormData();
        this._getSwmsData();
        this._getRiskAreaData();
        this._getRiskFormData();
        let tempGISlayer = this.state.GISLayersItems;
        let filteredWorkCenter = tempGISlayer.filter((t: any) => t.category === this.props.details.readOnlyLayer);
        let selectedGISlayersCategory: any = [];
        GISLayersCategoryItems.forEach((gisC: any) => {
            if (this.props.details.readOnlyLayer && this.props.details.readOnlyLayer.some((gC: any) => gC.trim() === gisC.id)) {
                selectedGISlayersCategory.push(gisC);
            }
        });
        let selectedGISlayers: any = [];
        GISLayersItems.forEach((gis: any) => {
            if (this.props.details.gisLayer && this.props.details.gisLayer.some((g: any) => g.trim() === gis.id)) {
                selectedGISlayers.push(gis);
            }
        });
        this.setState({
            id: this.props.details.id,
            title: this.props.details.title,
            code: this.props.details.code,
            rego: this.props.details.regonumber,
            riskassessment: this.props.details.riskassesment,
            highRisk: this.props.details.highRisk,
            fastCompleted: this.props.details.fastCompleted,
            fastCreated: this.props.details.fastCreated,
            befpicreq: this.props.details.befpicreq,
            aftpicreq: this.props.details.aftpicreq,
            tcp: this.props.details.tcp,
            isActive: this.props.details.isActive,
            allowWorkorderReassignment: this.props.details.allowWorkorderReassignment,
            // swms: this.props.details.swmsid,
            // RiskArea:this.props.details.riskArea,
            // riskForm: { id: this.props.details.formId && this.props.details.formId[0], text: this.props.details.formId && this.props.details.formId[0] },
            // template: this.props.details.template,
            gisLayer: selectedGISlayers,
            layerCategory: selectedGISlayersCategory,
            form: this.props.details.Projectjobchecklist ? { id: this.props.details.Projectjobchecklist, text: this.props.details.Projectjobchecklisttitle } : '',
            department: this.props.details.department ? { id: this.props.details.departmentId, text: this.props.details.department } : '',
            woSubcategory: this.props.details.SubcategoryId ? { id: this.props.details.SubcategoryId, text: this.props.details.SubcategoryName } : '',
            tempGISLayersItems: filteredWorkCenter && filteredWorkCenter.length > 0 ? filteredWorkCenter : tempGISlayer,
            alltempGISLayersItems: filteredWorkCenter && filteredWorkCenter.length > 0 ? filteredWorkCenter : tempGISlayer,
            hideAlertButton: false,
            worksystem: this.props.details.WorkSystemName ? { id: this.props.details.WorkSystemName, text: this.props.details.WorkSystemName } : '',
        });
        setTimeout(() => this.setState({ loading: false }), 1000);
    }
    private _getSwmsData() {
        utility.genericGetAPICall(Config.swmsDocumentList).then((data: any) => {
            this.swmsItems = [];

            for (let r of data) {
                this.swmsItems.push({ text: r.Title, id: r.Id, "Select All": r.Title });
            }
            let selectedSwms: any = [];
            if (this.props.details) {
                let tempswmsid = this.props.details.swmsid;
                if (tempswmsid !== null) {
                    tempswmsid.forEach((item: any, index: any) => {
                        let tempSwms = this.swmsItems.filter((r: any) => r.id === item);
                        tempSwms.forEach((item: any) => {
                            selectedSwms.push(item);
                        });
                    });
                }
            }
            this.setState({
                swmsItems: this.swmsItems,
                swms: selectedSwms,
                allswmsItems: this.swmsItems
            });
        }, (err) => {
            console.log(err);
        });
    }
    private _getDepartmentData() {

        utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {
            //console.log(data);
            this.departmentItems = [];

            for (let r of data) {
                this.departmentItems.push({ id: r.Id, text: r.Title });
            }
            this.setState({
                departmentItems: this.departmentItems,
                alldepartmentItems: this.departmentItems
            });
        }, (err) => {
            console.log(err);
        });
    }
    private _getRiskAreaData() {

        utility.genericGetAPICall(Config.RiskAreaList).then((data: any) => {
            //console.log(data);
            this.riskAreaItems = [];

            for (let r of data) {
                this.riskAreaItems.push({ id: r.Id, text: r.Title, "Select All": r.Title });
            }
            let selectedFormArea: any = [];
            if (this.props.details) {

                let tempFormAreaId = this.props.details.riskArea;
                if (tempFormAreaId !== null) {
                    tempFormAreaId.forEach((item: any, index: any) => {
                        let tempFormArea = this.riskAreaItems.filter((r: any) => r.id === item);
                        //console.log("tempFormArea", tempFormArea)
                        tempFormArea.forEach((item: any) => {
                            selectedFormArea.push(item);
                        });
                    })
                }
            }
            this.setState({
                riskAreaItems: this.riskAreaItems,
                allriskAreaItems: this.riskAreaItems,
                RiskArea: selectedFormArea,
            });
        }, (err) => {
            console.log(err);
        });

    }
    // private _getRiskFormData() {

    //     utility.genericGetAPICall(Config.RiskFormsList).then((data: any) => {
    //         //console.log(data);
    //         this.riskFormItems = [];

    //         for (let r of data) {
    //             this.riskFormItems.push({ id: r.Id, text: r.Title });
    //             if (this.props.details && this.props.details.formId && this.props.details.formId[0] === r.Id) {
    //                 this.setState({
    //                     riskForm: { id: r.Id, text: r.Title },
    //                 });
    //             }
    //         }
    //         this.setState({ riskFormItems: this.riskFormItems, allriskFormItems: this.riskFormItems });
    //     }, (err) => {
    //         console.log(err);
    //     });

    // }

    private async _getRiskFormData() {
        this.setState({
            riskFormloading: true
        });
        try {
            const data: any = await utility.genericGetAPICallForList(Config.NewRiskFormsList, 'GET');
            this.riskFormItems = [];
            let selectedForm: any = '';
            this.riskFormItems = data.map((r: any) => ({ id: r.id, text: r.title }));
            const sortedItems = utility._sortItems(this.riskFormItems, 'text', false);
            if (this.props.mode === 'edit' && (this.props.details.formId && this.props.details.formId.length > 0)) {
                selectedForm = this.riskFormItems.filter((r: any) => r.id === this.props.details.formId[0]);
                console.log("selectedForm", selectedForm);
            }

            this.setState({ riskFormItems: sortedItems, allriskFormItems: sortedItems, riskForm: selectedForm && selectedForm.length > 0 ? selectedForm[0] : '', riskFormloading: false });
        } catch (err) {
            console.log(err);
            this.setState({
                riskFormloading: false
            });
        }
    }
    private _getWorkTyepSubcategoriesData() {
        utility.genericGetAPICall(Config.worktypeSubcategories).then((data: any) => {
            //console.log(data);
            let worktypeSubcategoriesItems: any = [];
            for (let r of data) {
                worktypeSubcategoriesItems.push({ id: r.Id, text: r.Title });
            }
            this.setState({ worktypeSubcategoriesItems: worktypeSubcategoriesItems, allworktypeSubcategoriesItems: worktypeSubcategoriesItems });

        }, (err) => {
            console.log(err);
        });
    }
    private _getFormData() {
        utility.genericGetAPICall(Config.FormsListName).then((data: any) => {
            //console.log(data);
            let FormItems: any = [];
            let selectedTemplate: any = []
            for (let r of data) {
                FormItems.push({ id: r.Title, text: r.Title, "Select All": r.Title });
            }
            if (this.props.details && this.props.details.template) {

                let templateArr = this.props.details.template.split(',');
                templateArr.forEach((item: any, index: any) => {
                    let tempFormItems = FormItems.filter((r: any) => r.id === item.trim());
                    //console.log("tempFormArea", tempFormArea)
                    tempFormItems.forEach((sitem: any) => {
                        selectedTemplate.push(sitem);
                    });
                })
            }
            this.setState({ FormItems: FormItems, allFormItems: FormItems, template: selectedTemplate });

        }, (err) => {
            console.log(err);
        });
    }
    private async _getRiskCategoriesData() {
        try {
            const data: any = await utility.genericGetAPICallForList(Config.RiskCategorieswithGroups, 'GET');
            data.forEach((arr: any) => {
                arr.items = arr.riskGroups;
                delete arr.riskGroups;
            });
            const filtereddata = data.filter((f: any) => f.items && f.items.length > 0);
            filtereddata.forEach((element: any) => {
                if (element.items && element.items.length > 0) {
                    element.items.forEach((ChildItem: any) => {
                        ChildItem.riskgrpId = element.id;
                        ChildItem.selectedId = ChildItem.id;
                        ChildItem.id = `${ChildItem.id}_${element.id}`;
                        if (this.props.details && this.props.mode === 'edit') {
                            if (this.props.details.riskgroup && this.props.details.riskgroup.length > 0) {
                                this.props.details.riskgroup.forEach((selectedId: any) => {
                                    if (ChildItem.selectedId === selectedId) {
                                        element.expanded = true;
                                        ChildItem.selected = true;
                                    }

                                });
                            }
                        }
                    });
                }
            });

            this.setState({ allRiskCategories: data });

            if (this.props.mode === 'add') {
                this.setState({ allRiskCategories: data });
            }
        } catch (err) {
            console.log(err);
        }
    }

    treeViewSelectionChanged(e: any) {
        this.syncSelection(e.component);
    }

    treeViewContentReady(e: any) {
        this.syncSelection(e.component);
    }

    syncSelection(treeView: any) {
        const seletedItems = treeView.getSelectedNodes()
            .map((node: any) => node.itemData);

        this.setState(() => ({ seletedItems: seletedItems }));
    }

    public addUpdateItem() {
        //genericPostAPIcallForAdminList
        let dataToPassToService: any = {};
        let swms: any = [];
        if (this.state.swms) {
            this.state.swms.forEach((element: any) => {
                swms.push(element.id);
            });
        }
        let riskArea: any = [];
        if (this.state.RiskArea) {
            this.state.RiskArea.forEach((element: any) => {
                riskArea.push(element.id);
            });
        }
        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.code) {
            this.setState({
                alertMessage: 'Please enter  code',
                showAlert: true
            });
            return;
        }
        if (!this.state.department) {
            this.setState({
                alertMessage: 'Please select  department',
                showAlert: true
            });
            return;
        }
        if (!this.state.worksystem) {
            this.setState({
                alertMessage: 'Please select  Work System Name',
                showAlert: true
            });
            return;
        }

        // if (!this.state.template || this.state.template.length === 0) {
        //     this.setState({
        //         alertMessage: 'Please select template',
        //         showAlert: true
        //     });
        //     return;
        // }
        if (!this.state.woSubcategory) {
            this.setState({
                alertMessage: 'Please select  Activity category',
                showAlert: true
            });
            return;
        }

        if (this.state.riskassessment) {
            if (!this.state.riskForm) {
                this.setState({
                    alertMessage: 'Please select risk form',
                    showAlert: true
                });
                return;
            }
        }
        // if (!this.state.swms || this.state.swms.length === 0) {
        //     this.setState({
        //         alertMessage: 'Please select swms',
        //         showAlert: true
        //     });
        //     return;
        // }

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' type={'converging-spinner'} /> <br /> Loading...</div>,
            hideAlertButton: true,
            showAlert: true
        });
        let tempReadonlyLayer: any = [];
        if (this.state.layerCategory) {
            this.state.layerCategory.forEach((element: any) => {
                tempReadonlyLayer.push(element.id);
            });
        }
        let tempGisLayer: any = [];
        if (this.state.gisLayer) {
            this.state.gisLayer.forEach((element: any) => {
                tempGisLayer.push(element.id);
            });
        }
        let tempTemplate: any = [];
        if (this.state.template) {
            this.state.template.forEach((element: any) => {
                tempTemplate.push(element.id);
            });
        }
        dataToPassToService.Title = this.state.title;
        dataToPassToService.Code = this.state.code;
        dataToPassToService.DepartmentId = this.state.department.id;
        dataToPassToService.Riskassessmentrequired = this.state.riskassessment;
        dataToPassToService.Photorequiredbefore = this.state.befpicreq;
        dataToPassToService.Photorequiredafter = this.state.aftpicreq;
        dataToPassToService.Allowfastcomplete = this.state.fastCompleted;
        dataToPassToService.Allowfastcreate = this.state.fastCreated;
        dataToPassToService.Isactive = this.state.isActive;
        dataToPassToService.Tcp = this.state.tcp;
        dataToPassToService.Allowworkorderreassignment = this.state.allowWorkorderReassignment;
        dataToPassToService.Highrisk = this.state.highRisk;
        dataToPassToService.Subcategories = this.state.woSubcategory.id;
        dataToPassToService.swmsid = swms;
        dataToPassToService.Gislayers = { ReadOnlyLayers: tempReadonlyLayer, Layers: tempGisLayer };
        dataToPassToService.Template = tempTemplate.join();
        dataToPassToService.Teamid = 3;
        dataToPassToService.Tenantid = Config.tenantId;
        dataToPassToService.Userupdated = this.props.currentUser.Email;
        dataToPassToService.WorkSystemName = this.state.worksystem.text;
        dataToPassToService.riskFormIds = this.state.riskForm && (this.state.riskForm.id !== undefined && this.state.riskForm.id !== null) ? [this.state.riskForm.id] : [];
        dataToPassToService.riskGroupIds = this.getRiskRelations();
        // console.log("dataToPassToService", dataToPassToService);
        if (this.props.mode === 'edit') {
            dataToPassToService.ID = this.state.id;
            utility.genericUpdateAPICall(Config.WTlistname, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Activity has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.Usercreated = this.props.currentUser.Email;
            utility.genericPostAPIcall(Config.WTlistname, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Activity has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
        utility.removeItem(Config.WTlistname);
    }

    public clearFilter() {
        this.setState({
            title: '',
            code: '',
            department: '',
            befpicreq: false,
            aftpicreq: false,
            riskassessment: false,
            highRisk: false,
            fastCompleted: false,
            fastCreated: false,
            WorkOrderInSap: false,
            Closepathwayrequest: false,
            allowWorkorderReassignment: false,
            tcp: false,
            swms: [],
            RiskArea: [],
            riskForm: '',
            template: [],
            layerCategory: [],
            gisLayer: [],
            woSubcategory: ''
        });
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
        if (fieldName === "plannerGroup") {
            let tempworkcenter = this.state.workCenterItems;
            let filteredWorkCenter = tempworkcenter.filter((t: any) => t.plannerID === e.selectedItem.id);
            this.setState({
                disableWorkCenter: false,
                tempworkCenterItems: filteredWorkCenter,
                alltempworkCenterItems: filteredWorkCenter,
                workCenter: ''
            });
        }

    }
    private _multiFieldChanged = (values: any, fieldName: string) => {

        this.setState({ [fieldName]: values });
        if (fieldName === "layerCategory") {
            let tempGISlayer = this.state.GISLayersItems;

            if (values && values.length === 1) {
                let filteredWorkCenter = tempGISlayer.filter((t: any) => t.category === values[0].id);
                this.setState({
                    tempGISLayersItems: filteredWorkCenter,
                    alltempGISLayersItems: filteredWorkCenter,
                    gisLayer: []
                });
            } else {
                this.setState({
                    tempGISLayersItems: tempGISlayer,
                    alltempGISLayersItems: tempGISlayer,
                });
            }
        }
    };
    // private _multiFieldChanged = (event: any, fieldName: string) => {
    //     this.setState({ [fieldName]: event });
    //   };

    public getRiskRelations() {
        const riskGroupId: any = [];
        if (this.state.seletedItems && this.state.seletedItems.length > 0) {
            this.state.seletedItems.forEach((riskCat: any) => {
                if (!riskCat.items && riskCat.selected) {
                    riskGroupId.push(riskCat.selectedId);
                }

            });
        }
        return riskGroupId;
    }
    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.props.mode && capitalize(this.props.mode)} Activity`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>

                        <div className='popContentDiv'>
                            {!this.state.loading ?
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Title *"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.title}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                            onKeyDown={() => { }}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Code *"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.code}
                                            onKeyDown={() => { }}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'code')}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectBoxInput
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            showClearButton={true}
                                            items={this.state.departmentItems}
                                            selectedItem={this.state.department}
                                            label='Organisation'
                                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MultiSelect
                                            items={this.state.FormItems}
                                            onChange={(e: any) => this._multiFieldChanged(e, 'template')}
                                            selectedItem={this.state.template}
                                            label="Template"
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            showClearButton={true}
                                            placeholder="Select a value..."
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MultiSelect
                                            items={this.state.GISLayersCategoryItems}
                                            onChange={(e: any) => this._multiFieldChanged(e, 'layerCategory')}
                                            selectedItem={this.state.layerCategory}
                                            label="GIS Layer Category (Readonly Layer)"
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            showClearButton={true}
                                            placeholder="Select a value..."
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>

                                        <MultiSelect
                                            items={this.state.tempGISLayersItems}
                                            onChange={(e: any) => this._multiFieldChanged(e, 'gisLayer')}
                                            selectedItem={this.state.gisLayer}
                                            label="GIS Layer"
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            showClearButton={true}
                                            placeholder="Select a value..."
                                            disabled={this.state.disableGISLayer}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectBoxInput
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            showClearButton={true}
                                            items={this.state.worktypeSubcategoriesItems}
                                            selectedItem={this.state.woSubcategory}
                                            label='Activity Category *'
                                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'woSubcategory')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MultiSelect
                                            items={this.state.swmsItems}
                                            onChange={(e: any) => this._multiFieldChanged(e, 'swms')}
                                            selectedItem={this.state.swms}

                                            label="SWMS"
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            showClearButton={true}
                                            placeholder="Select a value..."
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectBoxInput
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            showClearButton={true}
                                            items={worksystemItems}
                                            selectedItem={this.state.worksystem ? this.state.worksystem : ""}
                                            label='Work System'
                                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'worksystem')}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <CheckBoxInput value={this.state.befpicreq} onValueChanged={(e: any) => this.handleValueChange(e, 'befpicreq')} text={'Before Photo Required'} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <CheckBoxInput value={this.state.aftpicreq} onValueChanged={(e: any) => this.handleValueChange(e, 'aftpicreq')} text={'After Photo Required'} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <CheckBoxInput value={this.state.riskassessment} onValueChanged={(e: any) => this.handleValueChange(e, 'riskassessment')} text={'Site Risk Assessment'} />
                                    </Grid>


                                    <Grid item xs={12} sm={4}>
                                        <CheckBoxInput value={this.state.fastCompleted} onValueChanged={(e: any) => this.handleValueChange(e, 'fastCompleted')} text={'Fast Completed'} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <CheckBoxInput value={this.state.fastCreated} onValueChanged={(e: any) => this.handleValueChange(e, 'fastCreated')} text={'Fast Created'} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <CheckBoxInput value={this.state.highRisk} onValueChanged={(e: any) => this.handleValueChange(e, 'highRisk')} text={'High Risk'} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <CheckBoxInput value={this.state.tcp} onValueChanged={(e: any) => this.handleValueChange(e, 'tcp')} text={'TCP'} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <CheckBoxInput value={this.state.allowWorkorderReassignment} onValueChanged={(e: any) => this.handleValueChange(e, 'allowWorkorderReassignment')} text={'Allow Workorder Reassignment'} />
                                    </Grid>
                                    {(this.state.riskassessment &&
                                        <Grid container spacing={3}>
                                            {!this.state.riskFormloading &&
                                                <Grid item xs={12} sm={6} className='ml-10'>
                                                    <SelectBoxInput
                                                        stylingMode='underlined'
                                                        displayExpr="text"
                                                        valueExpr="id"
                                                        showClearButton={true}
                                                        items={this.state.riskFormItems}
                                                        selectedItem={this.state.riskForm}
                                                        label='Risk Form'
                                                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'riskForm')}

                                                    />

                                                </Grid>
                                            }
                                            <Grid item xs={12} sm={12}>
                                                <div className='workTypeListContainer' >
                                                    {
                                                        (this.state.allRiskCategories && this.state.allRiskCategories.length > 0) && (
                                                            <h3>Risk Groups</h3>
                                                        )
                                                    }

                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={12}>
                                                            {!this.state.loading ?
                                                                <>
                                                                    {(this.state.allRiskCategories && this.state.allRiskCategories.length > 0) &&
                                                                        <>
                                                                            <TreeView
                                                                                id="treeview"
                                                                                ref={this.treeViewRef}
                                                                                width={600}
                                                                                height={320}
                                                                                items={this.state.filteredRiskCategories ? this.state.filteredRiskCategories : this.state.allRiskCategories}
                                                                                selectNodesRecursive={true}
                                                                                selectByClick={false}
                                                                                showCheckBoxesMode={'normal'}
                                                                                selectionMode={'multiple'}
                                                                                onSelectionChanged={this.treeViewSelectionChanged}
                                                                                onContentReady={this.treeViewContentReady}
                                                                                itemRender={renderTreeViewItem}
                                                                                searchMode={this.state.value}
                                                                                searchEnabled={true}
                                                                                searchExpr={'title'}
                                                                            />

                                                                        </>
                                                                    }
                                                                </> :
                                                                <Spinner size='large' label="Loading..." />
                                                            }

                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                :
                                <Spinner size="large" label="Loading all component. Please wait..." />
                            }
                        </div>
                    </ScrollView>

                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.addUpdateItem,
                        }}
                    />
                </Popup>
            </div >
        );
    }
}

function renderTreeViewItem(item: any) {
    return `${item.title}`;
}

