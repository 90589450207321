import * as React from 'react';

import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

import { Config } from '../../../classes/config';
import AlertDialog from '../../common/alertDialog';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import DateBoxInput from '../../common/reusableComponents/DateBox';

export default class AssignMultiple extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        let endaDate = new Date();
        endaDate.setHours(endaDate.getHours() + 2);
        this.state = {
            details: this.props.details,
            Eststartdate: new Date(),
            Estenddate: endaDate,
            crewteam: '',
            showAlert: false,
            alertMessage: '',
            hideAlertButton: false
        };
    }
    public componentDidMount() {
        ////console.log('component load');

    }

    public afterInit() {
        this.setState({
            crewteam: this.props.selectedCrew
        });
    }

    public handleValueChange(e: any, fieldName: string) {
        if (fieldName === 'Eststartdate') {
            let endaDate = new Date(e.value);
            endaDate.setHours(e.value.getHours() + 2);
            this.setState({
                Estenddate: endaDate
            });
        }
        this.setState({ [fieldName]: e.value });
    }

    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    public assignWO() {
        if (!this.state.crewteam) {
            this.setState({
                alertMessage: 'Please select crew',
                showAlert: true
            });
            return;
        }
        if (!this.state.Eststartdate) {
            this.setState({
                alertMessage: 'Please select start date',
                showAlert: true
            });
            return;
        }
        if (!this.state.Estenddate) {
            this.setState({
                alertMessage: 'Please select finish date',
                showAlert: true
            });
            return;
        }
        if (this.state.Eststartdate && this.state.Estenddate) {
            let Eststartdate = new Date(this.state.Eststartdate);
            let Estenddate = new Date(this.state.Estenddate);
            if (Estenddate.getTime() < Eststartdate.getTime()) {
                this.setState({
                    alertMessage: 'Scheduled Start Date should be greater than Scheduled Finish Date',
                    showAlert: true
                });
                return;
            }
        }
        let dataToUpdate = {
            crew: this.state.crewteam,
            startDate: this.state.Eststartdate,
            endDate: this.state.Estenddate,
        }
        this.props.assignMultipleWO(dataToUpdate);
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this._closeDialog()}
            />
        );
    }
    private _closeDialog = (): void => {
        this.setState({ alertMessage: '', showAlert: false });
    }
    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'50vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={"Assign Workorder(s)"}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={() => this.props.onClose()}
                    onShowing={() => this.afterInit()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                        <div className='popContentDiv'>
                            <div>
                                <SelectBoxInput
                                    stylingMode='underlined'
                                    label='Employee'
                                    selectedItem={this.state.crewteam}
                                    displayExpr="text"
                                    valueExpr="id"
                                    items={this.props.crewItems}
                                    onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'crewteam')}
                                    showClearButton={true}
                                />
                            </div>
                            <br />
                            <div>

                                <DateBoxInput
                                    stylingMode='underlined'
                                    label="Schedule Start Date"
                                    value={this.state.Eststartdate ? new Date(this.state.Eststartdate) : null}
                                    type="datetime"
                                    displayFormat={"dd/MM/yy hh:mm a"}
                                    showClearButton={true}
                                    onValueChanged={(e: any) => this.handleValueChange(e, 'Eststartdate')}
                                />
                            </div>
                            <br />
                            <div>
                                <DateBoxInput
                                    stylingMode='underlined'
                                    label="Schedule End Date"
                                    value={this.state.Estenddate ? new Date(this.state.Estenddate) : null}
                                    type="datetime"
                                    displayFormat={"dd/MM/yy hh:mm a"}
                                    showClearButton={true}
                                    onValueChanged={(e: any) => this.handleValueChange(e, 'Estenddate')}
                                />
                            </div>
                        </div>
                    </ScrollView>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: () => this.props.onClose(),
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: () => this.assignWO(),
                        }}
                    />
                </Popup>
            </div>
        );
    }
}

