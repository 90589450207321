import * as React from 'react';
import { CheckBox } from 'devextreme-react/check-box';
interface Props {
    text?: any;
    defaultValue?: string|any;
    value: any;
    disabled?: Boolean|any;
    onValueChanged:any;
  }
export default class CheckBoxInput extends React.Component<Props, any> {

    public state = {
    };

    public render(): React.ReactElement<any> {
        return (
            <CheckBox 
            defaultValue={this.props.defaultValue}
            value={this.props.value}
            text={this.props.text}
            onValueChanged={(e:any) => this.props.onValueChanged(e)}
            disabled={this.props.disabled?true:false}
          />
        );
    }
}


