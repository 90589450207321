import * as React from 'react';
import ArrayStore from 'devextreme/data/array_store';
import List from 'devextreme-react/list';
import SchedulerCard from './SchedulerCard';
import { Popover } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import DateBox from 'devextreme-react/date-box';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import utility from '../../../classes/utility';
export default class SchedulerWorkOrderListView extends React.Component<any, any> {
    public column: any;
    public list: any;
    constructor(props: any) {
        super(props);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.handleShowHildePopOver = this.handleShowHildePopOver.bind(this);
        this.handleCloseShowHildePopOver = this.handleCloseShowHildePopOver.bind(this);
        // this.list = React.createRef();

        this.state = {
            currentDate: this.props.currentDate,
            currentView: this.props.currentView === 'Calendar' ? 'Month' : this.props.currentView === 'Agenda' ? 'Day' : this.props.currentView,
        };
    }

    public componentDidMount() {

        this.setState({
            workorderData: this.props.workorders,
            selectedWorkorderbyCrew: this.props.selectedWorkorderbyCrew,
        })
    }
    public handleGroupChange = (value: any) => {

        this.setState({
            group: value
        })
    }
    public onHorizontalChange = (fieldName: any, event: any) => {
        this.setState({
            [fieldName]: event.newState,
        });
    }
    public onValueChanged(e: any) {
        this.setState({
            currentDate: e.value,
        });
        this.props.onViewDateChange(e.value, this.state.currentView)
    }
    public afterInit() {
        this.props.stopOnCrewSelected();
    }
    public render() {

        const workorderDataOptions = {
            store: new ArrayStore({
                data: this.props.selectedWorkorderbyCrew ? utility._sortItems(this.props.selectedWorkorderbyCrew, 'fieldstatus', true) : this.props.selectedWorkorderbyCrew,
                key: 'Workordernumber',
            }),
            group: 'crewtitle',
            searchExpr: ['worktype', 'Workordernumber', 'text', 'fieldstatus', 'suburb', 'crew', 'priority'],
        };
        if (this.props.onCrewSelected) {
            this.afterInit();
        }
        return (
            <div>
                {this.state.showMoreSettingMenu && this.renderShowMoreSettingMenu()}
                <div className={'ptlr-20'}>Work Orders for crew ({this.props.selectedWorkorderbyCrew && this.props.selectedWorkorderbyCrew.length})</div>
                <div className={'m-10 p-10'}>
                    <div className='displayFlex mb-10'>
                        <div>
                            <DateBox
                                style={{ height: '38px' }}
                                value={this.state.currentDate}
                                stylingMode={'outlined'}
                                useMaskBehavior={true}
                                // displayFormat={'dayOfWeek'}
                                onValueChanged={(e: any) => this.onValueChanged(e)}
                                displayFormat={this.state.currentView === 'Month' ? 'month' : 'longDate'}
                                type="date" />
                        </div>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={this.handleShowHildePopOver}
                            endIcon={<ExpandMoreIcon className={'primary'} />}
                            style={{ height: '38px' }}
                        >
                            {this.state.currentView}
                        </Button>
                        {/* <div className='mapBtnAssign selectedMapBtn'> <CheckBoxInput value={this.state.chkboxAssign ? true : false} onValueChanged={() => this.onValueChanged("chkboxAssign")} text="Assigned" /></div> */}
                    </div>
                    <div
                        //      onDragEnter={(e:any) => this.props.onDragEnter(e)}
                        // onDragLeave={(e:any) => this.props.onDragLeave(e)}
                        onDragOver={(e: any) => this.props.onDragOver(e)}
                        onDrop={(e: any) => this.props.onDrop(e)}
                    >
                        {!this.props.onCrewSelected ?
                            <List
                                // ref={this.list}
                                // className='listView'
                                selectionMode="single"
                                dataSource={workorderDataOptions}
                                grouped={true}
                                searchEnabled={true}
                                itemRender={(e: any) => this.renderListItem(e, this.props)}
                                elementAttr={{ class: 'list' }}
                                scrollingEnabled={true}
                            >
                            </List>
                            :
                            <LoadIndicator id="large-indicator" height={60} width={60} />
                        }
                    </div>
                </div>
            </div>
        );
    }


    public renderListGroup(group: any, id: any) {

        return <div className="city">{group.key}    </div>;
    }

    public renderListItem(woData: any, props: any) {

        return (
            <SchedulerCard
                woData={woData}
                // view={'Card'}
                view={props.view}
                viewItem={(e: any) => props.viewItem(e)}
                viewWO={(e: any) => props.viewWO(e)}
                zoomToSelectWO={(e: any) => props.zoomToSelectWO(e)}
                draggingGroupName={props.draggingGroupName}
                onItemDragStart={(e: any) => props.onItemDragStart(e)}
                onItemDragEnd={(e: any) => props.onItemDragEnd(e)}
                mapView={props.mapView}
                schedulerlistDraggingAllowed={true}
                onDragStart={(e: any, woData: any) => props.onDragStart(e, woData)}
                dashBoardCard={props.dashBoardCard}
                _onShowNotesPanel={() => props._onShowNotesPanel(woData)}
                showUnassignWO={true}
                unassignWO={() => this.props.unassignWO(woData)}
            />
        );
    }

    public handleShowHildePopOver(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            anchorEl: event.currentTarget,
            showMoreSettingMenu: true
        })
    }
    public handleCloseShowHildePopOver(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            anchorEl: null,
            showMoreSettingMenu: false
        })
    }
    public onViewChanged(e: any) {

        this.setState({
            currentView: e.itemData.text,
            showMoreSettingMenu: false
        });

        this.props.onViewDateChange(this.state.currentDate, e.itemData.text);
    }
    public renderShowMoreSettingMenu() {
        return (
            <Popover
                id={"i"}
                open={this.state.showMoreSettingMenu}
                anchorEl={this.state.anchorEl}
                onClose={this.handleCloseShowHildePopOver}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

            >
                <div style={{ width: '120px' }}>
                    <List
                        // ref={this.list}
                        // className='listView'
                        selectionMode="single"
                        dataSource={[
                            { id: 'Day', text: 'Day' },
                            { id: 'Work Week', text: 'Work Week' },
                            { id: 'Week', text: 'Week' },
                            { id: 'Month', text: 'Month' }
                        ]}
                        onItemClick={(e: any) => this.onViewChanged(e)}
                        elementAttr={{ class: 'list' }}
                    >
                    </List>
                </div>
            </Popover>
        )
    }
}