import * as React from 'react';
import global from '../../classes/global';


export interface ITestFrameState {
    iFramePanelOpenedforDetail: boolean;
    iFrameUrl: string;
}

export default class AssetMapforDetail extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            iFramePanelOpenedforDetail: false,
            iFrameUrl: global.AssetMapURL
        };
    }

    public _onIframeLoaded() {
        window.addEventListener('message', this.handleIframeTaskfordetail);

    }

    public componentDidMount() {
        //console.log(this.props);
    }

    public handleIframeTaskfordetail = (e: any) => {

        // if (e.origin !== 'https://worxonline.sharepoint.com') {
        //     return;
        // }
        // console.log(e.data);
        if (e.data.command === 'asset selected') {
            // debugger;
            let assetData = e.data.assets;
            let mapProps = e.data.assets[0];
            let properties = JSON.parse(mapProps.properties);

            if (this.props.selectMode === true) {
                this.props.selectAssets(properties);
                window.removeEventListener("message", this.handleIframeTaskfordetail, false);
            } else {
                //    console.log({properties});
                //MYDATA_CATEGORY
                let assetNumber = properties.UFI ? properties.UFI : properties.UFI_2 ? properties.UFI_2 : properties.UFI_US
                let assetType = properties.GmlID.split('.');

                let location = window.location.origin + window.location.pathname + "#/AssetDetail?ASSETNO="
                let AssetDetail = location + assetNumber + '&compkey=' + properties.ASSET_ID + "&assetType=" + assetType[0];
                //let link = "/AssetDetail?ASSETNO=" + props.dataItem.AssetNumber+'&Category='+ category;
                window.removeEventListener("message", this.handleIframeTaskfordetail, false);
                window.open(AssetDetail, "_self");

            }
            this.setState({
                iFramePanelOpenedforDetail: false
            });
        }
        // if(e.data.command !== 'asset selected'){
        //     window.removeEventListener("message", this.handleIframeTaskfordetail, false);
        // }


    };


    public render(): React.ReactElement<any> {
        return (
            <div style={{ height: '100%' }}>
                {/* <IFramePanel url={this.state.iFrameUrl}
                    type={PanelType.large}
                    headerText="Select Assets from Map"
                    closeButtonAriaLabel="Close"
                    isOpen={this.props.iFramePanelOpenedforDetail}
                    onDismiss={() => this.props.onClose()}
                    iframeOnLoad={this._onIframeLoaded.bind(this)} /> */}
                <iframe
                    src={this.state.iFrameUrl}
                    allowFullScreen={true}
                    width={'100%'}
                    style={{ height: '100vh' }}
                    onLoad={this._onIframeLoaded.bind(this)}
                />
            </div>
        );
    }
}
