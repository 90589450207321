import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditRisk from './AddEditRisk';
import moment from 'moment';
let _items: any = [];

export default class Risk extends React.Component<any, any> {

  public currentUser: any;
  public columns: any;
  public myRef: any;
  public swmsItems: any = [];
  public dataSource: any = [];
  constructor(props: any) {
    super(props);
    this.DeleteItem = this.DeleteItem.bind(this);
    this.EditItemModal = this.EditItemModal.bind(this);
    this.DeleteDialog = this.DeleteDialog.bind(this);
    //this._getSwmsData = this._getSwmsData.bind(this);
    this.myRef = React.createRef();
    this.swmsItems = [];
    this.dataSource = [];
    this.currentUser = this.props.currentUser;
    // if (this.props.roleId !== this.props.tenantConfig.Config.AdminRoleId) {
    //   window.open('#/AccessDenied', '_self');
    // }
    this.columns = [
      {
        field: 'id',
        title: 'ID',
        width: 80,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'title',
        title: 'Title',
        width: 250,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'formattedRiskAreas',
        title: 'Risk Groups',
        // width: 280,
        type: 'string',
      },
      {
        field: 'swms',
        title: 'Safety Documents',
        // width: 280,
        type: 'string',
      },

      {
        field: 'Isactive',
        title: 'Active',
        // width: 160,
        type: 'boolean',
        allowHiding: false,
      },
      {
        field: 'Tcp',
        title: 'TCP',
        // width: 280,
        type: 'boolean',
      },
      {
        field: 'updatedOn',
        title: 'Updated',
        width: 160,
        type: 'string',
        visible: true,
        allowHiding: true
      },

      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visible: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created',
        width: 160,
        type: 'string',
        visible: false,
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        visible: false,
        allowHiding: true
      },
    ];
    this.state = {
      items: _items,
      columns: this.columns
    };
  }

  public componentDidMount() {
    this._getItemData();
  }

  public DeleteItem(id: any) {
    this.setState({
      alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
      hideAlertButton: true,
      showAlert: true,
      showDeleteAlert: false,
    });

    utility.genericDeleteAPICall(Config.NewRiskList, id).then((data: any) => {
      this.setState({
        alertMessage: 'Risk has been deleted.',
        showAlert: true,
        showDeleteAlert: false,
        hideAlertButton: false,
        loading: false
      });
      this._getItemData()
    }, (err) => {
      let errorMsg = "Cannot delete risk. Please try again later.";
      if (errorMsg.toLowerCase().indexOf("foreign key violation")) {
        errorMsg = "Cannot delete risk as it may be associated with risk groups or risk control measures. Please remove the association with other  entities before deleting the risk";
      }
      this.setState({
        alertMessage: errorMsg,
        showAlert: true,
        showDeleteAlert: false,
        hideAlertButton: false,
      });
    });

  }

  // edit activty modal

  private EditItemModal(item: any) {
    this.setState({ showModal: true, details: item, mode: 'edit' });
  }

  public renderNewModal() {
    return (
      <AddEditRisk
        showModal={this.state.showModal}
        onClose={() => this._closeModal()}
        onClick={() => this._getItemData()}
        message={(e: any) => this.message(e)}
        currentUser={this.currentUser}
        mode={this.state.mode}
        details={this.state.details}
        Client={this.props.aadHttpClient}
      // swmsItems={this.state.swmsItems}
      />
    );
  }
  private _showModal = (): void => {

    this.setState({ showModal: true, details: null, mode: 'add' });
  }

  private _closeModal = (): void => {
    this.setState({ showModal: false });
  }

  public message(message: any) {
    // //console.log(message);
    this.setState({
      alertMessage: message + ' successfully',
      showAlert: true
    });
  }
  private _getItemData() {
    this.setState({
      loading: true,
      showModal: false
    });
    utility.genericGetAPICallForList(Config.GetAllRisksWithRiskGroups, 'GET').then(
      (data: any) => {
        _items = [];
        if (data.length <= 0) {
          this.setState({
            alertMessage: 'No results found for this criteria',
            showAlert: true
          });
          this.setState({ items: _items, loading: false });
          return;
        }
        else {
          for (let r of data) {
            const tempSwms: any = [];
            if (r.swms) {
              r.swms.forEach((r: any) => {
                tempSwms.push(r.name);
              });

            }
            let RiskGroupTitle: String = '';
            if (r.riskGroups && r.riskGroups.length > 0) {
              r.riskGroups.forEach((riskgrp: any) => {
                RiskGroupTitle += riskgrp.title + ',';
              });
              RiskGroupTitle = RiskGroupTitle.substring(0, RiskGroupTitle.length - 1);
            }
            _items.push({
              id: r.id,
              title: r.title ? r.title : '',
              swmsid: r.swmsIds,
              formID: r.riskFormId,
              riskGroupId: r.riskGroupIds,
              riskGroup: r.riskGroups ? r.riskGroups : '',
              formattedRiskAreas: RiskGroupTitle,
              Isactive: r.isActive ? true : false,
              Tcp: r.tcp ? r.tcp : false,
              updatedBy: r.updatedBy ? r.updatedBy : '',
              updatedOn: r.updated ? moment(r.updated).format("DD/MM/yyyy HH:mm a") : '',
              createdBy: r.createdBy ? r.createdBy : '',
              createdOn: r.created ? moment(r.created).format("DD/MM/yyyy HH:mm a") : '',
              created: r.created,
              riskScore: r.riskScore ? r.riskScore : '',
              swms: tempSwms ? tempSwms.join() : '',
              hierarchyId: r.hierarchyId ? r.hierarchyId : ''
            });
          }
        }
        _items = utility._sortItems(_items, 'title', false);

        this.setState({ items: _items, callAfterInit: true, loading: false });

        //console.log(_items);
      }, (err: any) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }

  public setSelection = (id: any) => {
    this.setState({
      selectedRow: id
    });
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }

  public render(): React.ReactElement<any> {
    return (
      <React.Fragment >
        {this.renderNewModal()}
        {this.state.showDeleteAlert && this.DeleteAlert()}
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}> Risks </span>
          </div>
        </div>
        <div>
          {!this.state.loading ?
            <div ref={this.myRef}>
              <DataTable
                style={{ width: '100%' }}
                filteralbe={true}
                groupable={true}
                rows={this.state.items}
                columns={this.state.columns}
                fileName={'Risks'}
                columnChooser={'risksColumns'}
                selectionMode={false}
                showHideColumnMenu={true}
                wordWrapEnabled={true}
                callAfterInit={this.state.callAfterInit}
                stopCommsAfterInit={() => this.stopCommsAfterInit()}
                loading={this.state.loading}
                allowExportSelectedData={false}
                showNewModal={() => this._showModal()}
                deleteItem={(a: any) => this.DeleteDialog(a)}
                editItem={(a: any) => this.EditItemModal(a)}
                // viewFile={(e)=>this.viewFile(e)}
                showCommandBtn={true}
                showNewBtn={true}
                hideNewBtn={this.state.showModal || this.state.showEditModal || this.props.hideNewBtn}
                showViewDetailBtn={false}
                defaultFilterValue={true}
              />
            </div>
            :
            <Spinner size='large' type={'converging-spinner'} className="loader" />
          }

        </div>
      </React.Fragment >
    );
  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        hideAlertButton={this.state.hideAlertButton}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }
  public DeleteDialog(item: any) {
    // //console.log({ item });
    this.setState({
      DeletealertMessage: 'Are you sure you want to delete?',
      showDeleteAlert: true,
      deleteItem: item
    });
  }
  public DeleteAlert() {
    return (
      <DeleteDialog
        showDialog={this.state.showDeleteAlert}
        message={this.state.DeletealertMessage}
        title={"worxOnline"}
        onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
        onOkButton={() => this.DeleteItem(this.state.deleteItem)}
        onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
      />
    );
  }

}

